import React, { FC } from 'react';
import { Routes } from '@pages/constants';
import Tooltip from '@components/Tooltip';
import Typography from '@components/Typography';
import { useIntl } from 'react-intl';
import SettingsIcon from '../assets/settings.svg';
import LogoutButton from '../assets/logout.svg';
import ProfileIcon from '../assets/profile.svg';
import { HeaderMenuItem, ProfileMenuButton, ProfileMenuLink, ProfileTooltipInner } from '../styles';

interface IProfileMenuItemProps {
    logout: () => void;
}

interface IProfileTooltipContentProps {
    logout: () => void;
}

const ProfileTooltipContent: FC<IProfileTooltipContentProps> = ({ logout }) => {
    const intl = useIntl();

    return (
        <ProfileTooltipInner>
            <ProfileMenuLink to={Routes.profile + Routes.personal}>
                <Typography variant="regular14">{intl.formatMessage({ defaultMessage: 'Профиль' })}</Typography>
                <SettingsIcon />
            </ProfileMenuLink>
            <ProfileMenuButton onClick={logout}>
                <Typography variant="regular14">{intl.formatMessage({ defaultMessage: 'Выход' })}</Typography>
                <LogoutButton />
            </ProfileMenuButton>
        </ProfileTooltipInner>
    );
};

const ProfileMenuItem: FC<IProfileMenuItemProps> = ({ logout }) => (
    <HeaderMenuItem>
        <Tooltip
            interactive
            placement="bottom-end"
            offset={[20, 20]}
            content={<ProfileTooltipContent logout={logout} />}
        >
            <ProfileIcon />
        </Tooltip>
    </HeaderMenuItem>
);

export default ProfileMenuItem;
