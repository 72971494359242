import React, { FC, useEffect, useState } from 'react';
import {
    AllNavLink,
    Header,
    NotificationsList,
    Title,
    Wrapper,
} from '@components/Header/components/NotificationWindow/styles';
import { Routes } from '@pages/constants';
import NotificationRow from '@components/Header/components/NotificationRow';
import { IFeedItem } from '@store/Feed/types';
import useClickOutside from '@hooks/useClickOutside';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';

interface INotificationWindow {
    feeds: IFeedItem[];
    setIsShowWindowNotifications: (arg: boolean) => void;
    isShowWindowNotifications: boolean;
}

const NotificationWindow: FC<INotificationWindow> = ({
    isShowWindowNotifications,
    feeds,
    setIsShowWindowNotifications,
}) => {
    const intl = useIntl();
    const history = useHistory();
    const [savedFeeds, setSavedFeeds] = useState<IFeedItem[]>([]);

    const hideWindowNotification = () => setIsShowWindowNotifications(false);

    const windowRef = useClickOutside(() => {
        hideWindowNotification();
    });

    useEffect(() => {
        if (feeds.length > 0) {
            setSavedFeeds(feeds);
        }
    }, [feeds]);

    const onClickAllFeeds = () => {
        history.push(Routes.feeds);
        hideWindowNotification();
    };

    if (isShowWindowNotifications) {
        return (
            <Wrapper ref={windowRef}>
                <Header>
                    <Title>{intl.formatMessage({ defaultMessage: 'Уведомления' })}</Title>
                    <AllNavLink onClick={onClickAllFeeds}>{intl.formatMessage({ defaultMessage: 'Все' })}</AllNavLink>
                </Header>
                <NotificationsList>
                    {savedFeeds.map((feed) => (
                        <NotificationRow hideWindowNotification={hideWindowNotification} key={feed.id} feed={feed} />
                    ))}
                </NotificationsList>
            </Wrapper>
        );
    }
    return null;
};

export default NotificationWindow;
