import React, { FC } from 'react';
import Typography, { TTypographyVariant } from '@components/Typography';
import colors from '@theme/colors';
import { nameToInitialsFormatter } from '@utils/formatters';
import styled, { css } from 'styled-components';

// eslint-disable-next-line no-shadow
export enum AVATAR_SIZE {
    xs = 'xs',
    lg = 'lg',
    md = 'md',
    sm = 'sm',
}

// eslint-disable-next-line no-shadow
export enum AVATAR_VARIANT {
    dark = 'dark',
    light = 'light',
}

interface IAvatarProps {
    size: AVATAR_SIZE;
    name: string;
    variant: AVATAR_VARIANT;
    isColumnPosition?: boolean;
    className?: string;
}

const sizes = {
    [AVATAR_SIZE.xs]: 25,
    [AVATAR_SIZE.sm]: 30,
    [AVATAR_SIZE.md]: 36,
    [AVATAR_SIZE.lg]: 50,
};

const colorsAvatar = {
    [AVATAR_VARIANT.dark]: colors.base(),
    [AVATAR_VARIANT.light]: colors.lightGrey(),
};

const sizeFontToSizeAvatar = {
    [AVATAR_SIZE.xs]: 'regular12',
    [AVATAR_SIZE.sm]: 'regular12',
    [AVATAR_SIZE.md]: 'regular12',
    [AVATAR_SIZE.lg]: 'title',
};

export const AvatarWrapper = styled.div<Pick<IAvatarProps, 'size' | 'variant'>>`
    ${({ size, variant }) => css`
        width: ${sizes[size]}px;
        height: ${sizes[size]}px;
        border-radius: ${sizes[size]}px;
        background-color: ${colorsAvatar[variant]};
    `}
    display: flex;
    flex-shrink: 0;
    margin-bottom: ${({
        // @ts-ignore
        isColumnPosition,
    }) => (isColumnPosition ? '8px' : '0')};
`;

const MerchantName = styled(Typography)`
    color: ${({ theme, color }) => (color === 'dark' ? theme.colors.lightGrey() : theme.colors.base())};
    margin: auto;
`;

const Avatar: FC<IAvatarProps> = ({ className, size, name, variant, isColumnPosition = true }) => {
    const sizeFont: TTypographyVariant = sizeFontToSizeAvatar[size] as any;

    return (
        <AvatarWrapper // @ts-ignore
            isColumnPosition={isColumnPosition}
            variant={variant}
            size={size}
            className={className}
        >
            <MerchantName color={variant} variant={sizeFont}>
                {nameToInitialsFormatter(name)}
            </MerchantName>
        </AvatarWrapper>
    );
};
export default Avatar;
