import styled from 'styled-components';
import Typography from '@components/Typography';
import { COLOR_BRIGHTNESS } from '@theme/utils';
import LogoIcon from '@assets/images/logo.svg';

export const Wrapper = styled.div`
    margin: 0 auto;
    width: 100%;
    height: 100vh;
    flex-direction: column;
    ${({ theme }) => theme.mixins.flexCenterCenter};

    @media ${({ theme }) => theme.breakpoints.sm} {
        max-width: 290px;
    }
`;

export const Logo = styled(LogoIcon)`
    margin-bottom: 47px;
`;

export const AuthTitle = styled(Typography).attrs({ variant: 'title', center: true })`
    margin-bottom: 17px;
`;

export const AuthInner = styled.div`
    width: 100%;
    max-width: 342px;
    ${({ theme }) => theme.mixins.flexCenter};
    flex-direction: column;
`;

export const AuthDescription = styled(Typography).attrs({ variant: 'regular12', center: true })`
    max-width: 330px;
    margin: 0 auto;
    color: ${({ theme }) => theme.colors.base(COLOR_BRIGHTNESS.PRE_MEDIUM)};
`;

export const FormWrapper = styled.div`
    width: 100%;
    margin-top: 23px;
`;

export const FormInner = styled.form`
    width: 100%;
`;

export const TimeoutText = styled(Typography).attrs({ variant: 'regular12', center: true })`
    margin-top: 15px;
    color: ${({ theme }) => theme.colors.base(COLOR_BRIGHTNESS.PRE_MEDIUM)};
`;

export const AuthButtonsContainer = styled.div`
    ${({ theme }) => theme.mixins.flexCenter};
    justify-content: space-between;
    margin-top: 50px;
    width: 100%;
`;
