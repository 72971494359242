import React, { FC } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { IUserProfile } from '@store/Auth/types';
import MainLayout from '@layouts/Main';
import Auth from '@pages/Auth';
import { Routes } from '@pages/constants';
import Dashboard from '@pages/Dashboard';
import Marketplace from '@pages/Marketplace';
import Introduce from '@pages/Introduce';
import Shop from '@pages/Shop';
import Payment from '@pages/Payment';
import OrderContainer from '@pages/OrderContainer';
import Profile from '@pages/Profile';
import Counterparty from '@pages/Counterparty';
import Feeds from '@pages/Feeds';
import Claim from '@pages/Claim';
import Refund from '@pages/Refund';

interface IAuthRouterProps {
    user: IUserProfile;
}

interface INotAuthRouterProps {}

export const AuthRouter: FC<IAuthRouterProps> = ({ user }) => {
    const isNewUser = !user?.firstName && !user?.lastName;

    return (
        <>
            {isNewUser ? (
                <Router>
                    <Switch>
                        <Route path={Routes.introduce}>
                            <Introduce />
                        </Route>
                        <Redirect to={Routes.introduce} />
                    </Switch>
                </Router>
            ) : (
                <Router>
                    <MainLayout>
                        <Switch>
                            <Route path={Routes.profile} component={Profile} />
                            <Route path={`${Routes.counterparty}/:counterpartyId`} component={Counterparty} />
                            <Route path={`${Routes.dashboard}${Routes.shop}/:merchantId`} component={Shop} />
                            <Route path={Routes.dashboard} component={Dashboard} />
                            <Route path={`${Routes.payment}/:payerUserId`} component={Payment} />
                            <Route path={Routes.marketplace} exact component={Marketplace} />
                            <Route path={Routes.feeds} exact component={Feeds} />
                            {/* роут для случаев, когда переход по нажатию на фид в фоне */}
                            <Route path={`${Routes.feeds}/:pushFeedId`} exact component={Feeds} />
                            <Route
                                path={`${Routes.ordercontainer}/:orderContainerId${Routes.refundCreate}`}
                                component={Refund}
                            />
                            <Route path={`${Routes.ordercontainer}/:orderContainerId`} component={OrderContainer} />
                            {/* когда переход на страницу создания заявки на платежи со страницы мерчанта */}
                            <Route path={`${Routes.claim}/:counterpartyId/:merchantId`} component={Claim} />
                            <Route path={`${Routes.claim}/:counterpartyId`} component={Claim} />
                            <Redirect to={Routes.dashboard} />
                        </Switch>
                    </MainLayout>
                </Router>
            )}
        </>
    );
};

export const NotAuthRouter: FC<INotAuthRouterProps> = () => (
    <Router>
        <Route path={Routes.auth} component={Auth} />
        <Redirect to={Routes.auth} />
    </Router>
);
