import React, { FC, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import Typography from '@components/Typography';
import IconClose from '@assets/icons/close-square.svg';
import { useIntl } from 'react-intl';

interface IToast {
    isShowToast: boolean;
    setIsShowToast: (isShow: boolean) => void;
    onClick: () => void;
    title: string;
    cancelFunc?: () => void;
    delayClosed?: number; // ms
    funcAfterClose?: () => void;
}

const Toast: FC<IToast> = ({
    funcAfterClose,
    onClick,
    title,
    cancelFunc,
    isShowToast,
    delayClosed = 5000,
    setIsShowToast,
}) => {
    const intl = useIntl();
    const onClose = useCallback(() => {
        setIsShowToast(false);
    }, [setIsShowToast]);

    useEffect(() => {
        if (isShowToast) {
            setTimeout(() => {
                onClose();
                if (funcAfterClose) {
                    funcAfterClose();
                }
            }, delayClosed);
        }
    }, [delayClosed, funcAfterClose, isShowToast, onClose]);

    const onClickToast = () => {
        onClick();
        onClose();
    };

    if (isShowToast) {
        return (
            <ToastWrapper>
                <MainButton isHaveCancel={Boolean(cancelFunc)} type="button" onClick={onClickToast}>
                    <Title>{title}</Title>
                </MainButton>
                <ButtonClose onClick={onClose}>
                    <IconClose />
                </ButtonClose>
                {cancelFunc && (
                    <ButtonCancel onClick={cancelFunc}>
                        {intl.formatMessage({ defaultMessage: 'Отменить' })}
                    </ButtonCancel>
                )}
            </ToastWrapper>
        );
    }

    return null;
};

export default Toast;

const ToastWrapper = styled.div`
    position: fixed;
    z-index: 10;
    right: 50px;
    top: 50px;
    width: 457px;
    max-height: 100px;
    border-radius: ${({ theme }) => theme.decorations.borderRadius.base}px;
    box-shadow: ${({ theme }) => theme.decorations.boxShadow.secondary};
    background: ${({ theme }) => theme.colors.base()};
    ${({ theme }) => theme.mixins.flexCenter}
    @media ${({ theme }) => theme.breakpoints.sm} {
        width: 95%;
        right: 10px;
    }
`;

interface IMainButton {
    isHaveCancel: boolean;
}

export const MainButton = styled.button<IMainButton>`
    width: 100%;
    height: 100%;
    padding: 28px ${({ isHaveCancel }) => (isHaveCancel ? '161px' : '80px')} 28px 30px;
`;

export const Title = styled(Typography).attrs({ variant: 'regular14' })`
    color: ${({ theme }) => theme.colors.white()};
    width: 100%;
`;

export const ButtonClose = styled.button`
    position: absolute;
    right: 24px;
    top: 28px;
    width: 24px;
    svg {
        path {
            stroke: ${({ theme }) => theme.colors.white()};
        }
    }
`;

export const ButtonCancel = styled.button`
    ${({ theme }) => theme.mixins.getTypography('regular14')}
    color: ${({ theme }) => theme.colors.yellow()};
    margin-left: 16px;
    width: 65px;
    position: absolute;
    right: 80px;
    tp: 32px;
`;
