import React from 'react';
import {
    SkeletonDate,
    SkeletonFeedItemWrapper,
    SkeletonIcon,
    SkeletonTitle,
} from '@components/Widgets/FeedWidget/components/SkeletonFeedItem/styles';

const SkeletonFeedItem = () => (
    <SkeletonFeedItemWrapper>
        <SkeletonIcon />
        <SkeletonTitle />
        <SkeletonDate />
    </SkeletonFeedItemWrapper>
);

export default SkeletonFeedItem;
