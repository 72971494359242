import styled from 'styled-components';
import Typography from '@components/Typography';

export const ReasonRefundWrapper = styled.div`
    padding: 40px 0;
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 16px;
`;

export const RadioWrapper = styled.label`
    ${({ theme }) => theme.mixins.flexCenter};
    grid-column-gap: 10px;
`;

export const ReasonLabel = styled(Typography).attrs({ variant: 'regular14' })``;
