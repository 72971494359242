import React, { FC, InputHTMLAttributes } from 'react';
import styled from 'styled-components';

interface IRadioProps extends InputHTMLAttributes<HTMLInputElement> {
    className?: string;
    checked: boolean;
}

interface IStyledRadioProps {
    checked: boolean;
}

const Radio: FC<IRadioProps> = ({ className, checked, ...props }) => (
    <RadioContainer className={className}>
        <HiddenInput checked={checked} {...props} type="radio" />
        <StyledRadio checked={checked}>
            <RadioIcon />
        </StyledRadio>
    </RadioContainer>
);

export default Radio;

const RadioContainer = styled.div`
    display: inline-block;
    vertical-align: middle;
`;

const HiddenInput = styled.input`
    display: none;
`;

const RadioIcon = styled.div`
    width: 10px;
    height: 10px;
    border-radius: 10px;
    background-color: ${({ theme }) => theme.colors.white()};
    transition: ${({ theme }) => theme.decorations.transition?.base};
`;

const StyledRadio = styled.div<IStyledRadioProps>`
    ${({ theme }) => theme.mixins.flexCenterCenter};
    width: 22px;
    height: 22px;
    background-color: ${({ checked, theme }) => (checked ? theme.colors.yellow() : 'transparent')};
    border: 2px solid ${({ checked, theme }) => (checked ? theme.colors.yellow() : theme.colors.grey())};
    border-radius: 100%;
    transition: ${({ theme }) => theme.decorations.transition?.base};
    cursor: pointer;

    ${RadioIcon} {
        opacity: ${(props) => (props.checked ? 1 : 0)};
    }
`;
