import styled from 'styled-components';

export const ClientListWrapper = styled.div`
    border-radius: ${({ theme }) => theme.decorations.borderRadius.base}px;
    overflow: hidden;
    border: 1px solid ${({ theme }) => theme.colors.lightGrey()};
    @media ${({ theme }) => theme.breakpoints.sm} {
        border-radius: 0;
    }
`;

export const LetterBorder = styled.div`
    padding: 5px 0 5px 26px;
    background: ${({ theme }) => theme.colors.lightGrey()};
`;
