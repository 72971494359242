import React, { FC, useEffect } from 'react';
import { FormFieldTypeEnum } from '@components/FormField';
import {
    BASKET_ITEMS_TYPES,
    COMMON_DICTIONARY_BASKET_ITEM_TYPES,
    ORDER_ITEM_FIELDS,
    TVatType,
} from '@store/Order/types';
import { useField } from 'react-final-form';
import { FormApi } from 'final-form';
import { InputTypeEnum } from '@components/Input';
import { IMeasure } from '@store/Merchant/types';
import { useIntl } from 'react-intl';
import {
    FieldAmountItem,
    FieldArticleItem,
    FieldMeasureItem,
    FieldNameItem,
    FieldQuantityItem,
    FieldTypeItem,
    FieldVatItem,
} from './styles';

interface IInputsModalAddOrderItem {
    vatTypes: TVatType[] | undefined;
    measures: IMeasure[] | undefined;
    form: FormApi;
}

const InputsModalAddOrderItem: FC<IInputsModalAddOrderItem> = ({ measures, form, vatTypes }) => {
    const intl = useIntl();
    const {
        input: { value: currentType },
    } = useField(ORDER_ITEM_FIELDS.type);

    const isServiceItem = currentType === COMMON_DICTIONARY_BASKET_ITEM_TYPES.SERVICE;

    useEffect(() => {
        if (isServiceItem) {
            form.mutators.setServiceFields();
        }
    }, [form.mutators, isServiceItem]);

    return (
        <>
            <FieldNameItem
                fieldType={FormFieldTypeEnum.input}
                name={ORDER_ITEM_FIELDS.name}
                label={intl.formatMessage({ defaultMessage: 'Наименование' })}
            />
            <FieldTypeItem
                fieldType={FormFieldTypeEnum.select}
                options={BASKET_ITEMS_TYPES}
                name={ORDER_ITEM_FIELDS.type}
                label={intl.formatMessage({ defaultMessage: 'Тип' })}
            />
            <FieldArticleItem
                fieldType={FormFieldTypeEnum.input}
                name={ORDER_ITEM_FIELDS.sku}
                label={intl.formatMessage({ defaultMessage: 'Артикул' })}
            />
            <FieldAmountItem
                allowDecimals
                fieldType={FormFieldTypeEnum.inputCurrency}
                name={ORDER_ITEM_FIELDS.amount}
                label={intl.formatMessage({ defaultMessage: 'Цена за единицу' })}
            />
            <FieldQuantityItem
                fieldType={FormFieldTypeEnum.input}
                typeInput={InputTypeEnum.numeric}
                name={ORDER_ITEM_FIELDS.quantity}
                label={intl.formatMessage({ defaultMessage: 'Количество' })}
                disabled={isServiceItem}
            />
            <FieldVatItem
                required
                fieldType={FormFieldTypeEnum.select}
                name={ORDER_ITEM_FIELDS.vatCode}
                options={vatTypes?.map(({ name: itemName, id, code }) => ({
                    label: itemName,
                    value: code,
                    id,
                }))}
                label={intl.formatMessage({ defaultMessage: 'Ставка НДС' })}
            />
            <FieldMeasureItem
                disabled={isServiceItem}
                required
                fieldType={FormFieldTypeEnum.select}
                name={ORDER_ITEM_FIELDS.measureCode}
                options={measures?.map(({ id, name }) => ({ label: name, value: id }))}
                label={intl.formatMessage({ defaultMessage: 'Единица измерения' })}
            />
        </>
    );
};

export default InputsModalAddOrderItem;
