import styled from 'styled-components';
import Typography from '@components/Typography';

export const CompositionWrapper = styled.div``;

export const CompositionTitle = styled(Typography).attrs({ variant: 'subheading' })`
    margin-bottom: 24px;
    @media ${({ theme }) => theme.breakpoints.md} {
        margin-bottom: 20px;
    }
`;

export const CompositionItems = styled.div`
    border-top: 1px solid ${({ theme }) => theme.colors.lightGrey()};
`;
