import styled from 'styled-components';
import Typography from '@components/Typography';
import { NavLink } from 'react-router-dom';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 12px;
`;

export const WrapTitle = styled.div`
    ${({ theme }) => theme.mixins.flexCenter}
`;

export const Heading = styled.div`
    width: 100%;
    position: relative;
    ${({ theme }) => theme.mixins.flexCenter}
    justify-content: space-between;
    padding-bottom: 20px;
`;

export const Title = styled(Typography).attrs({ variant: 'title' })`
    line-height: ${({ theme }) => theme.typography.bigTitle.fontSize}px;

    @media ${({ theme }) => theme.breakpoints.ld} {
        font-size: ${({ theme }) => theme.typography.subheading.fontSize}px;
    }
`;

export const MoreBtn = styled(NavLink)`
    text-decoration: none;
    color: ${({ theme }) => theme.colors.grey()};
`;
