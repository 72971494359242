import React, { FC } from 'react';
import { Form } from 'react-final-form';
import FormField, { FormFieldTypeEnum } from '@components/FormField';
import ButtonsNextPrev from '@pages/Claim/components/ButtonsNextPrev';
import { MERCHANT_FIELDS } from '@store/Merchant/contants';
import { IMcc, IMerchant } from '@store/Merchant/types';
import { removePlusAndGapsToPhone } from '@utils/formatters';
import useValidationSchema from '@hooks/useValidationSchema';
import { merchantPaymentSchema } from '@pages/Claim/validations';
import { createMerchant, setSelectedMerchantId, updateMerchant } from '@store/Merchant';
import { useAppDispatch } from '@store';
import { useParams } from 'react-router-dom';
import { createClaim, setSelectedClaimId } from '@store/Claims';
import { IClaim } from '@store/Claims/types';
import useDictionary from '@hooks/useDictionarty';
import { DICTIONARY_TYPES } from '@store/Dictionaries/constants';
import useLoadingSubmit from '@hooks/useLoadingSubmit';
import { useIntl } from 'react-intl';
import { FormMerchantWrapper, InputDescription, InputEmail, InputName, InputsWrapper, Warning } from './styles';

interface IFormMerchant {
    merchant: IMerchant | undefined;
    returnMerchantChoose: () => void;
    nextStep: () => void;
    checkMerchantClaim: () => void;
    resetTemplatePassportContractBank: () => void;
}

const FormMerchant: FC<IFormMerchant> = ({
    resetTemplatePassportContractBank,
    checkMerchantClaim,
    nextStep,
    merchant,
    returnMerchantChoose,
}) => {
    const intl = useIntl();
    const { isLoadingSubmit, toggleLoadingSubmit } = useLoadingSubmit();
    const validation = useValidationSchema(merchantPaymentSchema);
    const dispatch = useAppDispatch();
    const { counterpartyId, merchantId } = useParams<{ counterpartyId: string; merchantId: string }>();
    const mccList: IMcc[] = useDictionary(DICTIONARY_TYPES.mcc)?.data as any;

    const createClaimToLoad = async (merchantIdToCreate: string) => {
        const data = await dispatch(createClaim(merchantIdToCreate));
        // @ts-ignore
        if (!data?.error && data?.payload?.id) {
            const currentClaim: IClaim = data.payload as any;
            await dispatch(setSelectedClaimId(currentClaim.id));
        }
    };

    const onSubmit = async (formValues: IMerchant) => {
        const merchantFields = {
            ...formValues,
            [MERCHANT_FIELDS.phone]: removePlusAndGapsToPhone(formValues[MERCHANT_FIELDS.phone]),
            [MERCHANT_FIELDS.counterpartyId]: counterpartyId,
        };
        toggleLoadingSubmit();
        // если мерчант выбран пользователем или переход был осуществлен со страницы магазина
        if (merchant) {
            const data = await dispatch(updateMerchant(merchantFields));
            // @ts-ignore
            if (!data?.error && data?.payload) {
                checkMerchantClaim();
            }
        } else {
            // если создается новый мерчант
            const dataCreateMerchant = await dispatch(createMerchant(merchantFields));
            // @ts-ignore
            if (!dataCreateMerchant?.error && dataCreateMerchant?.payload) {
                resetTemplatePassportContractBank();
                nextStep();
                // @ts-ignore
                const idMerchant = dataCreateMerchant?.payload?.data.id;
                dispatch(setSelectedMerchantId(idMerchant));
                await createClaimToLoad(idMerchant);
            }
        }
        toggleLoadingSubmit();
    };

    return (
        <FormMerchantWrapper>
            <Warning>
                {intl.formatMessage({
                    defaultMessage: 'Данные магазинов необходимы для проверки магазинов и платежной страницы',
                })}
            </Warning>
            <Form onSubmit={onSubmit} initialValues={merchant} validate={validation}>
                {({ handleSubmit, valid }) => (
                    <form>
                        <InputsWrapper>
                            <InputName
                                name={MERCHANT_FIELDS.name}
                                label={intl.formatMessage({ defaultMessage: 'Наименование магазина' })}
                                placeholder={intl.formatMessage({ defaultMessage: 'Введите наименование' })}
                            />
                            <FormField
                                fieldType={FormFieldTypeEnum.select}
                                options={mccList.map(({ id, name }) => ({ label: name, value: id }))}
                                name={MERCHANT_FIELDS.mccId}
                                label={intl.formatMessage({ defaultMessage: 'Категория' })}
                                placeholder={intl.formatMessage({ defaultMessage: 'Выберите категорию' })}
                            />
                            <InputDescription
                                name={MERCHANT_FIELDS.description}
                                label={intl.formatMessage({ defaultMessage: 'Описание' })}
                                placeholder={intl.formatMessage({ defaultMessage: 'Введите описание' })}
                            />
                            <InputEmail
                                name={MERCHANT_FIELDS.email}
                                label="Email"
                                placeholder={intl.formatMessage({ defaultMessage: 'Электронная почта' })}
                            />
                            <FormField
                                name={MERCHANT_FIELDS.website}
                                label={intl.formatMessage({ defaultMessage: 'Адрес сайта' })}
                                placeholder={intl.formatMessage({ defaultMessage: 'Введите адрес сайта' })}
                            />
                            <FormField
                                autoFocus
                                type="tel"
                                label={intl.formatMessage({ defaultMessage: 'Телефон' })}
                                name="phone"
                                mask="+7 999 999 99 99"
                                placeholder="+7 XXX XXX XX XX"
                            />
                        </InputsWrapper>
                        <ButtonsNextPrev
                            isLoading={isLoadingSubmit}
                            isActiveNextButton={valid}
                            onClickPrev={merchantId ? null : returnMerchantChoose}
                            onClickNext={handleSubmit}
                        />
                    </form>
                )}
            </Form>
        </FormMerchantWrapper>
    );
};

export default FormMerchant;
