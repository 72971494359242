import React, { FC, useCallback, useEffect, useState } from 'react';
import {
    ButtonAddNewContact,
    ButtonSearch,
    ContactsWrapper,
    InputWrapper,
    ModalWrapper,
} from '@pages/Shop/ShopTabsTables/CreateNewClient/components/ModalSearchContact/styles';

import Input from '@components/Input';
import SearchIcon from '@pages/Shop/ShopTabsTables/CreateOrderToPay/assets/search.svg';
import PlusIcon from '@assets/icons/plus.svg';
import { useSelector } from 'react-redux';
import { getCurrentCounterpartyClients } from '@store/Client/selectors';
import { CLIENT_FIELDS } from '@store/Client/contants';
import RowContactModal from '@pages/Shop/ShopTabsTables/CreateNewClient/components/RowContactModal';
import { IClient } from '@store/Client/types';
import { useIntl } from 'react-intl';

interface IModalSearchContact {
    isOpen: boolean;
    onClose: () => void;
    clientCounterpartyId: number;
    openModalCreateContactPerson: () => void;
    selectContactPerson: (contactPerson: IClient) => void;
}

const ModalSearchContact: FC<IModalSearchContact> = ({
    openModalCreateContactPerson,
    clientCounterpartyId,
    isOpen,
    onClose,
    selectContactPerson,
}) => {
    const intl = useIntl();
    const [searchWord, setSearchWord] = useState('');
    const [contactPersons, setContactPersons] = useState<IClient[]>();

    const currentCounterpartyClients = useSelector(getCurrentCounterpartyClients);

    const unSelectedContactPersons = useCallback(
        () =>
            currentCounterpartyClients.filter(
                (item) =>
                    !item[CLIENT_FIELDS.contactCounterpartyIds] ||
                    (item[CLIENT_FIELDS.contactCounterpartyIds] &&
                        !item[CLIENT_FIELDS.contactCounterpartyIds].includes(clientCounterpartyId)),
            ),
        [clientCounterpartyId, currentCounterpartyClients],
    );

    useEffect(() => {
        setContactPersons(unSelectedContactPersons());
    }, [unSelectedContactPersons]);

    const applyFilter = () => {
        const afterSearchContacts = unSelectedContactPersons().filter((contactPerson) =>
            contactPerson[CLIENT_FIELDS.name].toUpperCase().includes(searchWord.toUpperCase()),
        );
        setContactPersons(afterSearchContacts);
        setSearchWord('');
    };

    const onSelectContactPerson = (contactPerson: IClient) => {
        selectContactPerson(contactPerson);
        onClose();
    };

    return (
        <ModalWrapper
            title={intl.formatMessage({ defaultMessage: 'Выберите контактное лицо' })}
            closeModal={onClose}
            isOpen={isOpen}
        >
            <InputWrapper>
                <Input
                    onChange={(string: string) => {
                        setSearchWord(string);
                    }}
                    value={searchWord}
                    name="searchClientWord"
                    placeholder={intl.formatMessage({ defaultMessage: 'Поиск по клиентам' })}
                />
                <ButtonSearch type="button" onClick={applyFilter}>
                    <SearchIcon />
                </ButtonSearch>
            </InputWrapper>
            <ButtonAddNewContact type="button" onClick={openModalCreateContactPerson}>
                <PlusIcon />
                {intl.formatMessage({ defaultMessage: 'Добавить контактное лицо' })}
            </ButtonAddNewContact>
            {contactPersons && (
                <ContactsWrapper>
                    {contactPersons.map((contactPerson) => (
                        <RowContactModal
                            selectContactPerson={onSelectContactPerson}
                            key={contactPerson[CLIENT_FIELDS.id]}
                            contactPerson={contactPerson}
                        />
                    ))}
                </ContactsWrapper>
            )}
        </ModalWrapper>
    );
};

export default ModalSearchContact;
