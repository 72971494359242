// eslint-disable-next-line no-shadow
export enum enumBackgroundStory {
    LIGHT = 'light',
    DARK = 'dark',
}

export interface IStoryBlock {
    id: number;
    title: string;
    description: string;
    background: string;
    backgroundColor: enumBackgroundStory;
    withLink?: boolean;
}

export interface IStory {
    id: number;
    url: string;
    preview: string;
    title: string;
    slides: IStoryBlock[];
}
