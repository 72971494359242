import styled, { css } from 'styled-components';

export const SliderContent = styled.div`
    position: relative;
    height: 440px;
`;

// styles yellow circle in frame
const StyleCircleYellowFirstFrame = css`
    right: -240px;
    top: -40px;
    width: 430px;
    height: 430px;
`;

const StyleCircleYellowSecondFrame = css`
    right: -130px;
    top: -59px;
    width: 440px;
    height: 440px;
`;

const StyleCircleYellowThirdFrame = css`
    width: 287px;
    height: 287px;
    border-width: 70px;
    right: -120px;
    top: -60px;
`;

const StyleCircleYellowForthFrame = css`
    width: 319px;
    height: 319px;
    border-width: 70px;
    right: -40px;
    top: -100px;
`;

// styles base circle in frame
const StyleCircleBaseFirstFrame = css`
    bottom: -30px;
    right: -80px;
    width: 360px;
    height: 360px;
`;

const StyleCircleBaseSecondFrame = css`
    width: 300px;
    height: 300px;
    right: -130px;
    border-width: 80px;
`;

const StyleCircleBaseThirdFrame = css`
    width: 372px;
    height: 372px;
    bottom: -50px;
    right: -110px;
`;

const StyleCircleBaseForthFrame = css`
    width: 355px;
    height: 355px;
    border-width: 80px;
    right: -120px;
`;

const getStyleBaseCircle = (frame: number) => {
    switch (frame) {
        case 1: {
            return StyleCircleBaseFirstFrame;
        }
        case 2: {
            return StyleCircleBaseSecondFrame;
        }
        case 3: {
            return StyleCircleBaseThirdFrame;
        }
        case 4: {
            return StyleCircleBaseForthFrame;
        }
        default:
            return StyleCircleBaseFirstFrame;
    }
};

const getStyleYellowCircle = (frame: number) => {
    switch (frame) {
        case 1: {
            return StyleCircleYellowFirstFrame;
        }
        case 2: {
            return StyleCircleYellowSecondFrame;
        }
        case 3: {
            return StyleCircleYellowThirdFrame;
        }
        case 4: {
            return StyleCircleYellowForthFrame;
        }
        default:
            return StyleCircleYellowFirstFrame;
    }
};

interface IBackgroundCircle {
    frame: number;
}

const StyleBackgroundCircle = css`
    transition: 0.6s;
    position: absolute;
    border-radius: 50%;
`;

export const BackgroundCircleYellow = styled.div<IBackgroundCircle>`
    ${StyleBackgroundCircle}
    z-index: 2;
    width: 430px;
    height: 430px;
    border: 100px solid ${({ theme }) => theme.colors.yellow()};
    right: -240px;
    top: -40px;
    ${({ frame }) => getStyleYellowCircle(frame)};
`;

export const BackgroundCircleBase = styled.div<IBackgroundCircle>`
    ${StyleBackgroundCircle}
    z-index: 1;
    width: 360px;
    height: 360px;
    border: 100px solid ${({ theme }) => theme.colors.base()};
    bottom: -30px;
    right: -80px;
    ${({ frame }) => getStyleBaseCircle(frame)};
`;

export const ButtonsSlider = styled.div`
    ${({ theme }) => theme.mixins.flexCenter};
    grid-column-gap: 20px;
    position: absolute;
    bottom: 40px;
`;

const StyleButtonSlider = css`
    width: 20px;
    height: 20px;
    border-radius: 50%;
    &:hover {
        opacity: 0.8;
    }
`;

export const ButtonPrev = styled.button`
    ${StyleButtonSlider}
`;

export const ButtonNext = styled.button`
    ${StyleButtonSlider}
    transform: rotateZ(180deg);
`;
