import axios from 'axios';
import { IS_DEV } from './constants';

export const BASE_URL = IS_DEV ? 'https://api.stage.invbox.ru/v3/' : '/v3/';
export const STRAPI_BASE_URL = 'https://typewriter.invbox.ru/';

export type ICommonListRequest<D> = Partial<D> & {
    _page?: number;
    _pageSize?: number;
};

export interface ICommonResponse<D, E = any> {
    data: D;
    metaData: {
        totalCount: number;
        pageSize: number;
        page: number;
    };
    extendedData: Array<E>;
}

const http = axios.create({
    baseURL: BASE_URL,
    withCredentials: true,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Content-Language': 'ru',
    },
});

export const strapiHttp = axios.create({
    baseURL: STRAPI_BASE_URL,
    withCredentials: true,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Content-Language': 'ru',
        Authorization: `bearer ${process.env.STRAPI_TOKEN}`,
    },
});

export const strapiConfig = {
    fields: ['title'],
    populate: {
        preview: {
            populate: {
                web: {
                    fields: ['url'],
                },
            },
        },
        slides: {
            fields: ['title', 'description', 'backgroundColor'],
            populate: {
                background: {
                    fields: ['url'],
                },
            },
        },
    },
    filters: {
        platforms: {
            web: { $eq: true },
        },
        environment: {
            [process.env.NODE_ENV || 'development']: { $eq: true },
        },
    },
};

export default http;
