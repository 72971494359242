import React, { FC } from 'react';
import {
    ColumnFieldLabel,
    ColumnFieldValue,
    ColumnFieldWrapper,
} from '@pages/Shop/ShopTabsTables/CreateNewClient/components/ColumnField/styles';

interface IColumnField {
    label: string;
    value: string | undefined;
}

const ColumnField: FC<IColumnField> = ({ label, value }) => (
    <ColumnFieldWrapper>
        <ColumnFieldLabel>{label}</ColumnFieldLabel>
        {value && <ColumnFieldValue>{value}</ColumnFieldValue>}
    </ColumnFieldWrapper>
);

export default ColumnField;
