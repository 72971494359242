import styled from 'styled-components';
import Skeleton from '@components/Skeleton';

export const DateHoursContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    grid-column-gap: 10px;
    grid-row-gap: 5px;
`;

export const SkeletonValue = styled(Skeleton)`
    height: 16px;
`;
