import React from 'react';
import { Routes } from '@pages/constants';
import { useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { CreateOrderButtonContainer, AddIcon, OrderButton } from '../../styles';

const CreateOrderButton = () => {
    const intl = useIntl();
    const { merchantId } = useParams<{ merchantId: string }>();

    return (
        <CreateOrderButtonContainer>
            <OrderButton to={`${Routes.dashboard}${Routes.shop}/${merchantId}${Routes.orderToPay}`}>
                <AddIcon />
                {intl.formatMessage({ defaultMessage: 'Создать заказ' })}
            </OrderButton>
        </CreateOrderButtonContainer>
    );
};

export default CreateOrderButton;
