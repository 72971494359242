import React, { FC } from 'react';
import { IconAdd, NavLinkCreateClient } from '@pages/Shop/ShopTabsTables/Clients/components/ButtonCreateClient/styles';
import { Routes } from '@pages/constants';
import { useParams } from 'react-router-dom';
import { typesClients } from '@pages/Shop/ShopTabsTables/Clients';
import { useIntl } from 'react-intl';

interface IButtonCreateClient {
    typeClient: typesClients;
}

const ButtonCreateClient: FC<IButtonCreateClient> = ({ typeClient }) => {
    const intl = useIntl();
    const { merchantId } = useParams<{ merchantId: string }>();
    const pathClientCreate = `${Routes.dashboard}${Routes.shop}/${merchantId}`;

    return (
        <NavLinkCreateClient
            to={
                typeClient === typesClients.CLIENT
                    ? pathClientCreate + Routes.createclient
                    : pathClientCreate + Routes.createcounterparty
            }
        >
            <IconAdd />
            {intl.formatMessage({ defaultMessage: 'Новый клиент' })}
        </NavLinkCreateClient>
    );
};

export default ButtonCreateClient;
