import React, { FC } from 'react';
import styled from 'styled-components';
import Typography from '@components/Typography';
import Skeleton from '@components/Skeleton';

interface IRowLabel {
    label: string;
    value: string | undefined;
    className?: string;
    isShowSkeleton?: boolean;
}

const RowLabel: FC<IRowLabel> = ({ className, isShowSkeleton = false, label, value }) => (
    <RowSetting className={className}>
        <LabelSetting>{label}</LabelSetting>
        {isShowSkeleton ? <SkeletonValue /> : <ValueSetting>{value}</ValueSetting>}
    </RowSetting>
);

export default RowLabel;

const SkeletonValue = styled(Skeleton)`
    height: 16px;
`;

const ValueSetting = styled(Typography).attrs({ variant: 'regular14' })`
    line-height: 140%;
`;

const LabelSetting = styled(Typography).attrs({ variant: 'regular12' })`
    color: ${({ theme }) => theme.colors.grey()};
    line-height: 140%;
`;

const RowSetting = styled.div`
    display: grid;
    grid-template-columns: 100%;
    grid-row-gap: 7px;
    grid-template-rows: 16px auto;
`;
