import React, { FC, useState } from 'react';

import {
    NewOrderPayAccordionWrapper,
    NewOrderPayAccordionContent,
    NewOrderPayHeadButton,
    NewOrderPayButtonLabel,
} from '@components/NewOrderPayAccordion/styles';
import IconOpen from './assets/top.svg';
import IconClose from './assets/bottom.svg';

interface INewOrderPayAccordion {
    title: string;
    // eslint-disable-next-line no-undef
    OptionalComponentInHeader?: () => JSX.Element;
}

const NewOrderPayAccordion: FC<INewOrderPayAccordion> = ({ title, children, OptionalComponentInHeader }) => {
    const [isOpen, setIsOpen] = useState(true);

    return (
        <NewOrderPayAccordionWrapper>
            <NewOrderPayHeadButton
                isOpen={isOpen}
                type="button"
                onClick={() => {
                    setIsOpen(!isOpen);
                }}
            >
                <NewOrderPayButtonLabel>{title}</NewOrderPayButtonLabel>
                {isOpen ? <IconOpen /> : <IconClose />}
            </NewOrderPayHeadButton>
            {OptionalComponentInHeader && <OptionalComponentInHeader />}
            <NewOrderPayAccordionContent isOpen={isOpen}>{children}</NewOrderPayAccordionContent>
        </NewOrderPayAccordionWrapper>
    );
};

export default NewOrderPayAccordion;
