import React, { FC, useEffect, useState } from 'react';
import { enumTabsConnection } from '@pages/Claim/components/MainConnectionPayment';
import TabsConnection from '@pages/Claim/components/TabsConnection';
import { IClaim } from '@store/Claims/types';
import Merchant from '@components/Widgets/ClaimModeratingWidget/components/Merchant';
import {
    ButtonCancelPayment,
    ReadOnlyWrapper,
} from '@components/Widgets/ClaimModeratingWidget/components/ClaimFieldsReadOnly/styles';
import { useAppDispatch } from '@store';
import { setSelectedMerchantId } from '@store/Merchant';
import { ClaimFields } from '@store/Claims/constants';
import { setSelectedContractId } from '@store/Contracts';
import { removeClaim, setPassportData, setSelectedClaimId } from '@store/Claims';
import { setCounterparty } from '@store/Counterparty';
import Tariff from '@components/Widgets/ClaimModeratingWidget/components/Tariff';
import Counterparty from '@components/Widgets/ClaimModeratingWidget/components/Counterparty';
import BankDetail from '@components/Widgets/ClaimModeratingWidget/components/BankDetail';
import PassportData from '@components/Widgets/ClaimModeratingWidget/components/PassportData';
import Documents from '@components/Widgets/ClaimModeratingWidget/components/Documents';
import useLoadingSubmit from '@hooks/useLoadingSubmit';
import { useIntl } from 'react-intl';

interface IClaimFieldsReadOnly {
    claim: IClaim;
}

const ClaimFieldsReadOnly: FC<IClaimFieldsReadOnly> = ({ claim }) => {
    const dispatch = useAppDispatch();
    const intl = useIntl();
    const { isLoadingSubmit, toggleLoadingSubmit } = useLoadingSubmit();

    const [step, setStep] = useState(enumTabsConnection.merchant);

    const tabs = {
        [enumTabsConnection.merchant]: {
            component: Merchant,
            name: intl.formatMessage({ defaultMessage: 'Магазин' }),
        },
        [enumTabsConnection.tariff]: {
            component: Tariff,
            name: intl.formatMessage({ defaultMessage: 'Тариф' }),
        },
        [enumTabsConnection.counterparty]: {
            component: Counterparty,
            name: intl.formatMessage({ defaultMessage: 'Контрагент' }),
        },
        [enumTabsConnection.bankDetails]: {
            component: BankDetail,
            name: intl.formatMessage({ defaultMessage: 'Банковские реквизиты' }),
        },
        [enumTabsConnection.passport]: {
            component: PassportData,
            name: intl.formatMessage({ defaultMessage: 'Паспортные данные' }),
        },
        [enumTabsConnection.documents]: {
            component: Documents,
            name: intl.formatMessage({ defaultMessage: 'Документы' }),
        },
    };

    const CurrentStepComponent = tabs[step].component;

    useEffect(() => {
        dispatch(setSelectedMerchantId(claim[ClaimFields.merchantId]));
        dispatch(setSelectedContractId(claim[ClaimFields.merchantContractId]));
        dispatch(setSelectedClaimId(claim[ClaimFields.id]));
        dispatch(setCounterparty(claim[ClaimFields.counterpartyId]));
        dispatch(setPassportData(claim[ClaimFields.passport]));
    }, [claim, dispatch]);

    const cancelClaim = async () => {
        toggleLoadingSubmit();
        const data = await dispatch(removeClaim(claim[ClaimFields.id]));
        // @ts-ignore
        if (!data?.error && data?.payload) {
            toggleLoadingSubmit();
        }
    };

    return (
        <ReadOnlyWrapper>
            <TabsConnection isSkipFirstTab={false} isActiveTabs setStep={setStep} activeStep={step} tabs={tabs} />
            <CurrentStepComponent />
            <ButtonCancelPayment isLoading={isLoadingSubmit} onClick={cancelClaim} type="secondary">
                {intl.formatMessage({ defaultMessage: 'Отменить подключение' })}
            </ButtonCancelPayment>
        </ReadOnlyWrapper>
    );
};

export default ClaimFieldsReadOnly;
