import React, { FC } from 'react';
import ItemCompositionOrder from '@pages/Shop/ShopTabsTables/CreateOrderToPay/components/ItemCompositionOrder/index';
import { ORDER_ITEM_FIELDS, TOrderItem, TVatType } from '@store/Order/types';
import { IMeasure } from '@store/Merchant/types';
import { useIntl } from 'react-intl';
import { ButtonAddNewItemOrder } from './styles';
import AddItemIcon from '../../assets/pluscircle.svg';

interface ICompositionOrderPay {
    itemsOrder: TOrderItem[];
    openModalAddOrderItem: () => void;
    removeItemOrder: (itemOrder: TOrderItem) => void;
    editItemOrder: (itemOrder: TOrderItem) => void;
    vatTypes: TVatType[] | undefined;
    measures: IMeasure[] | undefined;
}

const CompositionOrderPay: FC<ICompositionOrderPay> = ({
    removeItemOrder,
    editItemOrder,
    vatTypes,
    itemsOrder,
    openModalAddOrderItem,
    measures,
}) => {
    const intl = useIntl();
    if (itemsOrder.length === 0) {
        return (
            <ButtonAddNewItemOrder type="button" onClick={openModalAddOrderItem}>
                <AddItemIcon />
                {intl.formatMessage({ defaultMessage: 'Добавьте новую товарную позицию' })}
            </ButtonAddNewItemOrder>
        );
    }

    return (
        <>
            {itemsOrder.map((itemOrder) => (
                <ItemCompositionOrder
                    key={itemOrder[ORDER_ITEM_FIELDS.sku]}
                    editItemOrder={editItemOrder}
                    removeItemOrder={removeItemOrder}
                    orderItem={itemOrder}
                    vatTypes={vatTypes}
                    measures={measures}
                />
            ))}
        </>
    );
};

export default CompositionOrderPay;
