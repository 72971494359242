import React, { FC } from 'react';
import { MERCHANT_INTEGRATION_FIELDS } from '@store/MerchantIntegration/types';
import FormField, { FormFieldTypeEnum } from '@components/FormField';
import { useIntl } from 'react-intl';
import { InputTypeEnum } from '@components/Input';
import { maxLengthInputsMerchantIntegration } from '@utils/constants';
import { enumDynamicFieldNames, getDynamicFieldsLabel } from '@components/DynamicFormFields/helpers';

interface IDynamicFormFields {
    properties: { [key: string]: { [MERCHANT_INTEGRATION_FIELDS.type]: string } };
}

const DynamicFormFields: FC<IDynamicFormFields> = ({ properties }) => {
    const intl = useIntl();

    const getFieldAttributes = (nameField: string, typeField: string) => {
        const inputType = typeField === 'string' ? InputTypeEnum.text : InputTypeEnum.numeric;

        if (typeField === 'boolean') {
            return {
                label: getDynamicFieldsLabel(nameField as enumDynamicFieldNames, intl),
                name: nameField,
                fieldType: FormFieldTypeEnum.checkbox,
                type: 'checkbox',
            };
        }

        return {
            label: getDynamicFieldsLabel(nameField as enumDynamicFieldNames, intl),
            typeInput: inputType,
            name: nameField,
            maxLength: maxLengthInputsMerchantIntegration,
        };
    };

    return (
        <>
            {Object.entries(properties)?.map((property) => {
                const fieldName = property?.[0];
                if (fieldName !== 'body') {
                    const fieldAttributes = getFieldAttributes(fieldName, property?.[1]?.type);
                    return <FormField key={fieldName} {...fieldAttributes} />;
                }
                return null;
            })}
        </>
    );
};

export default DynamicFormFields;
