import React, { FC } from 'react';
import FilteredOrderList from '@components/FilterOrders';
import { ContainerNavPage } from '@pages/Shop/styles';

const Orders: FC = () => (
    <ContainerNavPage>
        <FilteredOrderList />
    </ContainerNavPage>
);

export default Orders;
