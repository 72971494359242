import styled from 'styled-components';
import FormField from '@components/FormField';

export const InputFindClient = styled(FormField)`
    margin-bottom: 20px;
    @media ${({ theme }) => theme.breakpoints.sm} {
        padding: 0 20px;
        margin-bottom: 30px;
        margin-top: 0;
        span {
            right: 40px;
            bottom: calc(50% - 2px);
        }
    }
`;
