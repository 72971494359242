import styled from 'styled-components';
import FormField from '@components/FormField';

export const InputsWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 30px;
    grid-row-gap: 20px;
    @media ${({ theme }) => theme.breakpoints.ld} {
        grid-template-columns: repeat(3, 1fr);
    }
    @media ${({ theme }) => theme.breakpoints.md} {
        grid-template-columns: 1fr;
        grid-row-gap: 10px;
    }
`;

export const InputName = styled(FormField)`
    grid-column-start: 1;
    grid-column-end: 4;
    @media ${({ theme }) => theme.breakpoints.ld} {
        grid-column-end: 3;
    }
    @media ${({ theme }) => theme.breakpoints.md} {
        grid-column-end: auto;
    }
`;

export const InputAddress = styled(FormField)`
    grid-column-start: 1;
    grid-column-end: 4;
    @media ${({ theme }) => theme.breakpoints.ld} {
        grid-column-end: 3;
    }
    @media ${({ theme }) => theme.breakpoints.md} {
        grid-column-end: auto;
    }
`;

export const InputCeo = styled(FormField)`
    grid-column-start: 1;
    grid-column-end: 4;
    @media ${({ theme }) => theme.breakpoints.ld} {
        grid-column-end: 3;
    }
    @media ${({ theme }) => theme.breakpoints.md} {
        grid-column-end: auto;
    }
`;

export const InputCfo = styled(FormField)`
    grid-column-start: 1;
    grid-column-end: 4;
    @media ${({ theme }) => theme.breakpoints.ld} {
        grid-column-end: 3;
    }
    @media ${({ theme }) => theme.breakpoints.md} {
        grid-column-end: auto;
    }
`;

export const InputEmail = styled(FormField)`
    grid-column-start: 1;
    grid-column-end: 3;
    @media ${({ theme }) => theme.breakpoints.ld} {
        grid-column-end: 2;
    }
    @media ${({ theme }) => theme.breakpoints.md} {
        grid-column-end: auto;
    }
`;

export const InputPhone = styled(FormField)`
    grid-column-start: 3;
    grid-column-end: 4;
    @media ${({ theme }) => theme.breakpoints.ld} {
        grid-column-start: 2;
        grid-column-end: 3;
    }
    @media ${({ theme }) => theme.breakpoints.md} {
        grid-column-start: auto;
        grid-column-end: auto;
    }
`;
