import styled from 'styled-components';
import Typography from '@components/Typography';

export const Empty = styled(Typography).attrs({ variant: 'subheading' })`
    color: ${({ theme }) => theme.colors.grey()};
    text-align: center;
`;

export const InnerContent = styled.div`
    border: 1px solid ${({ theme }) => theme.colors.lightGrey()};
`;
