import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import {
    RemoveMerchantModalWrapper,
    RemoveMerchantTitle,
    RemoveMerchantDescription,
    ButtonRemoveMerchantModal,
} from './styles';

import DeleteIcon from '../../assets/delete.svg';

interface IRemoveMerchantModal {
    closeModal: () => void;
    isOpen: boolean;
}

const RemoveMerchantModal: FC<IRemoveMerchantModal> = ({ closeModal, isOpen }) => {
    const intl = useIntl();

    return (
        <RemoveMerchantModalWrapper isOpen={isOpen} closeModal={closeModal}>
            <RemoveMerchantTitle>{intl.formatMessage({ defaultMessage: 'Удалить магазин' })}</RemoveMerchantTitle>
            <RemoveMerchantDescription>
                {intl.formatMessage({ defaultMessage: 'Вы уверены что хотите удалить магазин?' })}
            </RemoveMerchantDescription>
            <RemoveMerchantDescription>
                {intl.formatMessage({ defaultMessage: 'Все данные удалятся без возможности восстановления' })}
            </RemoveMerchantDescription>
            <ButtonRemoveMerchantModal icon={<DeleteIcon />}>
                {intl.formatMessage({ defaultMessage: 'Удалить магазин' })}
            </ButtonRemoveMerchantModal>
        </RemoveMerchantModalWrapper>
    );
};

export default RemoveMerchantModal;
