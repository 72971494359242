import yup from '@utils/validations';
import { MERCHANT_FIELDS } from '@store/Merchant/contants';
import { BANK_DETAILS_FIELDS, COUNTERPARTY_FIELDS } from '@store/Counterparty/contants';
import { INDIVIDUAL_VAT_NUMBER_LENGTH } from '@utils/constants';
import { PASSPORT_DATA_FIELDS } from '@store/Claims/constants';

export const merchantPaymentSchema = yup.object().shape({
    [MERCHANT_FIELDS.name]: yup.string().required(),
    [MERCHANT_FIELDS.mccId]: yup.number().required(),
    [MERCHANT_FIELDS.description]: yup.string().max(1000).required(),
    [MERCHANT_FIELDS.email]: yup.string().email().required(),
    [MERCHANT_FIELDS.phone]: yup.string().isValidPhone().required(),
    [MERCHANT_FIELDS.website]: yup.string().required(),
});

export const counterpartyPaymentSchema = yup.object().shape({
    [COUNTERPARTY_FIELDS.name]: yup.string().required(),
    [COUNTERPARTY_FIELDS.vatNumber]: yup.string().isValidInn().required(),
    [COUNTERPARTY_FIELDS.registrationAddress]: yup.string().required(),
    [COUNTERPARTY_FIELDS.legalEntityTypeId]: yup.string().required(),
    [COUNTERPARTY_FIELDS.ceoName]: yup.string().required(),
    [COUNTERPARTY_FIELDS.taxRegistrationReasonCode]: yup.string().isValidKpp().required(),
    [COUNTERPARTY_FIELDS.cfoName]: yup.string().required(),
    [COUNTERPARTY_FIELDS.registrationNumber]: yup.string().when(COUNTERPARTY_FIELDS.vatNumber, {
        is: (vat: string) => String(vat).length === INDIVIDUAL_VAT_NUMBER_LENGTH,
        then: yup.string().isValidOgrnip().required(),
        otherwise: yup.string().isValidOgrn().required(),
    }),
    [COUNTERPARTY_FIELDS.email]: yup.string().email().required(),
    [COUNTERPARTY_FIELDS.phone]: yup.string().isValidPhone().required(),
    [COUNTERPARTY_FIELDS.registrationDate]: yup.string().required(),
});

export const BankDetailSchema = yup.object().shape({
    [BANK_DETAILS_FIELDS.bankName]: yup.string().required(),
    [BANK_DETAILS_FIELDS.bic]: yup.string().isValidBik().required(),
    [BANK_DETAILS_FIELDS.correspondentAccount]: yup.string().isValidKs().required(),
    [BANK_DETAILS_FIELDS.settlementAccount]: yup.string().isValidRs().required(),
});

export const PassportDataSchema = yup.object().shape({
    [PASSPORT_DATA_FIELDS.name]: yup.string().min(10).required(),
    [PASSPORT_DATA_FIELDS.birthdate]: yup.string().required(),
    [PASSPORT_DATA_FIELDS.birthplace]: yup.string().required(),
    [PASSPORT_DATA_FIELDS.series]: yup.string().isValidPassSer().required(),
    [PASSPORT_DATA_FIELDS.number]: yup.string().isValidPassNum().required(),
    [PASSPORT_DATA_FIELDS.issuedPlace]: yup.string().required(),
    [PASSPORT_DATA_FIELDS.issuedPlaceCode]: yup.string().isValidIssuedPlaceCode().required(),
    [PASSPORT_DATA_FIELDS.issuedDate]: yup.string().required(),
});
