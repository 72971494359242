import { createIntl, createIntlCache } from 'react-intl';
import ruLocale from '../i18/ru';

const cache = createIntlCache();
const intl = createIntl({ locale: 'ru', messages: ruLocale }, cache);

export const IS_DEV = process.env.NODE_ENV === 'development';
export const UTC_DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ss+00:00';
export const ISO_SHORT_DATE_FORMAT = 'DD.MM.YYYY HH:mm';
export const DD_MM_YYYY_DATE_FORMAT = 'DD.MM.YYYY';
export const HH_MM_FORMAT = 'HH:mm';
export const DATE_YYYY_MM_DD = 'YYYY-MM-DD';
export const INDIVIDUAL_VAT_NUMBER_LENGTH = 12;
export const ORGANISATION_VAT_NUMBER_LENGTH = 10;
export const ORGANISATION_OGRN = 13;
export const INDIVIDUAL_OGRNIP = 15;
export const dateFormatFilter = 'YYYY-MM-DDT00:30:24+00:00';
export const statusField = 'status[eq]';
export const createdStartField = 'createdAt[ge]';
export const createdFinishField = 'createdAt[le]';
export const COUNT_ITEMS_TO_PAGE = 10;
export const COUNT_ORDERS_MERCHANT_DASHBOARD = 2;
export const textWhereSendDocuments = intl.formatMessage({
    defaultMessage: '196105, Санкт-Петербург, пр. Юрия Гагарина д. 1',
});
export const PIECE_MEASURE_VALUE = '796'; // id единицы измерения - Штука
export const labelTroublesInternetConnection = intl.formatMessage({
    defaultMessage: 'Проблемы с интернет соединением',
});
export const labelTroubleXHR = intl.formatMessage({ defaultMessage: 'Ошибка сети' });
export const networkErrorResponse = 'Error: Network Error';
export const delaySwitchSlidesInStoriesInSec = 8;
export const APIDescriptionLink = 'https://docs.invoicebox.ru/docs/notification';
export const maxLengthInputsMerchantIntegration = 50;
export const offerInvoiceBoxLink = 'https://www.invoicebox.ru/ru/offer.html';
export const supportInvoiceBoxLink = 'https://www.invoicebox.ru/ru/support/buyer';
