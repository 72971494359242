import React, { FC, ReactNode } from 'react';
import styled, { DefaultTheme } from 'styled-components';
import { COLOR_BRIGHTNESS } from '@theme/utils';
import LoadingLogo from '@components/Button/components/LoadingLogo';

type ButtonType = 'primary' | 'secondary' | 'link' | 'danger';
type StyledButtonType = ButtonType | 'linkWithIcon';

interface IButtonProps {
    type?: ButtonType;
    disabled?: boolean;
    icon?: ReactNode;
    onClick?: () => void;
    className?: string;
    htmlType?: ButtonHtmlType;
    fullWidth?: boolean;
    href?: string;
    isLoading?: boolean;
}

type ButtonHtmlType = 'submit' | 'reset' | 'button';

interface IButtonWrapperProps {
    buttonType: StyledButtonType;
    iconOnly: boolean;
    hasIcon: boolean;
    disabled: boolean;
    fullWidth: boolean;
}

interface IButtonInnerProps {
    iconOnly: boolean;
    isLoading: boolean;
}

const Button: FC<IButtonProps> = ({
    children,
    type = 'primary',
    disabled = false,
    icon,
    onClick,
    className,
    fullWidth = false,
    htmlType = 'button',
    href,
    isLoading = false,
}) => {
    const isLink = !!href;

    return (
        <ButtonWrapper
            as={isLink ? 'a' : 'button'}
            disabled={disabled}
            buttonType={getStyledButtonType(type, !!icon)}
            iconOnly={!children}
            onClick={isLink || isLoading ? undefined : onClick}
            hasIcon={!!icon}
            type={htmlType}
            fullWidth={fullWidth}
            className={className}
            href={href}
        >
            {isLoading && <LoadingLogo isPrimary={type === 'primary'} />}
            <ButtonInner isLoading={isLoading} iconOnly={!children}>
                {icon && icon}
                {children}
            </ButtonInner>
        </ButtonWrapper>
    );
};
export default Button;

const isLinkButton = (buttonType: StyledButtonType) => buttonType === 'link';
const isLinkWithIconButton = (buttonType: StyledButtonType) => buttonType === 'linkWithIcon';
const isTextButton = (buttonType: StyledButtonType) =>
    isLinkButton(buttonType) || isLinkWithIconButton(buttonType) || false;
const getStyledButtonType = (buttonType: ButtonType, hasIcon: boolean): StyledButtonType =>
    buttonType === 'link' && hasIcon ? 'linkWithIcon' : buttonType;

const ButtonWrapper = styled('a')<IButtonWrapperProps>`
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    border: none;
    cursor: pointer;
    text-align: center;
    justify-content: center;
    transition: ${({ theme }) => theme.decorations.transition?.base};
    width: ${({ fullWidth }) => (fullWidth ? '100%' : 'fit-content')};
    border-radius: ${({ theme, buttonType }) =>
        isLinkButton(buttonType) ? 0 : theme.decorations.borderRadius?.base}px;
    padding: ${({ buttonType, iconOnly, hasIcon }) => getButtonPaddings(buttonType, iconOnly, hasIcon)};
    background-color: ${({ theme, buttonType, disabled }) => typeToBg(buttonType, theme, disabled)};
    color: ${({ theme, buttonType }) => typeToColor(buttonType, theme)};
    text-decoration: none;

    &:hover {
        opacity: ${({ buttonType }) => (isLinkWithIconButton(buttonType) ? COLOR_BRIGHTNESS.PRE_MEDIUM / 100 : 1)};
        background-color: ${({ theme, buttonType, disabled }) => typeToHoverBg(buttonType, theme, disabled)};
        color: ${({ theme, buttonType }) => typeToColor(buttonType, theme)};
    }

    &:active,
    &:focus {
        background-color: ${({ theme, buttonType, disabled }) => typeToActiveBg(buttonType, theme, disabled)};
    }

    &:disabled {
        opacity: 1;
        cursor: not-allowed;
        background-color: ${({ buttonType, theme }) =>
            isTextButton(buttonType) ? 'trunsparent' : theme.colors.lightGrey()};

        & > * {
            opacity: ${({ buttonType }) =>
                isLinkButton(buttonType) ? COLOR_BRIGHTNESS.LIGHT / 100 : COLOR_BRIGHTNESS.MEDIUM / 100};
        }
    }
`;

const ButtonInner = styled.span<IButtonInnerProps>`
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 14px;
    line-height: 16px;
    visibility: ${({ isLoading }) => (isLoading ? 'hidden' : 'visible')};

    svg {
        margin-right: ${({ iconOnly }) => (iconOnly ? '0' : '9')}px;
        width: ${({ iconOnly }) => (iconOnly ? '24' : '16')}px;
        height: ${({ iconOnly }) => (iconOnly ? '24' : '16')}px;
    }
`;

const typeToBg = (buttonType: StyledButtonType, theme: DefaultTheme, disabled: boolean): string => {
    if (isTextButton(buttonType)) return 'transparent';
    if (disabled) return theme.colors.lightGrey();

    const hashMap = {
        primary: theme.colors.yellow(),
        secondary: theme.colors.lightGrey(),
        danger: theme.colors.red(),
        link: 'transparent',
        linkWithIcon: 'transparent',
    };

    return hashMap[buttonType];
};

const typeToHoverBg = (buttonType: StyledButtonType, theme: DefaultTheme, disabled: boolean): string => {
    if (isTextButton(buttonType)) return 'transparent';
    if (disabled) return theme.colors.lightGrey();

    const hashMap = {
        primary: theme.colors.hoverYellow(),
        secondary: theme.colors.hoverGray(),
        danger: theme.colors.redPressed(),
        link: 'transparent',
        linkWithIcon: 'transparent',
    };

    return hashMap[buttonType];
};

const typeToActiveBg = (buttonType: StyledButtonType, theme: DefaultTheme, disabled: boolean): string => {
    if (isTextButton(buttonType)) return 'transparent';
    if (disabled) return theme.colors.lightGrey();

    const hashMap = {
        primary: theme.colors.activeYellow(),
        secondary: theme.colors.activeGray(),
        danger: theme.colors.redPressed(),
        link: 'transparent',
        linkWithIcon: 'transparent',
    };

    return hashMap[buttonType];
};

const getButtonPaddings = (buttonType: StyledButtonType, iconOnly: boolean, hasIcon: boolean): string => {
    if (isTextButton(buttonType)) return '0px';

    if (iconOnly) {
        return '8px';
    } else {
        return hasIcon ? '15px 20px' : '12px 20px';
    }
};

const typeToColor = (buttonType: StyledButtonType, theme: DefaultTheme): string => {
    const hashMap = {
        primary: theme.colors.base(),
        secondary: theme.colors.base(),
        danger: theme.colors.white(),
        link: theme.colors.grey(),
        linkWithIcon: theme.colors.base(),
    };

    return hashMap[buttonType];
};
