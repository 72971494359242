import yup from '@utils/validations';
import { ORDER_CUSTOMER_FIELDS, ORDER_FIELDS, ORDER_ITEM_FIELDS } from '@store/Order/types';
import { COUNTERPARTY_FIELDS } from '@store/Counterparty/contants';
import { CLIENT_FIELDS } from '@store/Client/contants';

export const modalAddOrderItemSchema = yup.object().shape({
    [ORDER_ITEM_FIELDS.name]: yup.string().required(),
    [ORDER_ITEM_FIELDS.type]: yup.string().required(),
    [ORDER_ITEM_FIELDS.vatCode]: yup.string().required(),
    [ORDER_ITEM_FIELDS.amount]: yup.string().isValidMinPrice().required(),
    [ORDER_ITEM_FIELDS.quantity]: yup.number().min(1).required(),
    [ORDER_ITEM_FIELDS.sku]: yup.string().required(),
    [ORDER_ITEM_FIELDS.measureCode]: yup.string().required(),
});

export const orderSchema = yup.object().shape({
    [ORDER_FIELDS.merchantOrderId]: yup.string().required(),
    [ORDER_FIELDS.expirationDate]: yup.date().isNotToDayAndFifteenMinutesAfter().required(),
    [ORDER_CUSTOMER_FIELDS.phone]: yup.string().when(ORDER_FIELDS.isHideRequisites, {
        is: false,
        then: yup.string().isValidPhone().required(),
        otherwise: yup.string().notRequired(),
    }),
    [ORDER_CUSTOMER_FIELDS.email]: yup.string().when(ORDER_FIELDS.isHideRequisites, {
        is: false,
        then: yup.string().email().required(),
        otherwise: yup.string().notRequired(),
    }),
    [ORDER_CUSTOMER_FIELDS.name]: yup.string().when(ORDER_FIELDS.isHideRequisites, {
        is: false,
        then: yup.string().required(),
        otherwise: yup.string().notRequired(),
    }),
    [ORDER_CUSTOMER_FIELDS.vatNumber]: yup
        .string()
        .when([ORDER_FIELDS.isLegalCustomer, ORDER_FIELDS.isShowRequisites], {
            is: true,
            then: yup.string().isValidInn().required(),
            otherwise: yup.string().notRequired(),
        }),

    [ORDER_CUSTOMER_FIELDS.registrationAddress]: yup
        .string()
        .when([ORDER_FIELDS.isLegalCustomer, ORDER_FIELDS.isShowRequisites], {
            is: true,
            then: yup.string().required(),
            otherwise: yup.string().notRequired(),
        }),
});

export const ModalAddCounterpartySchema = yup.object().shape({
    [COUNTERPARTY_FIELDS.name]: yup.string().required(),
    [COUNTERPARTY_FIELDS.vatNumber]: yup.string().isValidInn().required(),
    [COUNTERPARTY_FIELDS.registrationAddress]: yup.string().required(),
});

export const ModalAddContactPersonSchema = yup.object().shape({
    [CLIENT_FIELDS.name]: yup.string().required(),
    [CLIENT_FIELDS.phone]: yup.string().isValidPhone().required(),
    [CLIENT_FIELDS.email]: yup.string().email().required(),
});

const getValidateByFields = (fieldName: string) => {
    switch (fieldName) {
        case 'phone': {
            return yup.string().isValidPhone();
        }
        case 'email': {
            return yup.string().email();
        }
        case 'vatNumber': {
            return yup.string().isValidInn();
        }
        default: {
            return yup.string();
        }
    }
};

export const dynamicFormSchema = (fields?: string[], required?: string[]) => {
    const validateArray = fields?.map((field) => {
        if (field !== 'body') {
            const isRequired = Boolean(required?.find((requiredField) => requiredField === field));
            const validationField = getValidateByFields(field);
            return { [field]: isRequired ? validationField.required() : validationField };
        }
        return null;
    });

    // @ts-ignore
    return validateArray && Object.assign(...validateArray);
};

export const createIntegrationSettingSchema = (fields?: string[], required?: string[]) =>
    yup.object().shape({
        ...dynamicFormSchema(fields, required),
        name: yup.string().required(),
        targetId: yup.string().required(),
        targetType: yup.string().required(),
        applicationId: yup.string().required(),
    });
