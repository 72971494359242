import http, { ICommonResponse } from '@utils/http';
import { CLIENT_FIELDS } from './contants';
import { IClient, TClientRequestFields } from './types';

export const createClientRequest = (
    data: Pick<
        IClient,
        CLIENT_FIELDS.name | CLIENT_FIELDS.phone | CLIENT_FIELDS.email | CLIENT_FIELDS.ownerCounterpartyId
    >,
) => http.post<ICommonResponse<IClient>>('billing/api/counterparty/contact', data);

export const getClientsListRequest = () => http.get<ICommonResponse<IClient[]>>('filter/api/counterparty/contact');

export const updateClientRequest = async (body: TClientRequestFields) =>
    http.put<ICommonResponse<IClient>>(`billing/api/counterparty/contact/${body.id}`, body);

export const removeClientRequest = (id: number) => http.delete(`billing/api/counterparty/contact/${id}`);
