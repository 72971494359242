import React, { FC } from 'react';
import { ArrowNavigationButton, SplitterNumbers } from '@components/FilterOrders/components/Pagination/styles';
import ButtonNumberPageComponent from '../ButtonNumberPage';
import ArrowIcon from '../../assets/arrow-left.svg';
import { PaginationContainer } from '../../styles';

interface IPaginationStack {
    pagesButtonsCount: number;
    pageNumber: number;
    onChange: (page: number) => void;
}

const PaginationStack: FC<IPaginationStack> = ({ pagesButtonsCount, pageNumber, onChange }) => {
    const FIRST_PAGE = 1;
    const renderButtonsNumber = (countPages: number, currentPage: number) => {
        const arrayNumbersJSX = [];
        // если не более 4х страниц - отобразим все
        if (countPages <= 4) {
            for (let i = 1; i <= countPages; i++) {
                arrayNumbersJSX.push(
                    <ButtonNumberPageComponent key={i} number={i} onChange={onChange} isActive={currentPage === i} />,
                );
            }
        } else if (currentPage <= FIRST_PAGE + 2) {
            // если текущая страница входит в первые 3
            for (let i = 1; i <= FIRST_PAGE + 2; i++) {
                arrayNumbersJSX.push(
                    <ButtonNumberPageComponent key={i} number={i} onChange={onChange} isActive={currentPage === i} />,
                );
            }
            if (currentPage === FIRST_PAGE + 2) {
                arrayNumbersJSX.push(
                    <ButtonNumberPageComponent key={FIRST_PAGE + 3} number={FIRST_PAGE + 3} onChange={onChange} />,
                );
                if (currentPage + 2 !== countPages) {
                    arrayNumbersJSX.push(<SplitterNumbers key="splitterStart">...</SplitterNumbers>);
                }
            } else {
                arrayNumbersJSX.push(<SplitterNumbers key="splitterStart">...</SplitterNumbers>);
            }
            arrayNumbersJSX.push(
                <ButtonNumberPageComponent key={countPages} number={countPages} onChange={onChange} />,
            );
        } else if (currentPage >= countPages - 2) {
            // если текущая страница входит в последние 3
            arrayNumbersJSX.push(
                <ButtonNumberPageComponent key={FIRST_PAGE} number={FIRST_PAGE} onChange={onChange} />,
            );
            arrayNumbersJSX.push(<SplitterNumbers key="splitterEnd">...</SplitterNumbers>);
            if (currentPage >= countPages - 1) {
                for (let i = countPages - 2; i <= countPages; i++) {
                    arrayNumbersJSX.push(
                        <ButtonNumberPageComponent
                            key={i}
                            number={i}
                            onChange={onChange}
                            isActive={currentPage === i}
                        />,
                    );
                }
            } else {
                for (let i = currentPage - 1; i <= currentPage + 1; i++) {
                    arrayNumbersJSX.push(
                        <ButtonNumberPageComponent
                            key={i}
                            number={i}
                            onChange={onChange}
                            isActive={currentPage === i}
                        />,
                    );
                }
                arrayNumbersJSX.push(
                    <ButtonNumberPageComponent key={countPages} number={countPages} onChange={onChange} />,
                );
            }
        } else {
            // если текущая страница не входит в первые 3 и последние 3
            arrayNumbersJSX.push(
                <ButtonNumberPageComponent key={FIRST_PAGE} number={FIRST_PAGE} onChange={onChange} />,
            );
            arrayNumbersJSX.push(<SplitterNumbers key="splitterStart">...</SplitterNumbers>);
            for (let i = currentPage - 1; i <= currentPage + 1; i++) {
                arrayNumbersJSX.push(
                    <ButtonNumberPageComponent key={i} number={i} onChange={onChange} isActive={currentPage === i} />,
                );
            }
            arrayNumbersJSX.push(<SplitterNumbers key="splitterEnd">...</SplitterNumbers>);
            arrayNumbersJSX.push(
                <ButtonNumberPageComponent key={countPages} number={countPages} onChange={onChange} />,
            );
        }
        return arrayNumbersJSX;
    };

    return (
        <PaginationContainer>
            <ArrowNavigationButton
                onClick={() => {
                    onChange(pageNumber - 1);
                }}
                isNextButton={false}
                type="button"
                disabled={pageNumber === FIRST_PAGE}
            >
                <ArrowIcon />
            </ArrowNavigationButton>
            {renderButtonsNumber(pagesButtonsCount, pageNumber)}
            <ArrowNavigationButton
                onClick={() => {
                    onChange(pageNumber + 1);
                }}
                isNextButton
                type="button"
                disabled={pageNumber === pagesButtonsCount}
            >
                <ArrowIcon />
            </ArrowNavigationButton>
        </PaginationContainer>
    );
};
export default PaginationStack;
