import React, { FC, useEffect } from 'react';
import {
    ButtonAddNewIntegration,
    HeaderDescription,
    IntegrationApiLink,
    IntegrationsHeader,
    IntegrationsList,
    IntegrationsListTitle,
    IntegrationsListWrapper,
    IntegrationsWrapper,
} from '@pages/Shop/ShopTabsTables/Integrations/styles';
import IconPlus from '@assets/icons/plus.svg';
import { Routes } from '@pages/constants';
import { useHistory, useParams } from 'react-router-dom';
import { useAppDispatch } from '@store';
import IntegrationItem from '@pages/Shop/ShopTabsTables/Integrations/components/IntegrationItem';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import ModalIntegrationCode from '@pages/Shop/ShopTabsTables/Integrations/components/ModalIntegrationCode';
import useModal from '@hooks/useModal';
import { APIDescriptionLink } from '@utils/constants';
import { fetchSecurityApiUserGroupList } from '@store/SecurityApiUseGroup';
import { getSecurityApiUserGroupList } from '@store/SecurityApiUseGroup/selectors';

interface IIntegrations {
    location?: {
        state: {
            activationCode: string;
            activationExpiredTime: string;
        };
    };
}

const Integrations: FC<IIntegrations> = ({ location }) => {
    const { merchantId } = useParams<{ merchantId: string }>();
    const history = useHistory();
    const dispatch = useAppDispatch();
    const intl = useIntl();
    const { isOpen: isOpenModal, open: openModal, close: onCloseModal } = useModal(false);

    const securityApiUserGroupList = useSelector(getSecurityApiUserGroupList);
    const integrationActiveCode = location?.state?.activationCode;
    const integrationExpiredCodeTime = location?.state?.activationExpiredTime;
    const canOpenModalActiveCode = integrationActiveCode && integrationExpiredCodeTime;

    useEffect(() => {
        if (canOpenModalActiveCode) {
            openModal();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [canOpenModalActiveCode]);

    useEffect(() => {
        dispatch(fetchSecurityApiUserGroupList({ targetId: merchantId, targetType: 'merchant' }));
    }, [dispatch, merchantId]);

    const goToCreateIntegrationPage = () => {
        history.push(`${Routes.dashboard}${Routes.shop}/${merchantId}${Routes.createintegration}`);
    };

    return (
        <IntegrationsWrapper>
            <IntegrationsHeader>
                <HeaderDescription>
                    {intl.formatMessage({
                        defaultMessage: `Интеграции позволяют настроить взаимодействие системы Инвойсбокс и учётной системы магазина с помощью `,
                    })}{' '}
                    <IntegrationApiLink target="_blank" href={APIDescriptionLink}>
                        API.
                    </IntegrationApiLink>
                </HeaderDescription>
                <ButtonAddNewIntegration onClick={goToCreateIntegrationPage} icon={<IconPlus />}>
                    {intl.formatMessage({ defaultMessage: 'Добавить новую интеграцию' })}
                </ButtonAddNewIntegration>
            </IntegrationsHeader>
            {securityApiUserGroupList?.length > 0 && (
                <IntegrationsListWrapper>
                    <IntegrationsListTitle>
                        {intl.formatMessage({ defaultMessage: 'Интеграции' })}
                    </IntegrationsListTitle>
                    <IntegrationsList>
                        {securityApiUserGroupList?.map((securityApiUserGroup) => (
                            <IntegrationItem
                                key={securityApiUserGroup.id}
                                securityApiUserGroup={securityApiUserGroup}
                            />
                        ))}
                    </IntegrationsList>
                </IntegrationsListWrapper>
            )}
            {integrationActiveCode && integrationExpiredCodeTime && (
                <ModalIntegrationCode
                    codeActivation={integrationActiveCode}
                    expiredTime={integrationExpiredCodeTime}
                    isAfterCreateIntegration
                    onClose={onCloseModal}
                    isOpen={isOpenModal}
                />
            )}
        </IntegrationsWrapper>
    );
};

export default Integrations;
