import styled from 'styled-components';
import Typography from '@components/Typography';

export const TotalRowWrapper = styled.div`
    ${({ theme }) => theme.mixins.flexCenter}
    justify-content: space-between;
    flex-wrap: wrap;
`;

export const TotalRowLabel = styled(Typography).attrs({ variant: 'regular14' })`
    color: ${({ theme }) => theme.colors.base()};
`;

export const TotalRowValue = styled(Typography).attrs({ variant: 'regular14' })`
    color: ${({ theme }) => theme.colors.grey()};
    white-space: nowrap;
`;
