import React, { FC } from 'react';
import {
    CompositionItems,
    CompositionTitle,
    CompositionWrapper,
} from '@pages/Shop/ShopTabsTables/RefundDetail/components/Composition/styles';
import { TOrderItem, TVatType } from '@store/Order/types';
import CompositionItem from '@pages/Shop/ShopTabsTables/RefundDetail/components/CompositionItem';
import useDictionary from '@hooks/useDictionarty';
import { DICTIONARY_TYPES } from '@store/Dictionaries/constants';
import { useIntl } from 'react-intl';

interface IComposition {
    refundItems?: TOrderItem[];
    basketItems?: TOrderItem[];
}

const Composition: FC<IComposition> = ({ refundItems, basketItems }) => {
    const intl = useIntl();
    const vatTypes: TVatType[] = useDictionary(DICTIONARY_TYPES.vat)?.data as any;

    return (
        <CompositionWrapper>
            <CompositionTitle>{intl.formatMessage({ defaultMessage: 'Состав возврата' })}</CompositionTitle>
            <CompositionItems>
                {refundItems && basketItems ? (
                    refundItems.map((refundItem) => {
                        const orderItem = basketItems.find((basketItem) => basketItem.sku === refundItem.sku);
                        const isFullRefund = orderItem?.totalAmount === refundItem.totalAmount;

                        return (
                            <CompositionItem
                                isFullRefund={isFullRefund}
                                vatTypes={vatTypes}
                                key={refundItem.sku}
                                refundItem={refundItem}
                            />
                        );
                    })
                ) : (
                    <CompositionItem vatTypes={vatTypes} />
                )}
            </CompositionItems>
        </CompositionWrapper>
    );
};

export default Composition;
