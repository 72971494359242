import styled from 'styled-components';
import Typography from '@components/Typography';
import { NavLink } from 'react-router-dom';

interface IClientItemWrapper {
    isclientcounterparty: boolean | undefined;
}

export const ClientItemWrapper = styled(NavLink)<IClientItemWrapper>`
    text-decoration: none;
    display: grid;
    grid-column-gap: 50px;
    align-items: center;
    grid-template-columns: ${({ isclientcounterparty }) => (isclientcounterparty ? 'auto 110px' : 'auto 200px 133px')};
    padding: 20px 25px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.lightGrey()};
    ${({ theme }) => theme.mixins.getTypography('regular14')};
    @media ${({ theme }) => theme.breakpoints.md} {
        grid-template-columns: ${({ isclientcounterparty }) => (isclientcounterparty ? 'auto 90px' : 'auto 141px')};
        grid-template-rows: repeat(2, 20px);
        grid-column-gap: 30px;
    }
    @media ${({ theme }) => theme.breakpoints.sm} {
        padding: 20px;
    }
`;

export const NameWrapper = styled.div`
    ${({ theme }) => theme.mixins.flexCenter};
    grid-column-gap: 20px;
    @media ${({ theme }) => theme.breakpoints.md} {
        grid-row-start: 1;
        grid-row-end: 3;
    }
    @media ${({ theme }) => theme.breakpoints.sm} {
        grid-column-gap: 13px;
    }
`;

export const Email = styled(Typography).attrs({ variant: 'regular14' })`
    @media ${({ theme }) => theme.breakpoints.md} {
        ${({ theme }) => theme.mixins.getTypography('regular12')}
        text-align: end;
    }
`;

export const Phone = styled(Typography).attrs({ variant: 'regular14' })`
    @media ${({ theme }) => theme.breakpoints.md} {
        grid-column-start: 2;
        ${({ theme }) => theme.mixins.getTypography('regular12')}
        text-align: end;
    }
`;

export const VatNumber = styled(Typography).attrs({ variant: 'regular14' })`
    @media ${({ theme }) => theme.breakpoints.md} {
        ${({ theme }) => theme.mixins.getTypography('regular12')}
        text-align: end;
        grid-row-start: 1;
        grid-row-end: 3;
    }
`;
