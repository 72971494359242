import styled from 'styled-components';
import StoriesContainer from '@pages/Dashboard/components/Stories/components/StoriesContainer';

export const ModalStoriesWrapper = styled(StoriesContainer)`
    width: 375px;
    height: 100%;
    padding: 14px 38px 48px 38px;
    position: relative;
    overflow: hidden;
    border-radius: ${({ theme }) => theme.decorations.borderRadius?.base}px;
    ${({ theme }) => theme.mixins.flexCenter};
    flex-direction: column;
    justify-content: space-between;

    @media ${({ theme }) => theme.breakpoints.sm} {
        width: 100%;
        height: 100%;
        padding-top: 47px;
    }
`;
