import styled from 'styled-components';
import Typography from '@components/Typography';
import Button from '@components/Button';

export const PaymentHeader = styled.div`
    margin: 33px 0 50px 0;
    ${({ theme }) => theme.mixins.flexCenter}
    grid-column-gap: 15px;
    @media ${({ theme }) => theme.breakpoints.ld} {
        margin-bottom: 40px;
    }
`;

export const Title = styled(Typography).attrs({ variant: 'bigTitle' })``;

export const InputContainer = styled.form`
    position: relative;
    margin-bottom: 50px;
    @media ${({ theme }) => theme.breakpoints.ld} {
        margin-bottom: 30px;
    }
`;

export const ButtonSearch = styled.button`
    position: absolute;
    right: 15px;
    bottom: 12px;
`;

export const ButtonPay = styled(Button)`
    width: 100%;
    margin-top: 24px;
`;
