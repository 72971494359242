import styled from 'styled-components';
import { COLOR_BRIGHTNESS } from '@theme/utils';
import Typography from '@components/Typography';

export const MobileSlideWrapper = styled.div`
    border-radius: ${({ theme }) => theme.decorations.borderRadius.base}px;
    background: ${({ theme }) => theme.colors.lightGrey(COLOR_BRIGHTNESS.MEDIUM)};
    padding: 30px 20px 72px 20px;
`;

export const Title = styled(Typography).attrs({ variant: 'title' })`
    margin-bottom: 24px;
`;

export const Paragraphs = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 14px;
`;

export const Paragraph = styled(Typography).attrs({ variant: 'regular14' })`
    color: ${({ theme }) => theme.colors.base(COLOR_BRIGHTNESS.MEDIUM)};
`;
