import { isArray, isObject, map, reduce } from 'lodash';

export type TStrapiEntity<T> = {
    id: number;
    attributes: T;
};

export type TStrapiLinkedEntity<T> = {
    data: TStrapiEntity<T>;
};

const flattenDataAndAttributes = (entity: any) =>
    reduce(
        entity,
        (result: any, value: any, key: any) => {
            const isAttributeOrDataField = key === 'attributes' || key === 'data';
            const isSimpleType = !isArray(value) && !isObject(value);
            if (!isAttributeOrDataField && isSimpleType) result[key] = value;
            if (!isAttributeOrDataField && isObject(value) && !isArray(value)) {
                result[key] = flattenDataAndAttributes(value);
            }
            if (!isAttributeOrDataField && isArray(value)) {
                result[key] = map(value, flattenDataAndAttributes);
            }
            if (isAttributeOrDataField && isObject(value)) {
                result = { ...result, ...flattenDataAndAttributes(value) };
            }
            return result;
        },
        {},
    );

export default flattenDataAndAttributes;
