import React, { FC } from 'react';
import {
    BackgroundBaseCircle,
    BackgroundYellowCircle,
    Commission,
    Description,
    FinishedTariff,
    HeadIconWrapper,
    ModalHead,
    Name,
    Note,
    TariffModalWrapper,
    TitleTariff,
} from '@components/Widgets/ClaimActiveWidget/components/ModalTariff/styles';
import TariffIcon from '@assets/icons/tariff.svg';
import { IContract } from '@store/Contracts/types';
import dayjs from 'dayjs';
import { DD_MM_YYYY_DATE_FORMAT } from '@utils/constants';
import { useIntl } from 'react-intl';

interface IModalTariff {
    isActiveTariff?: boolean;
    isCanConnectNewTariff?: boolean;
    contract: IContract;
    closeModal: () => void;
    isOpen: boolean;
}

const ModalTariff: FC<IModalTariff> = ({ isActiveTariff = false, contract, closeModal, isOpen }) => {
    const intl = useIntl();

    return (
        <TariffModalWrapper closeModal={closeModal} isOpen={isOpen}>
            <ModalHead>
                <HeadIconWrapper>
                    <TariffIcon />
                </HeadIconWrapper>
                <TitleTariff>
                    {isActiveTariff && (
                        <Note>
                            {intl.formatMessage({ defaultMessage: 'текущий тариф' })}
                            {contract.finishAt && (
                                <FinishedTariff>
                                    {dayjs(contract.finishAt).format(DD_MM_YYYY_DATE_FORMAT)}
                                </FinishedTariff>
                            )}
                        </Note>
                    )}
                    <Name>{contract.title}</Name>
                    <Commission>
                        {intl.formatMessage({ defaultMessage: 'от' })} {contract.minFeePercent}%
                    </Commission>
                </TitleTariff>
            </ModalHead>
            <Description>{contract.fullDescription}</Description>
            <BackgroundYellowCircle />
            <BackgroundBaseCircle />
        </TariffModalWrapper>
    );
};

export default ModalTariff;
