import styled from 'styled-components';

export const ClientCounterpartyFields = styled.div`
    display: grid;
    grid-template-columns: auto 110px auto;
    grid-column-gap: 20px;
    padding: 0 30px 30px 30px;
    @media ${({ theme }) => theme.breakpoints.md} {
        grid-template-columns: 1fr;
        grid-row-gap: 20px;
        padding: 0 40px 40px 40px;
    }
`;
