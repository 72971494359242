import styled from 'styled-components';
import Typography from '@components/Typography';

interface IButtonCardTariff {
    isActive: boolean;
}

export const ButtonCardTariff = styled.button<IButtonCardTariff>`
    border-radius: ${({ theme }) => theme.decorations.borderRadius.base}px;
    border: 1px solid ${({ isActive, theme }) => (isActive ? theme.colors.yellow() : `rgba(153, 153, 153, 0.3)`)};
    padding: 20px 14px 16px 14px;
    ${({ theme }) => theme.mixins.flexCenter}
    justify-content: space-between;
    flex-direction: column;
    height: 258px;
    @media ${({ theme }) => theme.breakpoints.ld} {
        height: auto;
    }
`;

export const Commission = styled(Typography).attrs({ variant: 'subheading' })`
    margin-top: 2px;
    padding-top: 12px;
    border-top: 1px solid rgba(153, 153, 153, 0.3);
    width: 100%;
    text-align: center;
`;

export const CommissionValue = styled.span`
    ${({ theme }) => theme.mixins.getTypography('bigTitle')}
`;

export const Information = styled.div`
    ${({ theme }) => theme.mixins.flexCenter}
    flex-direction: column;
    grid-row-gap: 10px;
`;

export const TariffName = styled(Typography).attrs({ variant: 'title' })`
    text-align: center;
`;

export const TariffDescription = styled(Typography).attrs({ variant: 'regular14' })`
    color: ${({ theme }) => theme.colors.grey()};
    width: 100%;
    text-align: center;
    height: 70px;
    overflow: hidden;
`;
