import styled from 'styled-components';

export const GeneralInformation = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: 32px;
    grid-column-gap: 45px;
    padding-left: 20px;
    margin-bottom: 59px;
    word-wrap: anywhere;

    div:last-child {
        grid-column-start: 1;
        grid-column-end: 3;
    }
    @media ${({ theme }) => theme.breakpoints.sm} {
        grid-template-columns: 100%;
        div:last-child {
            grid-column-start: auto;
            grid-column-end: auto;
        }
    }
`;
