import React, { FC } from 'react';
import { IUserProfile } from '@store/Auth/types';
import { Form } from 'react-final-form';
import FormField from '@components/FormField';
import { INTRODUCE_FIELDS } from '@store/Auth/constants';
import { PersonalForm } from '@pages/Profile/ProfileTabs/Personal/components/FormPersonal/styles';
import { useAppDispatch } from '@store';
import { updateUser } from '@store/Auth';
import useValidationSchema from '@hooks/useValidationSchema';
import { personalProfileSchema } from '@pages/Profile/validations';
import { useIntl } from 'react-intl';

interface IFormPersonal {
    formId: string;
    setIsEditSettings: (arg: boolean) => void;
    user: IUserProfile;
    toggleSaving: () => void;
}

const FormPersonal: FC<IFormPersonal> = ({ toggleSaving, formId, setIsEditSettings, user }) => {
    const intl = useIntl();
    const dispatch = useAppDispatch();
    const validation = useValidationSchema(personalProfileSchema);

    const onSubmit = async (formFields: IUserProfile) => {
        toggleSaving();
        const result = await dispatch(updateUser(formFields));
        // @ts-ignore
        if (!result?.error && result?.payload) {
            setIsEditSettings(false);
        }
        toggleSaving();
    };

    return (
        <Form onSubmit={onSubmit} initialValues={user} validate={validation}>
            {({ handleSubmit }) => (
                <PersonalForm onSubmit={handleSubmit} id={formId}>
                    <FormField
                        name={INTRODUCE_FIELDS.lastName}
                        label={intl.formatMessage({ defaultMessage: 'Фамилия' })}
                        placeholder={intl.formatMessage({ defaultMessage: 'Введите фамилию' })}
                    />
                    <FormField
                        name={INTRODUCE_FIELDS.firstName}
                        label={intl.formatMessage({ defaultMessage: 'Имя' })}
                        placeholder={intl.formatMessage({ defaultMessage: 'Введите имя' })}
                    />
                    <FormField
                        name={INTRODUCE_FIELDS.email}
                        label="Email"
                        placeholder={intl.formatMessage({ defaultMessage: 'Введите Email' })}
                    />
                </PersonalForm>
            )}
        </Form>
    );
};

export default FormPersonal;
