import React from 'react';
import { DescriptionField, OrderGeneralWrapper } from '@pages/OrderContainer/components/FormOrderGeneral/styles';

import { InputTypeEnum } from '@components/Input';
import { ORDER_FIELDS } from '@store/Order/types';
import FormField, { FormFieldTypeEnum } from '@components/FormField';
import { addMinutesToDate } from '@utils/helpers';
import { useIntl } from 'react-intl';

const FormOrderGeneral = () => {
    const intl = useIntl();

    return (
        <OrderGeneralWrapper>
            <FormField
                typeInput={InputTypeEnum.numeric}
                name={ORDER_FIELDS.merchantOrderId}
                label={intl.formatMessage({ defaultMessage: 'Номер счёта' })}
            />
            <FormField
                fieldType={FormFieldTypeEnum.inputDateTime}
                isShowIconIfHaveValue={false}
                name={ORDER_FIELDS.expirationDate}
                label={intl.formatMessage({ defaultMessage: 'Срок оплаты' })}
                minDate={new Date()}
                minTimeInSelectDay={addMinutesToDate(new Date(), 16)}
                showTimeInput
                dateFormat="d.MM.yyyy, H:mm"
                placeholder={intl.formatMessage({ defaultMessage: 'дд.мм.гггг, чч:мм' })}
            />
            <DescriptionField
                fieldType={FormFieldTypeEnum.input}
                name={ORDER_FIELDS.description}
                label={intl.formatMessage({ defaultMessage: 'Комментарий' })}
            />
        </OrderGeneralWrapper>
    );
};

export default FormOrderGeneral;
