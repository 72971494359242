import React, { FC } from 'react';
import {
    ClientItemWrapper,
    Email,
    NameWrapper,
    Phone,
    VatNumber,
} from '@pages/Shop/ShopTabsTables/Clients/components/ClientListItem/styles';
import { IClient } from '@store/Client/types';
import Avatar, { AVATAR_SIZE, AVATAR_VARIANT } from '@components/Avatar';
import { CLIENT_FIELDS } from '@store/Client/contants';
import { convertPhoneToShow } from '@utils/formatters';
import { IClientCounterparty } from '@store/ClientCounterparty/types';
import { CLIENT_COUNTERPARTY_FIELDS } from '@store/ClientCounterparty/constants';
import { typesClients } from '@pages/Shop/ShopTabsTables/Clients';
import { Routes } from '@pages/constants';
import { useParams } from 'react-router-dom';

interface IClientListItem {
    client: IClient | IClientCounterparty;
    typeClient: typesClients;
}

const ClientListItem: FC<IClientListItem> = ({ typeClient, client }) => {
    const { merchantId } = useParams<{ merchantId: string }>();
    const generalPath = `${Routes.dashboard}${Routes.shop}/${merchantId}`;
    const clientName = client[CLIENT_FIELDS.name];
    const isClientCounterParty = typeClient === typesClients.CLIENT_COUNTERPARTY;
    const uniqPathOnClickClient = isClientCounterParty
        ? `${generalPath}${Routes.detailclientcounterparty}/${client[CLIENT_FIELDS.id]}`
        : `${generalPath}${Routes.detailclient}/${client[CLIENT_FIELDS.id]}`;

    const renderDescription = (renderingClient: IClient | IClientCounterparty) => {
        if (isClientCounterParty) {
            const clientCounterparty: IClientCounterparty = renderingClient as any;
            return <VatNumber>{clientCounterparty[CLIENT_COUNTERPARTY_FIELDS.vatNumber]}</VatNumber>;
        }
        const clientData: IClient = renderingClient as any;
        return (
            <>
                <Email>{clientData[CLIENT_FIELDS.email]}</Email>
                <Phone>{clientData[CLIENT_FIELDS.phone] && convertPhoneToShow(clientData[CLIENT_FIELDS.phone])}</Phone>
            </>
        );
    };

    return (
        // @ts-ignore не дает упростить isclientcounterparty, иначе падает ошибка в консоли якобы свойство не булево
        <ClientItemWrapper to={uniqPathOnClickClient} isclientcounterparty={isClientCounterParty ? 1 : 0}>
            <NameWrapper>
                <Avatar
                    isColumnPosition={false}
                    size={AVATAR_SIZE.xs}
                    name={clientName}
                    variant={AVATAR_VARIANT.dark}
                />
                {clientName}
            </NameWrapper>
            {renderDescription(client)}
        </ClientItemWrapper>
    );
};

export default ClientListItem;
