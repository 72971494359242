import { feedAdapter } from './index';
import { RootState } from '../index';
const { selectAll, selectById, selectEntities } = feedAdapter.getSelectors();

export const getFeedEntities = (state: RootState) => selectEntities(state.feed);
export const getFeedList = (state: RootState) => selectAll(state.feed);
export const getLastFiveFeedItems = (state: RootState) => getFeedList(state).slice(0, 5);
export const getFeedById = (id: number) => (state: RootState) => selectById(state.feed, id);
export const getIsFeedEnabled = (state: RootState) => state.feed.enabled;
export const getIsFeedLoading = (state: RootState) => state.feed.loading;
export const getIsTokesSaved = (state: RootState) => state.feed.savedTokenId !== null;
export const getSavedToken = (state: RootState) => state.feed.savedToken;
export const getFeedMetaData = (state: RootState) => state.feed.metaData;
