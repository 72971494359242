import React, { FC } from 'react';
import { Form } from 'react-final-form';
import FormField from '@components/FormField';
import ButtonsNextPrev from '@pages/Claim/components/ButtonsNextPrev';
import { BANK_DETAILS_FIELDS } from '@store/Counterparty/contants';
import {
    BankDetailFormWrapper,
    BankNameInput,
    InputsWrapper,
    SettlementAccountInput,
} from '@pages/Claim/components/FormBankDetail/styles';
import { InputTypeEnum } from '@components/Input';
import useValidationSchema from '@hooks/useValidationSchema';
import { BankDetailSchema } from '@pages/Claim/validations';
import { ICounterpartyBankDetail } from '@store/Counterparty/types';
import { useAppDispatch } from '@store';
import { createCounterpartyBankDetails } from '@store/Counterparty';
import { useParams } from 'react-router-dom';
import useLoadingSubmit from '@hooks/useLoadingSubmit';
import { useIntl } from 'react-intl';

interface IFormBankDetail {
    onClickBack: () => void;
}

const FormBankDetail: FC<IFormBankDetail> = ({ onClickBack }) => {
    const intl = useIntl();
    const { isLoadingSubmit, toggleLoadingSubmit } = useLoadingSubmit();
    const validation = useValidationSchema(BankDetailSchema);
    const dispatch = useAppDispatch();
    const { counterpartyId } = useParams<{ counterpartyId: string }>();

    const onSubmit = async (formValues: ICounterpartyBankDetail) => {
        toggleLoadingSubmit();
        await dispatch(
            createCounterpartyBankDetails({ ...formValues, [BANK_DETAILS_FIELDS.counterpartyId]: counterpartyId }),
        );
        toggleLoadingSubmit();
    };

    return (
        <Form onSubmit={onSubmit} validate={validation}>
            {({ valid, handleSubmit }) => (
                <BankDetailFormWrapper onSubmit={handleSubmit}>
                    <InputsWrapper>
                        <BankNameInput
                            name={BANK_DETAILS_FIELDS.bankName}
                            label={intl.formatMessage({ defaultMessage: 'Наименование банка' })}
                            placeholder={intl.formatMessage({ defaultMessage: 'Введите наименование' })}
                        />
                        <SettlementAccountInput
                            name={BANK_DETAILS_FIELDS.settlementAccount}
                            label={intl.formatMessage({ defaultMessage: 'Номер расчётного счёта' })}
                            placeholder={intl.formatMessage({ defaultMessage: 'Введите номер расчётного счёта' })}
                            typeInput={InputTypeEnum.numeric}
                        />
                        <FormField
                            typeInput={InputTypeEnum.numeric}
                            name={BANK_DETAILS_FIELDS.bic}
                            label={intl.formatMessage({ defaultMessage: 'БИК' })}
                            placeholder={intl.formatMessage({ defaultMessage: 'Введите БИК' })}
                        />
                        <FormField
                            name={BANK_DETAILS_FIELDS.correspondentAccount}
                            label={intl.formatMessage({ defaultMessage: 'Номер корр. счёта' })}
                            placeholder={intl.formatMessage({ defaultMessage: 'Введите номер корр. счёта' })}
                            typeInput={InputTypeEnum.numeric}
                        />
                    </InputsWrapper>
                    <ButtonsNextPrev
                        isLoading={isLoadingSubmit}
                        isActiveNextButton={valid}
                        onClickPrev={onClickBack}
                        onClickNext={handleSubmit}
                    />
                </BankDetailFormWrapper>
            )}
        </Form>
    );
};

export default FormBankDetail;
