import React, { FC, useCallback, useEffect, useState } from 'react';
import {
    ButtonAddNew,
    ButtonContent,
    ChooseMerchantWrapper,
    MerchantCardButton,
    MerchantContent,
    MerchantName,
    MerchantsWrapper,
} from '@pages/Claim/components/MerchantPayment/styles';
import { useSelector } from 'react-redux';
import { getCurrentCounterpartyMerchantsWithoutModeratingOpenClaim, getMerchantById } from '@store/Merchant/selectors';
import MerchantLogo from '@components/MerchantLogo';
import { MERCHANT_FIELDS } from '@store/Merchant/contants';
import PlusImage from '@assets/icons/plus.svg';
import { IMerchant } from '@store/Merchant/types';
import ButtonsNextPrev from '@pages/Claim/components/ButtonsNextPrev';
import FormMerchant from '@pages/Claim/components/FormMerchant';
import { useParams } from 'react-router-dom';
import { enumTabsConnection, IStep } from '@pages/Claim/components/MainConnectionPayment';
import { useAppDispatch } from '@store';
import { setSelectedMerchantId } from '@store/Merchant';
import { createClaim, setPassportData, setSelectedClaimId } from '@store/Claims';
import { IClaim } from '@store/Claims/types';
import { getOpenClaimDraftByMerchantId } from '@store/Claims/selectors';
import { ClaimFields } from '@store/Claims/constants';
import { setSelectedContractId, setSelectedContractTemplateId } from '@store/Contracts';
import { getContractById } from '@store/Contracts/selectors';
import { setBankDetailId } from '@store/Counterparty';
import { useIntl } from 'react-intl';

const MerchantPayment: FC<IStep> = ({ setStep, setIsTabsActive }) => {
    const intl = useIntl();
    const dispatch = useAppDispatch();
    const { merchantId } = useParams<{ merchantId: string }>();

    const [selectedMerchant, setSelectedMerchant] = useState<IMerchant>();
    const [isNeedFillMerchant, setIsNeedFillMerchant] = useState(false);

    const urlMerchant = useSelector(getMerchantById(merchantId));
    const merchants = useSelector(getCurrentCounterpartyMerchantsWithoutModeratingOpenClaim);
    const claim = useSelector(getOpenClaimDraftByMerchantId(selectedMerchant?.id || null));
    const merchantContract = useSelector(getContractById(Number(claim?.merchantContractId)));

    useEffect(() => {
        if (urlMerchant) {
            setSelectedMerchant(urlMerchant);
        }
    }, [urlMerchant]);

    const createClaimToLoad = useCallback(
        async (merchantIdToCreate: string) => {
            const data = await dispatch(createClaim(merchantIdToCreate));
            // @ts-ignore
            if (!data?.error && data?.payload?.id) {
                const currentClaim: IClaim = data.payload as any;
                await dispatch(setSelectedClaimId(currentClaim.id));
            }
        },
        [dispatch],
    );

    const resetTemplatePassportContractBank = useCallback(() => {
        dispatch(setSelectedContractId(null));
        dispatch(setSelectedContractTemplateId(null));
        dispatch(setBankDetailId(null));
        dispatch(setPassportData({}));
    }, [dispatch]);

    const checkMerchantClaim = useCallback(() => {
        if (claim) {
            if (claim[ClaimFields.passport]?.name && claim[ClaimFields.merchantContractId]) {
                dispatch(setSelectedContractId(claim[ClaimFields.merchantContractId]));
                dispatch(setBankDetailId(merchantContract?.bankDetailId));
                dispatch(setPassportData(claim[ClaimFields.passport]));
                setStep(enumTabsConnection.documents);
                setIsTabsActive(true);
            } else {
                resetTemplatePassportContractBank();
                setStep(enumTabsConnection.tariff);
            }
            dispatch(setSelectedClaimId(claim.id));
        } else {
            resetTemplatePassportContractBank();
            createClaimToLoad((selectedMerchant as IMerchant).id);
            setStep(enumTabsConnection.tariff);
        }
    }, [
        claim,
        createClaimToLoad,
        dispatch,
        merchantContract?.bankDetailId,
        resetTemplatePassportContractBank,
        selectedMerchant,
        setIsTabsActive,
        setStep,
    ]);

    // если произошел переход со страницы мерчанта
    useEffect(() => {
        if (urlMerchant && selectedMerchant && urlMerchant === selectedMerchant) {
            if (checkRequiredFields(selectedMerchant as IMerchant)) {
                // проверка, есть ли на данного мерчанта созданная заявка
                checkMerchantClaim();
            } else {
                setIsNeedFillMerchant(true);
            }
            dispatch(setSelectedMerchantId((selectedMerchant as IMerchant).id));
        }
    }, [checkMerchantClaim, dispatch, selectedMerchant, urlMerchant]);

    const checkRequiredFields = (currentMerchant: IMerchant) => {
        const requiredFields = [
            MERCHANT_FIELDS.name,
            MERCHANT_FIELDS.mccId,
            MERCHANT_FIELDS.description,
            MERCHANT_FIELDS.email,
            MERCHANT_FIELDS.website,
            MERCHANT_FIELDS.phone,
        ];
        return requiredFields.every(
            (item) => Object.entries(currentMerchant).findIndex((field) => field[0] === item) !== -1,
        );
    };

    const onClickNext = async () => {
        if (checkRequiredFields(selectedMerchant as IMerchant)) {
            checkMerchantClaim();
        } else {
            setIsNeedFillMerchant(true);
        }
        const idMerchant = (selectedMerchant as IMerchant).id;
        await dispatch(setSelectedMerchantId(idMerchant));
    };

    const onClickAddNew = () => {
        setSelectedMerchant(undefined);
        setIsNeedFillMerchant(true);
    };

    const returnToMerchantChoose = () => {
        setIsNeedFillMerchant(false);
    };

    if (isNeedFillMerchant) {
        return (
            <FormMerchant
                resetTemplatePassportContractBank={resetTemplatePassportContractBank}
                checkMerchantClaim={checkMerchantClaim}
                nextStep={() => {
                    setStep(enumTabsConnection.tariff);
                }}
                returnMerchantChoose={returnToMerchantChoose}
                merchant={selectedMerchant}
            />
        );
    }

    return (
        <>
            <ChooseMerchantWrapper>
                <MerchantsWrapper>
                    {merchants.map((merchant) => (
                        <MerchantCardButton
                            onClick={() => {
                                setSelectedMerchant(merchant);
                            }}
                            isActive={selectedMerchant?.id === merchant[MERCHANT_FIELDS.id]}
                            key={merchant[MERCHANT_FIELDS.id]}
                        >
                            <MerchantContent>
                                <MerchantLogo merchantId={merchant[MERCHANT_FIELDS.id]} />
                                <MerchantName>{merchant[MERCHANT_FIELDS.name]}</MerchantName>
                            </MerchantContent>
                        </MerchantCardButton>
                    ))}
                </MerchantsWrapper>
                <ButtonAddNew onClick={onClickAddNew}>
                    <ButtonContent>
                        <PlusImage />
                        {intl.formatMessage({ defaultMessage: 'Добавить новый' })}
                    </ButtonContent>
                </ButtonAddNew>
            </ChooseMerchantWrapper>
            <ButtonsNextPrev isActiveNextButton={Boolean(selectedMerchant)} onClickNext={onClickNext} />
        </>
    );
};

export default MerchantPayment;
