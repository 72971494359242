import Breadcrumb from '@components/Breadcrumb';
import { Routes } from '@pages/constants';
import Skeleton from '@components/Skeleton';
import MerchantLogo from '@components/MerchantLogo';
import ShopNavigation from '@pages/Shop/ShopNavigation/ShopNavigation';
import React, { useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { IShopRouteParams } from '@pages/Shop';
import { useSelector } from 'react-redux';
import { IMerchant } from '@store/Merchant/types';
import { getMerchantById } from '@store/Merchant/selectors';
import ClaimBanners from '@pages/Shop/components/ClaimBanners';
import useClickOutside from '@hooks/useClickOutside';
import { useLargeTablet } from '@hooks/useMedia';
import MerchantsList from '@pages/Shop/components/MerchantsList';
import IconArrowImage from '@assets/icons/arrow-down.svg';
import { ModalMerchantCreate } from '@components/ProductsAccordion/styles';
import { AddingMerchantContext } from '@components/ProductsAccordion/context';
import MerchantModalInner from '@pages/Introduce/components/MerchantModalInner';
import useModal from '@hooks/useModal';
import { useIntl } from 'react-intl';
import { IconWrapper, MerchantName, ShopHeader, ShopHeaderWrapper } from './styles';

const hideHeaderRoutes = [
    Routes.orderToPay,
    Routes.createclient,
    Routes.createcounterparty,
    Routes.detailclientcounterparty,
    Routes.detailclient,
    Routes.refunddetail,
    Routes.createintegration,
];

const MerchantTemplate = () => {
    const intl = useIntl();

    const routesThirdLevelBreadcrumb = [
        {
            title: intl.formatMessage({ defaultMessage: 'Новый счет' }),
            path: Routes.orderToPay,
        },
        {
            title: intl.formatMessage({ defaultMessage: 'Новый клиент' }),
            path: Routes.createclient,
        },
        {
            title: intl.formatMessage({ defaultMessage: 'Возврат по заказу' }),
            path: Routes.refunddetail,
        },
        {
            title: intl.formatMessage({ defaultMessage: 'Добавление интеграции' }),
            path: Routes.createintegration,
        },
    ];

    const { isOpen: isOpenModal, open: openModal, close: onCloseModal } = useModal(false);

    const { merchantId } = useParams<IShopRouteParams>();
    const location = useLocation();
    const isLargeTablet = useLargeTablet();

    const merchant: IMerchant = useSelector(getMerchantById(merchantId)) as any;

    const isShowHeader = !hideHeaderRoutes.find((hideHeaderRoute) => location.pathname.includes(hideHeaderRoute));
    const thirdLevelBreadcrumb = routesThirdLevelBreadcrumb.find((route) => location.pathname.includes(route.path));

    const [isShowMerchantsList, setIsShowMerchantsList] = useState(false);

    const hideMerchantList = () => setIsShowMerchantsList(false);

    const merchantListWrapperRef = useClickOutside(() => {
        setIsShowMerchantsList(false);
    });

    const onClickOpenMerchantsList = () => {
        if (isLargeTablet) {
            setIsShowMerchantsList(!isShowMerchantsList);
        }
    };

    return (
        <>
            <Breadcrumb>
                <Breadcrumb.Item to={Routes.dashboard}>
                    {intl.formatMessage({ defaultMessage: 'Главная' })}
                </Breadcrumb.Item>
                <Breadcrumb.Item to={`${Routes.dashboard}${Routes.shop}/${merchantId}${Routes.overview}`}>
                    {merchant?.name || <Skeleton width={80} />}
                </Breadcrumb.Item>
                {thirdLevelBreadcrumb && <Breadcrumb.Item>{thirdLevelBreadcrumb.title}</Breadcrumb.Item>}
            </Breadcrumb>
            {isShowHeader && (
                <>
                    <ShopHeaderWrapper ref={merchantListWrapperRef}>
                        <ShopHeader type="button" onClick={onClickOpenMerchantsList}>
                            <MerchantLogo merchantId={merchantId} />
                            <MerchantName>{merchant?.name || <Skeleton width={220} />}</MerchantName>
                            {isLargeTablet && (
                                <IconWrapper isActiveIcon={isShowMerchantsList}>
                                    <IconArrowImage />
                                </IconWrapper>
                            )}
                        </ShopHeader>
                        {isShowMerchantsList && (
                            <MerchantsList
                                openModal={openModal}
                                hideMerchantList={hideMerchantList}
                                merchant={merchant}
                            />
                        )}
                    </ShopHeaderWrapper>
                    <ClaimBanners />
                    <ShopNavigation merchantId={merchantId} />
                    <ModalMerchantCreate
                        title={intl.formatMessage({ defaultMessage: 'Добавить новый магазин' })}
                        isOpen={isOpenModal}
                        closeModal={onCloseModal}
                    >
                        <AddingMerchantContext.Provider
                            value={{
                                closeModal: onCloseModal,
                            }}
                        >
                            <MerchantModalInner />
                        </AddingMerchantContext.Provider>
                    </ModalMerchantCreate>
                </>
            )}
        </>
    );
};

export default MerchantTemplate;
