import React, { FC } from 'react';
import { ButtonNumberPage } from '@components/FilterOrders/components/ButtonNumberPage/styles';

interface IButtonNumberPage {
    onChange: (page: number) => void;
    isActive?: boolean;
    number: number;
}

const ButtonNumberPageComponent: FC<IButtonNumberPage> = ({ onChange, isActive, number }) => (
    <ButtonNumberPage disabled={isActive} onClick={() => onChange(number)} isActive={isActive}>
        {number}
    </ButtonNumberPage>
);

export default ButtonNumberPageComponent;
