import styled from 'styled-components';
import Skeleton from '@components/Skeleton';

export const RefundsWrapper = styled.div`
    margin-top: 30px;
    width: 100%;
`;

export const SkeletonMissingClaim = styled(Skeleton).attrs({ borderRadius: 'base' })`
    height: 231px;
    width: 100%;
    margin-top: 60px;
`;
