import styled from 'styled-components';

export const InputsWrapper = styled.form`
    padding: 0 30px 30px 30px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 32px;
    grid-row-gap: 19px;
    @media ${({ theme }) => theme.breakpoints.md} {
        grid-template-columns: 1fr;
        padding: 0 20px 30px 20px;
        grid-row-gap: 10px;
    }
`;
