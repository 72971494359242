import styled from 'styled-components';
import Typography from '@components/Typography';

export const ButtonRowWrapper = styled.button`
    ${({ theme }) => theme.mixins.flexCenter}
    grid-column-gap: 23px;
    border-top: 1px solid ${({ theme }) => theme.colors.lightGrey()};
    padding: 14px 0;
    width: 100%;
`;

export const IconWrapper = styled.div`
    width 36px;
    height: 36px;
    border-radius: 50%;
    background: ${({ theme }) => theme.colors.lightGrey()};
    ${({ theme }) => theme.mixins.flexCenterCenter};
    padding: 15px 0;
    flex-shrink: 0;
`;

export const NotificationContent = styled.div``;

export const NotificationTitle = styled(Typography).attrs({ variant: 'regular14' })`
    margin-bottom: 7px;
`;

export const NotificationDate = styled(Typography).attrs({ variant: 'regular12' })`
    color: ${({ theme }) => theme.colors.grey()};
`;
