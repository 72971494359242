import React, { FC } from 'react';
import { Header } from '@components/Widgets/FeedWidget/components/HeadDay/styles';

interface IHeadDay {
    date: string;
}

const HeadDay: FC<IHeadDay> = ({ date }) => <Header>{date}</Header>;

export default HeadDay;
