import styled from 'styled-components';
import Typography from '@components/Typography';

export const OrderItemWrapper = styled.div`
    padding: 18px 0;
    border-bottom: 1px solid ${({ theme }) => theme.colors.lightGrey()};
    display: grid;
    grid-template-columns: 1fr 110px;
    align-items: center;
`;

export const OrderItemName = styled(Typography).attrs({ variant: 'regular14' })``;

export const QuantityAmount = styled(Typography).attrs({ variant: 'regular14' })`
    text-align: right;
`;
