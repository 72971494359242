import React, { FC, useEffect, useState } from 'react';
import {
    AmountField,
    ServiceFooterWrapper,
    Total,
    TotalLabel,
    TotalValue,
} from '@pages/Refund/components/ServiceFooter/styles';
import { ORDER_ITEM_FIELDS, TOrderItem } from '@store/Order/types';
import {
    calculateOrderItemValues,
    convertCurrencyToPointNumber,
    formatCurrencyToShow,
    toValidCurrencyFloat,
} from '@utils/helpers';
import { useIntl } from 'react-intl';

interface IServiceFooter {
    orderItem: TOrderItem;
    fields: any;
    vatRate: number;
}

const ServiceFooter: FC<IServiceFooter> = ({ vatRate, fields, orderItem }) => {
    const intl = useIntl();
    const maxAmount = orderItem[ORDER_ITEM_FIELDS.totalAmount] - 1;
    // save formatted number to string example: 13,27
    const [refundAmount, setRefundAmount] = useState(maxAmount.toString());
    const numberRefundAmount = convertCurrencyToPointNumber(refundAmount);

    // @ts-ignore
    const skus = fields.value?.map(({ sku }) => sku);
    const indexInForm = skus?.indexOf(orderItem.sku);

    const amountsDiff = formatCurrencyToShow(
        toValidCurrencyFloat(Math.max(orderItem[ORDER_ITEM_FIELDS.totalAmount] - numberRefundAmount, 0)),
    );

    useEffect(() => {
        const { totalAmount, totalVatAmount, amountWoVat } = calculateOrderItemValues(
            numberRefundAmount,
            orderItem[ORDER_ITEM_FIELDS.quantity],
            vatRate,
        );
        fields.update(indexInForm, {
            ...orderItem,
            [ORDER_ITEM_FIELDS.amount]: numberRefundAmount,
            [ORDER_ITEM_FIELDS.amountWoVat]: amountWoVat,
            [ORDER_ITEM_FIELDS.totalVatAmount]: totalVatAmount,
            [ORDER_ITEM_FIELDS.totalAmount]: totalAmount,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [numberRefundAmount]);

    const onChangeAmount = (e: any) => {
        if (e) {
            if (convertCurrencyToPointNumber(e) >= 0 && convertCurrencyToPointNumber(e) < maxAmount) {
                setRefundAmount(e);
            }
        } else {
            setRefundAmount('0');
        }
    };

    const onBlurAmountField = (e: any) => {
        if (e.target.value === '0' || e.target.value === '') {
            setRefundAmount('1');
        }
    };

    return (
        <ServiceFooterWrapper>
            <AmountField
                onBlur={onBlurAmountField}
                value={refundAmount}
                allowDecimals
                label={intl.formatMessage({ defaultMessage: 'Сумма' })}
                onChange={onChangeAmount}
            />
            <Total>
                <TotalLabel>{intl.formatMessage({ defaultMessage: 'Итого остаток' })}</TotalLabel>
                <TotalValue>{amountsDiff}</TotalValue>
            </Total>
        </ServiceFooterWrapper>
    );
};

export default ServiceFooter;
