import React, { FC } from 'react';
import ArrowSolidIcon from '@assets/icons/arrow-right2.svg';
import { currencyFormatter, dateFormatter } from '@utils/formatters';
import { unprocessableOrderStatuses } from '@store/Order/constants';
import { TOrder } from '@store/Order/types';
import { useSelector } from 'react-redux';
import { getCurrentCounterparty } from '@store/Counterparty/selectors';
import { IMerchant } from '@store/Merchant/types';
import { getUserName } from '@store/Auth/selectors';
import { useIntl } from 'react-intl';
import { Amount, InnerText, OrderIconWrapper, PaymentData, Status, TableRow } from './styles';

interface IOrderPaymentItem {
    order: TOrder;
    merchants?: IMerchant[];
}

const OrderPaymentItem: FC<IOrderPaymentItem> = ({ order, merchants }) => {
    const intl = useIntl();

    const counterparty = useSelector(getCurrentCounterparty);
    const userName = useSelector(getUserName);

    return (
        <TableRow>
            <OrderIconWrapper />
            <InnerText>{counterparty?.name || userName}</InnerText>
            <PaymentData>
                <ArrowSolidIcon />
                <InnerText>{merchants?.find(({ id: merchId }) => order.merchantId === merchId)?.name || ''}</InnerText>
                <InnerText>
                    {intl.formatMessage({ defaultMessage: 'Счёт' })} № {order.merchantOrderId} |{' '}
                    {dateFormatter(order.createdAt)}
                </InnerText>
            </PaymentData>
            <Amount>{currencyFormatter(order.amount)}</Amount>
            <Status>{unprocessableOrderStatuses.created}</Status>
        </TableRow>
    );
};

export default OrderPaymentItem;
