import styled from 'styled-components';
import Typography from '@components/Typography';
import { COLOR_BRIGHTNESS } from '@theme/utils';

export const HelperUploadDocument = styled.div`
    margin: 52px 0 0 60px;
    border: 1px solid ${({ theme }) => theme.colors.orange()};
    border-radius: ${({ theme }) => theme.decorations.borderRadius.base}px;
    padding: 17px 27px;
    width: 523px;
    @media ${({ theme }) => theme.breakpoints.md} {
        margin: 30px 0 0 0;
        width: 100%;
        padding: 20px;
    }
`;

export const HelperTitle = styled(Typography).attrs({ variant: 'regular14' })`
    margin-bottom: 3px;
    @media ${({ theme }) => theme.breakpoints.md} {
        margin-bottom: 16px;
    }
`;

export const CopyButton = styled.button``;

export const HelperFooter = styled.div`
    ${({ theme }) => theme.mixins.flexCenter}
    grid-column-gap: 7px;
`;

export const HelperDescription = styled(Typography).attrs({ variant: 'regular12' })`
    color: ${({ theme }) => theme.colors.base(COLOR_BRIGHTNESS.MEDIUM)};
`;
