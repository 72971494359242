import React, { FC } from 'react';
import BaseWidget from '@components/Widgets/BaseWidget';
import sizeWidget from '@theme/widget';
import HeadDay from '@components/Widgets/FeedWidget/components/HeadDay';
import { getFeedList, getIsFeedLoading } from '@store/Feed/selectors';
import { useSelector } from 'react-redux';
import { groupFeedsListByDate } from '@components/MerchantOrdersList/helpers';
import EventRow from '@components/Widgets/FeedWidget/components/EventRow';
import dayjs from 'dayjs';
import { Routes } from '@pages/constants';
import SkeletonFeedList from '@components/Widgets/FeedWidget/components/SkeletonFeedList';
import { useIntl } from 'react-intl';
import { FeedList, MoreNavLink, Title } from './styles';

interface IFeedWidget {
    isShowMoreButton?: boolean;
}

const FeedWidget: FC<IFeedWidget> = ({ isShowMoreButton = false }) => {
    const intl = useIntl();
    const feedList = useSelector(getFeedList);
    const isLoadingFeed = useSelector(getIsFeedLoading);

    const groupedFeeds = groupFeedsListByDate(feedList);
    const toDay = dayjs(new Date()).format('D MMMM YYYY');

    const renderFeedItems = () => {
        if (isLoadingFeed) {
            return <SkeletonFeedList />;
        }
        return groupedFeeds.map((groupedFeed) => (
            <div key={groupedFeed.group}>
                <HeadDay
                    date={
                        groupedFeed.group === toDay
                            ? `${intl.formatMessage({ defaultMessage: 'Сегодня' })}, ${groupedFeed.group}`
                            : groupedFeed.group
                    }
                />
                {groupedFeed.children.map((feed) => (
                    <EventRow key={feed.id} feed={feed} />
                ))}
            </div>
        ));
    };

    return (
        <BaseWidget size={sizeWidget.xl}>
            <Title>{intl.formatMessage({ defaultMessage: 'Уведомления' })}</Title>
            <FeedList>{renderFeedItems()}</FeedList>
            {isShowMoreButton && (
                <MoreNavLink to={Routes.feeds}>{intl.formatMessage({ defaultMessage: 'Ещё' })}</MoreNavLink>
            )}
        </BaseWidget>
    );
};

export default FeedWidget;
