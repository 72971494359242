import React from 'react';
import { SkeletonGroupFeedHead } from '@components/Widgets/FeedWidget/components/SkeletonFeedList/styles';
import SkeletonFeedItem from '@components/Widgets/FeedWidget/components/SkeletonFeedItem';

const SkeletonFeedList = () => {
    const skeletonFeedQueue = [
        SkeletonGroupFeedHead,
        SkeletonFeedItem,
        SkeletonFeedItem,
        SkeletonGroupFeedHead,
        SkeletonFeedItem,
        SkeletonFeedItem,
        SkeletonFeedItem,
    ];
    return (
        <>
            {skeletonFeedQueue.map((ComponentSkeleton, index) => (
                <ComponentSkeleton
                    /* eslint-disable-next-line react/no-array-index-key */
                    key={index}
                />
            ))}
        </>
    );
};

export default SkeletonFeedList;
