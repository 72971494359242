import React, { FC } from 'react';
import styled from 'styled-components';
import burgerIcon from '@assets/icons/menuBurger.svg';

interface IBaseWidgetProps {
    size: string;
}

const BaseWidget: FC<IBaseWidgetProps> = ({ size, children }) => (
    <Container size={size}>
        <BurgerIcon />
        {children}
    </Container>
);

export default BaseWidget;

const Container = styled.div<IBaseWidgetProps>`
    position: relative;
    width: ${(props) => props.size};
    box-shadow: ${({ theme }) => theme.decorations.boxShadow.base};
    border-radius: ${({ theme }) => theme.decorations.borderRadius.base}px;
    padding: 22px 30px;

    @media ${({ theme }) => theme.breakpoints.sm} {
        width: ${({ theme }) => theme.widget.xl};
    }

    @media ${({ theme }) => theme.breakpoints.ld} {
        padding: 28px 20px;
    }
    @media ${({ theme }) => theme.breakpoints.md} {
        width: 100%;
    }
    @media ${({ theme }) => theme.breakpoints.sm} {
        padding: 20px;
    }
`;

const BurgerIcon = styled(burgerIcon)`
    position: absolute;
    top: 10px;
    right: 10px;
    fill: ${({ theme }) => theme.colors.grey()};
    width: 12px;
    height: 12px;
    cursor: grab;
`;
