import React, { FC } from 'react';
import { isEmpty, map } from 'lodash';
import dayjs from 'dayjs';
import 'dayjs/locale/ru';
import { processableOrderStatuses } from '@store/Order/constants';
import { currencyFormatter, dateFormatter } from '@utils/formatters';
import colors from '@theme/colors';
import { getIconByStatusAndColor } from '@components/Widgets/helpers';
import { Routes } from '@pages/constants';
import { titleTypeOrder, TOrder, typeOrder } from '@store/Order/types';
import { useMobile } from '@hooks/useMedia';
import { useSelector } from 'react-redux';
import { getCurrentMerchantId } from '@store/Merchant/selectors';
import { getRefundIconByStatusAndColor } from '@utils/helpers';
import SkeletonOrderList from '@components/MerchantOrdersList/components/SkeletonOrderList/index';
import { useIntl } from 'react-intl';
import { groupOrdersListByDate } from '../../helpers';

import {
    Amount,
    Empty,
    InnerContent,
    InnerNumber,
    InnerText,
    InnerTextLeft,
    MobileDescriptionWrapper,
    OrderIcon,
    RefundIcon,
    Status,
    TableRow,
    TitleDate,
    WrapDate,
    WrapOrderIcon,
    WrapStatusIcon,
} from './styles';

dayjs.locale('ru');

interface IOrdersMerchantList {
    orders: TOrder[];
    orderType?: typeOrder;
    isLoading?: boolean;
}

const OrdersMerchantList: FC<IOrdersMerchantList> = ({ isLoading = false, orders, orderType = typeOrder.ORDER }) => {
    const intl = useIntl();

    const labelEmptyItems = {
        [typeOrder.ORDER]: intl.formatMessage({ defaultMessage: 'Нет заказов' }),
        [typeOrder.ORDER_CONTAINER]: intl.formatMessage({ defaultMessage: 'Нет счетов' }),
        [typeOrder.ORDER_REFUND]: intl.formatMessage({ defaultMessage: 'Нет возвратов' }),
    };

    const groupOrdersList = groupOrdersListByDate(orders);
    const today = dayjs(new Date()).format('D MMMM YYYY');
    const orderTitle = titleTypeOrder[orderType];
    const isMobile = useMobile();
    const merchantId = useSelector(getCurrentMerchantId);
    const getStatusColorAndIcon =
        orderType === typeOrder.ORDER_REFUND ? getRefundIconByStatusAndColor : getIconByStatusAndColor;
    const MainIcon = orderType === typeOrder.ORDER_REFUND ? RefundIcon : OrderIcon;

    if (!isLoading) {
        return (
            <>
                {!isEmpty(groupOrdersList) && (
                    <InnerContent>
                        {map(groupOrdersList, ({ group, children }) => {
                            const orderDate = group;
                            const orderToday = `${intl.formatMessage({ defaultMessage: 'Сегодня' })}, ${today
                                .split(' ')
                                .slice(0, 2)
                                .join(' ')}`;

                            return (
                                <div key={group}>
                                    <WrapDate>
                                        <TitleDate>{orderDate === today ? orderToday : orderDate}</TitleDate>
                                    </WrapDate>
                                    {map(
                                        children,
                                        ({
                                            id,
                                            merchantOrderId,
                                            orderContainerId,
                                            status,
                                            createdAt,
                                            amount,
                                            customer,
                                        }) => {
                                            const { icon, color } = getStatusColorAndIcon(status, colors);

                                            const path =
                                                orderType === typeOrder.ORDER_REFUND
                                                    ? `${Routes.dashboard}${Routes.shop}/${merchantId}${Routes.refunddetail}/${id}`
                                                    : `${Routes.ordercontainer}/${orderContainerId}`;
                                            const merchantOrderIdTitle =
                                                orderType !== typeOrder.ORDER_REFUND ? `№ ${merchantOrderId}` : '';
                                            const title = !isMobile ? orderTitle : '';
                                            const date =
                                                !isMobile || orderType === typeOrder.ORDER_REFUND
                                                    ? `${intl.formatMessage({ defaultMessage: 'от' })} ${dateFormatter(
                                                          createdAt,
                                                      )}`
                                                    : '';
                                            const info = `${title} ${merchantOrderIdTitle} ${date}`;

                                            return (
                                                <TableRow
                                                    key={id}
                                                    to={{
                                                        pathname: path,
                                                        state: {
                                                            isOrderContainer: orderType !== typeOrder.ORDER,
                                                        },
                                                    }}
                                                >
                                                    <InnerTextLeft>
                                                        <WrapOrderIcon>
                                                            <MainIcon />
                                                            {icon && <WrapStatusIcon>{icon}</WrapStatusIcon>}
                                                        </WrapOrderIcon>
                                                        <InnerText>
                                                            {customer?.name
                                                                ? customer.name
                                                                : intl.formatMessage({
                                                                      defaultMessage: 'Без плательщика',
                                                                  })}
                                                        </InnerText>
                                                    </InnerTextLeft>
                                                    <Amount>{currencyFormatter(amount)}</Amount>
                                                    <MobileDescriptionWrapper>
                                                        <InnerNumber>{info}</InnerNumber>
                                                        <Status color={color}>
                                                            {processableOrderStatuses[status]}
                                                        </Status>
                                                    </MobileDescriptionWrapper>
                                                </TableRow>
                                            );
                                        },
                                    )}
                                </div>
                            );
                        })}
                    </InnerContent>
                )}
                {isEmpty(groupOrdersList) && <Empty>{labelEmptyItems[orderType]}</Empty>}
            </>
        );
    }

    return <SkeletonOrderList />;
};

export default OrdersMerchantList;
