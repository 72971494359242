import React, { FC } from 'react';
import { ORDER_ITEM_FIELDS, TOrderItem, TVatType } from '@store/Order/types';
import {
    Amount,
    AmountAndType,
    RefundItemName,
    RefundItemSkeleton,
    RefundItemWrapper,
    RefundType,
    TotalAmount,
    Vat,
} from '@pages/Shop/ShopTabsTables/RefundDetail/components/CompositionItem/styles';
import { formatCurrencyToShow } from '@utils/helpers';
import { useIntl } from 'react-intl';

interface ICompositionItem {
    refundItem?: TOrderItem;
    vatTypes?: TVatType[];
    isFullRefund?: boolean;
}

const CompositionItem: FC<ICompositionItem> = ({ isFullRefund = false, vatTypes, refundItem }) => {
    const intl = useIntl();
    const isLoading = !vatTypes || !refundItem;

    const amountValue = `${refundItem?.[ORDER_ITEM_FIELDS.quantity]} x ${
        refundItem && formatCurrencyToShow(refundItem?.[ORDER_ITEM_FIELDS.amount])
    }`;
    const refundTypeValue = isFullRefund
        ? intl.formatMessage({ defaultMessage: 'Полный возврат' })
        : intl.formatMessage({ defaultMessage: 'Частичный возврат' });
    const vatRate =
        vatTypes &&
        // @ts-ignore
        vatTypes.find((vatType) => vatType?.code === refundItem?.[ORDER_ITEM_FIELDS.vatCode])?.rate * 100;
    const vatLabel = `${intl.formatMessage({ defaultMessage: 'НДС' })} ${vatRate} %`;

    return (
        <RefundItemWrapper>
            <RefundItemName>{isLoading ? <RefundItemSkeleton /> : refundItem?.[ORDER_ITEM_FIELDS.name]}</RefundItemName>
            <AmountAndType>
                <RefundType>{isLoading ? <RefundItemSkeleton /> : refundTypeValue}</RefundType>
                <Amount>{isLoading ? <RefundItemSkeleton /> : amountValue}</Amount>
            </AmountAndType>
            <TotalAmount>
                {isLoading ? (
                    <RefundItemSkeleton />
                ) : (
                    refundItem && formatCurrencyToShow(refundItem?.[ORDER_ITEM_FIELDS.totalAmount])
                )}
            </TotalAmount>
            <Vat>{isLoading ? <RefundItemSkeleton /> : vatLabel}</Vat>
        </RefundItemWrapper>
    );
};

export default CompositionItem;
