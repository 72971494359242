import http, { ICommonResponse } from '../../utils/http';
import { IRefundOrder } from './types';

export const fetchOrderRefundsListRequest = (params?: any) =>
    http.get<ICommonResponse<IRefundOrder[]>>('/filter/api/order/refund-order', {
        params: { _pageSize: 10, '_order[createdAt]': 'desc', ...params },
    });

export const createRefundRequest = (params: any) =>
    http.post<ICommonResponse<IRefundOrder>>('/billing/api/order/refund-order', params);
