import styled from 'styled-components';
import Button from '@components/Button';

export const ButtonCreateOrderPay = styled(Button)`
    width: 100%;
    margin-top: 30px;
    @media ${({ theme }) => theme.breakpoints.ld} {
        max-width: 180px;
        margin: 0;
    }
    @media ${({ theme }) => theme.breakpoints.sm} {
        margin-top: 30px;
    }
`;

export const TotalValuesContainer = styled.div`
    box-shadow: ${({ theme }) => theme.decorations.boxShadow.base};
    border-radius: ${({ theme }) => theme.decorations.borderRadius.base}px;
    padding: 25px 30px 30px 30px;
    position: sticky;
    top: 30px;
    @media ${({ theme }) => theme.breakpoints.ld} {
        ${({ theme }) => theme.mixins.flexCenter};
        padding: 20px 25px;
        justify-content: space-between;
        margin-left: -22px;
        margin-right: -22px;
        border-radius: ${({ theme }) => theme.decorations.borderRadius.base}px
            ${({ theme }) => theme.decorations.borderRadius.base}px 0 0;
        box-shadow: ${({ theme }) => theme.decorations.boxShadow.secondary};
    }
    @media ${({ theme }) => theme.breakpoints.sm} {
        flex-direction: column;
        box-shadow: none;
        padding: 0 25px 30px 20px;
        width: 100%;
        margin-left: 0;
        margin-right: 0;
    }
`;

export const TotalValuesDescriptions = styled.div`
    padding-bottom: 26px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.lightGrey()};
    display: grid;
    grid-template-columns: 100%;
    grid-row-gap: 4px;
    @media ${({ theme }) => theme.breakpoints.ld} {
        ${({ theme }) => theme.mixins.flexCenter}
        grid-column-gap: 29px;
        border: none;
        padding: 0;
    }
    @media ${({ theme }) => theme.breakpoints.sm} {
        flex-direction: column;
        width: 100%;
        border-top: 1px solid ${({ theme }) => theme.colors.lightGrey()};
        padding-top: 25px;
    }
`;
