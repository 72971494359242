import React, { FC, useCallback, useEffect, useState } from 'react';
import { Form } from 'react-final-form';
import RowCheckboxHours from '@pages/Shop/ShopTabsTables/Settings/components/RowCheckboxHours';
import { IWorkHours } from '@store/Merchant/types';
import { getConvertHoursDayToBackend } from '@utils/helpers/getConvertHoursDayToBackend';
import { useIntl } from 'react-intl';
import { ScheduleModalWrapper, ButtonSubmitSchedule } from './styles';

interface IScheduleModal {
    closeModal: () => void;
    isOpen: boolean;
    setWorkHoursToDay: (workHours: IWorkHours) => void;
    workHoursToDay: IWorkHours;
}

const ScheduleModal: FC<IScheduleModal> = ({ workHoursToDay, setWorkHoursToDay, closeModal, isOpen }) => {
    const intl = useIntl();

    const getValuesForForm = useCallback((workHours: IWorkHours) => {
        // @ts-ignore
        const startTimeDay = Object.fromEntries(
            Object.entries(workHours).map((item) => [`${item[0]}Start`, item[1][0] && item[1][0][0]]),
        );
        // @ts-ignore
        const endTimeDay = Object.fromEntries(
            Object.entries(workHours).map((item) => [`${item[0]}End`, item[1][0] && item[1][0][1]]),
        );
        return Object.assign(startTimeDay, endTimeDay);
    }, []);

    const [initialValues, setInitialValues] = useState(getValuesForForm(workHoursToDay));

    useEffect(() => {
        setInitialValues(getValuesForForm(workHoursToDay));
    }, [getValuesForForm, workHoursToDay]);

    return (
        <ScheduleModalWrapper
            title={intl.formatMessage({ defaultMessage: 'График работы' })}
            isOpen={isOpen}
            closeModal={closeModal}
        >
            <Form
                onSubmit={(formValues: { nameDay: string; time: string }) => {
                    const convertValues = getConvertHoursDayToBackend(formValues);
                    setWorkHoursToDay(convertValues);
                }}
                initialValues={initialValues}
                mutators={{
                    clearField: (args: string[], state, utils) => {
                        const dayName = args[0];
                        utils.changeValue(state, `${dayName}Start`, () => null);
                        utils.changeValue(state, `${dayName}End`, () => null);
                    },
                }}
            >
                {({ handleSubmit, form }) => (
                    <form onSubmit={handleSubmit} id="scheduleForm">
                        {Object.entries(workHoursToDay).map((day) => {
                            const dayName = day[0];
                            return (
                                <RowCheckboxHours
                                    form={form}
                                    day={dayName}
                                    isWorkDay={day[1][0] && Boolean(day[1][0].length)}
                                    nameFieldStart={`${dayName}Start`}
                                    nameFieldEnd={`${dayName}End`}
                                    key={dayName}
                                />
                            );
                        })}
                        <ButtonSubmitSchedule
                            onClick={() => {
                                handleSubmit();
                                closeModal();
                            }}
                        >
                            {intl.formatMessage({ defaultMessage: 'Применить' })}
                        </ButtonSubmitSchedule>
                    </form>
                )}
            </Form>
        </ScheduleModalWrapper>
    );
};

export default ScheduleModal;
