import styled from 'styled-components';

export const PersonalForm = styled.form`
    padding: 13px 33px 30px 30px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 30px;
    @media ${({ theme }) => theme.breakpoints.xl} {
        padding: 13px 20px 30px 20px;
        grid-column-gap: 20px;
    }
    @media ${({ theme }) => theme.breakpoints.md} {
        padding: 0 20px 30px 20px;
        grid-template-columns: 1fr;
        grid-row-gap: 10px;
    }
`;
