import styled, { createGlobalStyle, css } from 'styled-components';
import { COLOR_BRIGHTNESS } from '@theme/utils';
import leftIcon from '@assets/icons/circleLeftSolid.svg';

export const OverflowHidden = createGlobalStyle`
  body {
    overflow: hidden;
  }
`;

export const Container = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
`;

export const ContainerInner = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    overflow: hidden auto;
    align-items: flex-start;
    padding: 55px 0;

    @media ${({ theme }) => theme.breakpoints.sm} {
        padding: 15px 0;
    }
`;

export const Backdrop = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    background-color: ${({ theme }) => theme.colors.darkest(COLOR_BRIGHTNESS.MEDIUM)};
`;

export const Content = styled.div`
    margin: auto;
    max-width: calc(100vw - 30px);
    overflow: visible;
    position: relative;
    background-color: ${({ theme }) => theme.colors.white()};
    border-radius: ${({ theme }) => theme.decorations.borderRadius?.base}px;
    box-shadow: ${({ theme }) => theme.decorations.boxShadow?.base};
    height: 100%;

    @media ${({ theme }) => theme.breakpoints.sm} {
        width: 100%;
    }
`;

export const CloseButton = styled.button`
    position: absolute;
    display: flex;
    top: 0;
    right: -44px;
    z-index: 1000;
    svg {
        path {
            stroke: ${({ theme }) => theme.colors.white()};
        }
    }

    @media ${({ theme }) => theme.breakpoints.sm} {
        top: 16px;
        right: 20px;
    }
`;

export const ModalInner = styled.div`
    padding: 0 50px 50px;

    @media ${({ theme }) => theme.breakpoints.sm} {
        padding: 47px 20px 30px;
    }
`;

const IconStyles = css`
    width: 20px;
    height: 20px;
    padding: 0;
    border-radius: 50%;
    path {
        fill: ${({ theme }) => theme.colors.white()};
    }
`;

export const LeftIcon = styled(leftIcon)`
    ${IconStyles}
`;

export const RightIcon = styled(leftIcon)`
    transform: rotate(180deg);
    ${IconStyles}
`;

export const ButtonSwitchSlidePrev = styled.button`
    width: 20px;
    height: 20px;
    position: absolute;
    z-index: 3;
    left: -40px;
    top: 50%;
    transform: translateY(-50%);
`;

export const ButtonSwitchSlideNext = styled.button`
    width: 20px;
    height: 20px;
    position: absolute;
    z-index: 3;
    right: -40px;
    top: 50%;
    transform: translateY(-50%);
`;
