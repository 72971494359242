import { enumsReasons } from '@pages/Refund/components/ReasonRefund';
import { TOrderItem } from '../Order/types';

// eslint-disable-next-line no-shadow
export enum RefundOrderStatuses {
    created = 'created',
    completed = 'completed',
    processing = 'processing',
    error = 'error',
}

// eslint-disable-next-line no-shadow
export enum REFUND_FIELD {
    id = 'id',
    amount = 'amount',
    vatAmount = 'vatAmount',
    basketItems = 'basketItems',
    reasonId = 'reasonId',
    parentId = 'parentId',
    description = 'description',
}

export interface IRefundOrder {
    id: string;
    description: string;
    currencyId: string;
    amount: number;
    vatAmount: number;
    basketItems: TOrderItem[];
    orderContainerId: string;
    merchantId: string;
    status: RefundOrderStatuses;
    createdAt: string;
    merchantOrderId: string;
    parentId: string;
}

export interface ICreateRefundForm extends IRefundOrder {
    reasonId: enumsReasons;
}

export type TCommonListThunkResponse<T> = {
    data: T[];
    metaData: {
        totalCount: number;
        pageSize: number;
        page: number;
    };
};
