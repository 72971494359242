import styled from 'styled-components';

export const TariffsWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 24px;
    grid-row-gap: 20px;
    padding-top: 40px;
    @media ${({ theme }) => theme.breakpoints.ld} {
        grid-column-gap: 20px;
    }
    @media ${({ theme }) => theme.breakpoints.md} {
        grid-template-columns: 1fr;
        grid-row-gap: 10px;
        padding: 40px 15px 0 15px;
    }
`;
