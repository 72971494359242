import React, { FC, useEffect, useState } from 'react';
import { TariffsWrapper } from '@pages/Claim/components/TariffPayment/styles';
import { useSelector } from 'react-redux';
import {
    getContractsOrTemplatesForCurrentCounterparty,
    getSelectedContractId,
    getSelectedContractTemplateId,
} from '@store/Contracts/selectors';
import ButtonsNextPrev from '@pages/Claim/components/ButtonsNextPrev';
import { IContract, typeContract } from '@store/Contracts/types';
import { enumTabsConnection, IStep } from '@pages/Claim/components/MainConnectionPayment';
import { useParams } from 'react-router-dom';
import { COUNTERPARTY_FIELDS } from '@store/Counterparty/contants';
import { getCurrentCounterparty } from '@store/Counterparty/selectors';
import { ICounterparty } from '@store/Counterparty/types';
import { useAppDispatch } from '@store';
import { setSelectedContractId, setSelectedContractTemplateId } from '@store/Contracts';
import TariffCard from '@pages/Claim/components/TariffCard';

const TariffPayment: FC<IStep> = ({ setStep }) => {
    const dispatch = useAppDispatch();
    const { merchantId } = useParams<{ merchantId: string }>();

    const counterparty: ICounterparty = useSelector(getCurrentCounterparty) as any;
    const contractTemplatesAndContracts = useSelector(getContractsOrTemplatesForCurrentCounterparty);

    const [selectedContractTemplate, setSelectedContractTemplate] = useState<IContract>();

    // если claim существует, ищем выбранный ранее тариф
    const selectedContractTemplateId = useSelector(getSelectedContractTemplateId);
    const selectedContractId = useSelector(getSelectedContractId);
    useEffect(() => {
        if (selectedContractTemplateId || selectedContractId) {
            const foundContractTemplate = contractTemplatesAndContracts.find(
                (contractTemplate) =>
                    contractTemplate.id === selectedContractTemplateId || contractTemplate.id === selectedContractId,
            );
            setSelectedContractTemplate(foundContractTemplate);
        }
    }, [contractTemplatesAndContracts, selectedContractId, selectedContractTemplateId]);

    const onClickBack = () => {
        setStep(enumTabsConnection.merchant);
    };

    const checkRequiredFields = (currentCounterparty: ICounterparty) => {
        const requiredFieldsCounterparty = [
            COUNTERPARTY_FIELDS.name,
            COUNTERPARTY_FIELDS.vatNumber,
            COUNTERPARTY_FIELDS.registrationAddress,
            COUNTERPARTY_FIELDS.legalEntityTypeId,
            COUNTERPARTY_FIELDS.ceoName,
            COUNTERPARTY_FIELDS.taxRegistrationReasonCode,
            COUNTERPARTY_FIELDS.cfoName,
            COUNTERPARTY_FIELDS.registrationNumber,
            COUNTERPARTY_FIELDS.email,
            COUNTERPARTY_FIELDS.phone,
            COUNTERPARTY_FIELDS.registrationDate,
        ];
        return requiredFieldsCounterparty.every(
            (item) => Object.entries(currentCounterparty).findIndex((field) => field[0] === item) !== -1,
        );
    };

    const onClickNext = () => {
        if (selectedContractTemplate?.type === typeContract.template) {
            dispatch(setSelectedContractTemplateId(selectedContractTemplate?.id));
            dispatch(setSelectedContractId(null));
        }
        if (selectedContractTemplate?.type === typeContract.contract) {
            dispatch(setSelectedContractId(selectedContractTemplate?.id));
            dispatch(setSelectedContractTemplateId(null));
        }
        if (checkRequiredFields(counterparty as ICounterparty)) {
            setStep(enumTabsConnection.bankDetails);
        } else {
            setStep(enumTabsConnection.counterparty);
        }
    };

    return (
        <>
            <TariffsWrapper>
                {contractTemplatesAndContracts.map((contractTemplate) => (
                    <TariffCard
                        key={contractTemplate.id}
                        contractTemplate={contractTemplate}
                        selectedContractTemplateId={selectedContractTemplate?.id}
                        setSelectedContractTemplate={setSelectedContractTemplate}
                    />
                ))}
            </TariffsWrapper>
            <ButtonsNextPrev
                onClickPrev={merchantId ? null : onClickBack}
                onClickNext={onClickNext}
                isActiveNextButton={Boolean(selectedContractTemplate)}
            />
        </>
    );
};

export default TariffPayment;
