import React, { FC, useEffect, useState } from 'react';
import {
    CheckboxRefundItem,
    FullRefundFields,
    RefundItemWrapper,
    TogglePartial,
    TotalAmount,
} from '@pages/Refund/components/RefundItem/styles';
import { COMMON_DICTIONARY_BASKET_ITEM_TYPES, ORDER_ITEM_FIELDS, TOrderItem, TVatType } from '@store/Order/types';
import { formatCurrencyToShow } from '@utils/helpers';
import ServiceFooter from '@pages/Refund/components/ServiceFooter';
import CommodityFooter from '@pages/Refund/components/CommodityFooter';
import { find } from 'lodash';
import { useIntl } from 'react-intl';

interface IRefundItem {
    originalOrderItem: TOrderItem;
    isChosen?: boolean;
    fields: any;
    vatTypes?: TVatType[];
}

const RefundItem: FC<IRefundItem> = ({ vatTypes, fields, isChosen = false, originalOrderItem }) => {
    const intl = useIntl();
    const [isPartialRefund, setIsPartialRefund] = useState(false);
    const [isSelected, setIsSelected] = useState(isChosen);
    // @ts-ignore
    const skus = fields.value?.map(({ sku }) => sku);
    const indexInForm = skus?.indexOf(originalOrderItem.sku);
    const currentVat = find(vatTypes, { code: originalOrderItem[ORDER_ITEM_FIELDS.vatCode] });
    const vatRate = currentVat?.rate || 0;

    useEffect(() => {
        setIsSelected(isChosen);
    }, [isChosen]);

    const onChangeFullRefund = (event: React.ChangeEvent<HTMLInputElement>) => {
        const isPartialRefundCheck = event.target.checked;
        if (!isPartialRefundCheck) {
            fields.update(indexInForm, originalOrderItem);
        }
        setIsPartialRefund(event.target.checked);
    };

    const renderFooter = () => {
        if (originalOrderItem[ORDER_ITEM_FIELDS.type] === COMMON_DICTIONARY_BASKET_ITEM_TYPES.SERVICE) {
            return <ServiceFooter vatRate={vatRate} fields={fields} orderItem={originalOrderItem} />;
        }
        return <CommodityFooter vatRate={vatRate} fields={fields} orderItem={originalOrderItem} />;
    };

    const onChangeSelected = (e: React.ChangeEvent<HTMLInputElement>) => {
        const isCheck = e.target.checked;
        if (isCheck) {
            fields.push(originalOrderItem);
        } else {
            fields.remove(skus?.indexOf(originalOrderItem.sku));
        }
        setIsSelected(isCheck);
    };

    return (
        <RefundItemWrapper>
            <FullRefundFields>
                <CheckboxRefundItem
                    checked={isSelected}
                    onChange={onChangeSelected}
                    type="checkbox"
                    label={originalOrderItem[ORDER_ITEM_FIELDS.name]}
                />
                {!(
                    originalOrderItem[ORDER_ITEM_FIELDS.type] === COMMON_DICTIONARY_BASKET_ITEM_TYPES.COMMODITY &&
                    originalOrderItem[ORDER_ITEM_FIELDS.quantity] === 1
                ) && (
                    <TogglePartial
                        disabled={!isSelected}
                        rightLabel={intl.formatMessage({ defaultMessage: 'Частичный' })}
                        type="checkbox"
                        checked={isPartialRefund}
                        onChange={onChangeFullRefund}
                    />
                )}
                <TotalAmount isActually={isSelected && !isPartialRefund}>
                    {`${originalOrderItem[ORDER_ITEM_FIELDS.quantity]} x ${formatCurrencyToShow(
                        originalOrderItem[ORDER_ITEM_FIELDS.amount],
                    )}`}
                </TotalAmount>
            </FullRefundFields>
            {isPartialRefund && isSelected && renderFooter()}
        </RefundItemWrapper>
    );
};

export default RefundItem;
