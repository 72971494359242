import http, { ICommonResponse } from '../../utils/http';
import { IContract, ICreateOrUpdateContractInfo } from './types';

export const fetchMerchantContractsRequest = () =>
    http.get<ICommonResponse<Array<IContract>>>('filter/api/merchant/merchant-contract', {
        params: { _pageSize: 100 },
    });

export const fetchMerchantContractTemplatesRequest = () =>
    http.get<ICommonResponse<Array<IContract>>>('filter/api/merchant/merchant-contract-template');

export const createOrUpdateMerchantContractRequest = (payload: ICreateOrUpdateContractInfo) =>
    http.post<ICommonResponse<IContract>>('billing/api/merchant/merchant-contract', payload);

export const removeMerchantContractRequest = (merchantContractId: number) => {
    http.delete(`billing/api/merchant/merchant-contract/${merchantContractId}`);
};
