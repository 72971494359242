import React, { FC } from 'react';
import Slider from 'react-slick';
import MobileSlide from '@pages/Claim/components/MobileSlide';
import { MobileSliderWrapper, Pagination, PaginationWrapper } from '@pages/Claim/components/MobileSlider/styles';
import { ISliderContent } from '@pages/Claim/components/DesktopSlider';
// eslint-disable-next-line import/no-unresolved
import '@globalStyles/sliderStyles/slick.css';
// eslint-disable-next-line import/no-unresolved
import '@globalStyles/sliderStyles/slick-theme.css';

interface IMobileSlider {
    slides: ISliderContent[];
}

const MobileSlider: FC<IMobileSlider> = ({ slides }) => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        customPaging: () => (
            <PaginationWrapper>
                <Pagination />
            </PaginationWrapper>
        ),
    };
    return (
        <MobileSliderWrapper>
            <Slider {...settings}>
                {slides.map((slide) => (
                    <MobileSlide key={slide.frame} title={slide.title} paragraphs={slide.paragraphs} />
                ))}
            </Slider>
        </MobileSliderWrapper>
    );
};

export default MobileSlider;
