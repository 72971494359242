import React, { FC } from 'react';
import Lottie from 'lottie-react';
import styled from 'styled-components';
import animation from './animation.json';

const Loader: FC = () => (
    <Wrapper>
        <Lottie style={{ width: 120 }} width={99} height={51} animationData={animation} />
    </Wrapper>
);

const Wrapper = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    ${({ theme }) => theme.mixins.flexCenterCenter};
    overflow: hidden;
    background-color: ${({ theme }) => theme.colors.white()};
`;

export default Loader;
