import http, { ICommonResponse } from '../../utils/http';
import { IClaim, IUpdateClaimFields, IUpdateClaimStatusInfo } from './types';

export const fetchClaimsRequest = (params: any) =>
    http.get<ICommonResponse<Array<IClaim>>>('/filter/api/merchant/claim', { params });

export const createClaimRequest = (merchantId: string) =>
    http.post<ICommonResponse<IClaim>>('/billing/api/merchant/claim', { merchantId });

export const updateClaimRequest = (claimId: string, payload: IUpdateClaimFields) =>
    http.put<ICommonResponse<IClaim>>(`/billing/api/merchant/claim/${claimId}`, payload);

export const updateClaimStatusRequest = (id: string, payload: IUpdateClaimStatusInfo) =>
    http.put<ICommonResponse<IClaim>>(`/billing/api/merchant/claim/${id}/status`, payload);

export const removeClaimRequest = (claimId: string) => http.delete(`/billing/api/merchant/claim/${claimId}`);
