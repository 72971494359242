import { claimsAdapter } from './index';
import { RootState } from '../index';
import { ClaimStatuses, IClaim, IClaimStatus } from './types';
import { ClaimFields } from './constants';
import { getContractById } from '../Contracts/selectors';
import { getMerchantById } from '../Merchant/selectors';

const { selectAll, selectById } = claimsAdapter.getSelectors();

export const getClaims = (state: RootState) => selectAll(state.claims);
export const getClaimById = (id: string) => (state: RootState) => selectById(state.claims, id);
export const getCurrentSelectClaimId = (state: RootState) => state.claims.selectedId;
export const getCurrentClaim = (state: RootState) =>
    getClaims(state).find((claim) => claim[ClaimFields.id] === state.claims.selectedId);
export const getOpenClaims = (state: RootState) => getClaims(state).filter((claim) => !claim.closed);

export const getClaimsByCounterpartyId = (counterpartyId: string) => (state: RootState) =>
    getClaims(state).filter((claim) => claim.counterpartyId === counterpartyId);

export const getOpenClaimsByCounterpartyId = (counterpartyId: string) => (state: RootState) =>
    getClaims(state).filter((claim) => claim.counterpartyId === counterpartyId && !claim.closed);

export const getOpenClaimsByMerchantId = (merchantId: string) => (state: RootState) =>
    getClaims(state).filter((claim) => claim[ClaimFields.merchantId] === merchantId && !claim.closed);

export const getClaimByMerchantId = (merchantId: string | null) => (state: RootState) =>
    getClaims(state).find((claim) => claim[ClaimFields.merchantId] === merchantId);

export const getOpenClaimDraftByMerchantId = (merchantId: string | null) => (state: RootState) =>
    getClaims(state).find(
        (claim) =>
            claim[ClaimFields.merchantId] === merchantId &&
            claim[ClaimFields.status] === ClaimStatuses.draft &&
            !claim[ClaimFields.closed],
    );

export const getOpenClaimModeratingByMerchantId = (merchantId: string | null) => (state: RootState) =>
    getClaims(state).find(
        (claim) =>
            claim[ClaimFields.merchantId] === merchantId &&
            claim[ClaimFields.status] === ClaimStatuses.moderating &&
            !claim[ClaimFields.closed],
    );

export const getCurrentMerchantOpenClaims = (state: RootState) =>
    getOpenClaimsByMerchantId(state.merchant.selectedId as string)(state);

export const getCurrentMerchantOpenDraftClaimContractIds = (state: RootState) =>
    getOpenClaimsByMerchantId(state.merchant.selectedId as string)(state)
        .filter((claim) => claim[ClaimFields.status] === 'draft')
        .map((claim) => claim[ClaimFields.merchantContractId]);

export const getMerchantByIdOpenedModeratingClaims = (merchantId: string) => (state: RootState) =>
    getOpenClaimsByMerchantId(merchantId)(state).filter(
        (claim) => claim[ClaimFields.status] === ClaimStatuses.moderating,
    );

export const getClaimsOpenModerating = (state: RootState) =>
    getClaims(state).filter(
        (claim) => claim[ClaimFields.status] === ClaimStatuses.moderating && !claim[ClaimFields.closed],
    );

export const getCurrentMerchantDraftClaim = (state: RootState) =>
    getCurrentMerchantOpenClaims(state).find((claim) => claim[ClaimFields.status] === 'draft');

export const getCurrentCounterpartyOpenClaimsByStatus = (status: IClaimStatus) => (state: RootState) =>
    getOpenClaimsByCounterpartyId(state.counterparty.selectedId as string)(state).filter(
        (claim) => claim.status === status,
    );

export const getCurrentEditClaim = (state: RootState): IClaim | undefined =>
    getCurrentCounterpartyOpenClaimsByStatus('draft')(state)[0];

export const getClaimMerchant = (id: string) => (state: RootState) => {
    const claim = selectById(state.claims, id);

    if (claim) {
        return getMerchantById(claim.merchantId)(state);
    }
};

export const getClaimContract = (id: string) => (state: RootState) => {
    const claim = selectById(state.claims, id);
    if (claim) {
        return getContractById(claim.merchantContractId)(state);
    }
};

export const getCurrentSelectClaim = (state: RootState) => {
    const id = getCurrentSelectClaimId(state);
    return id ? getClaimById(id)(state) : undefined;
};

export const getSelectedPassportData = (state: RootState) => state.claims.passportData;

export const getMerchantClaimModeratingOrDraftOrCanceled = (merchantId: string) => (state: RootState) =>
    getOpenClaimsByMerchantId(merchantId)(state).find(
        (claim) =>
            claim[ClaimFields.status] === ClaimStatuses.moderating ||
            claim[ClaimFields.status] === ClaimStatuses.draft ||
            claim[ClaimFields.status] === ClaimStatuses.canceled,
    );

export const getIsLoadedClaims = (state: RootState) => state.claims.isLoaded;
