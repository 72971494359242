import React, { FC } from 'react';
import { isEmpty } from 'lodash';
import LastClientsSelect from '@components/LastClientsSelect';
import MerchantOrdersList from '@components/MerchantOrdersList';
import { useSelector } from 'react-redux';
import { getOrdersList } from '@store/Order/selectors';
import { ContainerNavPage } from '../../styles';

const DashboardPage: FC = () => {
    const ordersList = useSelector(getOrdersList);

    return (
        <ContainerNavPage>
            {!isEmpty(ordersList) && <LastClientsSelect />}
            <MerchantOrdersList />
        </ContainerNavPage>
    );
};

export default DashboardPage;
