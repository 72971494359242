import React, { FC, useState } from 'react';
import { IMerchant, IWorkHours } from '@store/Merchant/types';

import { MERCHANT_FIELDS } from '@store/Merchant/contants';
import { Form } from 'react-final-form';
import putTogetherTimetable from '@utils/helpers/putTogetherTimetable';
import DateHoursWork from '@pages/Shop/ShopTabsTables/Settings/components/DateHoursWork/index';
import useModal from '@hooks/useModal';
import ScheduleModal from '@pages/Shop/ShopTabsTables/Settings/components/ScheduleModal/index';
import FormField, { FormFieldTypeEnum } from '@components/FormField';
import { isEqualObjects } from '@utils/helpers';
import { updateMerchant } from '@store/Merchant';
import { useAppDispatch } from '@store';
import removeIntervalsWorkDay from '@utils/helpers/removeIntervalsWorkDay';
import { useIntl } from 'react-intl';
import { SettingsForm } from '../../styles';
import { DateHoursContainerInForm, ScheduleInputWrapper, SettingLabel, ButtonOpenModalHoursDay } from './styles';
import LocationIcon from '../../assets/location.svg';

interface IAddressForm {
    formId: string;
    setIsEditSettings: (arg: boolean) => void;
    toggleSaving: () => void;
    merchant: IMerchant;
    worksHours: IWorkHours;
}

const AddressForm: FC<IAddressForm> = ({ toggleSaving, formId, setIsEditSettings, merchant, worksHours }) => {
    const intl = useIntl();
    const dispatch = useAppDispatch();

    const { isOpen: scheduleModalVisible, open: openModal, close: closeModal } = useModal();

    const initialValues = {
        [MERCHANT_FIELDS.address]: merchant[MERCHANT_FIELDS.address],
    };

    const [workHoursToDay, setWorkHoursToDay] = useState(worksHours);

    const groupHours = putTogetherTimetable(workHoursToDay);

    const onSubmit = async (formAddressValues: { [MERCHANT_FIELDS.address]: string }) => {
        const formMerchant = { ...merchant, ...formAddressValues, [MERCHANT_FIELDS.hours]: workHoursToDay };

        if (!isEqualObjects(formMerchant, merchant)) {
            toggleSaving();
            const data = await dispatch(updateMerchant(formMerchant));
            // @ts-ignore
            if (!data?.error && data?.payload) {
                setIsEditSettings(false);
            }
            toggleSaving();
        } else {
            setIsEditSettings(false);
        }
    };

    const removeIntervalDays = (startDay: string, endDay: string) => {
        setWorkHoursToDay(removeIntervalsWorkDay(startDay, endDay, workHoursToDay));
    };

    return (
        <Form onSubmit={onSubmit} initialValues={initialValues}>
            {({ handleSubmit }) => (
                <SettingsForm onSubmit={handleSubmit} id={formId}>
                    <FormField
                        fieldType={FormFieldTypeEnum.autoCompleteAddress}
                        name={MERCHANT_FIELDS.address}
                        label={intl.formatMessage({ defaultMessage: 'Адрес' })}
                        placeholder={intl.formatMessage({ defaultMessage: 'Начните вводить адрес' })}
                        icon={<LocationIcon />}
                    />
                    <ScheduleInputWrapper>
                        <ButtonOpenModalHoursDay type="button" onClick={openModal}>
                            <SettingLabel>{intl.formatMessage({ defaultMessage: 'Режим работы' })}</SettingLabel>
                        </ButtonOpenModalHoursDay>
                        <DateHoursContainerInForm>
                            {groupHours.map((dayWork) => (
                                <DateHoursWork
                                    startPointDay={dayWork.startPointDay}
                                    endPointDay={dayWork.endPointDay}
                                    time={dayWork.time}
                                    isEdit
                                    key={dayWork.startPointDay}
                                    removeIntervalDays={removeIntervalDays}
                                />
                            ))}
                        </DateHoursContainerInForm>
                    </ScheduleInputWrapper>
                    <ScheduleModal
                        workHoursToDay={workHoursToDay}
                        setWorkHoursToDay={setWorkHoursToDay}
                        closeModal={closeModal}
                        isOpen={scheduleModalVisible}
                    />
                </SettingsForm>
            )}
        </Form>
    );
};

export default AddressForm;
