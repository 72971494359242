import styled, { css } from 'styled-components';

import Typography from '@components/Typography';
import { COLOR_BRIGHTNESS } from '@theme/utils';
import { NavLink } from 'react-router-dom';
import { IconWrapper } from '@components/CircleIcon';
import Modal from '@components/Modal';

interface IClickableItemProps {
    isActive?: boolean;
}

interface IArrowWrapperProps {
    isOpen?: boolean;
}

interface ICollapseItemWrapper {
    isOpen?: boolean;
}

interface ICollapseBodyProps {
    itemHeight: number;
}

interface ICollapseBodyItem {
    itemHeight: number;
}

export const AccordionWrapper = styled.div`
    border-top: 1px solid ${({ theme }) => theme.colors.lightGrey()};
`;

export const ClickableItem = styled.button<IClickableItemProps>`
    ${({ theme }) => theme.mixins.dropdownItem};
    border-bottom: 1px solid ${({ theme }) => theme.colors.lightGrey()};
    background-color: ${({ theme, isActive }) =>
        isActive ? theme.colors.lightGrey(COLOR_BRIGHTNESS.MEDIUM) : theme.colors.white()};

    &:hover {
        background-color: ${({ theme }) => theme.colors.lightGrey(COLOR_BRIGHTNESS.MEDIUM)};
    }
`;

export const ClickableItemLink = styled(NavLink)`
    ${({ theme }) => theme.mixins.dropdownItem};
    border-bottom: 1px solid ${({ theme }) => theme.colors.lightGrey()};
    text-decoration: none;
    background-color: ${({ theme }) => theme.colors.white()};

    &:hover {
        background-color: ${({ theme }) => theme.colors.lightGrey(COLOR_BRIGHTNESS.MEDIUM)};
    }
    ${css`
        &.active {
            background-color: ${({ theme }) => theme.colors.lightGrey(COLOR_BRIGHTNESS.MEDIUM)};
            ${IconWrapper} {
                background-color: ${({ theme }) => theme.colors.yellow()};
            }
        }
    `}
`;

export const Title = styled(Typography).attrs({ variant: 'subheading' })`
    margin-left: 20px;
    margin-right: auto;
    padding-right: 20px;
    ${({ theme }) => theme.mixins.textOverflowDots};
`;

export const CollapseTitle = styled(Typography).attrs({ variant: 'regular14' })`
    margin-left: 0;
    margin-right: auto;
    padding-right: 20px;
    ${({ theme }) => theme.mixins.textOverflowDots};
`;

export const Label = styled(Typography).attrs({ variant: 'regular14' })`
    color: ${({ theme }) => theme.colors.grey()};
    white-space: nowrap;
`;

export const CollapseItemWrapper = styled.div<ICollapseItemWrapper>`
    border-bottom: 1px solid ${({ theme, isOpen }) => (isOpen ? theme.colors.lightGrey() : 'transparent')};
`;

export const CollapseBody = styled.div<ICollapseBodyProps>`
    overflow: hidden;
    transition: ${({ theme }) => theme.decorations.transition.base};
    ${({ itemHeight }) =>
        css`
            height: ${itemHeight}px;
        `}
`;

export const CollapseBodyItemWrapper = styled.div<ICollapseBodyItem>`
    height: ${({ itemHeight }) => itemHeight}px;
    transition: ${({ theme }) => theme.decorations.transition.base};

    &:hover {
        background-color: ${({ theme }) => theme.colors.lightGrey(COLOR_BRIGHTNESS.MEDIUM)};
    }

    &:not(:last-child) {
        border-bottom: 1px solid ${({ theme }) => theme.colors.lightGrey()};
    }
`;

export const CollapseBodyItem = styled.button`
    ${({ theme }) => theme.mixins.dropdownItem};
    padding-left: 56px;
`;

export const ArrowWrapper = styled.div<IArrowWrapperProps>`
    display: flex;
    pointer-events: none;
    transform: rotate(0);
    transition: ${({ theme }) => theme.decorations.transition.base};
    ${({ isOpen }) =>
        isOpen &&
        css`
            transform: rotate(180deg);
        `};
`;

export const ButtonAddMerchant = styled.button`
    ${({ theme }) => theme.mixins.flexCenter};
    ${({ theme }) => theme.mixins.getTypography('regular14')}
    width: 100%;
    height: 100%;
    padding-left: 50px;
    grid-column-gap: 10px;
    svg {
        path {
            stroke: ${({ theme }) => theme.colors.base()};
        }
    }
`;

export const ModalMerchantCreate = styled(Modal)``;
