import React, { FC, useState } from 'react';
import { STRAPI_BASE_URL } from '@utils/http';
import { enumBackgroundStory, IStoryBlock } from '@pages/Dashboard/components/Stories/types';
import { useLargeTablet } from '@hooks/useMedia';
import {
    InvisibleButtonHalf,
    InvisibleButtons,
    SlideBackground,
    SlideContent,
    SlideDescription,
    SlideTitle,
} from './styles';

interface IStory {
    currentSlide: IStoryBlock;
    isPaused: boolean;
    setIsPaused: (arg: boolean) => void;
    goToNextSlideOrStory: () => void;
    goToPrevSlideOrStory: () => void;
}

const Story: FC<IStory> = ({ goToNextSlideOrStory, goToPrevSlideOrStory, isPaused, setIsPaused, currentSlide }) => {
    const isLargeTablet = useLargeTablet();
    const backgroundImageURL = `${STRAPI_BASE_URL}${currentSlide?.background.slice(1)}`;

    const [timerClickDown, setTimerClickDown] = useState<ReturnType<typeof setTimeout>>();

    const onMouseDown = () => {
        if (timerClickDown) {
            clearTimeout(timerClickDown);
        }
        const timeout = setTimeout(() => {
            setIsPaused(true);
        }, 600);
        setTimerClickDown(timeout);
    };

    const onMouseUp = (isButtonNextSlide: boolean) => {
        if (timerClickDown) {
            clearTimeout(timerClickDown);
        }
        if (isPaused) {
            setIsPaused(false);
        } else if (isButtonNextSlide) {
            goToNextSlideOrStory();
        } else {
            goToPrevSlideOrStory();
        }
    };

    return (
        <>
            <SlideBackground src={backgroundImageURL} />
            <SlideContent>
                <SlideTitle isDark={currentSlide?.backgroundColor === enumBackgroundStory.LIGHT}>
                    {currentSlide?.title}
                </SlideTitle>
                <SlideDescription isDark={currentSlide?.backgroundColor === enumBackgroundStory.LIGHT}>
                    {currentSlide?.description}
                </SlideDescription>
            </SlideContent>
            <InvisibleButtons>
                <InvisibleButtonHalf
                    type="button"
                    onMouseDown={isLargeTablet ? undefined : onMouseDown}
                    onMouseUp={
                        isLargeTablet
                            ? undefined
                            : () => {
                                  onMouseUp(false);
                              }
                    }
                    onTouchStart={isLargeTablet ? onMouseDown : undefined}
                    onTouchEnd={
                        isLargeTablet
                            ? () => {
                                  onMouseUp(false);
                              }
                            : undefined
                    }
                />
                <InvisibleButtonHalf
                    type="button"
                    onMouseDown={isLargeTablet ? undefined : onMouseDown}
                    onMouseUp={
                        isLargeTablet
                            ? undefined
                            : () => {
                                  onMouseUp(true);
                              }
                    }
                    onTouchStart={isLargeTablet ? onMouseDown : undefined}
                    onTouchEnd={
                        isLargeTablet
                            ? () => {
                                  onMouseUp(true);
                              }
                            : undefined
                    }
                />
            </InvisibleButtons>
        </>
    );
};

export default React.memo(Story);
