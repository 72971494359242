import React from 'react';
import {
    ButtonConnectClaim,
    Description,
    Info,
    MissingClaimWrapper,
    NavLinkToClaim,
    Title,
} from '@pages/Shop/ShopTabsTables/Refunds/components/MissingClaim/styles';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getOpenClaimModeratingByMerchantId } from '@store/Claims/selectors';
import { IShopRouteParams } from '@pages/Shop';
import { Routes } from '@pages/constants';
import { getCurrentCounterpartyId } from '@store/Counterparty/selectors';
import { useIntl } from 'react-intl';

const MissingClaim = () => {
    const intl = useIntl();
    const { merchantId } = useParams<IShopRouteParams>();

    const counterpartyId = useSelector(getCurrentCounterpartyId);
    const claimModerating = useSelector(getOpenClaimModeratingByMerchantId(merchantId));

    return (
        <MissingClaimWrapper>
            <Info>
                <Title>
                    {intl.formatMessage({
                        defaultMessage: 'Доступно магазинам с подключённой услугой - приём платежей',
                    })}
                </Title>
                <Description>
                    {intl.formatMessage({
                        defaultMessage:
                            'Для того чтобы осуществлять возвраты, Вам необходимо подключить к данному магазину услугу Приём платежей.',
                    })}
                </Description>
            </Info>
            {!claimModerating && (
                <NavLinkToClaim to={`${Routes.claim}/${counterpartyId}/${merchantId}`}>
                    <ButtonConnectClaim>
                        {intl.formatMessage({ defaultMessage: 'Подключить услугу' })}
                    </ButtonConnectClaim>
                </NavLinkToClaim>
            )}
        </MissingClaimWrapper>
    );
};

export default MissingClaim;
