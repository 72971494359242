import React, { FC, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useIntl } from 'react-intl';
import { AuthStepType, LoginContext } from './context';
import EnterPhoneForm from './components/EnterPhoneForm';
import EnterCodeForm from './components/EnterCodeForm';
import { Logo, Wrapper } from './styles';

interface IFormsMap {
    [key: number]: FC;
}

const formsMap: IFormsMap = {
    [AuthStepType.enterPhoneForm]: EnterPhoneForm,
    [AuthStepType.enterCodeForm]: EnterCodeForm,
};

const maxStep = Object.keys(formsMap).length - 1;

const Auth: FC = () => {
    const intl = useIntl();

    const [step, setStep] = useState(AuthStepType.enterPhoneForm);
    const [phone, setPhone] = useState('');

    const toNextStep = () => {
        if (step !== maxStep) {
            setStep((currentStep) => currentStep + 1);
        }
    };

    const toPrevStep = () => setStep((currentStep) => (currentStep === 0 ? currentStep : currentStep - 1));

    const AuthInner = formsMap[step];

    return (
        <Wrapper>
            <Helmet>
                <title>{intl.formatMessage({ defaultMessage: 'Вход в приложение' })}</title>
            </Helmet>
            <Logo />
            <LoginContext.Provider value={{ step, phone, setPhone, toNextStep, toPrevStep }}>
                <AuthInner />
            </LoginContext.Provider>
        </Wrapper>
    );
};

export default Auth;
