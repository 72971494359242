import React from 'react';
import { ButtonCreateWrapper } from '@pages/OrderContainer/components/ButtonCreateRefund/styles';
import IconRefund from '@assets/icons/return.svg';
import { Routes } from '@pages/constants';
import { useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';

const ButtonCreateRefund = () => {
    const { orderContainerId } = useParams<{ orderContainerId: string }>();
    const intl = useIntl();

    return (
        <ButtonCreateWrapper to={`${Routes.ordercontainer}/${orderContainerId}${Routes.refundCreate}`}>
            <IconRefund /> {intl.formatMessage({ defaultMessage: 'Создать возврат' })}
        </ButtonCreateWrapper>
    );
};

export default ButtonCreateRefund;
