import styled from 'styled-components';
import Input from '@components/Input';

export const CommodityFooterWrapper = styled.div`
    ${({ theme }) => theme.mixins.flexCenter};
    justify-content: space-between;
    margin-top: 10px;
    @media ${({ theme }) => theme.breakpoints.md} {
        grid-column-gap: 10px;
    }
`;

export const FieldCount = styled(Input)`
    width: 141px;
    @media ${({ theme }) => theme.breakpoints.xxl} {
        width: 104px;
    }
    @media ${({ theme }) => theme.breakpoints.md} {
        width: 70px;
    }
`;

export const Amount = styled.div`
    margin-left: 70px;
    width: 106px;
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 7px;
    @media ${({ theme }) => theme.breakpoints.md} {
        margin: 0;
        width: auto;
    }
`;

export const Residue = styled.div`
    max-width: 116px;
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 7px;
`;
