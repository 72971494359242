import { TOrder, TOrderStatusUpdateProps } from '@store/Order/types';
import http, { ICommonResponse } from '@utils/http';
import { COUNT_ITEMS_TO_DASHBOARD_PAGE } from '@store/Feed/constants';

export const getOrdersListRequest = (params: any) =>
    http.get<ICommonResponse<Array<TOrder>>>('filter/api/order/order', {
        params: { ...params, '_order[createdAt]': 'desc', _pageSize: COUNT_ITEMS_TO_DASHBOARD_PAGE },
    });

export const createOrderRequest = (data: TOrder) =>
    http.post<ICommonResponse<TOrder>>('/billing/api/order/order', data);

export const updateOrderRequest = (data: TOrder) =>
    http.put<ICommonResponse<TOrder>>(`/billing/api/order/order/${data.id}`, data);

export const updateOrderStatusRequest = (data: TOrderStatusUpdateProps) =>
    http.put<ICommonResponse<TOrder>>(`/billing/api/order/order/${data.id}/status`, data);

export const cancelOrderRequest = (id: number) =>
    http.delete<ICommonResponse<TOrder>>(`/billing/api/order/order/${id}`);
