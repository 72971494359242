import React, { FC } from 'react';
import FormField, { FormFieldTypeEnum } from '@components/FormField';
import { Form } from 'react-final-form';
import { IClientCounterparty } from '@store/ClientCounterparty/types';
import useValidationSchema from '@hooks/useValidationSchema';
import { ModalAddCounterpartySchema } from '@pages/Shop/validations';
import { useHistory, useParams } from 'react-router-dom';
import { useAppDispatch } from '@store';
import { useSelector } from 'react-redux';
import { getCurrentCounterpartyId } from '@store/Counterparty/selectors';
import { FormCreateCounterparty } from '@pages/Shop/ShopTabsTables/CreateNewClient/components/FormEditClientCounterparty/styles';
import { COUNTERPARTY_FIELDS } from '@store/Counterparty/contants';
import { ORDER_CUSTOMER_FIELDS } from '@store/Order/types';
import { ICounterpartyDetails } from '@components/InputAutocomplete/types';
import { createClientCounterparty, updateClientCounterparty } from '@store/ClientCounterparty';
import { CLIENT_COUNTERPARTY_FIELDS } from '@store/ClientCounterparty/constants';
import { Routes } from '@pages/constants';
import { useIntl } from 'react-intl';

interface IFormEditClientCounterparty {
    formId: string;
    setIsEditSettings: (arg: boolean) => void;
    clientCounterparty: IClientCounterparty | undefined;
    toggleSaving: () => void;
}

const FormEditClientCounterparty: FC<IFormEditClientCounterparty> = ({
    formId,
    setIsEditSettings,
    clientCounterparty,
    toggleSaving,
}) => {
    const intl = useIntl();
    const validation = useValidationSchema(ModalAddCounterpartySchema);
    const { merchantId, clientCounterpartyId } = useParams<{ merchantId: string; clientCounterpartyId: string }>();
    const dispatch = useAppDispatch();
    const currentCounterpartyId = useSelector(getCurrentCounterpartyId);
    const history = useHistory();

    const onSubmit = async (formValues: IClientCounterparty) => {
        toggleSaving();
        if (clientCounterpartyId && clientCounterparty) {
            const result = await dispatch(updateClientCounterparty(formValues));
            // @ts-ignore
            if (!result?.error && result?.payload) {
                setIsEditSettings(false);
            }
        } else {
            const result = await dispatch(
                createClientCounterparty({
                    ...formValues,
                    [CLIENT_COUNTERPARTY_FIELDS.ownerCounterpartyId]: String(currentCounterpartyId),
                }),
            );
            // @ts-ignore
            if (!result?.error && result?.payload) {
                history.push(
                    `${Routes.dashboard}${Routes.shop}/${merchantId}${Routes.detailclientcounterparty}/${result?.payload.id}`,
                );
            }
        }
        toggleSaving();
    };
    return (
        <Form onSubmit={onSubmit} initialValues={clientCounterparty} validate={validation}>
            {({ handleSubmit }) => (
                <FormCreateCounterparty id={formId} onSubmit={handleSubmit}>
                    <FormField
                        name={COUNTERPARTY_FIELDS.name}
                        label={intl.formatMessage({ defaultMessage: 'Наименование' })}
                        placeholder={intl.formatMessage({ defaultMessage: 'Введите наименование' })}
                    />
                    <FormField
                        fieldType={FormFieldTypeEnum.autoCompleteVat}
                        name={ORDER_CUSTOMER_FIELDS.vatNumber}
                        label={intl.formatMessage({ defaultMessage: 'ИНН' })}
                        placeholder={intl.formatMessage({ defaultMessage: 'Введите номер ИНН' })}
                        autoFill={({ name, registrationAddress }: ICounterpartyDetails) => ({
                            [ORDER_CUSTOMER_FIELDS.name]: name,
                            [ORDER_CUSTOMER_FIELDS.registrationAddress]: registrationAddress,
                        })}
                    />
                    <FormField
                        name={COUNTERPARTY_FIELDS.registrationAddress}
                        label={intl.formatMessage({ defaultMessage: 'Адрес' })}
                        placeholder={intl.formatMessage({ defaultMessage: 'Введите адрес регистрации' })}
                    />
                </FormCreateCounterparty>
            )}
        </Form>
    );
};

export default FormEditClientCounterparty;
