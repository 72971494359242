import React, { FC, useContext } from 'react';
import { Helmet } from 'react-helmet-async';

import Button from '@components/Button';
import { useMobile } from '@hooks/useMedia';
import ArrowRightIcon from '@assets/icons/arrow-right.svg';
import { useIntl } from 'react-intl';
import { IntroduceContext, ModalTypes } from '../../context';
import { ButtonsContainer, Description, IntroduceInner, Title } from '../../styles';

const LegalPayTarget: FC = () => {
    const intl = useIntl();
    const { openModal, toNextStep } = useContext(IntroduceContext);
    const isMobile = useMobile();

    const openCompanyModal = () => openModal(ModalTypes.company);

    return (
        <IntroduceInner>
            <Helmet>
                <title>{intl.formatMessage({ defaultMessage: 'Информация об организации' })}</title>
            </Helmet>
            <Title>{intl.formatMessage({ defaultMessage: 'Я хочу оплачивать счета как юр. лицо' })}</Title>
            <Description>
                {intl.formatMessage({
                    defaultMessage: 'Если вы хотите оформлять покупки от имени организации, добавьте информацию о ней',
                })}
            </Description>
            <ButtonsContainer>
                <Button onClick={openCompanyModal}>
                    {intl.formatMessage({ defaultMessage: 'Добавить организацию' })}
                </Button>
                <Button type="secondary" icon={isMobile && <ArrowRightIcon />} onClick={toNextStep}>
                    {!isMobile && intl.formatMessage({ defaultMessage: 'Пропустить' })}
                </Button>
            </ButtonsContainer>
        </IntroduceInner>
    );
};

export default LegalPayTarget;
