import React, { FC } from 'react';
import {
    LabelField,
    LabelWrapper,
    ValueField,
} from '@components/Widgets/ClaimModeratingWidget/components/ColumnLabel/styles';

interface IColumnLabel {
    label: string;
    value?: string;
    className?: string;
}

const ColumnLabel: FC<IColumnLabel> = ({ className, label, value }) => (
    <LabelWrapper className={className}>
        <LabelField>{label}</LabelField>
        {value && <ValueField>{value}</ValueField>}
    </LabelWrapper>
);

export default ColumnLabel;
