import styled from 'styled-components';
import Typography from '@components/Typography';

interface INewOrderPayAccordion {
    isOpen: boolean;
}

export const NewOrderPayHeadButton = styled.button<INewOrderPayAccordion>`
    ${({ theme }) => theme.mixins.flexCenter};
    justify-content: space-between;
    background: ${({ isOpen, theme }) => (isOpen ? 'rgba(241, 242, 246, 0.5)' : theme.colors.white())};
    border-radius: ${({ isOpen, theme }) =>
        isOpen
            ? `${theme.decorations.borderRadius.base}px ${theme.decorations.borderRadius.base}px 0 0`
            : `${theme.decorations.borderRadius.base}px`};
    width: 100%;
    padding: 30px;
    transition: ${({ theme }) => theme.decorations.transition?.base};
`;

export const NewOrderPayButtonLabel = styled(Typography).attrs({ variant: 'title' })``;

export const NewOrderAccordionsWrapper = styled.div`
    display: grid;
    grid-template-columns: 100%;
    grid-row-gap: 30px;
`;

export const NewOrderPayAccordionWrapper = styled.div`
    position: relative;
    box-shadow: ${({ theme }) => theme.decorations.boxShadow?.base};
    border-radius: ${({ theme }) => theme.decorations.borderRadius?.base}px;
`;

interface INewOrderPayAccordionContent {
    isOpen: boolean;
}

export const NewOrderPayAccordionContent = styled.div<INewOrderPayAccordionContent>`
    padding: 0 30px;
    @media ${({ theme }) => theme.breakpoints.ld} {
        padding: 0 20px;
    }
    overflow: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};
    max-height: ${({ isOpen }) => (isOpen ? '800px' : '0')};
    transition: ${({ theme }) => theme.decorations.transition?.base};
`;

export const Header = styled.div`
    padding: 0 30px;
`;
