import React, { FC } from 'react';
import { Tab, TabsWrapper } from '@pages/Claim/components/TabsConnection/styles';
import { IStep } from '@pages/Claim/components/MainConnectionPayment';

interface ITabComponent {
    component: React.FC<IStep>;
    name: string;
}

interface ITabsConnection {
    tabs: {
        '0': ITabComponent;
        '1': ITabComponent;
        '2': ITabComponent;
        '3': ITabComponent;
        '4': ITabComponent;
        '5': ITabComponent;
    };
    activeStep: number;
    setStep: (arg: number) => void;
    isActiveTabs: boolean;
    isSkipFirstTab: boolean;
}

const TabsConnection: FC<ITabsConnection> = ({ isSkipFirstTab, isActiveTabs, setStep, tabs, activeStep }) => (
    <TabsWrapper isSkipFirstTab={isSkipFirstTab}>
        {Object.entries(tabs).map((tab, index) => {
            if (isSkipFirstTab && index === 0) {
                return null;
            }
            return (
                <Tab
                    type="button"
                    onClick={
                        isActiveTabs
                            ? () => {
                                  setStep(Number(tab[0]));
                              }
                            : () => {}
                    }
                    isActive={activeStep.toString() === tab[0]}
                    key={tab[0]}
                >
                    {tab[1].name}
                </Tab>
            );
        })}
    </TabsWrapper>
);

export default TabsConnection;
