import styled from 'styled-components';

export const IntegrationInputsWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 20px;
    width: 412px;

    @media ${({ theme }) => theme.breakpoints.sm} {
        width: 100%;
        grid-row-gap: 24px;
    }
`;
