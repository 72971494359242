import styled from 'styled-components';

export const FormCreateCounterparty = styled.form`
    display: grid;
    grid-template-columns: 253px 160px 1fr;
    grid-column-gap: 30px;
    padding: 0 30px 30px 30px;
    @media ${({ theme }) => theme.breakpoints.md} {
        grid-template-columns: 1fr;
        grid-row-gap: 10px;
        padding: 0 20px 30px 20px;
    }
`;
