import { RootState } from '@store';
import { ordersAdapter } from '@store/Order/index';

const getOrders = (state: RootState) => state.orders;

const { selectAll, selectById, selectEntities } = ordersAdapter.getSelectors(getOrders);

export const getIsOrdersLoading = (state: RootState) => getOrders(state).isLoading;
export const getOrdersMetaData = (state: RootState) => getOrders(state).metaData;
export const getOrdersMap = (state: RootState) => selectEntities(state);
export const getOrderById = (id: string) => (state: RootState) => selectById(state, id);

export const getOrdersList = (state: RootState) => selectAll(state);
