import React, { FC, useContext } from 'react';
import { Form } from 'react-final-form';
import Typography from '@components/Typography';
import { FormFieldTypeEnum } from '@components/FormField';
import { COUNTERPARTY_FIELDS } from '@store/Counterparty/contants';
import useValidationSchema from '@hooks/useValidationSchema';
import { ICounterpartyRequestFields } from '@store/Counterparty/types';
import { useAppDispatch } from '@store';
import { createCounterparty } from '@store/Counterparty';
import { useSelector } from 'react-redux';
import { getIsCounterpartyLoading } from '@store/Counterparty/selectors';
import { companySchema } from '@pages/Introduce/validations';
import useDictionary from '@hooks/useDictionarty';
import { DICTIONARY_TYPES } from '@store/Dictionaries/constants';
import useLoadingSubmit from '@hooks/useLoadingSubmit';
import { useIntl } from 'react-intl';
import { IntroduceContext, IntroduceStepType, ModalTypes } from '../../context';
import {
    ModalFormButton,
    ModalFormCaption,
    ModalFormField,
    ModalFormInner,
    ModalFromFieldWrapperHalf,
    ModalFromFieldWrapperThird,
    ModalInner,
} from '../../styles';

interface ILegalEntityType {
    id: number;
    name: string;
}

interface ICompanyModalInner {
    onClose?: () => void;
}

const CompanyModalInner: FC<ICompanyModalInner> = ({ onClose }) => {
    const intl = useIntl();
    const { isLoadingSubmit, toggleLoadingSubmit } = useLoadingSubmit();
    const { step, toNextStep, setModalType, closeModal } = useContext(IntroduceContext);
    const validation = useValidationSchema(companySchema);

    const isLoadingCounterparty = useSelector(getIsCounterpartyLoading);
    const dispatch = useAppDispatch();

    const legalEntityTypes: ILegalEntityType[] = useDictionary(DICTIONARY_TYPES.legalEntityType)?.data as any;

    const submitHandler = async (values: ICounterpartyRequestFields) => {
        const counterparty = {
            ...values,
            [COUNTERPARTY_FIELDS.legalEntityTypeId]: +values[COUNTERPARTY_FIELDS.legalEntityTypeId],
        };
        toggleLoadingSubmit();
        const data = await dispatch(createCounterparty(counterparty));

        // @ts-ignore
        if (!data.error && data?.payload) {
            if (step === IntroduceStepType.saleTarget) {
                setModalType(ModalTypes.merchant);
            }

            if (step === IntroduceStepType.legalPayTarget) {
                toNextStep();
                closeModal();
            }

            if (onClose) {
                onClose();
            }
        }
    };

    return (
        <ModalInner>
            <Typography variant="subheading">{intl.formatMessage({ defaultMessage: 'Общая информация' })}</Typography>
            <Form onSubmit={submitHandler} validate={validation}>
                {({ valid, handleSubmit }) => (
                    <ModalFormInner onSubmit={handleSubmit}>
                        <ModalFormField
                            name={COUNTERPARTY_FIELDS.vatNumber}
                            label={intl.formatMessage({ defaultMessage: 'ИНН' })}
                            placeholder={intl.formatMessage({ defaultMessage: 'Введите номер ИНН' })}
                        />
                        <ModalFromFieldWrapperThird>
                            <ModalFormField
                                name={COUNTERPARTY_FIELDS.legalEntityTypeId}
                                label={intl.formatMessage({ defaultMessage: 'ОПФ' })}
                                placeholder={intl.formatMessage({ defaultMessage: 'Выберите ОПФ' })}
                                fieldType={FormFieldTypeEnum.select}
                                options={legalEntityTypes.map(({ id, name }) => ({ label: name, value: id }))}
                            />
                            <ModalFormField
                                name={COUNTERPARTY_FIELDS.name}
                                label={intl.formatMessage({ defaultMessage: 'Наименование' })}
                                placeholder={intl.formatMessage({ defaultMessage: 'Введите наименование' })}
                            />
                        </ModalFromFieldWrapperThird>
                        <ModalFormField
                            fieldType={FormFieldTypeEnum.autoCompleteAddress}
                            name={COUNTERPARTY_FIELDS.registrationAddress}
                            label={intl.formatMessage({ defaultMessage: 'Адрес' })}
                            placeholder={intl.formatMessage({ defaultMessage: 'Начните вводить адрес' })}
                        />
                        <ModalFromFieldWrapperHalf>
                            <ModalFormField
                                name={COUNTERPARTY_FIELDS.cfoName}
                                label={intl.formatMessage({ defaultMessage: 'Бухгалтер' })}
                                placeholder={intl.formatMessage({ defaultMessage: 'ФИО бухгалтера' })}
                            />
                            <ModalFormField
                                name={COUNTERPARTY_FIELDS.ceoName}
                                label={intl.formatMessage({ defaultMessage: 'Гендиректор' })}
                                placeholder={intl.formatMessage({ defaultMessage: 'ФИО гендиректора' })}
                            />
                        </ModalFromFieldWrapperHalf>
                        <ModalFormCaption>
                            {intl.formatMessage({ defaultMessage: '* Эти данные потребуются для выставления счетов' })}
                        </ModalFormCaption>
                        <ModalFormButton
                            isLoading={isLoadingSubmit}
                            disabled={!valid || isLoadingCounterparty}
                            onClick={handleSubmit}
                        >
                            {intl.formatMessage({ defaultMessage: 'Далее' })}
                        </ModalFormButton>
                    </ModalFormInner>
                )}
            </Form>
        </ModalInner>
    );
};

export default CompanyModalInner;
