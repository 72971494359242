import React, { FC } from 'react';
import { Form } from 'react-final-form';
import FormField, { FormFieldTypeEnum } from '@components/FormField';
import { COUNTERPARTY_FIELDS } from '@store/Counterparty/contants';
import { ORDER_CUSTOMER_FIELDS } from '@store/Order/types';
import { ICounterpartyDetails } from '@components/InputAutocomplete/types';
import useValidationSchema from '@hooks/useValidationSchema';
import { ModalAddCounterpartySchema } from '@pages/Shop/validations';
import { useSelector } from 'react-redux';
import { getCurrentCounterparty } from '@store/Counterparty/selectors';
import { useAppDispatch } from '@store';
import { createClientCounterparty } from '@store/ClientCounterparty';
import useLoadingSubmit from '@hooks/useLoadingSubmit';
import { useIntl } from 'react-intl';
import { FormInner, IconAdd, InputsContainer, ModalWrapper, RegistrationAddress, SubmitButton } from './styles';

interface IModalAddOrganization {
    onClose: () => void;
    isOpen: boolean;
    connectToOrganization: (idCounterparty: number) => void;
}

const ModalAddOrganization: FC<IModalAddOrganization> = ({ connectToOrganization, onClose, isOpen }) => {
    const intl = useIntl();
    const { isLoadingSubmit, toggleLoadingSubmit } = useLoadingSubmit();
    const validation = useValidationSchema(ModalAddCounterpartySchema);
    const dispatch = useAppDispatch();
    const currentCounterpartyId = useSelector(getCurrentCounterparty)?.id;

    const onSubmit = async (values: any) => {
        toggleLoadingSubmit();
        const result = await dispatch(
            createClientCounterparty({
                ...values,
                [COUNTERPARTY_FIELDS.ownerCounterpartyId]: currentCounterpartyId,
            }),
        );
        // @ts-ignore
        if (!result?.error && result?.payload) {
            toggleLoadingSubmit();
            connectToOrganization(result.payload.id);
            onClose();
        }
    };

    return (
        <ModalWrapper
            closeModal={onClose}
            isOpen={isOpen}
            title={intl.formatMessage({ defaultMessage: 'Добавить организацию' })}
        >
            <Form onSubmit={onSubmit} validate={validation}>
                {({ handleSubmit, valid }) => (
                    <FormInner onSubmit={handleSubmit}>
                        <InputsContainer>
                            <FormField
                                name={COUNTERPARTY_FIELDS.name}
                                label={intl.formatMessage({ defaultMessage: 'Наименование' })}
                                placeholder={intl.formatMessage({ defaultMessage: 'Введите наименование' })}
                            />
                            <FormField
                                fieldType={FormFieldTypeEnum.autoCompleteVat}
                                name={ORDER_CUSTOMER_FIELDS.vatNumber}
                                label={intl.formatMessage({ defaultMessage: 'ИНН' })}
                                placeholder={intl.formatMessage({ defaultMessage: 'Введите номер ИНН' })}
                                autoFill={({ name, registrationAddress }: ICounterpartyDetails) => ({
                                    [ORDER_CUSTOMER_FIELDS.name]: name,
                                    [ORDER_CUSTOMER_FIELDS.registrationAddress]: registrationAddress,
                                })}
                            />
                            <RegistrationAddress
                                name={COUNTERPARTY_FIELDS.registrationAddress}
                                label={intl.formatMessage({ defaultMessage: 'Адрес' })}
                                placeholder={intl.formatMessage({ defaultMessage: 'Введите адрес регистрации' })}
                            />
                        </InputsContainer>
                        <SubmitButton
                            isLoading={isLoadingSubmit}
                            onClick={handleSubmit}
                            disabled={!valid}
                            icon={<IconAdd />}
                        >
                            {intl.formatMessage({ defaultMessage: 'Добавить' })}
                        </SubmitButton>
                    </FormInner>
                )}
            </Form>
        </ModalWrapper>
    );
};

export default ModalAddOrganization;
