import React, { FC } from 'react';
import styled, { AnyStyledComponent, createGlobalStyle } from 'styled-components';

import { COLOR_BRIGHTNESS } from '@theme/utils';
import Portal from '@components/Portal';
import Typography from '@components/Typography';
import useClickOutside from '@hooks/useClickOutside';
import CloseIcon from '@assets/icons/close-square.svg';

interface IModalProps {
    isOpen: boolean;
    title?: string;
    caption?: string;
    className?: string;
    closeModal: () => void;
    HeaderComponent?: AnyStyledComponent;
}

const Modal: FC<IModalProps> = ({ children, title, caption, isOpen, closeModal, className, HeaderComponent }) => {
    const contentRef = useClickOutside(closeModal);
    const showHeaderOverlay = title || caption;

    if (!isOpen) return null;

    const Header = HeaderComponent || DefaultHeader;

    return (
        <Portal>
            <OverflowHidden />
            <Container>
                <ContainerInner>
                    <Backdrop />
                    <Content ref={contentRef}>
                        <CloseButton onClick={closeModal}>
                            <CloseIcon />
                        </CloseButton>
                        {showHeaderOverlay && (
                            <Header>
                                {title && <Title>{title}</Title>}
                                {caption && <Caption>{caption}</Caption>}
                            </Header>
                        )}
                        <ModalInner className={className}>{children}</ModalInner>
                    </Content>
                </ContainerInner>
            </Container>
        </Portal>
    );
};

export default Modal;

const OverflowHidden = createGlobalStyle`
  body {
    overflow: hidden;
  }
`;

const Container = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
`;

const ContainerInner = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    overflow: hidden auto;
    align-items: flex-start;
    padding: 55px 0;

    @media ${({ theme }) => theme.breakpoints.sm} {
        padding: 15px 0;
    }
`;

const Backdrop = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    background-color: ${({ theme }) => theme.colors.darkest(COLOR_BRIGHTNESS.MEDIUM)};
`;

const Content = styled.div`
    margin: auto;
    max-width: calc(100vw - 30px); //Full width - container paddings 15px
    overflow: auto;
    position: relative;
    background-color: ${({ theme }) => theme.colors.white()};
    border-radius: ${({ theme }) => theme.decorations.borderRadius?.base}px;
    box-shadow: ${({ theme }) => theme.decorations.boxShadow?.base};

    @media ${({ theme }) => theme.breakpoints.md} {
        width: 100%;
    }
`;

const CloseButton = styled.button`
    position: absolute;
    display: flex;
    top: 22px;
    right: 22px;
    z-index: 1000;
`;

const DefaultHeader = styled.div`
    ${({ theme }) => theme.mixins.modalHeader};
`;

const ModalInner = styled.div`
    padding: 0 50px 50px;

    @media ${({ theme }) => theme.breakpoints.sm} {
        padding: 0 20px 30px;
    }
`;

const Title = styled(Typography).attrs({ variant: 'title' })`
    @media ${({ theme }) => theme.breakpoints.sm} {
        ${({ theme }) => theme.mixins.getTypography('subheading')}
    }
`;

const Caption = styled(Typography).attrs({ variant: 'regular14' })`
    position: relative;
    margin-left: auto;
    padding-left: 20px;
`;
