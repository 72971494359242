import styled from 'styled-components';

export const ButtonsContainer = styled.div`
    margin: 52px auto 0;
    ${({ theme }) => theme.mixins.flexCenterCenter};
    grid-column-gap: 30px;
    @media ${({ theme }) => theme.breakpoints.md} {
        margin: 40px auto 0;
    }
`;
