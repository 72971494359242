import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { IMerchantIntegration } from '@store/MerchantIntegration/types';
import createAsyncThunkWithErrorHandler from '@utils/helpers/createAsyncThunkWithErrorHandler';
import { fetchMerchantIntegrationRequest } from '@store/MerchantIntegration/http';
import { Nullable } from '@utils/utilityTypes';

export const merchantIntegrationAdapter = createEntityAdapter<IMerchantIntegration>();

interface IInitialState {
    loading: boolean;
    selectedId: Nullable<string>;
    selected: Nullable<IMerchantIntegration>;
}

const initialState = merchantIntegrationAdapter.getInitialState<IInitialState>({
    loading: false,
    selectedId: null,
    selected: null,
});

export const fetchMerchantIntegration = createAsyncThunkWithErrorHandler('merchantIntegration/getAll', async () => {
    const {
        data: { data },
    } = await fetchMerchantIntegrationRequest();
    return data;
});

const MerchantIntegration = createSlice({
    name: 'merchantIntegration',
    initialState,
    reducers: {
        setSelectedMerchantIntegrationId(state, { payload }) {
            state.selectedId = payload;
        },
        setSelectedMerchantIntegration(state, { payload }) {
            state.selected = payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchMerchantIntegration.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(fetchMerchantIntegration.fulfilled, (state, { payload }) => {
            merchantIntegrationAdapter.upsertMany(state, payload);
            state.loading = false;
        });
        builder.addCase(fetchMerchantIntegration.rejected, (state) => {
            state.loading = false;
        });
    },
});

export const { setSelectedMerchantIntegration } = MerchantIntegration.actions;

export default MerchantIntegration.reducer;
