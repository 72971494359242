import React from 'react';
import { RadioWrapper, ReasonLabel, ReasonRefundWrapper } from '@pages/Refund/components/ReasonRefund/styles';
import Radio from '@components/Radio';
import { Field, useField } from 'react-final-form';
import FormField, { FormFieldTypeEnum } from '@components/FormField';
import { ORDER_FIELDS } from '@store/Order/types';
import { REFUND_FIELD } from '@store/OrderRefunds/types';
import { IntlShape, useIntl } from 'react-intl';

// eslint-disable-next-line no-shadow
export enum enumsReasons {
    productOfInadequateQuality = 'productOfInadequateQuality',
    discountForMarriage = 'discountForMarriage',
    deliveredAtTheWrongTime = 'deliveredAtTheWrongTime',
    anotherReason = 'anotherReason',
    other = 'other',
}

export const getLabelReason = (intl: IntlShape, reason: enumsReasons) => {
    const labelsReasons = {
        [enumsReasons.productOfInadequateQuality]: intl.formatMessage({
            defaultMessage: 'Товар ненадлежащего качества',
        }),
        [enumsReasons.discountForMarriage]: intl.formatMessage({ defaultMessage: 'Скидка за брак' }),
        [enumsReasons.deliveredAtTheWrongTime]: intl.formatMessage({ defaultMessage: 'Доставили не вовремя' }),
        [enumsReasons.anotherReason]: intl.formatMessage({ defaultMessage: 'Очередная причина' }),
        [enumsReasons.other]: intl.formatMessage({ defaultMessage: 'Другое' }),
    };

    return labelsReasons[reason];
};

const ReasonRefund = () => {
    const intl = useIntl();
    const {
        input: { value: reasonIdKey },
    } = useField(REFUND_FIELD.reasonId);

    const arrReasons = [
        enumsReasons.productOfInadequateQuality,
        enumsReasons.discountForMarriage,
        enumsReasons.deliveredAtTheWrongTime,
        enumsReasons.anotherReason,
        enumsReasons.other,
    ];

    const isShowOtherReasonInput = reasonIdKey === enumsReasons.other;

    return (
        <ReasonRefundWrapper>
            {arrReasons.map((reason) => (
                <Field name={REFUND_FIELD.reasonId} type="radio" value={reason} key={reason}>
                    {({ input }) => (
                        <RadioWrapper>
                            <Radio checked={!!input.checked} {...input} />
                            <ReasonLabel>{getLabelReason(intl, reason)}</ReasonLabel>
                        </RadioWrapper>
                    )}
                </Field>
            ))}
            {isShowOtherReasonInput && (
                <FormField
                    placeholder={intl.formatMessage({ defaultMessage: 'Введите текст' })}
                    name={ORDER_FIELDS.description}
                    fieldType={FormFieldTypeEnum.input}
                    label={intl.formatMessage({ defaultMessage: 'Укажите свою причину' })}
                />
            )}
        </ReasonRefundWrapper>
    );
};

export default ReasonRefund;
