import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { IDebitInvoice } from './types';

export const debitInvoiceAdapter = createEntityAdapter<IDebitInvoice>({
    selectId: (debitInvoice) => debitInvoice.orderContainerId,
});
const initialState = debitInvoiceAdapter.getInitialState();

const DebitInvoice = createSlice({
    name: 'debitInvoice',
    initialState,
    reducers: {
        addDebitInvoice(state, { payload }) {
            debitInvoiceAdapter.upsertOne(state, payload);
        },
        addDebitInvoicesList(state, { payload }) {
            debitInvoiceAdapter.upsertMany(state, payload);
        },
    },
});

export const { addDebitInvoice, addDebitInvoicesList } = DebitInvoice.actions;

export default DebitInvoice.reducer;
