import React, { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';

import {
    getError,
    getIsAuthInitialized,
    getIsAuthLoading,
    getIsVerificationExists,
    getUser,
} from '@store/Auth/selectors';
import { AuthRouter, NotAuthRouter } from '@pages/routes';
import { useThrottledEffect } from '@hooks/useThrottledEffect';
import { useAppDispatch } from '@store';
import { fetchUserProfile, setError } from '@store/Auth';
import Loader from '@components/Loader';
import useToast from '@hooks/useToast';
import Toast from '@components/Toast';
import { initializeDictionaries } from '@store/Dictionaries';
import Footer from '@components/Footer';
import { useReCaptchaTokenContext } from '../context/ReCaptchaTokenContext';

const Pages: FC = () => {
    const user = useSelector(getUser);
    const dispatch = useAppDispatch();
    const { token, onRefreshReCaptcha } = useReCaptchaTokenContext();

    const isAuthInitialized = useSelector(getIsAuthInitialized);
    const isAuthLoading = useSelector(getIsAuthLoading);
    const isVerificationExist = useSelector(getIsVerificationExists);

    const error = useSelector(getError);
    const { open: openToast, isShow: isShowToast, setIsShow: setIsShowToast } = useToast();

    const funcAfterCloseToast = () => {
        dispatch(
            setError({
                ...error,
                isActive: false,
            }),
        );
    };

    useEffect(() => {
        if (error.isActive) {
            openToast();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error.isActive]);

    useThrottledEffect(
        () => {
            if (!isAuthInitialized && !isAuthLoading && token) {
                dispatch(fetchUserProfile(token)).finally(() => onRefreshReCaptcha());
            }
        },
        [dispatch, isAuthInitialized, isAuthLoading, onRefreshReCaptcha, token],
        2000,
    );

    useEffect(() => {
        dispatch(initializeDictionaries());
    }, [dispatch]);

    if (!isAuthInitialized || (!isVerificationExist && isAuthLoading)) {
        return <Loader />;
    }

    return (
        <Router>
            {user ? <AuthRouter user={user} /> : <NotAuthRouter />}
            <Footer />
            <Toast
                funcAfterClose={funcAfterCloseToast}
                onClick={() => {}}
                isShowToast={isShowToast}
                setIsShowToast={setIsShowToast}
                title={error?.title}
            />
        </Router>
    );
};

export default Pages;
