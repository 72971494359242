import React, { useEffect, useState } from 'react';
import { COUNTERPARTY_FIELDS } from '@store/Counterparty/contants';
import FormField, { FormFieldTypeEnum } from '@components/FormField';
import { INDIVIDUAL_VAT_NUMBER_LENGTH } from '@utils/constants';
import { useField } from 'react-final-form';
import { InputTypeEnum } from '@components/Input';
import { ILegalEntityType } from '@store/Counterparty/types';
import useDictionary from '@hooks/useDictionarty';
import { DICTIONARY_TYPES } from '@store/Dictionaries/constants';
import { useIntl } from 'react-intl';
import { InputAddress, InputCeo, InputCfo, InputEmail, InputName, InputPhone, InputsWrapper } from './styles';

const CounterpartyInputs = () => {
    const intl = useIntl();
    const [isCounterpartySP, setIsCounterpartySP] = useState(false);
    const {
        input: { value: vatNumber },
    } = useField(COUNTERPARTY_FIELDS.vatNumber);
    const legalEntityTypes: ILegalEntityType[] = useDictionary(DICTIONARY_TYPES.legalEntityType)?.data as any;

    useEffect(() => {
        setIsCounterpartySP(vatNumber.length === INDIVIDUAL_VAT_NUMBER_LENGTH);
    }, [vatNumber]);

    return (
        <InputsWrapper>
            <InputName
                name={COUNTERPARTY_FIELDS.name}
                label={
                    isCounterpartySP
                        ? intl.formatMessage({ defaultMessage: 'ФИО' })
                        : intl.formatMessage({ defaultMessage: 'Наименование' })
                }
                placeholder={
                    isCounterpartySP
                        ? intl.formatMessage({ defaultMessage: 'Введите ФИО' })
                        : intl.formatMessage({ defaultMessage: 'Введите наименование' })
                }
            />
            <FormField
                typeInput={InputTypeEnum.numeric}
                name={COUNTERPARTY_FIELDS.vatNumber}
                label={intl.formatMessage({ defaultMessage: 'ИНН' })}
                placeholder={intl.formatMessage({ defaultMessage: 'Введите ИНН' })}
            />
            <InputAddress
                fieldType={FormFieldTypeEnum.autoCompleteAddress}
                name={COUNTERPARTY_FIELDS.registrationAddress}
                label={intl.formatMessage({ defaultMessage: 'Адрес' })}
                placeholder={intl.formatMessage({ defaultMessage: 'Введите адрес регистрации' })}
            />
            <FormField
                name={COUNTERPARTY_FIELDS.legalEntityTypeId}
                label={intl.formatMessage({ defaultMessage: 'ОПФ' })}
                placeholder={intl.formatMessage({ defaultMessage: 'Выберите ОПФ' })}
                fieldType={FormFieldTypeEnum.select}
                options={legalEntityTypes.map(({ id, name }) => ({ label: name, value: id }))}
            />
            <InputCeo
                name={COUNTERPARTY_FIELDS.ceoName}
                label={intl.formatMessage({ defaultMessage: 'Генеральный директор' })}
                placeholder={intl.formatMessage({ defaultMessage: 'ФИО генерального директора' })}
            />
            <FormField
                name={COUNTERPARTY_FIELDS.taxRegistrationReasonCode}
                label={intl.formatMessage({ defaultMessage: 'КПП' })}
                placeholder={intl.formatMessage({ defaultMessage: 'Введите КПП' })}
            />
            <InputCfo
                name={COUNTERPARTY_FIELDS.cfoName}
                label={intl.formatMessage({ defaultMessage: 'Бухгалтерия' })}
                placeholder={intl.formatMessage({ defaultMessage: 'ФИО бухгалтера' })}
            />
            <FormField
                name={COUNTERPARTY_FIELDS.registrationNumber}
                label={
                    isCounterpartySP
                        ? intl.formatMessage({ defaultMessage: 'ОГРНИП' })
                        : intl.formatMessage({ defaultMessage: 'ОГРН' })
                }
                placeholder={
                    isCounterpartySP
                        ? intl.formatMessage({ defaultMessage: 'Введите ОГРНИП' })
                        : intl.formatMessage({ defaultMessage: 'Введите ОГРН' })
                }
            />
            <InputEmail
                name={COUNTERPARTY_FIELDS.email}
                label="Email"
                placeholder={intl.formatMessage({ defaultMessage: 'Электронная почта' })}
            />
            <InputPhone
                type="tel"
                label={intl.formatMessage({ defaultMessage: 'Телефон' })}
                name={COUNTERPARTY_FIELDS.phone}
                mask="+7 999 999 99 99"
                placeholder="+7 XXX XXX XX XX"
            />
            <FormField
                fieldType={FormFieldTypeEnum.inputDateTime}
                name={COUNTERPARTY_FIELDS.registrationDate}
                label={intl.formatMessage({ defaultMessage: 'Дата регистрации' })}
                dateFormat="d.MM.yyyy"
            />
        </InputsWrapper>
    );
};

export default CounterpartyInputs;
