import React, { FC } from 'react';
import {
    AccessToInvoicingDescription,
    AccessToInvoicingModalInner,
    AccessToInvoicingModalWrapper,
    AccessToInvoicingTitle,
    ButtonCreateMerchant,
} from '@modules/Products/styles';
import { useIntl } from 'react-intl';

interface IAccessToInvoicingModalProps {
    closeModal: () => void;
    isOpen: boolean;
    isCounterparty?: boolean;
    onClickCreateMerchant: () => void;
}

export const AccessToInvoicingModal: FC<IAccessToInvoicingModalProps> = ({
    closeModal,
    isOpen,
    isCounterparty = false,
    onClickCreateMerchant,
}) => {
    const intl = useIntl();

    return (
        <AccessToInvoicingModalWrapper isOpen={isOpen} closeModal={closeModal}>
            <AccessToInvoicingModalInner>
                <AccessToInvoicingTitle>
                    {intl.formatMessage({ defaultMessage: 'Доступ к оформлению счетов' })}
                </AccessToInvoicingTitle>
                <AccessToInvoicingDescription>
                    {isCounterparty ? (
                        <>
                            {intl.formatMessage({
                                defaultMessage:
                                    'Чтобы получить доступы к оформлению счетов Вам необходимо создать магазин',
                            })}
                            <ButtonCreateMerchant onClick={onClickCreateMerchant} type="button">
                                {intl.formatMessage({ defaultMessage: 'Создать магазин' })}
                            </ButtonCreateMerchant>
                        </>
                    ) : (
                        intl.formatMessage({
                            defaultMessage:
                                'Чтобы получить доступы к оформлению счетов Вам необходимо выбрать организацию',
                        })
                    )}
                </AccessToInvoicingDescription>
            </AccessToInvoicingModalInner>
        </AccessToInvoicingModalWrapper>
    );
};
