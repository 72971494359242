import React, { FC } from 'react';
import { Field, Form } from 'react-final-form';
import { find } from 'lodash';

import { MERCHANT_FIELDS } from '@store/Merchant/contants';
import Radio from '@components/Radio';
import { currencyFormatter } from '@utils/formatters';
import { IMerchant } from '@store/Merchant/types';
import useValidationSchema from '@hooks/useValidationSchema';
import { merchantSchema } from '@modules/Products/validations';
import { IMerchantSettlementBalance } from '@store/MerchantSettlementBalance/types';
import { useIntl } from 'react-intl';
import {
    MerchantsModalHeader,
    MerchantsModalSubmit,
    MerchantsModalWrapper,
    ModalFormInner,
    ModalMerchantsBalance,
    ModalMerchantsField,
    ModalMerchantsName,
} from '../styles';

export interface IMerchantModalValue {
    merchantId: string;
}

interface IMerchantsModal {
    closeModal: () => void;
    isOpen: boolean;
    onSubmit: (value: IMerchantModalValue) => void;
    merchants: Array<IMerchant>;
    balances: Array<IMerchantSettlementBalance>;
}

export const MerchantsModal: FC<IMerchantsModal> = ({ closeModal, merchants, balances, isOpen, onSubmit }) => {
    const validation = useValidationSchema(merchantSchema);
    const intl = useIntl();

    const initialValues = {
        merchantId: merchants[0]?.id,
    };

    return (
        <MerchantsModalWrapper
            closeModal={closeModal}
            isOpen={isOpen}
            title={intl.formatMessage({ defaultMessage: 'Выберите магазин' })}
            HeaderComponent={MerchantsModalHeader}
        >
            <Form onSubmit={onSubmit} validate={validation} initialValues={initialValues}>
                {({ valid, handleSubmit }) => (
                    <ModalFormInner onSubmit={handleSubmit}>
                        {merchants.map((merchant) => (
                            <Field
                                name="merchantId"
                                type="radio"
                                value={merchant[MERCHANT_FIELDS.id]}
                                key={merchant[MERCHANT_FIELDS.id]}
                            >
                                {({ input }) => (
                                    <ModalMerchantsField>
                                        <Radio checked={!!input.checked} {...input} />

                                        <ModalMerchantsName>{merchant[MERCHANT_FIELDS.name]}</ModalMerchantsName>
                                        <ModalMerchantsBalance>
                                            {currencyFormatter(
                                                find(balances, { merchantId: merchant[MERCHANT_FIELDS.id] })
                                                    ?.settlementTotalAmount || 0,
                                            )}
                                        </ModalMerchantsBalance>
                                    </ModalMerchantsField>
                                )}
                            </Field>
                        ))}
                        <MerchantsModalSubmit htmlType="submit" disabled={!valid}>
                            {intl.formatMessage({ defaultMessage: 'Выбрать' })}
                        </MerchantsModalSubmit>
                    </ModalFormInner>
                )}
            </Form>
        </MerchantsModalWrapper>
    );
};
