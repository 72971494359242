import React, { FC } from 'react';
import { Routes } from '@pages/constants';
import { useIntl } from 'react-intl';
import { NavigationItem, NavigationLink, NavigationWrapper } from '../styles';

interface INavigationProps {
    onItemClick?: () => void;
}

const Navigation: FC<INavigationProps> = ({ onItemClick }) => {
    const intl = useIntl();

    const NAVIGATION_ITEMS = [
        { label: intl.formatMessage({ defaultMessage: 'Главная' }), to: Routes.dashboard },
        { label: intl.formatMessage({ defaultMessage: 'Маркетплейс' }), to: Routes.marketplace },
        { label: intl.formatMessage({ defaultMessage: 'Клиенты' }), to: Routes.clients },
        { label: intl.formatMessage({ defaultMessage: 'Справка' }), to: Routes.reference },
        { label: intl.formatMessage({ defaultMessage: 'Новости' }), to: Routes.news },
    ];

    return (
        <NavigationWrapper>
            {NAVIGATION_ITEMS.map(({ label, to }) => (
                <NavigationItem key={label + to} onClick={onItemClick}>
                    <NavigationLink to={to}>{label}</NavigationLink>
                </NavigationItem>
            ))}
        </NavigationWrapper>
    );
};

export default Navigation;
