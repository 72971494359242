import React from 'react';
import { EmptyTariffWrapper, Title } from '@components/Widgets/ClaimActiveWidget/components/EmptyNextTariff/styles';
import { useIntl } from 'react-intl';

const EmptyNextTariff = () => {
    const intl = useIntl();

    return (
        <EmptyTariffWrapper>
            <Title>
                {intl.formatMessage({
                    defaultMessage:
                        'Вы всегда можете подключить другой тариф. Он сменит текущий по истечении календарного месяца',
                })}
            </Title>
        </EmptyTariffWrapper>
    );
};

export default EmptyNextTariff;
