import { pick } from 'lodash';
import { RootState } from '../index';
import { orderRefundsAdapter } from './constants';
import { getOrderById, getOrdersMap } from '../Order/selectors';

const getOrderRefunds = (state: RootState) => state.orderRefunds;

const { selectAll, selectById } = orderRefundsAdapter.getSelectors(getOrderRefunds);

export const getOrderRefundsList = (state: RootState) => selectAll(state);
export const getOrderRefundsListWithCustomer = (state: RootState) => {
    const refunds = selectAll(state);
    return refunds.map((refund) => {
        const customer = getOrderById(refund.parentId)(state)?.customer;
        return { ...refund, customer };
    });
};

export const getIsOrderRefundsLoading = (state: RootState) => getOrderRefunds(state).isLoading;
export const getOrderRefundsOrdersMap = (state: RootState) => {
    const { orderIds } = getOrderRefunds(state);
    const ordersMap = getOrdersMap(state);
    return pick(ordersMap, orderIds);
};
export const getOrderRefundMetaData = (state: RootState) => state.orderRefunds.metaData;
export const getOrderRefundIsLoading = (state: RootState) => state.orderRefunds.isLoading;
export const getOrderRefundById = (id: string) => (state: RootState) => selectById(state, id);
