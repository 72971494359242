import React, { FC } from 'react';
import {
    EventTime,
    EventTitle,
    EventWrapperButton,
    EventWrapperNavLink,
    IconWrapper,
} from '@components/Widgets/FeedWidget/components/EventRow/styles';
import IconChat from '@assets/icons/chat.svg';
import { IFeedItem } from '@store/Feed/types';
import dayjs from 'dayjs';
import { HH_MM_FORMAT } from '@utils/constants';
import useModal from '@hooks/useModal';
import ModalFeed from '@components/Widgets/FeedWidget/components/ModalFeed';
import { Routes } from '@pages/constants';
import { typesFeedOpenOrderContainer } from '@store/Feed/constants';

interface IEventRow {
    feed: IFeedItem;
}

const EventRow: FC<IEventRow> = ({ feed }) => {
    const time = dayjs(feed.createdAt).format(HH_MM_FORMAT);
    const { isOpen, open, close } = useModal(false);

    const onClickFeed = () => {
        open();
    };

    const renderContentEventRow = () => (
        <>
            <IconWrapper>
                <IconChat />
            </IconWrapper>
            <EventTitle>{feed.title}</EventTitle>
            <EventTime>{time}</EventTime>
        </>
    );

    if (typesFeedOpenOrderContainer.find((type) => type === feed.type) && feed?.metadata?.targetId) {
        return (
            <EventWrapperNavLink
                to={{
                    pathname: `${Routes.ordercontainer}/${feed.metadata.targetId}`,
                    state: {
                        isOrderContainer: true,
                    },
                }}
            >
                {renderContentEventRow()}
            </EventWrapperNavLink>
        );
    }

    return (
        <>
            <EventWrapperButton type="button" onClick={onClickFeed}>
                {renderContentEventRow()}
            </EventWrapperButton>
            <ModalFeed onClose={close} isOpen={isOpen} feed={feed} />
        </>
    );
};

export default EventRow;
