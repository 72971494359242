import React, { FC } from 'react';
import {
    ClientNameField,
    ModalWrapper,
    SubmitButton,
    InputsContainer,
} from '@pages/Shop/ShopTabsTables/CreateNewClient/components/ModalCreateContactPerson/styles';
import { Form } from 'react-final-form';
import { CLIENT_FIELDS } from '@store/Client/contants';
import FormField from '@components/FormField';
import useValidationSchema from '@hooks/useValidationSchema';
import { ModalAddContactPersonSchema } from '@pages/Shop/validations';
import { useAppDispatch } from '@store';
import { useSelector } from 'react-redux';
import { getCurrentCounterpartyId } from '@store/Counterparty/selectors';
import { createClient } from '@store/Client';
import { removePlusAndGapsToPhone } from '@utils/formatters';
import { IconAdd } from '@pages/Shop/ShopTabsTables/CreateNewClient/components/ModalAddOrganization/styles';
import useLoadingSubmit from '@hooks/useLoadingSubmit';
import { useIntl } from 'react-intl';

interface IModalCreateContactPerson {
    isOpen: boolean;
    onClose: () => void;
    clientCounterpartyId: number;
}

interface IFormContactPersonValues {
    name: string;
    phone: string;
    email: string;
}

const ModalCreateContactPerson: FC<IModalCreateContactPerson> = ({ clientCounterpartyId, isOpen, onClose }) => {
    const intl = useIntl();
    const { isLoadingSubmit, toggleLoadingSubmit } = useLoadingSubmit();
    const validation = useValidationSchema(ModalAddContactPersonSchema);
    const dispatch = useAppDispatch();
    const currentCounterpartyId = useSelector(getCurrentCounterpartyId);

    const onSubmit = async (formValues: IFormContactPersonValues) => {
        toggleLoadingSubmit();
        const client = {
            ...formValues,
            [CLIENT_FIELDS.phone]: removePlusAndGapsToPhone(formValues.phone),
            [CLIENT_FIELDS.ownerCounterpartyId]: currentCounterpartyId,
            [CLIENT_FIELDS.contactCounterpartyIds]: [clientCounterpartyId],
        };
        // @ts-ignore
        const result = await dispatch(createClient(client));
        // @ts-ignore
        if (!result?.error && result.payload) {
            toggleLoadingSubmit();
            onClose();
        }
    };

    return (
        <ModalWrapper
            title={intl.formatMessage({ defaultMessage: 'Добавить пользователя' })}
            isOpen={isOpen}
            closeModal={onClose}
        >
            <Form onSubmit={onSubmit} validate={validation}>
                {({ handleSubmit, valid }) => (
                    <form onSubmit={handleSubmit}>
                        <InputsContainer>
                            <ClientNameField
                                name={CLIENT_FIELDS.name}
                                label={intl.formatMessage({ defaultMessage: 'ФИО' })}
                                placeholder={intl.formatMessage({ defaultMessage: 'Введите ФИО' })}
                            />
                            <FormField
                                type="tel"
                                label={intl.formatMessage({ defaultMessage: 'Телефон' })}
                                name={CLIENT_FIELDS.phone}
                                mask="+7 999 999 99 99"
                                placeholder="+7 XXX XXX XX XX"
                            />
                            <FormField
                                name={CLIENT_FIELDS.email}
                                label="Email"
                                placeholder={intl.formatMessage({ defaultMessage: 'Электронная почта' })}
                            />
                        </InputsContainer>
                        <SubmitButton
                            isLoading={isLoadingSubmit}
                            onClick={handleSubmit}
                            disabled={!valid}
                            icon={<IconAdd />}
                        >
                            {intl.formatMessage({ defaultMessage: 'Добавить' })}
                        </SubmitButton>
                    </form>
                )}
            </Form>
        </ModalWrapper>
    );
};

export default ModalCreateContactPerson;
