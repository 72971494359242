import React, { useState } from 'react';
import { ButtonSearch, InputContainer, PaymentHeader, Title } from '@pages/Payment/styles';
import PaymentIcon from '@pages/Payment/assets/paperInCircle.svg';
import { Form } from 'react-final-form';
import FormField from '@components/FormField';
import SearchIcon from '@assets/icons/search.svg';
import OrderContainerFilter from '@pages/Payment/components/OrderContainerFilter';
import { useIntl } from 'react-intl';

const searchInputName = 'searchInputName';

const PaymentGeneral = () => {
    const intl = useIntl();
    const [searchString, setSearchString] = useState('');
    const onSubmit = (formValues: { [searchInputName]: string }) => {
        setSearchString(formValues[searchInputName]);
    };

    return (
        <>
            <PaymentHeader>
                <PaymentIcon />
                <Title>{intl.formatMessage({ defaultMessage: 'Входящие счета' })}</Title>
            </PaymentHeader>
            <Form onSubmit={onSubmit}>
                {({ handleSubmit }) => (
                    <InputContainer onSubmit={handleSubmit}>
                        <FormField
                            name={searchInputName}
                            placeholder={intl.formatMessage({ defaultMessage: 'Поиск по номеру счёта' })}
                        />
                        <ButtonSearch type="submit">
                            <SearchIcon />
                        </ButtonSearch>
                    </InputContainer>
                )}
            </Form>
            <OrderContainerFilter searchString={searchString} />
        </>
    );
};

export default PaymentGeneral;
