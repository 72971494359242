import styled from 'styled-components';
import Typography from '@components/Typography';

interface IButtonBankCard {
    isActive: boolean;
}

export const ButtonBankCardWrapper = styled.button<IButtonBankCard>`
    width: 100%;
    border: 1px solid ${({ theme, isActive }) => (isActive ? theme.colors.yellow() : `rgba(153, 153, 153, 0.3)`)};
    border-radius: ${({ theme }) => theme.decorations.borderRadius.base}px;
    padding: 17px 17px 14px 55px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 40px;
    @media ${({ theme }) => theme.breakpoints.xxl} {
        padding: 14px 25px;
    }
    @media ${({ theme }) => theme.breakpoints.md} {
        grid-template-columns: 1fr;
        grid-row-gap: 18px;
        padding: 30px 40px;
    }
`;

export const BankNameWrapper = styled.div``;

export const CorrespondentAccountWrapper = styled.div``;

export const SettlementAccountWrapper = styled.div``;

export const BicWrapper = styled.div`
    margin-left: 40px;
    @media ${({ theme }) => theme.breakpoints.xxl} {
        margin-left: 0;
    }
`;

export const Label = styled(Typography).attrs({ variant: 'regular12' })`
    color: ${({ theme }) => theme.colors.grey()};
    margin-bottom: 7px;
`;

export const Value = styled(Typography).attrs({ variant: 'regular14' })``;
