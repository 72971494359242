import { RootState } from '@store';
import { getCurrentCounterpartyId } from '@store/Counterparty/selectors';
import { merchantAdapter } from '@store/Merchant/index';
import { getClaimsOpenModerating } from '@store/Claims/selectors';
import { ClaimFields } from '@store/Claims/constants';
import { MERCHANT_FIELDS } from '@store/Merchant/contants';

const { selectAll, selectById } = merchantAdapter.getSelectors();
export const getMerchant = (state: RootState) => state.merchant;
export const getIsMerchantLoading = (state: RootState) => getMerchant(state).loading;
export const getAllMerchants = (state: RootState) => selectAll(state.merchant);
export const getCurrentCounterpartyMerchants = (state: RootState) =>
    getAllMerchants(state)?.filter(({ counterpartyId }) => counterpartyId === getCurrentCounterpartyId(state));
export const getMerchantById = (id: string) => (state: RootState) => selectById(state.merchant, id);
export const getCurrentMerchantId = (state: RootState) => state.merchant.selectedId;
export const getCurrentMerchant = (state: RootState) => {
    const merchantId = getCurrentMerchantId(state);
    return merchantId && getMerchantById(merchantId)(state);
};
export const getCurrentCounterpartyMerchantsWithoutModeratingOpenClaim = (state: RootState) =>
    getCurrentCounterpartyMerchants(state).filter(
        (merchant) =>
            !getClaimsOpenModerating(state).find(
                (claim) => claim[ClaimFields.merchantId] === merchant[MERCHANT_FIELDS.id],
            ),
    );
