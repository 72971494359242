import React, { FC, useState } from 'react';
import {
    Balance,
    BalanceLabel,
    BalanceValue,
    ButtonAboutTariff,
    ButtonWidget,
    ClaimActiveWrapper,
    Content,
    DateEnd,
    Description,
    FooterClaim,
    HeadClaim,
    IconTariffWrapper,
    IconToggleWrapper,
    TariffDescription,
    TariffName,
} from '@components/Widgets/ClaimActiveWidget/styles';
import TariffIcon from '@assets/icons/tariff.svg';
import InfoIcon from '@assets/icons/info.svg';
import ArrowDownIcon from '@assets/icons/arrow-down.svg';
import useModal from '@hooks/useModal';
import ModalTariff from '@components/Widgets/ClaimActiveWidget/components/ModalTariff';
import { IContract } from '@store/Contracts/types';
import { useSelector } from 'react-redux';
import { getMerchantBalance } from '@store/MerchantSettlementBalance/selectors';
import { useParams } from 'react-router-dom';
import { formatCurrencyToShow } from '@utils/helpers';
import EmptyNextTariff from '@components/Widgets/ClaimActiveWidget/components/EmptyNextTariff';
import dayjs from 'dayjs';
import { DD_MM_YYYY_DATE_FORMAT } from '@utils/constants';
import { useIntl } from 'react-intl';

interface IClaimActiveWidget {
    contract: IContract;
}

const ClaimActiveWidget: FC<IClaimActiveWidget> = ({ contract }) => {
    const intl = useIntl();
    const { merchantId } = useParams<{ merchantId: string }>();
    const { isOpen: isOpenTariffModal, open: openTariffModal, close: onCloseTariffModal } = useModal(false);

    const merchantBalance = useSelector(getMerchantBalance(merchantId));

    const [isOpen, setIsOpen] = useState(false);

    const onClickToWidget = () => {
        setIsOpen(!isOpen);
    };

    return (
        <ClaimActiveWrapper>
            <Content>
                <ButtonWidget type="button" onClick={onClickToWidget} />
                <HeadClaim>
                    <IconTariffWrapper>
                        <TariffIcon />
                    </IconTariffWrapper>
                    <Description>
                        <TariffName>
                            {contract.title}
                            <ButtonAboutTariff type="button" onClick={openTariffModal}>
                                <InfoIcon />
                            </ButtonAboutTariff>
                        </TariffName>
                        <TariffDescription>
                            {intl.formatMessage({ defaultMessage: 'комиссия от' })} {contract.minFeePercent}%
                            {contract.finishAt && (
                                <DateEnd>
                                    {intl.formatMessage({ defaultMessage: 'до' })}{' '}
                                    {dayjs(contract.finishAt).format(DD_MM_YYYY_DATE_FORMAT)}
                                </DateEnd>
                            )}
                        </TariffDescription>
                    </Description>
                </HeadClaim>
                <FooterClaim>
                    <Balance>
                        <BalanceLabel>{intl.formatMessage({ defaultMessage: 'К выплате по тарифу:' })}</BalanceLabel>
                        <BalanceValue>{formatCurrencyToShow(merchantBalance)}</BalanceValue>
                    </Balance>
                    <IconToggleWrapper isOpen={isOpen}>
                        <ArrowDownIcon />
                    </IconToggleWrapper>
                </FooterClaim>
            </Content>
            {isOpen && <EmptyNextTariff />}
            <ModalTariff
                isActiveTariff
                contract={contract}
                closeModal={onCloseTariffModal}
                isOpen={isOpenTariffModal}
            />
        </ClaimActiveWrapper>
    );
};

export default ClaimActiveWidget;
