import React, { FC } from 'react';
import FormField from '@components/FormField';
import { Form } from 'react-final-form';
import { FormClient } from '@pages/Shop/ShopTabsTables/CreateNewClient/components/FormEditClient/styles';
import { CLIENT_FIELDS } from '@store/Client/contants';
import { IClient } from '@store/Client/types';
import useValidationSchema from '@hooks/useValidationSchema';
import { ModalAddContactPersonSchema } from '@pages/Shop/validations';
import { useHistory, useParams } from 'react-router-dom';
import { useAppDispatch } from '@store';
import { createClient, updateClient } from '@store/Client';
import { removePlusAndGapsToPhone } from '@utils/formatters';
import { useSelector } from 'react-redux';
import { getCurrentCounterpartyId } from '@store/Counterparty/selectors';
import { Routes } from '@pages/constants';
import { useIntl } from 'react-intl';

interface IFormEditClient {
    formId: string;
    setIsEditSettings: (arg: boolean) => void;
    client: IClient | undefined;
    toggleSaving: () => void;
}

const FormEditClient: FC<IFormEditClient> = ({ toggleSaving, client, formId, setIsEditSettings }) => {
    const intl = useIntl();
    const validation = useValidationSchema(ModalAddContactPersonSchema);
    const { merchantId, clientId } = useParams<{ merchantId: string; clientId: string }>();
    const dispatch = useAppDispatch();
    const currentCounterpartyId = useSelector(getCurrentCounterpartyId);
    const history = useHistory();

    const onSubmit = async (formValues: IClient) => {
        toggleSaving();
        if (clientId && client) {
            const result = await dispatch(
                updateClient({
                    ...formValues,
                    [CLIENT_FIELDS.phone]: removePlusAndGapsToPhone(formValues[CLIENT_FIELDS.phone]),
                }),
            );
            // @ts-ignore
            if (!result?.error && result?.payload) {
                setIsEditSettings(false);
            }
        } else {
            const result = await dispatch(
                createClient({
                    ...formValues,
                    [CLIENT_FIELDS.phone]: removePlusAndGapsToPhone(formValues[CLIENT_FIELDS.phone]),
                    [CLIENT_FIELDS.ownerCounterpartyId]: String(currentCounterpartyId),
                }),
            );
            // @ts-ignore
            if (!result?.error && result?.payload) {
                history.push(
                    `${Routes.dashboard}${Routes.shop}/${merchantId}${Routes.detailclient}/${result?.payload.id}`,
                );
            }
        }
        toggleSaving();
    };

    return (
        <Form onSubmit={onSubmit} initialValues={client} validate={validation}>
            {({ handleSubmit }) => (
                <FormClient id={formId} onSubmit={handleSubmit}>
                    <FormField
                        name={CLIENT_FIELDS.name}
                        label={intl.formatMessage({ defaultMessage: 'ФИО' })}
                        placeholder={intl.formatMessage({ defaultMessage: 'Введите ФИО' })}
                    />
                    <FormField
                        type="tel"
                        label={intl.formatMessage({ defaultMessage: 'Телефон' })}
                        name={CLIENT_FIELDS.phone}
                        mask="+7 999 999 99 99"
                        placeholder="+7 XXX XXX XX XX"
                    />
                    <FormField
                        name={CLIENT_FIELDS.email}
                        label="Email"
                        placeholder={intl.formatMessage({ defaultMessage: 'Электронная почта' })}
                    />
                </FormClient>
            )}
        </Form>
    );
};

export default FormEditClient;
