import React, { FC, ReactNode, useEffect, useRef } from 'react';
import Tippy from '@tippyjs/react';

import { Instance, Placement } from 'tippy.js';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light.css';
import styled, { createGlobalStyle } from 'styled-components';

interface ITooltipProps {
    content: ReactNode;
    hideOnClick?: boolean | 'toggle';
    onHide?: () => void;
    onShow?: () => void;
    onClickOutside?: () => void;
    visible?: boolean;
    interactive?: boolean;
    placement?: Placement;
    trigger?: string;
    maxWidth?: number;
    offset?: [number, number];
}

const arrow = `
    <svg width="33" height="13" viewBox="0 0 33 13" fill="none" id="arrow">
        <path d="M0 13C0 13 3.70425 12.9718 9.63188 5.16754C12.0677 1.95006 14.2931 0.013065 16.5 6.50957e-05C18.7069 -0.0129348 20.9302 1.92189 23.3949 5.16754C29.3556 13.0108 33 13 33 13L0 13Z"
              fill="white"/>
    </svg>
`;

const Tooltip: FC<ITooltipProps> = ({
    children,
    content,
    hideOnClick = false,
    onHide = () => {},
    onShow = () => {},
    onClickOutside = () => {},
    visible,
    interactive = false,
    placement = 'top',
    maxWidth = 350,
    trigger = 'mouseenter focus',
    offset = [0, 20],
}) => {
    const tippy = useRef<Instance>();

    useEffect(() => {
        if (visible) {
            tippy.current?.show();
        } else {
            tippy.current?.hide();
        }
    }, [visible]);

    return (
        <Tippy
            trigger={trigger}
            onShow={onShow}
            theme="light"
            content={content}
            offset={offset}
            hideOnClick={hideOnClick}
            onHide={onHide}
            arrow={arrow}
            placement={placement}
            interactive={interactive}
            onClickOutside={onClickOutside}
            // interactiveBorder={8}
            maxWidth={maxWidth}
            onCreate={(tip) => {
                tippy.current = tip;
            }}
        >
            <TooltipInner>
                <TooltipStyles />
                {children}
            </TooltipInner>
        </Tippy>
    );
};

export default Tooltip;

const TooltipStyles = createGlobalStyle`
  .tippy-box[data-theme~=light] {
    border-radius: ${({ theme }) => theme.decorations.borderRadius.base}px;
    background-color: ${({ theme }) => theme.colors.white()};
    box-shadow: ${({ theme }) => theme.decorations.boxShadow.secondary};
    padding: 15px 22px;
    font-size: 14px;
    color: ${({ theme }) => theme.colors.base()};
  }

  .tippy-content {
    padding: 0;
  }

  .tippy-box[data-placement^=top] > .tippy-svg-arrow {
    bottom: 0
  }

  .tippy-box[data-placement^=top] > .tippy-svg-arrow:after, .tippy-box[data-placement^=top] > .tippy-svg-arrow > svg {
    top: 13px;
    transform: rotate(180deg)
  }

  .tippy-box[data-placement^=bottom] > .tippy-svg-arrow {
    top: 0
  }

  .tippy-box[data-placement^=bottom] > .tippy-svg-arrow > svg {
    bottom: 13px
  }

  .tippy-box[data-placement^=left] > .tippy-svg-arrow {
    right: 0
  }

  .tippy-box[data-placement^=left] > .tippy-svg-arrow:after, .tippy-box[data-placement^=left] > .tippy-svg-arrow > svg {
    transform: rotate(90deg);
    top: calc(50% - 7px);
    left: 23px
  }

  .tippy-box[data-placement^=right] > .tippy-svg-arrow {
    left: 0
  }

  .tippy-box[data-placement^=right] > .tippy-svg-arrow:after, .tippy-box[data-placement^=right] > .tippy-svg-arrow > svg {
    transform: rotate(-90deg);
    top: calc(50% - 7px);
    right: 23px
  }

  .tippy-svg-arrow {
    width: 34px;
    height: 15px;
    text-align: initial
  }

  .tippy-svg-arrow, .tippy-svg-arrow > svg {
    position: absolute
  }

  #arrow {
    stroke: none !important;
    fill: none !important;
  }
`;

const TooltipInner = styled.div`
    display: flex;
`;
