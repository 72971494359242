import React from 'react';
import { PassportDataWrapper } from '@components/Widgets/ClaimModeratingWidget/components/PassportData/styles';
import { useSelector } from 'react-redux';
import { getSelectedPassportData } from '@store/Claims/selectors';
import { IClaimPassportData } from '@store/Claims/types';
import { PASSPORT_DATA_FIELDS } from '@store/Claims/constants';
import ColumnLabel from '@components/Widgets/ClaimModeratingWidget/components/ColumnLabel';
import dayjs from 'dayjs';
import { DD_MM_YYYY_DATE_FORMAT } from '@utils/constants';
import { useIntl } from 'react-intl';

const PassportData = () => {
    const intl = useIntl();
    const passport: IClaimPassportData = useSelector(getSelectedPassportData) as any;
    const issueCode =
        passport?.[PASSPORT_DATA_FIELDS.issuedPlaceCode] &&
        `${passport[PASSPORT_DATA_FIELDS.issuedPlaceCode].slice(0, 3)}-${passport[
            PASSPORT_DATA_FIELDS.issuedPlaceCode
        ].slice(3)}`;

    const passportFields = [
        {
            label: intl.formatMessage({ defaultMessage: 'ФИО руководителя организации' }),
            value: passport?.[PASSPORT_DATA_FIELDS.name],
        },
        {
            label: intl.formatMessage({ defaultMessage: 'Дата рождения' }),
            value:
                passport?.[PASSPORT_DATA_FIELDS.birthdate] &&
                dayjs(passport[PASSPORT_DATA_FIELDS.birthdate]).format(DD_MM_YYYY_DATE_FORMAT),
        },
        {
            label: intl.formatMessage({ defaultMessage: 'Место рождения' }),
            value: passport?.[PASSPORT_DATA_FIELDS.birthplace],
        },
        {
            label: intl.formatMessage({ defaultMessage: 'Серия' }),
            value: passport?.[PASSPORT_DATA_FIELDS.number] && passport[PASSPORT_DATA_FIELDS.number].slice(0, 4),
        },
        {
            label: intl.formatMessage({ defaultMessage: 'Номер' }),
            value: passport?.[PASSPORT_DATA_FIELDS.number] && passport[PASSPORT_DATA_FIELDS.number].slice(4),
        },
        {
            label: intl.formatMessage({ defaultMessage: 'Орган выдавший документ' }),
            value: passport?.[PASSPORT_DATA_FIELDS.issuedPlace],
        },
        {
            label: intl.formatMessage({ defaultMessage: 'Код подразделения' }),
            value: issueCode,
        },
        {
            label: intl.formatMessage({ defaultMessage: 'Дата выдачи паспорта' }),
            value:
                passport?.[PASSPORT_DATA_FIELDS.issuedDate] &&
                dayjs(passport[PASSPORT_DATA_FIELDS.issuedDate]).format(DD_MM_YYYY_DATE_FORMAT),
        },
    ];

    return (
        <PassportDataWrapper>
            {passportFields.map((field) => (
                <ColumnLabel key={field.label} label={field.label} value={field.value} />
            ))}
        </PassportDataWrapper>
    );
};

export default PassportData;
