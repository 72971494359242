import styled, { css } from 'styled-components';
import Typography from '@components/Typography';
import { NavLink } from 'react-router-dom';
import Button from '@components/Button';
import backgroundImage from './assets/bg.png';

const wrapperStyles = css`
    border-radius: ${({ theme }) => theme.decorations.borderRadius.base}px;
    background: ${({ theme }) => theme.colors.white()};
    background: url(${backgroundImage}) right;
    background-repeat: no-repeat;
    background-size: 65% 100%;
    width: 100%;
    height: 201px;
    box-shadow: ${({ theme }) => theme.decorations.boxShadow?.base};
    ${({ theme }) => theme.mixins.flexCenter}
    justify-content: space-between;
    overflow: hidden;

    @media ${({ theme }) => theme.breakpoints.md} {
        flex-direction: column;
        width: 100%;
        height: 325px;
        // background: none;
        // svg т.к. для ширины планшета и мобилки одна картинка и на планшетах она шакалилась
        background-image: url("data:image/svg+xml,%3Csvg width='328' height='274' viewBox='0 0 328 274' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg filter='url(%23filter0_d_11110_27564)'%3E%3Cpath d='M0 42L0 273.001C6.50009 273.001 315.525 273.001 327 273.001C326.5 -61.4993 327 201.5 327 36C198.5 88.5 87.9999 83 0 42Z' fill='%23202932'/%3E%3C/g%3E%3Cpath d='M327.5 24L327.5 52.4174C220.5 103.918 63.8423 86.5866 -1.46642e-06 57.5477L-4.55379e-07 34.4178C61.6604 60.738 218.5 75.918 327.5 24Z' fill='%23FAD247'/%3E%3Cdefs%3E%3Cfilter id='filter0_d_11110_27564' x='-52' y='0' width='399' height='309.001' filterUnits='userSpaceOnUse' color-interpolation-filters='sRGB'%3E%3CfeFlood flood-opacity='0' result='BackgroundImageFix'/%3E%3CfeColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha'/%3E%3CfeOffset dx='-16'/%3E%3CfeGaussianBlur stdDeviation='18'/%3E%3CfeComposite in2='hardAlpha' operator='out'/%3E%3CfeColorMatrix type='matrix' values='0 0 0 0 0.270833 0 0 0 0 0.251406 0 0 0 0 0.14941 0 0 0 0.21 0'/%3E%3CfeBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_11110_27564'/%3E%3CfeBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_11110_27564' result='shape'/%3E%3C/filter%3E%3C/defs%3E%3C/svg%3E%0A");
        background-position: left top;
        background-size: cover;
        height: auto;
        background-repeat: no-repeat;
    }
    @media ${({ theme }) => theme.breakpoints.sm} {
        background-size: cover;
        background-position: center top 30px;
        height: auto;
    }
`;

export const PromoWrapper = styled.div`
    ${wrapperStyles}
`;

export const WrapperNavLink = styled(NavLink)`
    ${wrapperStyles}
    text-decoration: none;
`;

export const TitleWrapper = styled.div`
    position: relative;
    z-index: 1;
    padding: 42px 30px 43px 48px;
    width: 40%;
    @media ${({ theme }) => theme.breakpoints.md} {
        padding: 29px 28px 0 28px;
        width: 100%;
    }
    @media ${({ theme }) => theme.breakpoints.sm} {
        padding: 28px 13px;
    }
`;

export const NavLinkToClaim = styled(NavLink)`
    text-decoration: none;
`;

export const ButtonConnectService = styled(Button)`
    @media ${({ theme }) => theme.breakpoints.md} {
        display: none;
    }
`;

export const Title = styled(Typography).attrs({ variant: 'medium34' })`
    margin-bottom: 22px;

    @media ${({ theme }) => theme.breakpoints.md} {
        margin-bottom: 90px;
    }
    @media ${({ theme }) => theme.breakpoints.sm} {
        margin-bottom: 80px;
    }
`;

export const DescriptionWrapper = styled.div`
    padding: 31px 31px 31px 0;
    width: 60%;
    height: 100%;
    @media ${({ theme }) => theme.breakpoints.md} {
        width: 100%;
        padding-left: 31px;
    }
    @media ${({ theme }) => theme.breakpoints.sm} {
        padding: 0 16px 37px 16px;
    }
`;

export const Benefits = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 33px;
    grid-row-gap: 18px;
    margin-left: 86px;
    color: ${({ theme }) => theme.colors.white()};
    @media ${({ theme }) => theme.breakpoints.md} {
        margin: 0;
    }
    @media ${({ theme }) => theme.breakpoints.sm} {
        grid-column-gap: 21px;
    }
`;

export const Description = styled(Typography).attrs({ variant: 'regular14' })`
    line-height: 17px;
    @media ${({ theme }) => theme.breakpoints.sm} {
        ${({ theme }) => theme.mixins.getTypography('regular12')}
    }
`;

export const PaymentNavLink = styled(NavLink)`
    color: ${({ theme }) => theme.colors.yellow()};
`;
