import styled from 'styled-components';
import Typography from '@components/Typography';

export const RowLabelWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 84px;
    grid-column-gap: 4px;
`;

export const RefundLabel = styled(Typography).attrs({ variant: 'regular14' })``;

export const RefundValue = styled(Typography).attrs({ variant: 'regular14' })`
    color: ${({ theme }) => theme.colors.grey()};
    text-align: right;
`;
