import firebase from 'firebase/app';
import 'firebase/messaging';
import firebaseDevelopment from './firebase.development.json';
import firebaseProduction from './firebase.production.json';
import firebaseStaging from './firebase.staging.json';

export const isSupportedFirebase = firebase.messaging.isSupported();

export const getFirebaseConfig = (nodeEnv) => {
    switch (nodeEnv) {
        case 'development': {
            return firebaseDevelopment.FIREBASE_CONFIG;
        }
        case 'production': {
            return firebaseProduction.FIREBASE_CONFIG;
        }
        case 'staging': {
            return firebaseStaging.FIREBASE_CONFIG;
        }
        default: {
            return firebaseDevelopment.FIREBASE_CONFIG;
        }
    }
};

const firebaseConfig = getFirebaseConfig(process.env.NODE_ENV);

firebase.initializeApp(firebaseConfig);

const messaging = isSupportedFirebase && firebase.messaging();

export const getToken = async () => {
    let currentToken = '';
    try {
        currentToken = await messaging.getToken({
            vapidKey: firebaseDevelopment.FIREBASE_PUBLIC_KEY,
        });
    } catch (error) {
        // eslint-disable-next-line no-console
        console.log('An error occurred while retrieving token. ', error);
    }
    return currentToken;
};

export const deleteToken = async () => {
    await messaging.deleteToken();
};

export const onMessageListener = () =>
    new Promise((resolve) => {
        messaging.onMessage((payload) => {
            resolve(payload);
        });
    });
