import { createContext, Dispatch, SetStateAction } from 'react';
import { Nullable } from '@utils/utilityTypes';

// eslint-disable-next-line no-shadow
export enum ModalTypes {
    merchant = 'merchant',
    company = 'company',
}

// eslint-disable-next-line no-shadow
export enum IntroduceStepType {
    saleTarget,
    legalPayTarget,
    getProfileName,
}

export interface IIntroduceContextProps {
    step: IntroduceStepType;
    toNextStep: () => void;
    toPrevStep: () => void;
    openModal: (type: ModalTypes) => void;
    closeModal: () => void;
    modalType: Nullable<ModalTypes>;
    setModalType: Dispatch<SetStateAction<Nullable<ModalTypes>>>;
    modalIsOpen: boolean;
}

export const IntroduceContext = createContext<IIntroduceContextProps>({} as IIntroduceContextProps);
