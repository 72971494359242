// eslint-disable-next-line no-shadow
export enum Routes {
    // routes first level
    profile = '/profile',
    dashboard = '/dashboard',
    auth = '/auth',
    introduce = '/introduce',
    marketplace = '/marketplace',
    payment = '/payment',
    shop = '/shop',
    reference = '/reference',
    news = '/news',
    ordercontainer = '/ordercontainer',
    refundCreate = '/refundCreate',
    counterparty = '/counterparty',
    feeds = '/feeds',
    claim = '/claim',

    // routes for profile
    personal = '/personal',
    organizations = '/organizations',

    // routes for shop
    overview = '/overview',
    orders = '/orders',
    refunds = '/refunds',
    clients = '/clients',
    orderToPay = '/ordertopay',
    createclient = '/createclient',
    createcounterparty = '/createcounterparty',
    settings = '/settings',
    detailclient = '/detailclient',
    detailclientcounterparty = '/detailclientcounterparty',
    refunddetail = '/refunddetail',
    integrations = '/integrations',
    createintegration = '/createintegration',
}
