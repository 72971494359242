import React, { FC } from 'react';
import { ClientListWrapper, LetterBorder } from '@pages/Shop/ShopTabsTables/Clients/components/ClientsList/styles';
import { IClient } from '@store/Client/types';
import ClientListItem from '@pages/Shop/ShopTabsTables/Clients/components/ClientListItem';
import { IClientCounterparty } from '@store/ClientCounterparty/types';
import { typesClients } from '@pages/Shop/ShopTabsTables/Clients';

interface IClientsList {
    clients: IClient[] | IClientCounterparty[];
    typeClient: typesClients;
}

const ClientsList: FC<IClientsList> = ({ typeClient, clients }) => {
    let firstLetter: string | null = null;
    return (
        <ClientListWrapper>
            {clients.map((client) => {
                if (client.name[0].toUpperCase() !== firstLetter) {
                    // @ts-ignore
                    // eslint-disable-next-line prefer-destructuring
                    firstLetter = client.name[0].toUpperCase();
                    return (
                        <div key={client.id}>
                            <LetterBorder>{firstLetter}</LetterBorder>
                            <ClientListItem typeClient={typeClient} client={client} />
                        </div>
                    );
                }
                return <ClientListItem typeClient={typeClient} key={client.id} client={client} />;
            })}
        </ClientListWrapper>
    );
};

export default ClientsList;
