import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { getCounterpartyClients, getLastClients } from '@store/Client/selectors';
import Avatar, { AVATAR_SIZE, AVATAR_VARIANT } from '@components/Avatar';
import { CLIENT_FIELDS } from '@store/Client/contants';
import { IClient } from '@store/Client/types';
import { useField, useForm } from 'react-final-form';
import { ORDER_CUSTOMER_FIELDS, ORDER_FIELDS } from '@store/Order/types';
import ButtonFindClient from '@pages/Shop/ShopTabsTables/CreateOrderToPay/components/ButtonFindClient/index';
import useModal from '@hooks/useModal';
import ModalFindClient from '@pages/Shop/ShopTabsTables/CreateOrderToPay/components/ModalFindClient/index';
import { getTwoWordsFromName } from '@utils/helpers';
import { getLastCounterpartiesList } from '@store/ClientCounterparty/selectors';
import { IClientCounterparty } from '@store/ClientCounterparty/types';
import { RootState } from '@store';
import ModalAddContactPerson from '@pages/Shop/ShopTabsTables/CreateOrderToPay/components/ModalAddContactPerson/index';
import { useIntl } from 'react-intl';
import { ClientName, LabelAbsentClients, ButtonLastClient, LastClientsContainer } from './styles';

const LastClients = () => {
    const intl = useIntl();
    const { isOpen: findClientModalVisible, open: openModalFindClient, close: closeModalFindClient } = useModal();
    const {
        isOpen: addContactPersonModalVisible,
        open: openModalAddContactPerson,
        close: closeModalAddContactPerson,
    } = useModal();
    const {
        input: { value: isLegalCustomer },
    } = useField(ORDER_FIELDS.isLegalCustomer);

    const selectorClients: (state: RootState) => IClient[] | IClientCounterparty[] = isLegalCustomer
        ? getLastCounterpartiesList
        : getLastClients;

    const clientsList = useSelector(selectorClients);

    // клиенты в роли контактных лиц выбранного контрагента
    const [clientCounterpartyId, setClientCounterpartyId] = useState<number>(0);
    const clientsCounterpartyList = useSelector(getCounterpartyClients(clientCounterpartyId));

    const { change } = useForm();

    const selectClient = useCallback(
        (client: IClient | IClientCounterparty, isLegal: boolean, isClientCounterparty: boolean) => {
            if (isLegal) {
                const { name, vatNumber, registrationAddress }: IClientCounterparty = client as any;
                change(ORDER_CUSTOMER_FIELDS.vatNumber, vatNumber);
                change(ORDER_CUSTOMER_FIELDS.name, name);
                change(ORDER_CUSTOMER_FIELDS.registrationAddress, registrationAddress);
            } else {
                const { name, email, phone }: IClient = client as any;
                if (!isClientCounterparty) {
                    change(ORDER_CUSTOMER_FIELDS.name, name);
                }
                change(ORDER_CUSTOMER_FIELDS.phone, phone);
                change(ORDER_CUSTOMER_FIELDS.email, email);
            }
        },
        [change],
    );

    const openModalCounterpartyClient = (idCounterparty: number) => {
        setClientCounterpartyId(idCounterparty);
        openModalAddContactPerson();
    };

    const renderLastClientsList = useCallback(
        (clients: IClient[] | IClientCounterparty[], isLegal: boolean) => {
            if (clients.length !== 0) {
                return clients.map((client) => (
                    <ButtonLastClient
                        onClick={() => {
                            selectClient(client, isLegal, false);
                            if (isLegal) {
                                setClientCounterpartyId(client.id);
                                openModalAddContactPerson();
                            }
                        }}
                        type="button"
                        key={client[CLIENT_FIELDS.id]}
                    >
                        <Avatar size={AVATAR_SIZE.md} variant={AVATAR_VARIANT.dark} name={client[CLIENT_FIELDS.name]} />
                        <ClientName>{getTwoWordsFromName(client[CLIENT_FIELDS.name])}</ClientName>
                    </ButtonLastClient>
                ));
            }
            return (
                <LabelAbsentClients>
                    {' '}
                    {intl.formatMessage({ defaultMessage: 'Нет последних клиентов' })}{' '}
                </LabelAbsentClients>
            );
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [openModalAddContactPerson, selectClient],
    );

    return (
        <LastClientsContainer>
            <ButtonFindClient onClick={openModalFindClient} />
            {renderLastClientsList(clientsList, isLegalCustomer)}
            <ModalFindClient
                closeModal={closeModalFindClient}
                isOpen={findClientModalVisible}
                isLegalCustomer={isLegalCustomer}
                selectClient={selectClient}
                openModalCounterpartyClient={openModalCounterpartyClient}
            />
            <ModalAddContactPerson
                closeModal={closeModalAddContactPerson}
                isOpen={addContactPersonModalVisible}
                clients={clientsCounterpartyList}
                selectClient={selectClient}
            />
        </LastClientsContainer>
    );
};

export default LastClients;
