export const addMinutes = (date: Date, minutes: number) => new Date(date.getTime() + minutes * 60000);

export const getTimeOffset = (date: Date) => Math.abs(date.getTimezoneOffset());

export const getTimeDiff = (date1: Date, date2: Date) => {
    const offset = getTimeOffset(date1);
    const dateWithOffset = addMinutes(date1, offset);

    return dateWithOffset.getTime() - date2.getTime();
};
