import React, { FC, useEffect } from 'react';
import { DocumentsButtons, DocumentsWrapper } from '@pages/Claim/components/Documents/styles';
import { enumTabsConnection, IStep } from '@pages/Claim/components/MainConnectionPayment';
import DownloadIcon from '@assets/icons/download.svg';
import Fluent from '@assets/icons/fluent.svg';
import { useAppDispatch } from '@store';
import { createDocument } from '@store/Document';
import { IDocument, IDocumentPayload } from '@store/Document/types';
import { useSelector } from 'react-redux';
import { getCurrentClaim, getSelectedPassportData } from '@store/Claims/selectors';
import { saveAs } from 'file-saver';
import ButtonsNextPrev from '@pages/Claim/components/ButtonsNextPrev';
import DocumentCard from '@pages/Claim/components/DocumentCard';
import { getCurrentCounterpartyId, getSelectedBankDetailId } from '@store/Counterparty/selectors';
import { ClaimFields } from '@store/Claims/constants';
import useToast from '@hooks/useToast';
import Toast from '@components/Toast';
import { updateClaim, updateClaimStatus } from '@store/Claims';
import { ClaimStatuses } from '@store/Claims/types';
import { getContractById, getSelectedContractId, getSelectedContractTemplateId } from '@store/Contracts/selectors';
import { createOrUpdateMerchantContract, setSelectedContractId, setSelectedContractTemplateId } from '@store/Contracts';
import { IContract } from '@store/Contracts/types';
import { useHistory } from 'react-router-dom';
import { Routes } from '@pages/constants';
import { isEqualObjects } from '@utils/helpers';
import HelperUpload from '@pages/Claim/components/HelperUpload';
import useLoadingSubmit from '@hooks/useLoadingSubmit';
import { useIntl } from 'react-intl';

export interface IButtonDocument {
    id: number;
    title: string;
    description: string;
    action: () => void;
    // eslint-disable-next-line no-undef
    icon: JSX.Element;
    isDropZoneUpload: boolean;
}

const Documents: FC<IStep> = ({ file, setFile, setStep, setIsTabsActive }) => {
    const intl = useIntl();
    const { isLoadingSubmit, toggleLoadingSubmit } = useLoadingSubmit();
    const history = useHistory();
    const dispatch = useAppDispatch();
    const { open: openToast, isShow: isShowToast, setIsShow: setIsShowToast } = useToast();

    const selectedClaim = useSelector(getCurrentClaim);
    const counterpartyId = useSelector(getCurrentCounterpartyId);
    const templateId = useSelector(getSelectedContractTemplateId);
    const selectedContractId = useSelector(getSelectedContractId);
    const bankDetailId = useSelector(getSelectedBankDetailId);
    const passportData = useSelector(getSelectedPassportData);
    const reduxMerchantContract = useSelector(getContractById(Number(selectedClaim?.merchantContractId)));

    useEffect(() => {
        (async () => {
            if (counterpartyId && (templateId || selectedContractId) && bankDetailId) {
                let merchantContractId = null;
                // если выбран тариф на основе договора
                if ((!templateId && selectedContractId) || !templateId) {
                    merchantContractId = selectedContractId;
                } else {
                    // если никакие поля договора не изменились
                    // eslint-disable-next-line no-lonely-if
                    if (
                        reduxMerchantContract?.bankDetailId === bankDetailId &&
                        reduxMerchantContract?.templateId === templateId &&
                        reduxMerchantContract?.counterpartyId === counterpartyId
                    ) {
                        merchantContractId = reduxMerchantContract?.id;
                    } else {
                        const dataCreateContract = await dispatch(
                            createOrUpdateMerchantContract({
                                counterpartyId,
                                templateId,
                                bankDetailId,
                            }),
                        );
                        if (dataCreateContract?.payload) {
                            const merchantContract: IContract = dataCreateContract?.payload as any;
                            merchantContractId = merchantContract.id;
                            dispatch(setSelectedContractId(merchantContractId));
                            dispatch(setSelectedContractTemplateId(null));
                        }
                    }
                }

                if (merchantContractId && selectedClaim) {
                    if (
                        !selectedClaim?.passport ||
                        !isEqualObjects(selectedClaim?.passport, passportData) ||
                        selectedClaim.merchantContractId !== selectedContractId
                    ) {
                        const dataUpdateClaim = await dispatch(
                            updateClaim({
                                claimId: selectedClaim[ClaimFields.id],
                                payload: {
                                    merchantContractId,
                                    passport: passportData,
                                },
                            }),
                        );
                        if (dataUpdateClaim?.payload) {
                            setFile(null);
                            setIsTabsActive(true);
                        }
                    }
                }
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const setDocumentToClaim = async () => {
        toggleLoadingSubmit();
        if (selectedClaim && counterpartyId && file) {
            const configDocumentUpload = {
                type: 'merchant-contract-package-upload',
                format: 'any',
                counterpartyId,
                setting: {
                    fileId: file.id,
                    merchantContractId: selectedClaim[ClaimFields.merchantContractId],
                },
            };
            // @ts-ignore
            const data = await dispatch(createDocument(configDocumentUpload));
            if (data?.payload) {
                const dataUpdateClaimStatus = await dispatch(
                    updateClaimStatus({
                        id: selectedClaim[ClaimFields.id],
                        payload: {
                            [ClaimFields.status]: ClaimStatuses.moderating,
                            [ClaimFields.clientDescription]: '',
                        },
                    }),
                );
                if (dataUpdateClaimStatus?.payload) {
                    history.push(
                        `${Routes.dashboard}${Routes.shop}/${selectedClaim[ClaimFields.merchantId]}${Routes.overview}`,
                    );
                }
            }
        }
        toggleLoadingSubmit();
    };

    const buttonsActionsDocuments: IButtonDocument[] = [
        {
            id: 1,
            title: intl.formatMessage({ defaultMessage: 'Скачать документы' }),
            description: intl.formatMessage({
                defaultMessage:
                    'Здесь можно дать описание того, какие документы будут скачаны, для чего они, что с ними необходимо сделать и какую-либо еще информацию, которая является необходимой для пользователя.',
            }),
            action: async () => {
                if (selectedClaim) {
                    const createDocumentConfig: IDocumentPayload = {
                        type: 'merchant-contract-package',
                        format: 'pdf',
                        counterpartyId: selectedClaim.counterpartyId,
                        generate: true,
                        setting: {
                            merchantContractId: selectedClaim.merchantContractId,
                        },
                    };

                    const data = await dispatch(createDocument(createDocumentConfig));
                    // @ts-ignore
                    if (!data?.error && data?.payload) {
                        const documentData: IDocument = data.payload as any;
                        const urlDocument: string = documentData.url;
                        saveAs(urlDocument, 'document.pdf');
                    }
                }
            },
            isDropZoneUpload: false,
            icon: <DownloadIcon />,
        },
        {
            id: 2,
            title: intl.formatMessage({ defaultMessage: 'Загрузить подписанные документы' }),
            description: intl.formatMessage({
                defaultMessage:
                    'Тут , по всей видимости, можно дать пользователю краткую информацию о том, куда отправятся документы и как долго они будет на рассмотрении.',
            }),
            action: () => {},
            isDropZoneUpload: true,
            icon: <Fluent />,
        },
    ];

    const onBackStep = () => {
        setStep(enumTabsConnection.passport);
    };

    return (
        <DocumentsWrapper>
            <DocumentsButtons>
                {buttonsActionsDocuments.map((button) => (
                    <DocumentCard fileName={file?.filename} setFile={setFile} key={button.id} buttonDocument={button} />
                ))}
            </DocumentsButtons>
            {file && <HelperUpload openToast={openToast} />}
            <ButtonsNextPrev
                isLoading={isLoadingSubmit}
                isActiveNextButton={Boolean(file)}
                onClickNext={setDocumentToClaim}
                onClickPrev={onBackStep}
                titleNext={intl.formatMessage({ defaultMessage: 'Отправить заявку' })}
            />
            <Toast
                onClick={() => {}}
                isShowToast={isShowToast}
                setIsShowToast={setIsShowToast}
                title={intl.formatMessage({ defaultMessage: 'Скопировано в буфер обмена' })}
            />
        </DocumentsWrapper>
    );
};

export default Documents;
