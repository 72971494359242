import styled from 'styled-components';

export const UpdateImageWrapper = styled.div`
    border: 1px dashed rgba(153, 153, 153, 0.87);
    border-radius: ${({ theme }) => theme.decorations.borderRadius.base}px;
    min-height: 115px;
    height: auto;
    ${({ theme }) => theme.mixins.flexCenterCenter};
    cursor: pointer;
`;
