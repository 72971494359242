import React, { FC, useEffect, useState } from 'react';
import { Amount, CommodityFooterWrapper, FieldCount, Residue } from '@pages/Refund/components/CommodityFooter/styles';
import { TotalLabel, TotalValue } from '@pages/Refund/components/ServiceFooter/styles';
import { ORDER_ITEM_FIELDS, TOrderItem } from '@store/Order/types';
import { InputTypeEnum } from '@components/Input';
import { calculateOrderItemValues, formatCurrencyToShow, toValidCurrencyFloat } from '@utils/helpers';
import { useIntl } from 'react-intl';

interface ICommodityFooter {
    orderItem: TOrderItem;
    fields: any;
    vatRate: number;
}

const CommodityFooter: FC<ICommodityFooter> = ({ vatRate, fields, orderItem }) => {
    const intl = useIntl();
    const maxQuantity = orderItem[ORDER_ITEM_FIELDS.quantity] - 1;
    const [countRefund, setCountRefund] = useState(maxQuantity);

    // @ts-ignore
    const skus = fields.value?.map(({ sku }) => sku);
    const indexInForm = skus?.indexOf(orderItem.sku);
    const orderItemInForm = fields.value[indexInForm];

    const amountsDiff = formatCurrencyToShow(
        Math.max(toValidCurrencyFloat(orderItem[ORDER_ITEM_FIELDS.totalAmount] - orderItemInForm?.totalAmount), 0),
    );
    const amountRefund = formatCurrencyToShow(toValidCurrencyFloat(orderItem[ORDER_ITEM_FIELDS.amount] * countRefund));

    useEffect(() => {
        const { totalAmount, totalVatAmount } = calculateOrderItemValues(
            orderItem[ORDER_ITEM_FIELDS.amount],
            countRefund,
            vatRate,
        );
        fields.update(indexInForm, {
            ...orderItemInForm,
            [ORDER_ITEM_FIELDS.quantity]: Number(countRefund),
            [ORDER_ITEM_FIELDS.totalAmount]: totalAmount,
            [ORDER_ITEM_FIELDS.totalVatAmount]: totalVatAmount,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [countRefund]);

    const onChangeFieldCount = (countRefundNumber: any) => {
        setCountRefund(countRefundNumber);
    };

    const onBlurCountField = () => {
        if (orderItemInForm.quantity === 0) {
            setCountRefund(1);
        }
    };

    return (
        <CommodityFooterWrapper>
            <FieldCount
                value={countRefund}
                onChange={onChangeFieldCount}
                label={intl.formatMessage({ defaultMessage: 'Кол-во' })}
                maxValue={maxQuantity}
                onBlur={onBlurCountField}
                typeInput={InputTypeEnum.numeric}
            />
            <Amount>
                <TotalLabel>{intl.formatMessage({ defaultMessage: 'На сумму' })}</TotalLabel>
                <TotalValue>{amountRefund}</TotalValue>
            </Amount>
            <Residue>
                <TotalLabel>{intl.formatMessage({ defaultMessage: 'Итого остаток' })}</TotalLabel>
                <TotalValue>{amountsDiff}</TotalValue>
            </Residue>
        </CommodityFooterWrapper>
    );
};

export default CommodityFooter;
