import styled from 'styled-components';

export const BankDetailWrapper = styled.div`
    padding-top: 40px;
    @media ${({ theme }) => theme.breakpoints.md} {
        padding-top: 22px;
    }
`;

export const Banks = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 24px;
    @media ${({ theme }) => theme.breakpoints.md} {
        grid-row-gap: 10px;
    }
`;

export const ButtonAddNew = styled.button`
    ${({ theme }) => theme.mixins.flexCenterCenter};
    width: 100%;
    border-radius: ${({ theme }) => theme.decorations.borderRadius.base}px;
    box-shadow: ${({ theme }) => theme.decorations.boxShadow?.base};
    padding: 26px 0;
`;

export const ButtonContent = styled.div`
    ${({ theme }) => theme.mixins.flexCenter};
    grid-column-gap: 6px;
    ${({ theme }) => theme.mixins.getTypography('regular14')}
    path {
        stroke: ${({ theme }) => theme.colors.base()};
    }
`;
