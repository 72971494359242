import React, { FC, FocusEvent, TextareaHTMLAttributes } from 'react';
import styled from 'styled-components';
import useFocusField from '@hooks/useFocusField';
import Typography from '@components/Typography';

export interface ITextareaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
    label?: string;
    disabled?: boolean;
    hasError?: boolean;
    className?: string;
}

interface ITextareaWrapperProps {
    disabled: boolean;
}

interface ITextareaFieldProps {
    hasError: boolean;
    inFocus: boolean;
}

interface ITextareaLabelProps {
    inFocus: boolean;
}

const Textarea: FC<ITextareaProps> = ({
    label,
    hasError = false,
    disabled = false,
    className,
    onFocus,
    onBlur,
    ...props
}) => {
    const { inFocus, onBlurHandler, onFocusHandler } = useFocusField<FocusEvent<HTMLTextAreaElement>>({
        isFocus: false,
        onFocus,
        onBlur,
    });

    return (
        <TextareaWrapper disabled={disabled} className={className}>
            {label && <TextareaLabel inFocus={inFocus}>{label}</TextareaLabel>}
            <TextareaField
                hasError={hasError}
                inFocus={inFocus}
                onBlur={onBlurHandler}
                onFocus={onFocusHandler}
                {...props}
            />
        </TextareaWrapper>
    );
};

export default Textarea;

const TextareaLabel = styled(Typography).attrs({ variant: 'regular12' })<ITextareaLabelProps>`
    ${({ theme }) => theme.mixins.fieldLabelStyles};
    color: ${({ theme, inFocus }) => theme.helpers.getFieldLabelColor(theme, inFocus)};
`;

const TextareaWrapper = styled.div<ITextareaWrapperProps>`
    position: relative;
    padding-top: 10px;
    width: 100%;
    opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};

    &:hover {
        & ${TextareaLabel} {
            color: ${({ theme }) => theme.colors.base()};
        }
    }
`;

const TextareaField = styled.textarea<ITextareaFieldProps>`
    ${({ theme }) => theme.mixins.fieldStyles}
    resize: none;
    border: 1px solid ${({ theme, hasError, inFocus }) => theme.helpers.getFieldBorderColor(theme, hasError, inFocus)};

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
`;
