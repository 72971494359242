import { uniq } from 'lodash';
import { filter, flow, isNil, map, uniqBy } from 'lodash/fp';
import { RootState } from '@store';
import { TOrder } from '@store/Order/types';
import { CLIENT_COUNTERPARTY_FIELDS } from '@store/ClientCounterparty/constants';
import { clientCounterpartyAdapter } from './index';
import { getCurrentCounterpartyId } from '../Counterparty/selectors';
import { getClientCounterpartiesIds, getLastClients } from '../Client/selectors';
import { getOrdersList } from '../Order/selectors';
import { IClientCounterparty } from './types';
const omitUndefined = filter((el) => !isNil(el));
const getCustomerCounterpartyId = map(({ contactCounterpartyId }) => contactCounterpartyId);
const getCustomerVatNumber = map((order: TOrder) => {
    if (order?.customer?.vatNumber) {
        return order.customer.vatNumber;
    }
    return null;
});
const getLastCustomerCounterpartyIds = flow(getCustomerCounterpartyId, omitUndefined);
const getLastOrdersCustomerCounterpartyVatNumbers = flow(getCustomerVatNumber, omitUndefined);

const { selectAll } = clientCounterpartyAdapter.getSelectors();
export const getClientCounterparties = (state: RootState) => selectAll(state.clientCounterparty);
export const getCurrentCounterpartiesClient = (state: RootState) =>
    getClientCounterparties(state).filter(
        ({ ownerCounterpartyId }) => ownerCounterpartyId === getCurrentCounterpartyId(state),
    );
export const getCounterpartiesClient = (state: RootState) =>
    getClientCounterpartiesIds(state)?.reduce((acc, id) => {
        const existId = getCurrentCounterpartiesClient(state).find((item) => item.id === id);
        if (existId) {
            acc.push(existId);
        }
        return acc;
    }, [] as IClientCounterparty[]);

export const getLastCounterpartiesList = (state: RootState) => {
    const lastClientsIDs = uniq(
        getLastClients(state).reduce<number[]>(
            (acc, { contactCounterpartyIds }) => [...acc, ...(contactCounterpartyIds || [])],
            [],
        ),
    );
    const lastOrders = getOrdersList(state);
    const lastCounterpartiesIds = getLastCustomerCounterpartyIds(lastOrders);
    const lastOrdersVatNumbers = getLastOrdersCustomerCounterpartyVatNumbers(lastOrders);
    const lastCounterpartiesByClient = getClientCounterparties(state).filter(({ id }) => lastClientsIDs.includes(id));
    const lastCounterpartiesById = getClientCounterparties(state).filter(
        ({ id, vatNumber }) => lastCounterpartiesIds.includes(id) || lastOrdersVatNumbers.includes(vatNumber),
    );
    return uniqBy('id', [...lastCounterpartiesById, ...lastCounterpartiesByClient]) as IClientCounterparty[];
};

export const getCounterpartyByIds = (idsCounterparties: number[]) => (state: RootState) =>
    getClientCounterparties(state).filter((item) => idsCounterparties.includes(item[CLIENT_COUNTERPARTY_FIELDS.id]));

export const getCounterpartyClientById = (idCounterparty: number | undefined) => (state: RootState) => {
    if (idCounterparty) {
        return getClientCounterparties(state).find(
            (clientCounterparty) => clientCounterparty[CLIENT_COUNTERPARTY_FIELDS.id] === idCounterparty,
        );
    }
    return undefined;
};
