import React, { FC } from 'react';
import { IClientCounterparty } from '@store/ClientCounterparty/types';
import { CLIENT_COUNTERPARTY_FIELDS } from '@store/ClientCounterparty/constants';
import { ClientCounterpartyFields } from '@pages/Shop/ShopTabsTables/CreateNewClient/components/ClientCounterpartyInformation/styles';
import ColumnField from '@pages/Shop/ShopTabsTables/CreateNewClient/components/ColumnField';
import FormEditClientCounterparty from '@pages/Shop/ShopTabsTables/CreateNewClient/components/FormEditClientCounterparty';
import { FORM_CREATE_CLIENT_COUNTERPARTY } from '@pages/Shop/ShopTabsTables/CreateNewClient/components/DetailClientCounterparty';
import { useIntl } from 'react-intl';

interface IClientCounterpartyInformation {
    clientCounterparty?: IClientCounterparty;
    isEditMode?: boolean;
    formId?: string;
    setIsEditSettings?: (arg: boolean) => void;
    toggleSaving?: () => void;
}

const ClientCounterpartyInformation: FC<IClientCounterpartyInformation> = ({
    setIsEditSettings = () => null,
    formId = FORM_CREATE_CLIENT_COUNTERPARTY,
    clientCounterparty,
    isEditMode,
    toggleSaving = () => null,
}) => {
    const intl = useIntl();
    const clientCounterpartyFields = [
        {
            label: intl.formatMessage({ defaultMessage: 'Наименование' }),
            value: clientCounterparty && clientCounterparty[CLIENT_COUNTERPARTY_FIELDS.name],
        },
        {
            label: intl.formatMessage({ defaultMessage: 'ИНН' }),
            value: clientCounterparty && clientCounterparty[CLIENT_COUNTERPARTY_FIELDS.vatNumber],
        },
        {
            label: intl.formatMessage({ defaultMessage: 'Адрес' }),
            value: clientCounterparty && clientCounterparty[CLIENT_COUNTERPARTY_FIELDS.registrationAddress],
        },
    ];

    if (isEditMode) {
        return (
            <FormEditClientCounterparty
                toggleSaving={toggleSaving}
                formId={formId}
                setIsEditSettings={setIsEditSettings}
                clientCounterparty={clientCounterparty}
            />
        );
    }

    return (
        <ClientCounterpartyFields>
            {clientCounterpartyFields.map((counterpartyField) => (
                <ColumnField
                    key={counterpartyField.label}
                    label={counterpartyField.label}
                    value={counterpartyField.value}
                />
            ))}
        </ClientCounterpartyFields>
    );
};

export default ClientCounterpartyInformation;
