import React from 'react';
import Breadcrumb from '@components/Breadcrumb';
import { Routes } from '@pages/constants';
import { Navbar, ProfileHeader, ProfileIconWrapper, ProfileTitle } from '@pages/Profile/styles';
import ProfileIcon from '@assets/icons/profile.svg';
import { ShopNavLink } from '@pages/Shop/styles';
import { Route, Switch } from 'react-router-dom';
import Personal from '@pages/Profile/ProfileTabs/Personal';
import Organizations from '@pages/Profile/ProfileTabs/Organizations';
import { useIntl } from 'react-intl';

export const Profile = () => {
    const intl = useIntl();

    const profileNavigations = [
        {
            label: intl.formatMessage({ defaultMessage: 'Личные' }),
            path: Routes.profile + Routes.personal,
            component: Personal,
        },
        {
            label: intl.formatMessage({ defaultMessage: 'Организации' }),
            path: Routes.profile + Routes.organizations,
            component: Organizations,
        },
    ];

    return (
        <>
            <Breadcrumb>
                <Breadcrumb.Item to={Routes.dashboard}>
                    {intl.formatMessage({ defaultMessage: 'Главная' })}
                </Breadcrumb.Item>
                <Breadcrumb.Item>{intl.formatMessage({ defaultMessage: 'Профиль пользователя' })}</Breadcrumb.Item>
            </Breadcrumb>
            <ProfileHeader>
                <ProfileIconWrapper>
                    <ProfileIcon />
                </ProfileIconWrapper>
                <ProfileTitle>{intl.formatMessage({ defaultMessage: 'Профиль пользователя' })}</ProfileTitle>
            </ProfileHeader>
            <Navbar>
                {profileNavigations.map((navigationLink) => (
                    <ShopNavLink key={navigationLink.label} to={navigationLink.path} activeClassName="isActive">
                        {navigationLink.label}
                    </ShopNavLink>
                ))}
            </Navbar>
            <Switch>
                {profileNavigations.map((route) => (
                    <Route key={route.label} path={route.path} component={route.component} />
                ))}
            </Switch>
        </>
    );
};

export default Profile;
