import styled from 'styled-components';

export const FormClient = styled.form`
    display: grid;
    grid-template-columns: repeat(3, auto);
    grid-column-gap: 30px;
    padding: 0 30px 30px 30px;
    @media ${({ theme }) => theme.breakpoints.md} {
        grid-template-columns: 1fr;
        grid-row-gap: 18px;
    }
`;
