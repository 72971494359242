import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const BreadcrumbsWrapper = styled.div`
    ${({ theme }) => theme.mixins.flexCenter};
    align-items: flex-start;
`;

export const BreadcrumbItemWrapper = styled.div`
    ${({ theme }) => theme.mixins.getTypography('regular12')};
    color: ${({ theme }) => theme.colors.grey()};
    position: relative;

    &:not(:last-child) {
        padding-right: 20px;

        &:before {
            content: '';
            position: absolute;
            right: 6px;
            bottom: 3px;
            width: 8px;
            height: 8px;
            // Yes, a crutch, but there were no more options.
            background-image: url("data:image/svg+xml,%3Csvg width='8' height='8' viewBox='0 0 8 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.83276 1.66742L5.1661 4.00075L2.83276 6.33409' stroke='%23999999' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
        }
    }
`;

export const BreadcrumbItemLink = styled(Link)`
    position: relative;
    text-decoration: none;

    &:after {
        content: '';
        position: absolute;
        width: 0;
        height: 1px;
        display: block;
        right: 0;
        background-color: currentColor;
        transition: ${({ theme }) => theme.decorations.transition.base};
    }

    &:hover {
        &:after {
            width: 100%;
            left: 0;
        }
    }
`;
