// eslint-disable-next-line no-shadow
export enum CLIENT_FIELDS {
    id = 'id',
    userId = 'userId',
    phone = 'phone',
    name = 'name',
    description = 'description',
    email = 'email',
    contactCounterpartyIds = 'contactCounterpartyIds',
    ownerCounterpartyId = 'ownerCounterpartyId',
}
