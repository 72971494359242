import styled from 'styled-components';
import Skeleton from '@components/Skeleton';

export const WidgetsWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 20px;
    margin-bottom: 40px;
`;

export const SkeletonPromoBanner = styled(Skeleton).attrs({ borderRadius: 'base' })`
    height: 73px;
`;
