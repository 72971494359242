import React, { FC } from 'react';
import {
    ClientFieldsWrapper,
    ClientTitle,
    ClientWrapper,
} from '@pages/Shop/ShopTabsTables/RefundDetail/components/ClientData/styles';
import { CUSTOMER_TYPES, ORDER_CUSTOMER_FIELDS, TOrderCustomer } from '@store/Order/types';
import ColumnField from '@pages/Shop/ShopTabsTables/RefundDetail/components/ColumnField';
import { convertPhoneToShow } from '@utils/formatters';
import { useIntl } from 'react-intl';

interface IClientData {
    customer?: TOrderCustomer;
}

const ClientData: FC<IClientData> = ({ customer }) => {
    const intl = useIntl();

    const customerFields = [
        {
            label: intl.formatMessage({ defaultMessage: 'Телефон' }),
            value: customer ? convertPhoneToShow(customer?.[ORDER_CUSTOMER_FIELDS.phone]) : null,
        },
        {
            label: intl.formatMessage({ defaultMessage: 'Email' }),
            value: customer?.[ORDER_CUSTOMER_FIELDS.email] || null,
        },
        {
            label:
                customer?.[ORDER_CUSTOMER_FIELDS.type] === CUSTOMER_TYPES.legal
                    ? intl.formatMessage({ defaultMessage: 'Наименование' })
                    : intl.formatMessage({ defaultMessage: 'ФИО' }),
            value: customer?.[ORDER_CUSTOMER_FIELDS.name] || null,
        },
        {
            label: intl.formatMessage({ defaultMessage: 'Адрес' }),
            value: customer?.[ORDER_CUSTOMER_FIELDS.registrationAddress],
        },
        {
            label: intl.formatMessage({ defaultMessage: 'ИНН' }),
            value: customer?.[ORDER_CUSTOMER_FIELDS.vatNumber],
        },
    ];

    return (
        <ClientWrapper>
            <ClientTitle>{intl.formatMessage({ defaultMessage: 'Реквизиты клиента' })}</ClientTitle>
            <ClientFieldsWrapper>
                {customerFields.map((field) => (
                    <ColumnField key={field.label} label={field.label} value={field.value} />
                ))}
            </ClientFieldsWrapper>
        </ClientWrapper>
    );
};

export default ClientData;
