import React, { FC } from 'react';
import { Balance, MerchantItemWrapper, MerchantName } from '@pages/Shop/components/MerchantListItem/styles';
import { IMerchant } from '@store/Merchant/types';
import { MERCHANT_FIELDS } from '@store/Merchant/contants';
import MerchantLogo, { enumSizeLogo } from '@components/MerchantLogo';
import { formatCurrencyToShow } from '@utils/helpers';
import { Routes } from '@pages/constants';
import { useHistory } from 'react-router-dom';

interface IMerchantListItem {
    merchant: IMerchant;
    balance: number;
    isActive: boolean;
    hideMerchantList: () => void;
}

const MerchantListItem: FC<IMerchantListItem> = ({ hideMerchantList, isActive, balance, merchant }) => {
    const history = useHistory();

    const onClickToMerchantItem = () => {
        history.push(`${Routes.dashboard}${Routes.shop}/${merchant.id}${Routes.overview}`);
        hideMerchantList();
    };

    return (
        <MerchantItemWrapper
            onClick={onClickToMerchantItem}
            // @ts-ignore fix error button to set param isActive
            isactivemerchant={isActive ? 1 : undefined}
        >
            <MerchantLogo merchantId={merchant[MERCHANT_FIELDS.id]} size={enumSizeLogo.SMALL} />
            <MerchantName>{merchant[MERCHANT_FIELDS.name]}</MerchantName>
            <Balance>{formatCurrencyToShow(balance)}</Balance>
        </MerchantItemWrapper>
    );
};

export default MerchantListItem;
