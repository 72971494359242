import { CUSTOMER_TYPES, ORDER_CUSTOMER_FIELDS, ORDER_FIELDS, TOrder, TOrderCustomer } from '@store/Order/types';
import { removePlusAndGapsToPhone } from '@utils/formatters';

const getCustomerForSubmitOrder = (formValues: TOrder): TOrderCustomer | {} => {
    if (formValues[ORDER_FIELDS.isHideRequisites]) {
        return {};
    }
    const customer = {
        [ORDER_CUSTOMER_FIELDS.phone]: removePlusAndGapsToPhone(formValues[ORDER_CUSTOMER_FIELDS.phone]),
        [ORDER_CUSTOMER_FIELDS.email]: formValues[ORDER_CUSTOMER_FIELDS.email],
        [ORDER_CUSTOMER_FIELDS.name]: formValues[ORDER_CUSTOMER_FIELDS.name],
    };
    if (formValues[ORDER_FIELDS.isLegalCustomer]) {
        return {
            ...customer,
            [ORDER_CUSTOMER_FIELDS.vatNumber]: formValues[ORDER_CUSTOMER_FIELDS.vatNumber],
            [ORDER_CUSTOMER_FIELDS.registrationAddress]: formValues[ORDER_CUSTOMER_FIELDS.registrationAddress],
            [ORDER_CUSTOMER_FIELDS.type]: CUSTOMER_TYPES.legal,
        };
    }

    return {
        ...customer,
        [ORDER_CUSTOMER_FIELDS.type]: CUSTOMER_TYPES.private,
    };
};

export default getCustomerForSubmitOrder;
