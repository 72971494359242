import React, { FC } from 'react';
import { AccordionWrapper } from './styles';
import Item, { IItemProps } from './components/Item';
import CollapseItem, { ICollapseItemProps } from './components/CollapseItem';

interface IProductsAccordionComposition {
    Item: FC<IItemProps>;
    CollapseItem: FC<ICollapseItemProps>;
}

const ProductsAccordion: FC & IProductsAccordionComposition = ({ children }) => (
    <AccordionWrapper>{children}</AccordionWrapper>
);

ProductsAccordion.Item = Item;
ProductsAccordion.CollapseItem = CollapseItem;

export default ProductsAccordion;
