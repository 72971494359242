import React, { useEffect, useState } from 'react';
import { ThemeProvider } from 'styled-components';
import { Provider } from 'react-redux';
import { HelmetProvider } from 'react-helmet-async';
import { Scrollbars } from 'react-custom-scrollbars';
import { debounce } from 'lodash';
import { IntlProvider } from 'react-intl';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import GlobalStyle from './globalStyles/GlobalStyle';
import them from './theme';
import store from './store';
import Pages from './pages';
import ruMessages from './i18/ru';
import ReCaptchaTokenContext from './context/ReCaptchaTokenContext';

export const locale = 'ru';

const App = () => {
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);

    useEffect(() => {
        const resizeHandler = debounce(() => {
            setWindowHeight(window.innerHeight);
        }, 300);

        window.addEventListener('resize', resizeHandler);
        return () => window.removeEventListener('resize', resizeHandler);
    }, [windowHeight]);

    return (
        <Provider store={store}>
            <ThemeProvider theme={them}>
                <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY || ''}>
                    <ReCaptchaTokenContext>
                        <IntlProvider locale={locale} messages={ruMessages}>
                            <GlobalStyle />
                            <HelmetProvider>
                                <Scrollbars
                                    className="scroll-bar"
                                    autoHide
                                    style={{ height: `${windowHeight}px`, width: '100%' }}
                                >
                                    <Pages />
                                </Scrollbars>
                            </HelmetProvider>
                        </IntlProvider>
                    </ReCaptchaTokenContext>
                </GoogleReCaptchaProvider>
            </ThemeProvider>
        </Provider>
    );
};

export default App;
