import styled from 'styled-components';
import Typography from '@components/Typography';
import { COLOR_BRIGHTNESS } from '@theme/utils';
import { TotalValues, TotalValuesBlock, TotalWrapper } from '@pages/OrderContainer/components/OrderTotal/styles';
import { StatusWrapper } from '@pages/OrderContainer/components/StatusOrder/styles';
import { TotalRowLabel, TotalRowValue, TotalRowWrapper } from '@pages/OrderContainer/components/TotalRow/styles';
import { MobileButtonWrapper } from '@pages/OrderContainer/components/MobileButtonEditOrSave/styles';
import { WrapperQRBlock } from '@pages/OrderContainer/components/QRCodeBlock/styles';

export const DetailOrderWrapper = styled.div`
    width: 100%;
    padding-top: 38px;
`;

export const OrderTitle = styled(Typography).attrs({ variant: 'bigTitle' })`
    margin-bottom: 54px;
    ${({ theme }) => theme.mixins.flexCenter};
    grid-column-gap: 8px;
`;

export const OrderForm = styled.form`
    border-radius: ${({ theme }) => theme.decorations.borderRadius?.base}px;
    box-shadow: ${({ theme }) => theme.decorations.boxShadow?.base};
    overflow: hidden;
`;

export const OrderHeader = styled.div`
    ${({ theme }) => theme.mixins.flexCenter}
    justify-content: space-between;
    background: ${({ theme }) => theme.colors.lightGrey()};
    padding: 28px;
`;

export const OrderHeaderTitle = styled(Typography).attrs({ variant: 'title' })``;

export const OrderInformation = styled.div`
    border-right: 1px solid ${({ theme }) => theme.colors.grey(COLOR_BRIGHTNESS.LIGHT)};
    padding: 20px 49px 0 0;
    @media ${({ theme }) => theme.breakpoints.ld} {
        padding-right: 20px;
    }
    @media ${({ theme }) => theme.breakpoints.md} {
        border: none;
        padding: 0;
    }
`;

export const OrderSubtitle = styled(Typography).attrs({ variant: 'subheading' })`
    margin-bottom: 19px;
`;

interface IOrderBorder {
    isEdit: boolean;
}

export const OrderBorder = styled.div<IOrderBorder>`
    display: grid;
    grid-template-columns: 1fr 307px;
    padding: 24px 46px 23px 30px;
    @media ${({ theme }) => theme.breakpoints.betweenLdAndMd} {
        grid-template-columns: ${({ isEdit }) => (isEdit ? '100%' : '1fr 230px')};
        padding: ${({ isEdit }) => (isEdit ? '24px 24px 0 20px' : '24px 24px 23px 20px')};
        padding-right: 24px;
        // стили, если форма деталки заказа на планшете и в режиме редактирования (isEdit)
        ${WrapperQRBlock} {
            display: ${({ isEdit }) => isEdit && 'none'};
        }
        ${TotalValuesBlock} {
            border-bottom: ${({ isEdit }) => isEdit && 'none'};
            margin-bottom: ${({ isEdit }) => isEdit && '0'};
            display: ${({ isEdit }) => isEdit && 'flex'};
            justify-content: space-between;
            margin-top: ${({ isEdit }) => isEdit && '100px'};
            padding: ${({ isEdit }) => isEdit && '19px 19px 19px 0'};
            position: ${({ isEdit }) => isEdit && 'absolute'};
            width: 100%;
            box-shadow: ${({ isEdit, theme }) => isEdit && theme.decorations.boxShadow.base};
            border-radius: ${({ isEdit, theme }) => isEdit && `${theme.decorations.borderRadius.base}px`};
            background: ${({ theme, isEdit }) => isEdit && theme.colors.white()};
            margin-left: ${({ isEdit }) => isEdit && '-42px'};
        }
        ${StatusWrapper} {
            display: ${({ isEdit }) => isEdit && 'none'};
        }
        ${TotalValues} {
            display: ${({ isEdit }) => isEdit && 'flex'};
            margin-top: ${({ isEdit }) => isEdit && '0'};
        }
        ${TotalRowWrapper} {
            padding: ${({ isEdit }) => isEdit && '0 19px'};
            grid-column-gap: ${({ isEdit }) => isEdit && '3px'};
            border-right: ${({ isEdit, theme }) => isEdit && `1px solid ${theme.colors.lightGrey()}`};
            &:last-child {
                border: ${({ isEdit }) => isEdit && 'none'};
            }
        }
        ${TotalRowValue} {
            color: ${({ isEdit, theme }) => isEdit && theme.colors.base()};
        }
        ${TotalRowLabel} {
            &:after {
                ${({ isEdit }) => isEdit && `content: ':'`};
            }
        }
        ${OrderInformation} {
            padding-right: ${({ isEdit }) => isEdit && '0'};
            border-right: ${({ isEdit }) => isEdit && 'none'};
        }
        ${TotalWrapper} {
            padding: ${({ isEdit }) => isEdit && '0'};
        }
        ${MobileButtonWrapper} {
            margin-top: ${({ isEdit }) => isEdit && '0'};
        }
    }

    @media ${({ theme }) => theme.breakpoints.md} {
        grid-template-columns: 100%;
        padding: 28px 20px 23px 20px;
        ${WrapperQRBlock} {
            display: ${({ isEdit }) => isEdit && 'none'};
        }
        ${TotalValuesBlock} {
            border-bottom: ${({ isEdit }) => isEdit && 'none'};
            padding-bottom: ${({ isEdit }) => isEdit && '40px'};
            margin-bottom: ${({ isEdit }) => isEdit && '0'};
        }
    }
`;
