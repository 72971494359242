// eslint-disable-next-line no-shadow
export enum CLIENT_COUNTERPARTY_FIELDS {
    id = 'id',
    name = 'name',
    vatNumber = 'vatNumber',
    taxRegistrationReasonCode = 'taxRegistrationReasonCode',
    registrationAddress = 'registrationAddress',
    description = 'description',
    counterpartyId = 'counterpartyId',
    ownerCounterpartyId = 'ownerCounterpartyId',
}
