import { useState } from 'react';

const useToast = () => {
    const [isShow, setIsShow] = useState(false);

    const open = () => setIsShow(true);
    const close = () => setIsShow(false);

    return { isShow, open, close, setIsShow };
};

export default useToast;
