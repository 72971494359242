import styled from 'styled-components';
import Typography from '@components/Typography';

export const ButtonEdit = styled.button`
    ${({ theme }) => theme.mixins.flexCenter}
    grid-column-gap: 9px;
    ${({ theme }) => theme.mixins.getTypography('regular14')}
`;

interface IButtonSave {
    isValid: boolean;
}

export const ButtonSave = styled.button<IButtonSave>`
    color: ${({ theme, isValid }) => (isValid ? theme.colors.base() : theme.colors.grey())};
    path {
        stroke: ${({ theme, isValid }) => (isValid ? theme.colors.base() : theme.colors.grey())};
    }
    ${({ theme }) => theme.mixins.flexCenter}
    grid-column-gap: 9px;
    ${({ theme }) => theme.mixins.getTypography('regular14')}
`;

export const ButtonWrapper = styled.div`
    @media ${({ theme }) => theme.breakpoints.ld} {
        display: none;
    }
`;

export const Saved = styled(Typography).attrs({ variant: 'regular14' })``;
