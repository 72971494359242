import React, { FC } from 'react';
import { ICounterparty, ILegalEntityType } from '@store/Counterparty/types';
import { useAppDispatch } from '@store';
import { Form } from 'react-final-form';
import { InputsWrapper } from '@pages/Counterparty/components/FormGeneral/styles';
import { COUNTERPARTY_FIELDS } from '@store/Counterparty/contants';
import useValidationSchema from '@hooks/useValidationSchema';
import { generalSchema } from '@pages/Counterparty/validations';
import { updateCounterparty } from '@store/Counterparty';
import dayjs from 'dayjs';
import { DATE_YYYY_MM_DD } from '@utils/constants';
import InputsGeneral from '@pages/Counterparty/components/InputsGeneral';

interface IFormGeneral {
    counterparty: ICounterparty;
    formId: string;
    setIsEditSettings: (arg: boolean) => void;
    legalEntityTypes: ILegalEntityType[] | undefined;
    toggleSaving: () => void;
}

const FormGeneral: FC<IFormGeneral> = ({ toggleSaving, formId, counterparty, setIsEditSettings, legalEntityTypes }) => {
    const validation = useValidationSchema(generalSchema);
    const dispatch = useAppDispatch();

    const onSubmit = async (formValues: ICounterparty) => {
        toggleSaving();
        const result = await dispatch(
            updateCounterparty({
                ...formValues,
                [COUNTERPARTY_FIELDS.legalEntityTypeId]: Number(formValues[COUNTERPARTY_FIELDS.legalEntityTypeId]),
                [COUNTERPARTY_FIELDS.registrationDate]: dayjs(formValues[COUNTERPARTY_FIELDS.registrationDate]).format(
                    DATE_YYYY_MM_DD,
                ),
                [COUNTERPARTY_FIELDS.registrationNumber]: formValues[COUNTERPARTY_FIELDS.registrationNumber] || '',
                [COUNTERPARTY_FIELDS.taxRegistrationReasonCode]:
                    formValues[COUNTERPARTY_FIELDS.taxRegistrationReasonCode] || '',
            }),
        );

        // @ts-ignore
        if (!result?.error && result?.payload) {
            setIsEditSettings(false);
        }
        toggleSaving();
    };

    const initialValues = {
        ...counterparty,
        [COUNTERPARTY_FIELDS.legalEntityTypeId]: counterparty[COUNTERPARTY_FIELDS.legalEntityTypeId].toString(),
    };

    return (
        <Form onSubmit={onSubmit} initialValues={initialValues} validate={validation}>
            {({ handleSubmit }) => (
                <InputsWrapper onSubmit={handleSubmit} id={formId}>
                    <InputsGeneral legalEntityTypes={legalEntityTypes} />
                </InputsWrapper>
            )}
        </Form>
    );
};

export default FormGeneral;
