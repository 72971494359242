import styled from 'styled-components';

export const MerchantWrapper = styled.div`
    padding-top: 40px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: 33px;
    grid-column-gap: 29px;
    @media ${({ theme }) => theme.breakpoints.md} {
        grid-template-columns: 1fr;
        grid-row-gap: 20px;
    }
`;
