import React, { useEffect, useState } from 'react';
import FinderClients from '@pages/Shop/ShopTabsTables/Clients/components/FinderClients';
import {
    ClientsHeader,
    ClientsListHeader,
    ClientsListWrapper,
    ClientsWrapper,
    EmptyClients,
    SelectorsTypeClient,
    SelectorTypeClient,
} from '@pages/Shop/ShopTabsTables/Clients/styles';
import ButtonCreateClient from '@pages/Shop/ShopTabsTables/Clients/components/ButtonCreateClient';
import ClientsList from '@pages/Shop/ShopTabsTables/Clients/components/ClientsList';
import { useSelector } from 'react-redux';
import { getClientIsLoaded, getCurrentCounterpartyClients } from '@store/Client/selectors';
import { getSortedClients } from '@utils/helpers';
import { getCurrentCounterpartiesClient } from '@store/ClientCounterparty/selectors';
import { RootState } from '@store';
import { IClient } from '@store/Client/types';
import { IClientCounterparty } from '@store/ClientCounterparty/types';
import SkeletonClientsList from '@pages/Shop/ShopTabsTables/Clients/components/SkeletonClientsList';
import { useIntl } from 'react-intl';

// eslint-disable-next-line no-shadow
export enum typesClients {
    CLIENT = 'client',
    CLIENT_COUNTERPARTY = 'clientCounterparty',
}

const Clients = () => {
    const intl = useIntl();

    const clientsTypes = [
        {
            title: intl.formatMessage({ defaultMessage: 'Контактные лица' }),
            type: typesClients.CLIENT,
        },
        {
            title: intl.formatMessage({ defaultMessage: 'Организации' }),
            type: typesClients.CLIENT_COUNTERPARTY,
        },
    ];

    const [typeClient, setTypeClient] = useState<typesClients>(typesClients.CLIENT);

    const selector: (state: RootState) => IClient[] | IClientCounterparty[] =
        typeClient === typesClients.CLIENT ? getCurrentCounterpartyClients : getCurrentCounterpartiesClient;
    const allClients = getSortedClients(useSelector(selector));
    const isLoaded = useSelector(getClientIsLoaded);

    useEffect(() => {
        setClients(allClients);
    }, [allClients]);

    const [clients, setClients] = useState(allClients);

    const applySearchString = (searchWord: string) => {
        // @ts-ignore
        const filteredClients = allClients.filter(
            (client: IClient | IClientCounterparty) =>
                client.name.toUpperCase().indexOf(searchWord.toUpperCase()) === 0,
        );
        setClients(filteredClients);
    };

    const renderClientsList = () => {
        if (clients.length === 0) {
            if (isLoaded) {
                return <EmptyClients>{intl.formatMessage({ defaultMessage: 'Нет клиентов' })}</EmptyClients>;
            }
            return <SkeletonClientsList />;
        }
        return <ClientsList typeClient={typeClient} clients={clients} />;
    };

    return (
        <ClientsWrapper>
            <ClientsHeader>
                <FinderClients applySearchString={applySearchString} />
                <ButtonCreateClient typeClient={typeClient} />
            </ClientsHeader>
            <ClientsListWrapper>
                <ClientsListHeader>
                    {intl.formatMessage({ defaultMessage: 'Клиенты' })}
                    <SelectorsTypeClient>
                        {clientsTypes.map((clientsType) => (
                            <SelectorTypeClient
                                isActive={typeClient === clientsType.type}
                                type="button"
                                key={clientsType.type}
                                onClick={() => setTypeClient(clientsType.type)}
                            >
                                {clientsType.title}
                            </SelectorTypeClient>
                        ))}
                    </SelectorsTypeClient>
                </ClientsListHeader>
                {renderClientsList()}
            </ClientsListWrapper>
        </ClientsWrapper>
    );
};

export default Clients;
