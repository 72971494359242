import React, { FC } from 'react';
import { IClientCounterparty } from '@store/ClientCounterparty/types';
import {
    ButtonOrganization,
    IconWrapper,
    Name,
    Title,
    VatNumber,
} from '@pages/Shop/ShopTabsTables/CreateNewClient/components/RowOrganizationModal/styles';
import IconWork from '@assets/icons/work.svg';
import { CLIENT_COUNTERPARTY_FIELDS } from '@store/ClientCounterparty/constants';
import { useIntl } from 'react-intl';

interface IRowOrganizationModal {
    clientCounterparty: IClientCounterparty;
    connectToOrganization: (idClientCounterparty: number) => void;
}

const RowOrganizationModal: FC<IRowOrganizationModal> = ({ connectToOrganization, clientCounterparty }) => {
    const intl = useIntl();
    const onClickClientCounterparty = () => {
        connectToOrganization(clientCounterparty[CLIENT_COUNTERPARTY_FIELDS.id]);
    };

    return (
        <ButtonOrganization type="button" onClick={onClickClientCounterparty}>
            <Title>
                <IconWrapper>
                    <IconWork />
                </IconWrapper>
                <Name>{clientCounterparty[CLIENT_COUNTERPARTY_FIELDS.name]}</Name>
            </Title>
            <VatNumber>
                {intl.formatMessage({ defaultMessage: 'ИНН' })}:{' '}
                {clientCounterparty[CLIENT_COUNTERPARTY_FIELDS.vatNumber]}
            </VatNumber>
        </ButtonOrganization>
    );
};

export default RowOrganizationModal;
