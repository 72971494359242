import React, { FC, ReactElement } from 'react';
import { BenefitWrapper } from '@components/PaymentPromo/components/Benefit/styles';
import CheckImage from '../../assets/check.svg';

interface IBenefit {
    description: ReactElement<any, any>;
}

const Benefit: FC<IBenefit> = ({ description }) => (
    <BenefitWrapper>
        <CheckImage />
        {description}
    </BenefitWrapper>
);

export default Benefit;
