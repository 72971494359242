import React, { useEffect } from 'react';
import Breadcrumb from '@components/Breadcrumb';
import { Routes } from '@pages/constants';
import { setCounterparty } from '@store/Counterparty';
import { useParams } from 'react-router-dom';
import { useAppDispatch } from '@store';
import PaymentGeneral from '@pages/Payment/components/PaymentGeneral';
import { useIntl } from 'react-intl';

const Payment = () => {
    const intl = useIntl();
    const dispatch = useAppDispatch();
    const { payerUserId } = useParams<{ payerUserId: string }>();

    useEffect(() => {
        dispatch(setCounterparty(payerUserId === 'user' ? null : payerUserId));
    }, [payerUserId, dispatch]);

    return (
        <>
            <Breadcrumb>
                <Breadcrumb.Item to={Routes.dashboard}>
                    {intl.formatMessage({ defaultMessage: 'Обзор' })}
                </Breadcrumb.Item>
                <Breadcrumb.Item>{intl.formatMessage({ defaultMessage: 'Входящие счета' })}</Breadcrumb.Item>
            </Breadcrumb>
            <PaymentGeneral />
        </>
    );
};

export default Payment;
