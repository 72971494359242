import styled from 'styled-components';

export const DocumentsWrapper = styled.div`
    padding-top: 40px;
`;

export const ButtonsWrapper = styled.div`
    ${({ theme }) => theme.mixins.flexStart};
    flex-direction: column;
    grid-row-gap: 24px;
`;
