import styled, { css } from 'styled-components';
// @ts-ignore
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import Typography from '@components/Typography';

export const CalendarWrapper = styled.div`
    height: 20px;
    display: grid;
    grid-template-columns: 20px auto 20px;
    grid-column-gap: 20px;
    align-items: center;
    justify-content: flex-end;
    ${({ theme }) => theme.mixins.getTypography('regular14')}
    text-transform: capitalize;
    cursor: pointer;
    @media ${({ theme }) => theme.breakpoints.ld} {
        margin-right: 0;
        margin-left: auto;
        justify-content: flex-start;
    }
    @media ${({ theme }) => theme.breakpoints.sm} {
        grid-column-gap: 5px;
    }
`;

export const PrevMonthButton = styled.button`
    ${({ theme }) => theme.mixins.flexCenter};
    justify-content: center;
    width: 100%;
    height: 12px;
`;

export const FilterHeader = styled.div`
    display: grid;
    align-items: center;
    grid-template-columns: 20px auto;
    grid-column-gap: 8px;
    justify-content: center;
    text-align: center;
`;

export const FilterButton = styled.button`
    display: contents;
`;

export const SelectedDateInterval = styled(Typography).attrs({ variant: 'regular14' })`
    text-align: center;
    &:first-letter {
        text-transform: capitalize;
    }
`;

export const CalendarImage = styled.div`
    width: 20px;
    height: 20px;
    margin-top: -4px;
`;

export const CalendarStyles = css`
    .react-calendar {
        border: none;
    }

    .react-calendar__month-view__weekdays__weekday {
        ${({ theme }) => theme.mixins.getTypography('regular12')}
        font-weight: normal;
        abbr {
            text-decoration: none;
        }
    }

    .react-calendar__month-view__days__day {
        color: ${({ theme }) => theme.colors.grey()};
    }

    .react-calendar__month-view__days__day--weekend {
        color: ${({ theme }) => theme.colors.grey()};
    }

    .react-calendar__tile {
        ${({ theme }) => theme.mixins.getTypography('regular12')}
        height: 22px;
        padding: 0;
        margin-bottom: 6px;
    }

    .react-calendar__tile--active {
        color: ${({ theme }) => theme.colors.base()};
        background: ${({ theme }) => theme.colors.dragAndDropBackground()};
    }
    .react-calendar__navigation__prev2-button {
        display: none;
    }
    .react-calendar__navigation__next2-button {
        display: none;
    }
    .react-calendar__navigation {
        height: auto;
    }
    .react-calendar__tile--rangeStart {
        background: ${({ theme }) => theme.colors.yellow()};
        border-radius: 5px;
        position: relative;
        border-top-right-radius: 8px 10px;
        border-bottom-right-radius: 8px 10px;
    }
    .react-calendar__navigation__label__labelText {
        display: inline;
        &:first-letter {
            text-transform: capitalize;
        }
    }
    .react-calendar__tile--active:enabled:focus {
        background: ${({ theme }) => theme.colors.yellow()};
    }
    .react-calendar__navigation__arrow {
        @media ${({ theme }) => theme.breakpoints.sm} {
            min-width: 22px;
        }
    }
`;

export const DateRangeCustom = styled(DateRangePicker)`
    .react-daterange-picker__wrapper {
        display: none;
    }
    .react-daterange-picker__calendar {
        padding: 30px;
        background: ${({ theme }) => theme.colors.white()};
        box-shadow: ${({ theme }) => theme.decorations.boxShadow.secondary};
        border-radius: ${({ theme }) => theme.decorations.borderRadius.base}px;
        width: 270px;
    }
    ${CalendarStyles}
`;
