import styled, { css } from 'styled-components';
import Typography from '@components/Typography';
import { NavLink } from 'react-router-dom';

const styleEventWrapper = css`
    width: 100%;
    display: grid;
    grid-template-columns: 36px 1fr 35px;
    align-items: center;
    grid-column-gap: 28px;
    padding: 20px 30px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.lightGrey()};
    &:last-child {
        border: none;
    }
    @media ${({ theme }) => theme.breakpoints.ld} {
        padding: 20px;
    }
`;

export const EventWrapperButton = styled.button`
    ${styleEventWrapper}
`;

export const EventWrapperNavLink = styled(NavLink)`
    ${styleEventWrapper}
    text-decoration: none;
`;

export const IconWrapper = styled.div`
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background: ${({ theme }) => theme.colors.lightGrey()};
    ${({ theme }) => theme.mixins.flexCenterCenter};
`;

export const EventTitle = styled(Typography).attrs({ variant: 'regular14' })`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

export const EventTime = styled(Typography).attrs({ variant: 'regular14' })`
    color: ${({ theme }) => theme.colors.grey()};
`;
