import React from 'react';
import { CreatorLabel, FooterWrapper, LinkFooter, LinksWrapper } from '@components/Footer/styles';
import { offerInvoiceBoxLink, supportInvoiceBoxLink } from '@utils/constants';

const Footer = () => {
    const year = new Date().getFullYear();
    return (
        <FooterWrapper>
            <CreatorLabel>© {year}, ООО &Prime;ОРЦ&Prime;</CreatorLabel>
            <LinksWrapper>
                <LinkFooter target="_blank" href={offerInvoiceBoxLink}>
                    Оферта
                </LinkFooter>
                <LinkFooter target="_blank" href={supportInvoiceBoxLink}>
                    Поддержка
                </LinkFooter>
            </LinksWrapper>
        </FooterWrapper>
    );
};

export default Footer;
