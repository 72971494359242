import React, { FC, useState } from 'react';
import useValidationSchema from '@hooks/useValidationSchema';
import { Form } from 'react-final-form';
import FormField from '@components/FormField';
import { INTRODUCE_FIELDS } from '@store/Auth/constants';
import Checkbox from '@components/Checkbox';
import { Helmet } from 'react-helmet-async';
import { useAppDispatch } from '@store';
import { updateUser } from '@store/Auth';
import { IUserProfile } from '@store/Auth/types';
import { getUser } from '@store/Auth/selectors';
import { useSelector } from 'react-redux';
import useLoadingSubmit from '@hooks/useLoadingSubmit';
import { useIntl } from 'react-intl';
import { profileSchema } from '../../validations';
import {
    CheckboxLabel,
    CheckboxLabelText,
    FormInner,
    FormInputWrapper,
    GetProfileInner,
    SaveButton,
    Title,
} from '../../styles';

const GetProfileName: FC = () => {
    const intl = useIntl();
    const [isAgree, setIsAgree] = useState(false);
    const validation = useValidationSchema(profileSchema);
    const user = useSelector(getUser);
    const dispatch = useAppDispatch();
    const { isLoadingSubmit, toggleLoadingSubmit } = useLoadingSubmit();

    const toggleIsAgree = () => setIsAgree(!isAgree);

    const formSubmit = async (values: Pick<IUserProfile, 'firstName' | 'lastName'>) => {
        toggleLoadingSubmit();
        await dispatch(updateUser({ ...values, id: user?.id as number }));
    };

    return (
        <GetProfileInner>
            <Helmet>
                <title>{intl.formatMessage({ defaultMessage: 'Создание пользователя' })}</title>
            </Helmet>
            <Title>{intl.formatMessage({ defaultMessage: 'Представьтесь пожалуйста' })}</Title>
            <Form onSubmit={formSubmit} validate={validation}>
                {({ handleSubmit, valid }) => (
                    <FormInner onSubmit={handleSubmit}>
                        <FormInputWrapper>
                            <FormField
                                name={INTRODUCE_FIELDS.lastName}
                                label={intl.formatMessage({ defaultMessage: 'Фамилия' })}
                                autoFocus
                                placeholder={intl.formatMessage({ defaultMessage: 'Введите фамилию' })}
                            />
                        </FormInputWrapper>
                        <FormInputWrapper>
                            <FormField
                                name={INTRODUCE_FIELDS.firstName}
                                label={intl.formatMessage({ defaultMessage: 'Имя' })}
                                placeholder={intl.formatMessage({ defaultMessage: 'Введите имя' })}
                            />
                        </FormInputWrapper>

                        <CheckboxLabel>
                            <Checkbox checked={isAgree} onChange={toggleIsAgree} />
                            <CheckboxLabelText>
                                {intl.formatMessage({
                                    defaultMessage:
                                        'Я соглашаюсь с правилами оказания услуг, а также условиями обработки персональных данных.',
                                })}
                            </CheckboxLabelText>
                        </CheckboxLabel>
                        <SaveButton isLoading={isLoadingSubmit} onClick={handleSubmit} disabled={!valid || !isAgree}>
                            {intl.formatMessage({ defaultMessage: 'Сохранить' })}
                        </SaveButton>
                    </FormInner>
                )}
            </Form>
        </GetProfileInner>
    );
};

export default GetProfileName;
