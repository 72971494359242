import React, { FC, useContext } from 'react';
import { Form } from 'react-final-form';
import { useSelector } from 'react-redux';

import { useAppDispatch } from '@store';
import { FormFieldTypeEnum } from '@components/FormField';
import Typography from '@components/Typography';
import useValidationSchema from '@hooks/useValidationSchema';
import { MERCHANT_FIELDS } from '@store/Merchant/contants';
import { getIsMerchantLoading } from '@store/Merchant/selectors';
import { createMerchant } from '@store/Merchant';
import { IMcc, IMerchantRequestFields } from '@store/Merchant/types';
import { getCurrentCounterpartyId } from '@store/Counterparty/selectors';
import { IntroduceContext } from '@pages/Introduce/context';
import useDictionary from '@hooks/useDictionarty';
import { DICTIONARY_TYPES } from '@store/Dictionaries/constants';
import useLoadingSubmit from '@hooks/useLoadingSubmit';
import { AddingMerchantContext } from '@components/ProductsAccordion/context';
import { useHistory } from 'react-router-dom';
import { Routes } from '@pages/constants';
import { useIntl } from 'react-intl';
import { merchantSchema } from '../../validations';
import { ModalFormButton, ModalFormField, ModalFormInner, ModalInner } from '../../styles';

const MerchantModalInner: FC = () => {
    const intl = useIntl();
    const history = useHistory();
    const { toNextStep, closeModal } = useContext(IntroduceContext);
    const { closeModal: closeModalAddingMerchant } = useContext(AddingMerchantContext);
    const validation = useValidationSchema(merchantSchema);
    const { isLoadingSubmit, toggleLoadingSubmit } = useLoadingSubmit();

    const isLoading = useSelector(getIsMerchantLoading);
    const counterpartyId = useSelector(getCurrentCounterpartyId);
    const dispatch = useAppDispatch();
    const mccList: IMcc[] = useDictionary(DICTIONARY_TYPES.mcc)?.data as any;

    const formSubmit = async (values: IMerchantRequestFields) => {
        toggleLoadingSubmit();
        const merchant = {
            ...values,
            [MERCHANT_FIELDS.counterpartyId]: counterpartyId,
            [MERCHANT_FIELDS.mccId]: +values[MERCHANT_FIELDS.mccId],
        };

        const data = await dispatch(createMerchant(merchant));
        // @ts-ignore
        if (!data?.error && data?.payload) {
            // User goes through the registration path
            if (toNextStep) {
                // skip step for create counterparty
                toNextStep();
                toNextStep();
                closeModal();
            } else {
                closeModalAddingMerchant();
                const { data: newMerchant } = data?.payload as any;
                history.push(`${Routes.dashboard}${Routes.shop}/${newMerchant.id}${Routes.overview}`);
            }
        } else {
            toggleLoadingSubmit();
        }
    };

    return (
        <ModalInner>
            <Typography variant="subheading">{intl.formatMessage({ defaultMessage: 'Общая информация' })}</Typography>
            <Form onSubmit={formSubmit} validate={validation}>
                {({ valid, handleSubmit }) => (
                    <ModalFormInner onSubmit={handleSubmit}>
                        <ModalFormField
                            name={MERCHANT_FIELDS.name}
                            label={intl.formatMessage({ defaultMessage: 'Наименование' })}
                            placeholder={intl.formatMessage({ defaultMessage: 'Введите наименование' })}
                        />
                        <ModalFormField
                            name={MERCHANT_FIELDS.mccId}
                            label={intl.formatMessage({ defaultMessage: 'Категория' })}
                            fieldType={FormFieldTypeEnum.select}
                            placeholder={intl.formatMessage({ defaultMessage: 'Введите значение' })}
                            options={mccList.map(({ id, name }) => ({ label: name, value: id }))}
                        />
                        <ModalFormField
                            fieldType={FormFieldTypeEnum.textarea}
                            name={MERCHANT_FIELDS.description}
                            label={intl.formatMessage({ defaultMessage: 'Описание' })}
                            placeholder={intl.formatMessage({ defaultMessage: 'Введите описание' })}
                            rows={4}
                        />
                        <ModalFormButton
                            onClick={handleSubmit}
                            isLoading={isLoadingSubmit}
                            disabled={!valid || isLoading}
                        >
                            {intl.formatMessage({ defaultMessage: 'Далее' })}
                        </ModalFormButton>
                    </ModalFormInner>
                )}
            </Form>
        </ModalInner>
    );
};

export default MerchantModalInner;
