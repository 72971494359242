import styled from 'styled-components';
import Modal from '@components/Modal';
import Typography from '@components/Typography';
import Button from '@components/Button';
import Skeleton from '@components/Skeleton';

export const ProductsGrid = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px 30px;
    width: 100%;

    @media ${({ theme }) => theme.breakpoints.sm} {
        grid-gap: 12px 15px;
    }
`;

export const MerchantsModalWrapper = styled(Modal)`
    padding: 0 0 30px 0;
`;

export const MerchantsModalHeader = styled.div`
    ${({ theme }) => theme.mixins.modalHeader};
    height: 80px;
    padding: 30px 20px 0;
`;

export const ModalFormInner = styled.form`
    ${({ theme }) => theme.mixins.flexCenter};
    flex-direction: column;
    width: 392px;

    @media ${({ theme }) => theme.breakpoints.sm} {
        width: calc(100vw - 30px);
    }
`;

export const ModalMerchantsField = styled.label`
    ${({ theme }) => theme.mixins.flexCenter};
    padding: 26px 20px;
    width: 100%;
    border-bottom: 1px solid ${({ theme }) => theme.colors.lightGrey()};
`;

export const ModalMerchantsName = styled(Typography).attrs({ variant: 'regular14' })`
    margin-left: 20px;
    ${({ theme }) => theme.mixins.textOverflowDots};
`;

export const ModalMerchantsBalance = styled(Typography).attrs({ variant: 'regular14' })`
    white-space: nowrap;
    color: ${({ theme }) => theme.colors.grey()};
`;

export const MerchantsModalSubmit = styled(Button)`
    margin-top: 30px;
`;

export const AccessToInvoicingModalWrapper = styled(Modal)`
    padding: 45px 0;
`;

export const AccessToInvoicingModalInner = styled.div`
    width: 493px;
    ${({ theme }) => theme.mixins.flexCenterCenter};
    flex-direction: column;

    @media ${({ theme }) => theme.breakpoints.md} {
        width: 100%;
    }

    @media ${({ theme }) => theme.breakpoints.sm} {
        width: calc(100vw - 30px);
    }
`;

export const AccessToInvoicingTitle = styled(Typography).attrs({ variant: 'regular14' })`
    margin-bottom: 14px;
`;

export const AccessToInvoicingDescription = styled(Typography).attrs({ variant: 'regular12', center: true })`
    color: ${({ theme }) => theme.colors.grey()};
    max-width: 275px;
`;

export const ProductCardSkeleton = styled(Skeleton).attrs({ borderRadius: 'base' })`
    height: 78px;

    @media ${({ theme }) => theme.breakpoints.sm} {
        height: 101px;
    }
`;

export const ButtonCreateMerchant = styled.button`
    ${({ theme }) => theme.mixins.getTypography('regular12')}
    color: ${({ theme }) => theme.colors.grey()};
    margin-top: 17px;
    text-align: center;
    text-decoration: underline;
`;
