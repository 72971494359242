import React, { FC, useState } from 'react';

import { useMiniLaptop, useMobile } from '@hooks/useMedia';
import { ICounterparty } from '@store/Counterparty/types';
import { logout } from '@store/Auth';
import { useAppDispatch } from '@store';
import ProfileMenuItem from '@components/Header/components/ProfileMenuItem';
import { Nullable } from '@utils/utilityTypes';
import NotificationMenuItem from '@components/Header/components/NotificationMenuItem/index';
import { Routes } from '@pages/constants';
import Drawer from './components/Drawer';
import LogoIcon from './assets/logo.svg';
import BurgerIcon from './assets/burger-icon.svg';
import { BurgerButton, HeaderControls, HeaderInner, HeaderLeft, HeaderMenu, HeaderWrapper, LogoLink } from './styles';

interface IHeaderProps {
    counterparties: Nullable<Array<ICounterparty>>;
    setCounterparty: (id: Nullable<string>) => void;
    selectedCounterparty: Nullable<string>;
    counterpartiesIsPending: boolean;
}

const Header: FC<IHeaderProps> = ({
    counterparties,
    setCounterparty,
    selectedCounterparty,
    counterpartiesIsPending,
}) => {
    const [drawerIsOpen, setDrawerIsOpen] = useState(false);
    const isMiniLaptop = useMiniLaptop();
    const isMobile = useMobile();
    const dispatch = useAppDispatch();

    const logoutHandler = async () => {
        dispatch(logout());
    };

    const closeDrawer = () => setDrawerIsOpen(false);
    const toggleDrawer = () => setDrawerIsOpen((v) => !v);

    return (
        <HeaderWrapper>
            <HeaderInner>
                <HeaderLeft>
                    <BurgerButton onClick={toggleDrawer}>
                        <BurgerIcon />
                    </BurgerButton>
                    <LogoLink to={Routes.dashboard}>
                        <LogoIcon />
                    </LogoLink>
                </HeaderLeft>
                <HeaderControls>
                    {/* todo временно убраны пункты меню из шапки */}
                    {/* {!isMiniLaptop && <Navigation />} */}
                    {isMiniLaptop && (
                        <Drawer
                            isOpen={drawerIsOpen}
                            close={closeDrawer}
                            logout={logoutHandler}
                            counterparties={counterparties}
                            setCounterparty={setCounterparty}
                            selectedCounterparty={selectedCounterparty}
                            counterpartiesIsPending={counterpartiesIsPending}
                        />
                    )}
                    <HeaderMenu>
                        <NotificationMenuItem />
                        {/* todo Временно убран переключатель языков */}
                        {/* {!isMobile && <LanguageMenuItem />} */}
                        {!isMobile && <ProfileMenuItem logout={logoutHandler} />}
                    </HeaderMenu>
                </HeaderControls>
            </HeaderInner>
        </HeaderWrapper>
    );
};

export default Header;
