import { IWorkHours } from '@store/Merchant/types';

export interface IPointWorkTime {
    startPointDay: string;
    endPointDay: string;
    time: [string, string];
}

const compareArray = (arrayOne: any, arrayTwo: any) =>
    arrayOne.length === arrayTwo.length && arrayOne.every((v: any, i: any) => v === arrayTwo[i]);

// функция для группировки значений графика работы merchant к виду пн-ср 10:00-23:00
const putTogetherTimetable = (workHours: IWorkHours): IPointWorkTime[] => {
    let [startPointDay, endPointDay, time] = [null, null, null];

    const array = Object.entries(workHours).map((key) => key);

    const resultArray = [];

    for (let i = 0; i < array.length; i++) {
        if (array[i][1][0] && array[i][1][0].length > 0) {
            if (startPointDay === null) {
                // @ts-ignore
                // eslint-disable-next-line prefer-destructuring
                startPointDay = array[i][0];
                // @ts-ignore
                // eslint-disable-next-line prefer-destructuring
                endPointDay = array[i][0];
                // @ts-ignore
                // eslint-disable-next-line prefer-destructuring
                time = array[i][1][0];
                if (!array[i + 1]) {
                    resultArray.push({ startPointDay, endPointDay, time });
                }
            } else if (compareArray(array[i][1][0], time)) {
                // @ts-ignore
                // eslint-disable-next-line prefer-destructuring
                endPointDay = array[i][0];
                if (!array[i + 1]) {
                    resultArray.push({ startPointDay, endPointDay, time });
                }
            } else {
                resultArray.push({ startPointDay, endPointDay, time });
                // @ts-ignore
                // eslint-disable-next-line prefer-destructuring
                startPointDay = array[i][0];
                // @ts-ignore
                // eslint-disable-next-line prefer-destructuring
                endPointDay = array[i][0];
                // @ts-ignore
                // eslint-disable-next-line prefer-destructuring
                time = array[i][1][0];
            }
        } else if (startPointDay != null) {
            resultArray.push({ startPointDay, endPointDay, time });
            startPointDay = null;
            endPointDay = null;
            time = null;
        }
    }

    // @ts-ignore
    return resultArray;
};

export default putTogetherTimetable;
