import React, { FC } from 'react';
import { Form } from 'react-final-form';
import ButtonsNextPrev from '@pages/Claim/components/ButtonsNextPrev';
import { enumTabsConnection, IStep } from '@pages/Claim/components/MainConnectionPayment';
import { InputName, InputsWrapper, PassportForm } from '@pages/Claim/components/PassportData/styles';
import { PASSPORT_DATA_FIELDS } from '@store/Claims/constants';
import FormField, { FormFieldTypeEnum } from '@components/FormField';
import { InputTypeEnum } from '@components/Input';
import { IClaimPassportData } from '@store/Claims/types';
import useValidationSchema from '@hooks/useValidationSchema';
import { PassportDataSchema } from '@pages/Claim/validations';
import { useAppDispatch } from '@store';
import { setPassportData } from '@store/Claims';
import { useSelector } from 'react-redux';
import { getSelectedPassportData } from '@store/Claims/selectors';
import dayjs from 'dayjs';
import { DATE_YYYY_MM_DD } from '@utils/constants';
import { useIntl } from 'react-intl';

const PassportData: FC<IStep> = ({ setStep }) => {
    const intl = useIntl();
    const validation = useValidationSchema(PassportDataSchema);
    const dispatch = useAppDispatch();
    const initialPassportData = useSelector(getSelectedPassportData);
    const currentDate = new Date();

    const initialValues = initialPassportData[PASSPORT_DATA_FIELDS.number] && {
        ...initialPassportData,
        [PASSPORT_DATA_FIELDS.series]: initialPassportData[PASSPORT_DATA_FIELDS.number].slice(0, 4),
        [PASSPORT_DATA_FIELDS.number]: initialPassportData[PASSPORT_DATA_FIELDS.number].slice(4, 10),
    };

    const onSubmit = async (formValues: IClaimPassportData) => {
        const passportFields = {
            [PASSPORT_DATA_FIELDS.name]: formValues[PASSPORT_DATA_FIELDS.name],
            [PASSPORT_DATA_FIELDS.birthdate]: dayjs(formValues[PASSPORT_DATA_FIELDS.birthdate]).format(DATE_YYYY_MM_DD),
            [PASSPORT_DATA_FIELDS.birthplace]: formValues[PASSPORT_DATA_FIELDS.birthplace],
            [PASSPORT_DATA_FIELDS.number]: `${formValues[PASSPORT_DATA_FIELDS.series]}${
                formValues[PASSPORT_DATA_FIELDS.number]
            }`,
            [PASSPORT_DATA_FIELDS.issuedPlace]: formValues[PASSPORT_DATA_FIELDS.issuedPlace],
            [PASSPORT_DATA_FIELDS.issuedPlaceCode]: formValues[PASSPORT_DATA_FIELDS.issuedPlaceCode].replace(
                /[^0-9]/g,
                '',
            ),
            [PASSPORT_DATA_FIELDS.issuedDate]: dayjs(formValues[PASSPORT_DATA_FIELDS.issuedDate]).format(
                DATE_YYYY_MM_DD,
            ),
        };
        dispatch(setPassportData(passportFields));
        setStep(enumTabsConnection.documents);
    };

    const returnBankDetailStep = () => {
        setStep(enumTabsConnection.bankDetails);
    };

    return (
        <Form onSubmit={onSubmit} validate={validation} initialValues={initialValues}>
            {({ handleSubmit, valid }) => (
                <PassportForm>
                    <InputsWrapper>
                        <InputName
                            name={PASSPORT_DATA_FIELDS.name}
                            label={intl.formatMessage({ defaultMessage: 'ФИО руководителя организации' })}
                            placeholder={intl.formatMessage({ defaultMessage: 'Введите ФИО' })}
                        />
                        <FormField
                            fieldType={FormFieldTypeEnum.inputDateTime}
                            name={PASSPORT_DATA_FIELDS.birthdate}
                            label={intl.formatMessage({ defaultMessage: 'Дата рождения' })}
                            dateFormat="d.MM.yyyy"
                            placeholder={intl.formatMessage({ defaultMessage: 'дд.мм.гггг' })}
                            maxDate={
                                new Date(currentDate.getFullYear() - 10, currentDate.getMonth(), currentDate.getDate())
                            }
                        />
                        <FormField
                            name={PASSPORT_DATA_FIELDS.birthplace}
                            label={intl.formatMessage({ defaultMessage: 'Место рождения' })}
                            placeholder={intl.formatMessage({ defaultMessage: 'Введите место рождения' })}
                        />
                        <FormField
                            name={PASSPORT_DATA_FIELDS.series}
                            typeInput={InputTypeEnum.numeric}
                            label={intl.formatMessage({ defaultMessage: 'Серия' })}
                            placeholder={intl.formatMessage({ defaultMessage: 'Введите серию' })}
                            mask="9999"
                        />
                        <FormField
                            typeInput={InputTypeEnum.numeric}
                            name={PASSPORT_DATA_FIELDS.number}
                            label={intl.formatMessage({ defaultMessage: 'Номер' })}
                            placeholder={intl.formatMessage({ defaultMessage: 'Введите номер' })}
                            mask="999999"
                        />
                        <FormField
                            name={PASSPORT_DATA_FIELDS.issuedPlace}
                            label={intl.formatMessage({ defaultMessage: 'Орган выдавший документ' })}
                            placeholder={intl.formatMessage({ defaultMessage: 'Введите орган выдавший документ' })}
                        />
                        <FormField
                            name={PASSPORT_DATA_FIELDS.issuedPlaceCode}
                            label={intl.formatMessage({ defaultMessage: 'Код подразделения' })}
                            placeholder="000-000"
                            mask="999-999"
                        />
                        <FormField
                            fieldType={FormFieldTypeEnum.inputDateTime}
                            name={PASSPORT_DATA_FIELDS.issuedDate}
                            label={intl.formatMessage({ defaultMessage: 'Дата выдачи паспорта' })}
                            dateFormat="d.MM.yyyy"
                            placeholder={intl.formatMessage({ defaultMessage: 'дд.мм.гггг' })}
                            maxDate={currentDate}
                        />
                    </InputsWrapper>
                    <ButtonsNextPrev
                        isActiveNextButton={valid}
                        onClickPrev={returnBankDetailStep}
                        onClickNext={handleSubmit}
                    />
                </PassportForm>
            )}
        </Form>
    );
};

export default PassportData;
