// import * as Device from 'expo-device';
// import * as Application from 'expo-application';
import http, { ICommonResponse } from '../../utils/http';
import { IFeedItem, INotificationTokenResponse } from './types';

export const getFeedListRequest = (params?: any) =>
    http.get<ICommonResponse<Array<IFeedItem>>>('/feed/api/personal-feed-item', {
        params: { ...params, '_order[id]': 'desc' },
    });

export const submitNotificationsToken = (token: string, userId: string) =>
    http.post<ICommonResponse<INotificationTokenResponse>>('/notification/api/notification-token', {
        token,
        userId,
        typeOS: 'window.navigator.oscpu',
        versionOS: 'window.navigator.appVersion',
        deviceType: 'deviceType',
        appName: 'appName',
        appVersion: 'appVersion',
    });

export const deleteNotificationsToken = async (tokenId: number) =>
    http.delete(`/notification/api/notification-token/${tokenId}`);

export const getFeedOne = async (feedId: number) =>
    http.get<ICommonResponse<IFeedItem>>(`/feed/api/personal-feed-item`, {
        params: {
            id: feedId,
        },
    });
