import styled from 'styled-components';
import FormField from '@components/FormField';

export const CheckboxContainer = styled.div`
    ${({ theme }) => theme.mixins.flexStart}
    flex-direction: column;
    grid-row-gap: 12px;
    padding: 31px 0 38px 0;
`;

export const CustomerWrapper = styled.div`
    padding: 20px 0 30px 0;
    @media ${({ theme }) => theme.breakpoints.ld} {
        padding: 20px 0;
    }
`;

export const InputsCustomer = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: 20px;
    grid-column-gap: 28px;
    @media ${({ theme }) => theme.breakpoints.ld} {
        grid-template-columns: repeat(3, 1fr);
        grid-column-gap: 20px;
    }
    @media ${({ theme }) => theme.breakpoints.sm} {
        grid-template-columns: 100%;
    }
`;

export const RegistrationAddressCustomerField = styled(FormField)`
    grid-column-start: 1;
    grid-column-end: 3;
    @media ${({ theme }) => theme.breakpoints.ld} {
        grid-column-start: 2;
        grid-column-end: 4;
    }
    @media ${({ theme }) => theme.breakpoints.sm} {
        grid-column-start: auto;
        grid-column-end: auto;
    }
`;
