import React, { FC, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import MerchantLogo from '@components/MerchantLogo';
import { IMerchant } from '@store/Merchant/types';
import { MERCHANT_FIELDS } from '@store/Merchant/contants';
import { useSelector } from 'react-redux';
import { getCurrentCounterpartyId } from '@store/Counterparty/selectors';
import { setFileMerchant, updateMerchant } from '@store/Merchant';
import { useAppDispatch } from '@store';
import colors from '@theme/colors';
import Skeleton from '@components/Skeleton';
import { LabelSetting } from '../../styles';
import { UpdateImageWrapper } from './styles';

interface IUpdateImage {
    merchant: IMerchant;
}

const UpdateImage: FC<IUpdateImage> = ({ merchant }) => {
    const showSkeleton = !merchant?.[MERCHANT_FIELDS.name];
    const counterpartyId = useSelector(getCurrentCounterpartyId);

    const dispatch = useAppDispatch();

    const onDrop = useCallback(
        async (acceptedFiles) => {
            const uploadFile = acceptedFiles[0];

            const formData: FormData = new FormData();
            formData.append('file', uploadFile);
            formData.append('counterpartyId', counterpartyId || '');
            const resultUploadPhoto = await dispatch(setFileMerchant(formData));
            // @ts-ignore
            if (!resultUploadPhoto?.error && resultUploadPhoto?.payload) {
                const merchantNewLogo = {
                    ...merchant,
                    [MERCHANT_FIELDS.logoImageId]: resultUploadPhoto.payload.id,
                };
                await dispatch(updateMerchant(merchantNewLogo));
            }
        },
        [merchant, counterpartyId, dispatch],
    );

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

    if (showSkeleton) {
        return <Skeleton height={115} />;
    }

    return (
        <UpdateImageWrapper
            {...getRootProps()}
            style={{ background: isDragActive ? colors.dragAndDropBackground() : colors.white() }}
        >
            <input {...getInputProps()} />
            <MerchantLogo merchantId={merchant[MERCHANT_FIELDS.id]} />
            <LabelSetting> {merchant[MERCHANT_FIELDS.logoImageId]} </LabelSetting>
        </UpdateImageWrapper>
    );
};

export default UpdateImage;
