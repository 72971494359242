import styled from 'styled-components';
import { COLOR_BRIGHTNESS } from '@theme/utils';

export const OrganizationsWrapper = styled.div`
    width: 100%;
    border-radius: ${({ theme }) => theme.decorations.borderRadius.base}px;
    overflow: hidden;
    box-shadow: ${({ theme }) => theme.decorations.boxShadow?.base};
`;

export const Header = styled.div`
    background-color: ${({ theme }) => theme.colors.lightGrey(COLOR_BRIGHTNESS.MEDIUM)};
    padding: 29px 0 29px 29px;
    ${({ theme }) => theme.mixins.getTypography('subheading')}
`;

export const Content = styled.div`
    padding: 30px 32px;
    @media ${({ theme }) => theme.breakpoints.md} {
        padding: 0;
    }
`;

export const ButtonAddNewOrganization = styled.button`
    ${({ theme }) => theme.mixins.flexCenter}
    grid-column-gap: 20px;
    border: 1px solid ${({ theme }) => theme.colors.lightGrey()};
    padding: 20px 30px;
    width: 100%;
    ${({ theme }) => theme.mixins.getTypography('regular14')}
    color: ${({ theme }) => theme.colors.base(COLOR_BRIGHTNESS.MEDIUM)};
    @media ${({ theme }) => theme.breakpoints.md} {
        border: none;
    }
`;

export const IconWrapper = styled.div`
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background: ${({ theme }) => theme.colors.lightGrey()};
    ${({ theme }) => theme.mixins.flexCenterCenter};
`;
