import React, { FC } from 'react';
import {
    ModalDescription,
    ModalNote,
    ModalSuccessRefundWrapper,
    ModalTitle,
    RefundsNavLink,
} from '@pages/Refund/components/ModalSuccessRefund/styles';
import { Routes } from '@pages/constants';
import { useHistory, useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';

interface IModalSuccessRefund {
    onClose: () => void;
    isOpen: boolean;
    merchantId: string;
}

const ModalSuccessRefund: FC<IModalSuccessRefund> = ({ merchantId, onClose, isOpen }) => {
    const intl = useIntl();
    const { orderContainerId } = useParams<{ orderContainerId: string }>();
    const history = useHistory();

    const onCloseModal = () => {
        onClose();
        history.push(`${Routes.ordercontainer}/${orderContainerId}`);
    };

    return (
        <ModalSuccessRefundWrapper closeModal={onCloseModal} isOpen={isOpen}>
            <ModalTitle>{intl.formatMessage({ defaultMessage: 'Возврат оформлен!' })}</ModalTitle>
            <ModalDescription>
                {intl.formatMessage({ defaultMessage: 'В ближайшее время клиент получит SMS уведомление' })}
            </ModalDescription>
            <ModalNote>
                {intl.formatMessage({ defaultMessage: 'Повторно найти возврат можно в' })}{' '}
                <RefundsNavLink to={`${Routes.dashboard}${Routes.shop}/${merchantId}${Routes.refunds}`}>
                    {intl.formatMessage({ defaultMessage: 'списке возвратов' })}
                </RefundsNavLink>
            </ModalNote>
        </ModalSuccessRefundWrapper>
    );
};

export default ModalSuccessRefund;
