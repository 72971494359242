import React, { FC } from 'react';
import { BreadcrumbItemLink, BreadcrumbItemWrapper } from '../styles';

export interface IBreadcrumbItem {
    to?: string;
}

const Item: FC<IBreadcrumbItem> = ({ children, to }) => (
    <BreadcrumbItemWrapper>
        {to ? <BreadcrumbItemLink to={to}>{children}</BreadcrumbItemLink> : children}
    </BreadcrumbItemWrapper>
);

export default Item;
