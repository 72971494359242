import React, { FC } from 'react';
import { IClient } from '@store/Client/types';
import IconProfile from '@assets/icons/profile.svg';
import {
    ButtonRemove,
    Email,
    IconWrapper,
    Phone,
    RowContactWrapper,
    Title,
} from '@pages/Shop/ShopTabsTables/CreateNewClient/components/RowContactPerson/styles';
import { CLIENT_FIELDS } from '@store/Client/contants';
import IconDelete from '@assets/icons/delete.svg';
import { convertPhoneToShow } from '@utils/formatters';

interface IRowContactPerson {
    contactPerson: IClient;
    removeContactPerson: (contactPerson: IClient) => void;
}

const RowContactPerson: FC<IRowContactPerson> = ({ contactPerson, removeContactPerson }) => (
    <RowContactWrapper>
        <Title>
            <IconWrapper>
                <IconProfile />
            </IconWrapper>
            {contactPerson[CLIENT_FIELDS.name]}
        </Title>
        <Phone>{contactPerson[CLIENT_FIELDS.phone] && convertPhoneToShow(contactPerson[CLIENT_FIELDS.phone])}</Phone>
        <Email>{contactPerson[CLIENT_FIELDS.email]}</Email>
        <ButtonRemove
            type="button"
            onClick={() => {
                removeContactPerson(contactPerson);
            }}
        >
            <IconDelete />
        </ButtonRemove>
    </RowContactWrapper>
);

export default RowContactPerson;
