import React from 'react';
import {
    ButtonCreateIntegration,
    CreateIntegrationTitle,
    CreateIntegrationWrapper,
} from '@pages/Shop/ShopTabsTables/CreateIntegration/styles';
import { Form } from 'react-final-form';
import { useIntl } from 'react-intl';
import { useAppDispatch } from '@store';
import IntegrationInputs from '@pages/Shop/ShopTabsTables/CreateIntegration/components/IntegrationInputs';
import { useSelector } from 'react-redux';
import useValidationSchema from '@hooks/useValidationSchema';
import { createIntegrationSettingSchema } from '@pages/Shop/validations';
import { useHistory, useParams } from 'react-router-dom';
import _ from 'lodash';
import useLoadingSubmit from '@hooks/useLoadingSubmit';
import { Routes } from '@pages/constants';
import { getSelectedApplication } from '@store/Application/selectors';
import { IIKO_APPLICATION_ID, TApplication } from '@store/Application/types';
import { TCreateSecurityApiUserGroup, TSecurityApiUserGroup } from '@store/SecurityApiUseGroup/types';
import { codeActivateSecurityApiUserGroup, createSecurityApiUserGroup } from '@store/SecurityApiUseGroup';

const CreateIntegration = () => {
    const intl = useIntl();
    const dispatch = useAppDispatch();
    const history = useHistory();
    const { merchantId } = useParams<{ merchantId: string }>();
    const { isLoadingSubmit, toggleLoadingSubmit } = useLoadingSubmit();

    const selectedApplication: TApplication = useSelector(getSelectedApplication) as any;

    const properties = selectedApplication?.settings?.properties;
    const arrayFields = properties && Object.keys(properties);

    const onSubmit = async (values: TCreateSecurityApiUserGroup) => {
        toggleLoadingSubmit();
        // @ts-ignore
        const settings = Object.fromEntries(
            Object.entries(values)
                ?.filter((valueForm) => arrayFields?.includes(valueForm[0]))
                .map((valueFormItem) => {
                    if (properties?.[valueFormItem[0]]?.type === 'integer') {
                        return [valueFormItem[0], Number(valueFormItem[1])];
                    }
                    return valueFormItem;
                }),
        );

        const submitData = {
            ...values,
            settings: _.isEmpty(settings) ? undefined : settings,
        };

        const dataMerchantIntegrationSetting = await dispatch(createSecurityApiUserGroup(submitData));
        const merchantIntegrationSetting: TSecurityApiUserGroup = dataMerchantIntegrationSetting?.payload as any;

        if (merchantIntegrationSetting?.id) {
            const dataMerchantIntegrationSettingCode = await dispatch(
                codeActivateSecurityApiUserGroup(merchantIntegrationSetting.id),
            );
            const merchantIntegrationSettingCode: TSecurityApiUserGroup =
                dataMerchantIntegrationSettingCode?.payload as any;
            const activationCode = merchantIntegrationSettingCode?.activationCode;
            const activationExpiredTime = merchantIntegrationSettingCode?.activationExpiredAt;
            const stateIntegrationPage =
                selectedApplication.applicationId === IIKO_APPLICATION_ID
                    ? {
                          activationCode,
                          activationExpiredTime,
                      }
                    : {};

            if (merchantIntegrationSettingCode.id) {
                history.push(
                    `${Routes.dashboard}${Routes.shop}/${merchantId}${Routes.integrations}`,
                    stateIntegrationPage,
                );
            } else {
                toggleLoadingSubmit();
            }
        } else {
            toggleLoadingSubmit();
        }
    };

    const validation = useValidationSchema(
        createIntegrationSettingSchema(arrayFields, selectedApplication?.settings?.required),
    );

    const initialValues = {
        targetType: 'merchant',
        targetId: merchantId,
    };

    return (
        <CreateIntegrationWrapper>
            <CreateIntegrationTitle>
                {intl.formatMessage({ defaultMessage: 'Добавление интеграции' })}
            </CreateIntegrationTitle>
            <Form onSubmit={onSubmit} validate={validation} initialValues={initialValues}>
                {({ handleSubmit, valid }) => (
                    <form onSubmit={handleSubmit}>
                        <IntegrationInputs />
                        <ButtonCreateIntegration
                            isLoading={isLoadingSubmit}
                            disabled={!valid || !selectedApplication}
                            htmlType="submit"
                        >
                            {intl.formatMessage({ defaultMessage: 'Добавить интеграцию' })}
                        </ButtonCreateIntegration>
                    </form>
                )}
            </Form>
        </CreateIntegrationWrapper>
    );
};

export default CreateIntegration;
