import React, { FC } from 'react';
import { TotalRowLabel, TotalRowValue, TotalRowWrapper } from '@pages/OrderContainer/components/TotalRow/styles';
import Skeleton from '@components/Skeleton';

interface ITotalRow {
    label: string;
    value: string;
    isShowSkeleton?: boolean;
}

const TotalRow: FC<ITotalRow> = ({ isShowSkeleton = false, label, value }) => (
    <TotalRowWrapper>
        <TotalRowLabel>{label}</TotalRowLabel>
        <TotalRowValue>{isShowSkeleton ? <Skeleton width={70} /> : value}</TotalRowValue>
    </TotalRowWrapper>
);

export default TotalRow;
