// eslint-disable-next-line no-shadow
export enum COUNTERPARTY_FIELDS {
    id = 'id',
    name = 'name',
    nameFull = 'nameFull',
    nameI18n = 'nameI18n',
    vatNumber = 'vatNumber',
    // ОГРН или ОГРНИП
    registrationNumber = 'registrationNumber',
    registrationDate = 'registrationDate',
    registrationAddress = 'registrationAddress',
    postAddress = 'postAddress',
    postAddressZip = 'postAddressZip',
    phone = 'phone',
    email = 'email',
    website = 'website',
    // ОПФ
    legalEntityTypeId = 'legalEntityTypeId',
    countryId = 'countryId',
    cityId = 'cityId',
    // КПП
    taxRegistrationReasonCode = 'taxRegistrationReasonCode',
    ceoName = 'ceoName',
    cfoName = 'cfoName',
    registered = 'registered',
    ownerCounterpartyId = 'ownerCounterpartyId',
}

// eslint-disable-next-line no-shadow
export enum BANK_DETAILS_FIELDS {
    id = 'id',
    bic = 'bic',
    settlementAccount = 'settlementAccount',
    correspondentAccount = 'correspondentAccount',
    personalAccount = 'personalAccount',
    bankName = 'bankName',
    bankCityName = 'bankCityName',
    currencyId = 'currencyId',
    counterpartyId = 'counterpartyId',
}

export const DEFAULT_BANK_DETAILS_CURRENCY_ID = 'RUB';
