import React, { FC } from 'react';
import IconEdit from '@assets/icons/edit.svg';
import IconSave from '@assets/icons/tick-square.svg';
import { ButtonEdit, ButtonSave, ButtonWrapper, Saved } from '@pages/OrderContainer/components/ButtonEditOrSave/styles';
import { useIntl } from 'react-intl';

interface IButtonEditOrSave {
    isSaved: boolean;
    valid: boolean;
    isEdit: boolean;
    setIsEdit: (arg: boolean) => void;
    handleSubmit: (
        event?: Partial<Pick<React.SyntheticEvent<Element, Event>, 'preventDefault' | 'stopPropagation'>> | undefined,
    ) => Promise<any | undefined> | undefined;
}

const ButtonEditOrSave: FC<IButtonEditOrSave> = ({ isSaved, valid, isEdit, setIsEdit, handleSubmit }) => {
    const intl = useIntl();

    const renderButton = () => {
        if (isSaved) {
            return <Saved>{intl.formatMessage({ defaultMessage: 'Сохраняется...' })}</Saved>;
        }
        if (isEdit) {
            return (
                <ButtonSave disabled={!valid} onClick={handleSubmit} type="button" isValid={valid}>
                    <IconSave />
                    {intl.formatMessage({ defaultMessage: 'Сохранить' })}
                </ButtonSave>
            );
        }

        return (
            <ButtonEdit
                onClick={() => {
                    setIsEdit(true);
                }}
                type="button"
            >
                <IconEdit />
                {intl.formatMessage({ defaultMessage: 'Редактировать' })}
            </ButtonEdit>
        );
    };

    return <ButtonWrapper>{renderButton()}</ButtonWrapper>;
};

export default ButtonEditOrSave;
