import React from 'react';
import FilterOrders from '@components/FilterOrders/components/FilterByStatus';
import FinderByNumber from '@components/FilterOrders/components/FinderWithButtonCreateOrder';
import CreateOrderButton from '@components/FilterOrders/components/CreateOrderButton';
import { useIntl } from 'react-intl';
import { FilterMain, Row, FilterContainer, Wrapper } from './styles';

const FilteredOrderList = () => {
    const intl = useIntl();
    const title = intl.formatMessage({ defaultMessage: 'Заказы' });

    return (
        <FilterMain>
            <FilterContainer>
                <Row>
                    <FinderByNumber />
                    <CreateOrderButton />
                </Row>
            </FilterContainer>
            <Wrapper>
                <FilterOrders title={title} />
            </Wrapper>
        </FilterMain>
    );
};

export default FilteredOrderList;
