import styled from 'styled-components';
import Typography from '@components/Typography';

export const TotalRowContainer = styled.div`
    ${({ theme }) => theme.mixins.flexCenter};
    justify-content: space-between;
    flex-wrap: wrap;

    @media ${({ theme }) => theme.breakpoints.ld} {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        grid-column-gap: 30px;
        padding: 10px 0;
        &:first-child {
            padding-right: 25px;
            border-right: 1px solid ${({ theme }) => theme.colors.lightGrey()};
        }
    }
    @media ${({ theme }) => theme.breakpoints.sm} {
        &:first-child {
            padding-right: 0;
            border: none;
        }
        width: 100%;
        grid-column-gap: 0;
        flex-wrap: nowrap;
    }
`;

export const TotalRowLabel = styled(Typography).attrs({ variant: 'regular14' })`
    @media ${({ theme }) => theme.breakpoints.sm} {
        flex-shrink: 0;
    }
`;

export const TotalRowValue = styled(Typography).attrs({ variant: 'regular14' })`
    color: ${({ theme }) => theme.colors.grey()};
    white-space: nowrap;
    @media ${({ theme }) => theme.breakpoints.sm} {
        flex-shrink: 0;
    }
`;

export const OnlyMobileDesignContainer = styled.div`
    display: none;
    @media ${({ theme }) => theme.breakpoints.sm} {
        display: block;
        margin: 0 10px;
        border-bottom: 1px dashed ${({ theme }) => theme.colors.lightGrey()};
        height: 16px;
        width: 100%;
    }
`;
