import styled from 'styled-components';

export const GeneralInformationWrapper = styled.div`
    padding: 13px 39px 43px 50px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 57px;
    @media ${({ theme }) => theme.breakpoints.xl} {
        padding: 13px 40px 40px 40px;
    }
    @media ${({ theme }) => theme.breakpoints.md} {
        grid-template-columns: 1fr;
        grid-row-gap: 20px;
        padding: 0 40px 40px 40px;
    }
`;
