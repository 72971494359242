import styled from 'styled-components';
import Modal from '@components/Modal';
import Typography from '@components/Typography';
import Button from '@components/Button';

export const RemoveMerchantModalWrapper = styled(Modal)`
    width: 642px;
    padding: 46px 100px 53px 100px;

    @media ${({ theme }) => theme.breakpoints.md} {
        width: 100%;
        padding: 46px 50px 50px 50px;
    }
`;

export const RemoveMerchantTitle = styled(Typography).attrs({ variant: 'title' })`
    color: ${({ theme }) => theme.colors.base()};
    margin-bottom: 52px;
    text-align: center;
`;

export const RemoveMerchantDescription = styled(Typography).attrs({ variant: 'regular14' })`
    color: ${({ theme }) => theme.colors.grey()};
    text-align: center;
    line-height: 140%;
`;

export const ButtonRemoveMerchantModal = styled(Button)`
    color: ${({ theme }) => theme.colors.base()};
    margin: 40px auto 0;
`;
