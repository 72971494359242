export default {
    '++vM35': 'Интеграции',
    '+19wDS': 'Нет клиентов',
    '+65WKv': 'Количество',
    '+HB3Cb': 'Ввести код позже',
    '+UTTCT': 'Клиенты',
    '+glFq7': 'Сегодня',
    '+lWI+2': 'Профиль пользователя',
    '/0DORD': 'Понедельник',
    '/DCgTj': 'ОГРНИП может состоять только из цифр',
    '/lHl3C': 'Магазин',
    '/mIHBS': 'Покупатель',
    '/qjQzi': 'Заказы',
    '/uBhq0': 'КПП',
    '0/HWuH': 'Автоматизация процесса оформления счетов',
    '04IXg4': 'Нет счетов',
    '07GeQd': 'Код действителен',
    '0MXpNX': 'Выберите контактное лицо',
    '0i9OHs': 'Суббота',
    '0t5q4G': 'Профиль',
    '0zWrAT': 'Наименование',
    '15WBez': 'Оплачен',
    '18ELKp': 'Серия',
    '1bEqO5': 'Редактировать',
    '1rJaKm': 'Отменить заказ',
    '1tEp5n': 'Отклонено',
    '2+3sdJ': 'Введите корректный ИНН (10 или 12 цифр)',
    '2LlFKy': 'Проверен',
    '2Ncoyt': 'Новый счёт',
    '2mgQQd': 'Неправильный формат КПП',
    '32v2Fc': 'Вы можете заполнить реквизиты в режиме редактирования',
    '3Y6zTF': 'Создать возврат',
    '3j6hgF': 'Новости',
    '3jnoht': 'новое уведомление',
    '3rO2tG': 'Код подразделения',
    '3tLJ5N': 'Добавление интеграции',
    '488iP3': 'Наименование банка',
    '4JJw/R': 'Сформирован новый код',
    '4n5aq2': 'Неверный код, осталось',
    '4n7ksK': 'Поиск по клиентам',
    '4pWZK3': 'Карточка клиента',
    '5+LApM': 'Маркетплейс',
    '566NoP': 'Входящие: ожидает оплаты',
    '58gPvK': 'Контрагент',
    '5X72QJ': 'Далее',
    '5YzljG':
        'Для того чтобы осуществлять возвраты, Вам необходимо подключить к данному магазину услугу Приём платежей.',
    '63ItSW': 'Введите номер ИНН',
    '6DyGho': 'Ошибка',
    '6KGM/i': 'Добавить новый',
    '6tjO1L': 'Карточка магазина',
    '7/g2ad': 'Не активна',
    '7QCoVW': 'Главный бухгалтер',
    '7TwBQ2': 'Дата рождения',
    '7VQAAE': 'Счёт',
    '7hD3RK': 'Создан',
    '7jOaZA': 'Дата должна быть не раньше текущего времени + 15 минут',
    '7k7RP7': 'Сумма',
    '8L+cOM': 'Номер корр. счёта',
    '8LDsup': 'Автоматизация оформления счёта',
    '8LLEnx': 'Ср',
    '8Schsv': 'Другое',
    '8VNF6Z': 'Добавить новое контактное лицо к организации',
    '8Z9v2y': 'URL нотификации',
    '8kmCWm': 'ОПФ',
    '9/r/+L': 'Информация о магазине',
    '96Q6qI': 'Обзор',
    '9W2P6V': 'Введите номер расчётного счёта',
    '9ZIQcT': 'Бесплатное подключение',
    '9c381n': 'Категория',
    '9c3UKX': 'Адрес веб-сайта',
    A8XEHj: 'Возврат по заказу',
    AH93Oe: 'Сб',
    ARew9T: 'Общие сведения',
    ASsTHk: 'Настройка позиций и деталей возврата',
    AYbkuT: 'Документы',
    Amdw8L: 'БИК может состоять только из цифр',
    AzyM4P: 'Не активирована',
    B8ZlPo: 'Частичный возврат',
    'BPN8+c': 'Комиссия от',
    BPPGNC: 'Нет последних клиентов',
    'BQTkk/': 'Добавить организацию',
    BWnmAa: 'Число должно быть не меньше 0.01',
    Bmy01h: 'Общая информация',
    C1zKLE: 'Входящие счета',
    C6gYLk: 'Алгоритм входа',
    C6rnQv: 'ОГРН может состоять только из 13 цифр',
    CCLE2s: 'ФИО',
    CIRQjV: 'Скачать подписанные документы',
    CPgw5k: 'Bearer авторизационный токен',
    CfVDvu: 'Приватный счет',
    CynsD4: 'Активирована',
    D033oV: 'Добавить контактное лицо',
    DbPmzN: 'Личные',
    ER4wOR: 'Здесь можно дать описание того, какие документы будут скачаны, для чего они, что с ними необходимо сделать и какую-либо еще информацию, которая является необходимой для пользователя.',
    'ES0+tm': 'Доставили не вовремя',
    EtNUDM: 'Чтобы получить доступы к оформлению счетов Вам необходимо создать магазин',
    F9CfLC: 'Номер расчётного счёта',
    FGb7PW: 'Выберите из списка',
    FMl5ji: 'Счёт не найден',
    FSO7Mr: 'Итого сумма после возврата',
    FtBSYk: 'Интервал повтора подключения',
    FtnnH6: 'Введите фамилию',
    Fx45Wy: 'Проблемы с интернет соединением',
    Fzw8qo: 'попыток',
    GPi9i2: 'Введите текст',
    GelxEX: 'ОГРНИП может состоять только из 15 цифр',
    HB14BD: 'Состав заказа',
    HH11iz: 'Итого остаток',
    'HLGU/S': 'Тип',
    HLgUo8: 'Время ожидания вышло, запросите новый код',
    HPejyd: 'На сумму',
    Hi9xQK: 'График работы',
    Hx6FCV: 'Если вы хотите оформлять покупки от имени организации, добавьте информацию о ней',
    I1qpvW: 'КПП может состоять только из 9 знаков',
    I2nJZi: 'Отправить заявку',
    IBJvbD: 'QR код для оплаты',
    IHNrWR: 'текущий тариф',
    IIC0xB: 'Удалить магазин',
    IR4ku0: 'Начните вводить адрес',
    Is2Cqs: 'Паспортные данные',
    J95uHu: 'Полный возврат',
    JG0qZf: 'Обеспечение документооборота с клиентом',
    JN41fj: 'Активна',
    JPYZL1: 'Ещё',
    JdlT2r: 'Отменён',
    Jgp2UG: 'Интегрируйте сервис со своим сайтом и контролируйте платежи через приложение.',
    Jh2x16: 'Чт',
    KAkJ6M: 'Вход в приложение',
    KbSPfR: 'Дата регистрации',
    KdMnnS: 'Введите номер корр. счёта',
    Kg1yXB: 'Поиск по номеру заказа',
    'KkIHG+': 'Подключить услугу',
    KnJeu8: 'Заказ',
    LFyDDp: 'Вы уверены что хотите удалить магазин?',
    'LIjRP+': 'Покупатель является юридическим лицом',
    LUCzCR: 'Выберите',
    LkNZfG: 'Юридический адрес',
    Lpg6N0: 'Введите КПП',
    MOrvXR: 'ИНН должен состоять из 10 или 12 цифр',
    MZEsDN: 'Подключение приёма платежей',
    MZkFSm: 'Нет возвратов',
    McBGHZ: 'ФИО руководителя организации',
    NMeGel: 'Вторник',
    Nhuo1J: 'Последние клиенты',
    NvY05K: 'Четверг',
    OUKeDq: 'Выберите магазин',
    OeiAWv: 'Введите корректный адрес',
    OozVJL: 'Продажи',
    Opx9VX: 'Добавление банковских реквизитов',
    OrG0t1: 'Введите адрес регистрации',
    P1MygK: 'БИК',
    P88qYO: 'Введите наименование',
    PB5Yox: 'Номер счёта',
    PT4BDJ: 'Создание пользователя',
    PWN65j: 'C',
    Q1rfY2: 'Вт',
    Q9wWGL: 'Возвраты',
    Qc1yEg: 'Создать заказ',
    Qk4m8d: 'Оплатить',
    R6xTSU: 'Удалить организацию',
    RHgSHP: 'Сгенерированный системой QR код которым вы можете поделиться',
    Rh5AAu: 'Введите описание',
    SMzCPW: 'Сохранить',
    STnawu: 'Выберите ОПФ',
    SYzoMe: 'Ген.директор',
    SnLlwT: 'Адрес регистрации',
    SxHN5V: 'Карточка организации',
    T24pln: 'Информация',
    T9inVy: 'Укажите свою причину',
    TCZdGJ: 'Скачать документы',
    THaf0F: 'Настройки',
    TOFrR7: 'Доступно магазинам с подключённой услугой - приём платежей',
    TShmaY: 'Добавить новую организацию к клиенту',
    TTO2yU: 'Реквизиты',
    TntDZt: 'новых уведомления',
    TnvrKP: 'Уведомления',
    UBmfzT: 'дд.мм.гггг, чч:мм',
    UWxPV9: 'У вас',
    UngCvh: '* Эти данные потребуются для выставления счетов',
    UoPq8f: 'Имя пользователя для авторизации',
    'UpD+nK': 'Удалить клиента',
    V3G2yR: 'Банк',
    V7CinP: 'Отменить',
    VAuUuY: 'ФИО бухгалтера',
    'VDRw+W': 'Для вас',
    VHKPrN: 'Поиск по организации',
    VMpPw4: 'Клиент',
    'VN/Jr+': 'Пятница',
    Vl15tO: 'ФИО генерального директора',
    VuH2j7: 'дд.мм.гггг',
    VxUtO1: 'Неверное контрольное число',
    'W/MRGf': 'Сохраняем...',
    WA9FYt: 'Доступ к оформлению счетов',
    WEqyGe: 'Очередная причина',
    WFKOxx: 'Частичный',
    WG8Cwa: 'Неверный код, запросите новый код',
    WGrBve: 'Применить',
    WK9moF: 'Некорректная серия',
    WQ754m: 'Услуга',
    X4GdgD: 'Мои организации',
    XMoICV: 'Оригиналы документов необходимо отправить по адресу:',
    XOJT7V: 'Генеральный директор',
    'XQLkz/': 'Артикул',
    XTyoDa: 'Продавец',
    XnLtsK: 'Главная',
    Xo0C0b: 'Пожалуйста, введите код с СМС сообщения отправленный на',
    XqkhFl: 'Вс',
    XxIouQ: 'Просрочен',
    Y0lsaI: 'Контактные лица',
    Y4IAeQ: 'Введите ОГРН',
    YyG8WQ: 'новых уведомлений',
    Z11o5b: 'Описание',
    Z7p9GM: 'Организация',
    ZB4Jx6: 'Некорректнй номер',
    ZNSvf0: 'Выход',
    'ZVwz/b': 'Выбрать',
    ZbgFU7: 'Загрузить подписанные документы',
    ZoX9wE: 'Введите адрес сайта',
    a0UAwM: 'Скидка за брак',
    a56gNi: 'Выберите тип вашей учётной системы',
    a6MvPf: 'Пожалуйста, введите номер телефона для входа или регистрации в приложении',
    a9EOyc: 'Введите юридический адрес',
    a9doGu: 'НДС',
    aA4Fwf: 'ОГРН может состоять только из цифр',
    aJI7jK: 'Счета',
    aPNkxZ: 'Приём платежей через Visa/MasterCard/МИР',
    aj0XRE: 'до',
    azOdBP: 'Принимайте оплату через популярные платежные инструменты Visa/MasterCard/МИР по безналичному расчёту, отслеживая факт поступления оплаты.',
    b4GpQk: 'Поиск по номеру счёта',
    b60xKj: 'Round Robin поддержка',
    bGospQ: 'Среда',
    bNT5LP: 'Вы можете поделиться ссылкой на счёт',
    bPtegp: 'Телефон',
    bU7RgX: 'Наименование магазина',
    bewYj4: 'Скопировано в буфер обмена',
    bleezq: 'Я хочу оплачивать счета как юр. лицо',
    bxYjV2: 'Новый код через:',
    cSLQxQ: 'Воскресенье',
    cUnI5F: 'много интересного',
    cV8n0R: 'Добавьте новую товарную позицию',
    civqPC: 'Режим работы',
    cp6hot: 'ИНН',
    cpnaHD: 'Сумма заказа',
    d9QMs7: 'Инвойсбокс | Панель управления',
    dCX67V: 'Орган выдавший документ',
    dNp3xb: 'Введите БИК',
    dNtpfd: 'Номер',
    dcopRb: '196105, Санкт-Петербург, пр. Юрия Гагарина д. 1',
    dfN2t3: 'Срок оплаты',
    dhhRTr: 'Чтобы получить доступы к оформлению счетов Вам необходимо выбрать организацию',
    e3dEpO: 'Назад',
    e3vm8I: 'Добавить новую интеграцию',
    eEaTZU: 'Я хочу продавать товары или услуги',
    eZSgqm: 'Причина возврата',
    eaNjIe: 'ОГРН',
    efGgEE: 'Дата выдачи паспорта',
    epPuM0: 'И ещё очень',
    exFUdj: 'Сохраняется...',
    'f+9mML': 'Добавить пользователя',
    f3aWJj: 'Введите номер',
    f7dwgi: 'Состав возврата',
    fVHzIJ: 'Придумайте имя, например «Касса 1»',
    fW331M: 'Контактная информация',
    fcZnRC: 'Введите имя',
    fjgZTg: 'Создание возврата',
    fsyEvr: 'Ключ входа',
    fw8b4d: 'Адрес сайта',
    fxmSAi: 'Добавить магазин',
    'g+S+lA': 'До',
    'g5+zdJ': 'Введите серию',
    gDQNQC: 'Новый код',
    gFFP5N: 'Тип ОПФ',
    gmYnMe: 'Карточка компании',
    'h0Bg/b': 'Единица измерения',
    h0Chh6: 'Неправильное контрольное число',
    h1lIfp: 'Юр.адрес',
    hMLlYo: 'Нет заказов',
    hQJEoU: 'Интеграция успешно добавлена',
    hcIGh4: 'Интеграции позволяют настроить взаимодействие системы Инвойсбокс и учётной системы магазина с помощью',
    hrG7Hb: 'Выбор клиента',
    i5Q6Tm: 'Данные магазинов необходимы для проверки магазинов и платежной страницы',
    i8KTyz: 'Комментарий',
    iGF9UW: 'Расчётный счёт может состоять только из цифр',
    id1nWO: 'Скопировать ссылку на оплату',
    j2oIk1: 'Сформировать счет',
    jDocm6: 'Чтобы закончить настройку, введите на кассе код активации',
    jFflJn: 'Шаг',
    jQLKS7: 'Количество попыток повторного подключению',
    'jRCu+B': 'Справка',
    jez89g: 'Новый счет',
    jlZM3V: 'ОГРНИП',
    jnQ2E2: 'Отменить подключение',
    k9LLC0: 'Место рождения',
    kCRjXR: 'Для открытия меню выберите организацию',
    kEUS9I: 'В ближайшее время клиент получит SMS уведомление',
    kOoq8S: 'Электронный документооборот',
    'kaTXR+': 'от',
    'l+dOog': 'Покупатель укажет свои данные',
    'l2+bJb': 'Пт',
    lGWBeN: 'К выплате по тарифу:',
    lmKWxx: 'Введите ОГРНИП',
    lvh1Ut: 'Все данные удалятся без возможности восстановления',
    mfeLHk: 'Введите ФИО',
    mhWiRY: 'Расчётный счёт может состоять только из 20 цифр',
    n0mixY: 'Произвести возврат',
    'nImL+V': 'Выполнен',
    nwztvj: 'На рассмотрении',
    'nxZ+Pc': 'Ставка НДС',
    oNLwN8: 'Без плательщика',
    oQ1Nnf: 'Бухгалтер',
    'oSDX/Y': 'Вы всегда можете подключить другой тариф. Он сменит текущий по истечении календарного месяца',
    oWLSye: 'Электронная почта',
    'oX+epd': 'списке возвратов',
    oie7cL: 'Кол-во',
    okUsF9: 'Сайт',
    'p/0+9i': 'За всё время',
    pGauf3: 'ФИО гендиректора',
    'pQ6q+N': 'Оформлен',
    pScUY4: 'Выберите организацию',
    pT8skR: 'Добавить новый магазин',
    pYFEnw: 'списке счетов',
    'pdBz/6': 'Бухгалтерия',
    pfBrYY: 'Возврат',
    poK15L: 'Я соглашаюсь с правилами оказания услуг, а также условиями обработки персональных данных.',
    pvfxwb: 'Продукты',
    'pxwXj+': 'Все',
    qAoxIm: 'Организации',
    qXh0mH: 'Имя',
    qsZKUJ: 'Спасибо, счёт оформлен!',
    qzytcr: 'Введите название',
    'r+nvkQ': 'Банковские реквизиты',
    r07jnD: 'Номер рассч.счёта',
    r5EAZD: 'Тут , по всей видимости, можно дать пользователю краткую информацию о том, куда отправятся документы и как долго они будет на рассмотрении.',
    r8Je4R: 'Не требуется покупать Online-кассу',
    rHrsMF: 'Кор. счёт может состоять только из 20 цифр',
    'rMD0+o': 'Статус',
    rS0lOD: 'Пароль для авторизации',
    rU66pi: 'Цена за единицу',
    rVQCOG: 'Приём платежей',
    rmfRvS: 'Введите Email',
    rmkmup: 'Повторно найти счёт можно в',
    rmoXSg: 'Сум. возврата',
    s07lob: 'Причины возврата',
    s1jAMo: 'Кор. счёт может состоять только из цифр',
    's3dl/1': 'комиссия от',
    s7ar2b: 'Возврат оформлен!',
    sCLdLh: 'Некорректный номер',
    'sEPo+k': 'В процессе заполнения',
    sibHfv: 'Введите ИНН',
    sx9jDS: 'Если вы хотите принимать платежи вы можете добавить информацию о магазине',
    'sy+pv5': 'Email',
    'tO+3yF': 'Счёт детально',
    tSyyZP: 'Добавить интеграцию',
    tUQeoe: 'Пропустить',
    taVtrT: 'Введите место рождения',
    tkcHSF: 'БИК может состоять только из 9 цифр',
    u6KeoV: 'Введите корректный БИК',
    uJyOct: 'Создать магазин',
    um3WHd: 'Товар',
    v5Hrir: 'Введите значение',
    vHLwp2: 'Сформированный системой QR код счёта',
    vIHPnK: 'Фамилия',
    vSvwd5: 'Выбрать все позиции',
    w5OZuO: 'Повторно найти возврат можно в',
    'wI0+6S': 'Последние заказы',
    weuM3c: 'Товар ненадлежащего качества',
    wgnWCk: 'Метод входа',
    wo5cyx: 'Добавить позицию',
    wt2i1M: 'Настройка позиций возврата',
    wx7lwV: 'Состав счёта',
    x1MUPc: 'Адрес',
    x1OG6o: 'Тариф',
    xlIopF: 'Верификация',
    y2nxeW: 'Номер кор.счёта',
    yKKamR: 'Выберите категорию',
    yTtNMl: 'QR код',
    'yUwR+m': 'Введите орган выдавший документ',
    yYw9yE: 'Новый клиент',
    yldHUr: 'Гендиректор',
    yqmvj4: 'Представьтесь пожалуйста',
    ysgE6w: 'Информация об организации',
    ytMgEc: 'Интервал подключения',
    'z+6mB0': 'Обрабатывается',
    z4ziT3: 'Счет',
    zDCKWs: 'Реквизиты клиента',
    zK932z: 'Пн',
    zXkVd5: 'Добавить',
    zuNJCe: 'Ошибка сети',
};
