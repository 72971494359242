import React, { FC, InputHTMLAttributes, ReactNode } from 'react';
import useFocusField from '@hooks/useFocusField';
import {
    IconClickableContainer,
    IconContainer,
    InputContainer,
    InputField,
    InputLabel,
    InputWrapper,
    MaskField,
} from '@components/Input/styles';

// eslint-disable-next-line no-shadow
export enum InputTypeEnum {
    text = 'text',
    numeric = 'numeric',
}

export interface IInputProps extends InputHTMLAttributes<HTMLInputElement> {
    label?: string;
    disabled?: boolean;
    hasError?: boolean;
    className?: string;
    mask?: string;
    icon?: ReactNode;
    onIconClick?: () => void;
    iconIsDisabled?: boolean;
    onChange?: any;
    typeInput?: InputTypeEnum;
    maxValue?: number;
    maxLength?: number;
}

export interface IInputContainerProps {
    hasError: boolean;
    hasIcon: boolean;
    inFocus: boolean;
}

interface InputIconContainerProps {
    onClick?: () => void;
    iconIsDisabled?: boolean;
}

const InputIconContainer: FC<InputIconContainerProps> = ({ onClick, iconIsDisabled, children }) =>
    onClick ? (
        <IconClickableContainer onClick={onClick} disabled={iconIsDisabled}>
            {children}
        </IconClickableContainer>
    ) : (
        <IconContainer>{children}</IconContainer>
    );

const Input: FC<IInputProps> = ({
    maxLength,
    typeInput = InputTypeEnum.text,
    maxValue,
    placeholder,
    disabled = false,
    hasError = false,
    label = '',
    className,
    mask,
    icon,
    onIconClick,
    iconIsDisabled,
    onFocus,
    onBlur,
    ...props
}) => {
    const { inFocus, onFocusHandler, onBlurHandler } = useFocusField({ isFocus: false, onFocus, onBlur });
    // only use the input mask if there is a value -- this prevents issues with Chrome auto-fill
    const inputMask = props.value ? mask : null;

    const onChangeOnlyNumbers = (value: string) => {
        props.onChange(value.replace(/[^0-9]/g, ''));
    };

    const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        const valueInput = maxLength ? e.target.value.slice(0, maxLength) : e.target.value;
        if (typeInput === InputTypeEnum.numeric) {
            if (maxValue) {
                if (maxValue >= Number(valueInput)) {
                    onChangeOnlyNumbers(valueInput);
                }
            } else {
                onChangeOnlyNumbers(valueInput);
            }
        } else {
            props.onChange(valueInput);
        }
    };

    return (
        <InputWrapper disabled={disabled} className={className}>
            {label && <InputLabel inFocus={inFocus}>{label}</InputLabel>}
            <InputContainer hasError={hasError} hasIcon={!!icon} inFocus={inFocus}>
                {mask ? (
                    <MaskField
                        // @ts-ignore
                        mask={inputMask}
                        placeholder={placeholder}
                        disabled={disabled}
                        onFocus={onFocusHandler}
                        onBlur={onBlurHandler}
                        {...props}
                    />
                ) : (
                    <InputField
                        placeholder={placeholder}
                        disabled={disabled}
                        onFocus={onFocusHandler}
                        onBlur={onBlurHandler}
                        {...props}
                        onChange={onChangeHandler}
                    />
                )}
                {icon && (
                    <InputIconContainer onClick={onIconClick} iconIsDisabled={iconIsDisabled}>
                        {icon}
                    </InputIconContainer>
                )}
            </InputContainer>
        </InputWrapper>
    );
};

export default React.memo(Input);
