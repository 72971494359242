import http, { ICommonResponse } from '@utils/http';
import { IMerchant, IMerchantRequestFields } from './types';

export const createMerchantRequest = (payload: IMerchantRequestFields) =>
    http.post<ICommonResponse<IMerchant>>('billing/api/merchant/merchant', payload);

export const getMerchantsListRequest = (params?: any) =>
    http.get<ICommonResponse<Array<IMerchant>>>('filter/api/merchant/merchant', {
        params: { ...params },
    });

export const updateMerchantRequest = (data: IMerchant) =>
    http.put<ICommonResponse<IMerchant>>(`billing/api/merchant/merchant/${data.id}`, data);

export const setFileMerchantRequest = (data: FormData) => http.post<ICommonResponse<any>>('file/api/file/file', data);
