import React, { FC } from 'react';
import {
    BankNameInput,
    ButtonAdd,
    CorrespondentAccountInput,
    FormWrapper,
    InputsWrapper,
    ModalWrapper,
    SettlementAccountInput,
} from '@pages/Claim/components/ModalCreateBankDetail/styles';
import FormField from '@components/FormField';
import { Form } from 'react-final-form';
import { BANK_DETAILS_FIELDS } from '@store/Counterparty/contants';
import { InputTypeEnum } from '@components/Input';
import useValidationSchema from '@hooks/useValidationSchema';
import { BankDetailSchema } from '@pages/Claim/validations';
import { ICounterpartyBankDetail } from '@store/Counterparty/types';
import { useAppDispatch } from '@store';
import { useParams } from 'react-router-dom';
import { createCounterpartyBankDetails } from '@store/Counterparty';
import useLoadingSubmit from '@hooks/useLoadingSubmit';
import { useIntl } from 'react-intl';

interface IModalCreateBankDetail {
    isOpen: boolean;
    onClose: () => void;
}

const ModalCreateBankDetail: FC<IModalCreateBankDetail> = ({ isOpen, onClose }) => {
    const intl = useIntl();
    const { isLoadingSubmit, toggleLoadingSubmit } = useLoadingSubmit();
    const validation = useValidationSchema(BankDetailSchema);
    const dispatch = useAppDispatch();
    const { counterpartyId } = useParams<{ counterpartyId: string }>();

    const onSubmit = async (formValues: ICounterpartyBankDetail) => {
        toggleLoadingSubmit();
        const data = await dispatch(
            createCounterpartyBankDetails({ ...formValues, [BANK_DETAILS_FIELDS.counterpartyId]: counterpartyId }),
        );
        // @ts-ignore
        if (!data?.error && data?.payload) {
            onClose();
        }
        toggleLoadingSubmit();
    };

    return (
        <ModalWrapper
            title={intl.formatMessage({ defaultMessage: 'Добавление банковских реквизитов' })}
            isOpen={isOpen}
            closeModal={onClose}
        >
            <Form onSubmit={onSubmit} validate={validation}>
                {({ handleSubmit, valid }) => (
                    <FormWrapper>
                        <InputsWrapper>
                            <BankNameInput
                                name={BANK_DETAILS_FIELDS.bankName}
                                label={intl.formatMessage({ defaultMessage: 'Банк' })}
                                placeholder={intl.formatMessage({ defaultMessage: 'Введите наименование' })}
                            />
                            <FormField
                                typeInput={InputTypeEnum.numeric}
                                name={BANK_DETAILS_FIELDS.bic}
                                label={intl.formatMessage({ defaultMessage: 'БИК' })}
                                placeholder="000000000"
                            />
                            <CorrespondentAccountInput
                                name={BANK_DETAILS_FIELDS.correspondentAccount}
                                label={intl.formatMessage({ defaultMessage: 'Номер кор.счёта' })}
                                placeholder="00000000000000000000"
                                typeInput={InputTypeEnum.numeric}
                            />
                            <SettlementAccountInput
                                name={BANK_DETAILS_FIELDS.settlementAccount}
                                label={intl.formatMessage({ defaultMessage: 'Номер рассч.счёта' })}
                                placeholder="00003424500044668654"
                                typeInput={InputTypeEnum.numeric}
                            />
                        </InputsWrapper>
                        <ButtonAdd isLoading={isLoadingSubmit} disabled={!valid} onClick={handleSubmit}>
                            {intl.formatMessage({ defaultMessage: 'Добавить' })}
                        </ButtonAdd>
                    </FormWrapper>
                )}
            </Form>
        </ModalWrapper>
    );
};

export default ModalCreateBankDetail;
