import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { persistReducer } from 'redux-persist';
import { getUser } from '@store/Auth/selectors';
import { thunkHandler } from '@utils/helpers';
import createAsyncThunkWithErrorHandler from '@utils/helpers/createAsyncThunkWithErrorHandler';
import { RootState } from '@store';
import { deleteNotificationsToken, getFeedListRequest, getFeedOne, submitNotificationsToken } from './http';
import { IFeedItem } from './types';
import { deleteToken, getToken } from '../../firebaseInit';

export const feedAdapter = createEntityAdapter<IFeedItem>();

export const fetchFeedList = createAsyncThunkWithErrorHandler('feed/fetchList', async (params: {}) => {
    const { data } = await getFeedListRequest(params);
    return data;
});

export const fetchMoreFeeds = createAsyncThunkWithErrorHandler<IFeedItem[]>('feed/fetchMore', async (arg, thunkAPI) => {
    const { selectTotal } = feedAdapter.getSelectors((state: RootState) => state.feed);
    const total = selectTotal(thunkAPI.getState() as RootState);
    const page = Math.ceil(total / 10) + (total % 10 === 0 ? 1 : 0);
    const {
        data: { data },
    } = await getFeedListRequest({ _page: page });
    return data;
});

export const fetchOneFeed = createAsyncThunkWithErrorHandler('feed/fetchOne', async (data: number, thunkAPI) => {
    const fetchedFeeds = await thunkHandler(getFeedOne(data), thunkAPI);
    return fetchedFeeds;
});

export const subscribeNotifications = createAsyncThunkWithErrorHandler<any>(
    'feed/initNotifications',
    async (arg, thunkAPI) => {
        const userId = getUser(thunkAPI.getState() as RootState)?.userId;

        const token = await getToken();
        const {
            data: {
                data: { id: savedTokenId },
            },
        } = await submitNotificationsToken(token, userId as string);

        return {
            savedTokenId,
            savedToken: token,
        };
    },
);

export const unsubscribeNotifications = createAsyncThunk<any>(
    'feed/unsubscribeNotification',
    async (args, thunkAPI) => {
        const {
            feed: { savedTokenId },
        } = thunkAPI.getState() as RootState;
        await deleteToken();
        await deleteNotificationsToken(savedTokenId as number);
        return true;
    },
);

const initialState = feedAdapter.getInitialState<{
    loading: boolean;
    registered: boolean;
    enabled: boolean;
    savedTokenId: number | null;
    savedToken: string | null;
    metaData: {
        totalCount: number;
        pageSize: number;
        page: number;
    };
}>({
    loading: false,
    registered: false,
    enabled: false,
    savedTokenId: null,
    savedToken: null,
    metaData: {
        totalCount: 0,
        pageSize: 0,
        page: 0,
    },
});

const Feed = createSlice({
    name: 'feed',
    initialState,
    reducers: {
        addFeedItem(state, { payload }) {
            feedAdapter.upsertOne(state, payload);
        },
        removeFeedItem(state, { payload }) {
            feedAdapter.removeOne(state, payload);
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchFeedList.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(fetchFeedList.fulfilled, (state, { payload }) => {
            feedAdapter.setAll(state, payload.data);
            state.metaData = payload.metaData;
            state.loading = false;
        });
        builder.addCase(fetchOneFeed.fulfilled, (state, { payload }) => {
            feedAdapter.setOne(state, payload[0]);
        });
        builder.addCase(subscribeNotifications.fulfilled, (state, { payload: { savedTokenId, savedToken } }) => {
            state.savedTokenId = savedTokenId;
            state.savedToken = savedToken;
        });
        builder.addCase(unsubscribeNotifications.fulfilled, () => initialState);
    },
});
const persistConfig = {
    key: 'feed',
    storage: AsyncStorage,
    whitelist: ['enabled', 'registered', 'tokenId'],
};

export default persistReducer(persistConfig, Feed.reducer);
