import styled from 'styled-components';
import { COLOR_BRIGHTNESS } from '@theme/utils';
import FormField from '@components/FormField';

export const FormMerchantWrapper = styled.div`
    padding-top: 30px;
`;

export const Warning = styled.div`
    padding: 21px 57px;
    color: ${({ theme }) => theme.colors.base(COLOR_BRIGHTNESS.MEDIUM)};
    background: ${({ theme }) => theme.colors.lightGrey()};
    border-radius: ${({ theme }) => theme.decorations.borderRadius.base}px;
    text-align: center;
    margin-bottom: 37px;
    @media ${({ theme }) => theme.breakpoints.md} {
        ${({ theme }) => theme.mixins.getTypography('regular12')};
        padding: 21px 20px;
        margin-bottom: 30px;
    }
`;

export const InputsWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 30px;
    grid-row-gap: 20px;
    @media ${({ theme }) => theme.breakpoints.ld} {
        grid-template-columns: repeat(3, 1fr);
    }
    @media ${({ theme }) => theme.breakpoints.md} {
        grid-template-columns: 1fr;
        grid-row-gap: 7px;
    }
`;

export const InputName = styled(FormField)`
    grid-column-start: 1;
    grid-column-end: 4;
    @media ${({ theme }) => theme.breakpoints.ld} {
        grid-column-end: 3;
    }
    @media ${({ theme }) => theme.breakpoints.md} {
        grid-column-end: auto;
    }
`;

export const InputDescription = styled(FormField)`
    grid-column-start: 1;
    grid-column-end: 5;
    @media ${({ theme }) => theme.breakpoints.ld} {
        grid-column-end: 4;
    }
    @media ${({ theme }) => theme.breakpoints.md} {
        grid-column-end: auto;
    }
`;

export const InputEmail = styled(FormField)`
    grid-column-start: 1;
    grid-column-end: 3;
    @media ${({ theme }) => theme.breakpoints.ld} {
        grid-column-end: 2;
    }
    @media ${({ theme }) => theme.breakpoints.md} {
        grid-column-end: auto;
    }
`;
