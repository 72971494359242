import styled from 'styled-components';
import Typography from '@components/Typography';

export const MerchantName = styled(Typography).attrs({ variant: 'bigTitle' })`
    ${({ theme }) => theme.mixins.textOverflowDots};

    @media ${({ theme }) => theme.breakpoints.sm} {
        ${({ theme }) => theme.mixins.getTypography('subheading')};
    }
`;

export const ShopHeaderWrapper = styled.div`
    display: inline-block;
    position: relative;
    margin: 33px 0 40px 0;

    @media ${({ theme }) => theme.breakpoints.ld} {
        margin-top: 36px;
    }

    @media ${({ theme }) => theme.breakpoints.sm} {
        margin-top: 39px;
    }
`;

export const ShopHeader = styled.button`
    display: inline-grid;
    width: auto;
    grid-template-columns: 36px 1fr 11px;
    align-items: center;
    grid-gap: 15px;
    cursor: default;

    @media ${({ theme }) => theme.breakpoints.ld} {
        cursor: pointer;
    }

    @media ${({ theme }) => theme.breakpoints.sm} {
        grid-gap: 5px;
    }
`;

interface IIconArrow {
    isActiveIcon: boolean;
}

export const IconWrapper = styled.div<IIconArrow>`
    width: 11px;
    margin-left: -4px;
    @media ${({ theme }) => theme.breakpoints.sm} {
        margin-left: 6px;
    }
    transform: ${({ isActiveIcon }) => (isActiveIcon ? 'rotateZ(180deg)' : 'none')};
    transition: ${({ theme }) => theme.decorations.transition?.base};
    path {
        stroke: ${({ theme, isActiveIcon }) => (isActiveIcon ? theme.colors.yellow() : theme.colors.base())};
    }
`;
