import styled from 'styled-components';
import Modal from '@components/Modal';
import Typography from '@components/Typography';
import { COLOR_BRIGHTNESS } from '@theme/utils';
import Button from '@components/Button';

export const TariffModalWrapper = styled(Modal)`
    position: relative;
    padding: 60px 80px;
    width: 580px;
    overflow: hidden;
    @media ${({ theme }) => theme.breakpoints.md} {
        width: 100%;
        padding: 40px 20px;
    }
`;

export const ModalHead = styled.div`
    ${({ theme }) => theme.mixins.flexStart};
    grid-column-gap: 20px;
    margin-bottom: 34px;
`;

export const HeadIconWrapper = styled.div`
    width: 74px;
    height: 74px;
    ${({ theme }) => theme.mixins.flexCenterCenter};

    svg {
        transform: scale(3);
    }
`;

export const TitleTariff = styled.div`
    ${({ theme }) => theme.mixins.flexStart};
    flex-direction: column;
    grid-row-gap: 2px;
`;

export const Note = styled(Typography).attrs({ variant: 'regular12' })`
    color: ${({ theme }) => theme.colors.grey()};
`;

export const NoteOtherTariff = styled(Typography).attrs({ variant: 'regular12' })`
    color: ${({ theme }) => theme.colors.base(COLOR_BRIGHTNESS.MEDIUM)};
    margin-top: 16px;
`;

export const Name = styled(Typography).attrs({ variant: 'subheading' })``;

export const Commission = styled(Typography).attrs({ variant: 'medium34' })`
    @media ${({ theme }) => theme.breakpoints.md} {
        ${({ theme }) => theme.mixins.getTypography('headline')}
    }
`;

export const Description = styled(Typography).attrs({ variant: 'regular14' })`
    color: ${({ theme }) => theme.colors.grey()};
    border-radius: ${({ theme }) => theme.decorations.borderRadius.base}px;
    background: ${({ theme }) => theme.colors.lightGrey()};
    padding: 30px 35px 30px 43px;
    @media ${({ theme }) => theme.breakpoints.md} {
        padding: 30px 20px;
        position: relative;
        z-index: 3;
    }
`;

export const ButtonShowTariffs = styled(Button)`
    margin-top: 40px;
`;

export const BackgroundYellowCircle = styled.div`
    width: 129px;
    height: 129px;
    border-radius: 50%;
    border: 26px solid ${({ theme }) => theme.colors.yellow()};
    position: absolute;
    z-index: 2;
    right: -44px;
    bottom: -30px;
`;

export const BackgroundBaseCircle = styled.div`
    width: 97px;
    height: 97px;
    border-radius: 50%;
    border: 24px solid ${({ theme }) => theme.colors.base()};
    position: absolute;
    right: 26px;
    bottom: -50px;
`;

export const FinishedTariff = styled(Typography).attrs({ variant: 'regular12' })`
    color: ${({ theme }) => theme.colors.orange()};
`;
