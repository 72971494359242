import React, { FC } from 'react';
import { IClientCounterparty } from '@store/ClientCounterparty/types';
import {
    ButtonAddNewContactPerson,
    Content,
    Header,
    IconWrapper,
    RelatedContactsWrapper,
} from '@pages/Shop/ShopTabsTables/CreateNewClient/components/RelatedContacts/styles';
import PlusIcon from '@assets/icons/plus.svg';
import { useSelector } from 'react-redux';
import { getCounterpartyClients } from '@store/Client/selectors';
import { CLIENT_COUNTERPARTY_FIELDS } from '@store/ClientCounterparty/constants';
import RowContactPerson from '@pages/Shop/ShopTabsTables/CreateNewClient/components/RowContactPerson';
import { CLIENT_FIELDS } from '@store/Client/contants';
import useModal from '@hooks/useModal';
import ModalSearchContact from '@pages/Shop/ShopTabsTables/CreateNewClient/components/ModalSearchContact';
import { IClient } from '@store/Client/types';
import { useAppDispatch } from '@store';
import { updateClient } from '@store/Client';
import ModalCreateContactPerson from '@pages/Shop/ShopTabsTables/CreateNewClient/components/ModalCreateContactPerson';
import { useIntl } from 'react-intl';

interface IRelatedContacts {
    clientCounterparty: IClientCounterparty;
}

const RelatedContacts: FC<IRelatedContacts> = ({ clientCounterparty }) => {
    const intl = useIntl();
    const clientCounterpartyId = clientCounterparty[CLIENT_COUNTERPARTY_FIELDS.id];
    const { isOpen: searchModalVisible, open: openModalSearch, close: closeModalSearch } = useModal();
    const { isOpen: createModalVisible, open: openModalCreate, close: closeModalCreate } = useModal();
    const dispatch = useAppDispatch();

    const contactsClientsCounterparty = useSelector(getCounterpartyClients(clientCounterpartyId));

    const selectContactPerson = (contactPerson: IClient) => {
        const counterpartyContacts = contactPerson[CLIENT_FIELDS.contactCounterpartyIds] || [];
        const upgradeClient = {
            ...contactPerson,
            [CLIENT_FIELDS.contactCounterpartyIds]: [...counterpartyContacts, clientCounterpartyId],
        };
        dispatch(updateClient(upgradeClient));
    };

    const removeContactPerson = (contactPerson: IClient) => {
        const contactPersonFilteredContacts = contactPerson[CLIENT_FIELDS.contactCounterpartyIds].filter(
            (contact) => contact !== clientCounterpartyId,
        );
        dispatch(
            updateClient({
                ...contactPerson,
                [CLIENT_FIELDS.contactCounterpartyIds]: contactPersonFilteredContacts,
            }),
        );
    };

    const openModalCreateContactPerson = () => {
        openModalCreate();
        closeModalSearch();
    };

    return (
        <RelatedContactsWrapper>
            <Header>{intl.formatMessage({ defaultMessage: 'Контактные лица' })}</Header>
            <Content>
                {contactsClientsCounterparty.map((contactPerson) => (
                    <RowContactPerson
                        removeContactPerson={removeContactPerson}
                        key={contactPerson[CLIENT_FIELDS.id]}
                        contactPerson={contactPerson}
                    />
                ))}
                <ButtonAddNewContactPerson type="button" onClick={openModalSearch}>
                    <IconWrapper>
                        <PlusIcon />
                    </IconWrapper>
                    {intl.formatMessage({ defaultMessage: 'Добавить новое контактное лицо к организации' })}
                </ButtonAddNewContactPerson>
            </Content>
            <ModalSearchContact
                clientCounterpartyId={clientCounterpartyId}
                isOpen={searchModalVisible}
                onClose={closeModalSearch}
                openModalCreateContactPerson={openModalCreateContactPerson}
                selectContactPerson={selectContactPerson}
            />
            <ModalCreateContactPerson
                clientCounterpartyId={clientCounterpartyId}
                isOpen={createModalVisible}
                onClose={closeModalCreate}
            />
        </RelatedContactsWrapper>
    );
};

export default RelatedContacts;
