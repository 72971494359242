import React, { FC, useEffect, useState } from 'react';
import {
    Decoration,
    ParagraphSlide,
    ParagraphsWrapper,
    SliderBlock,
    SlideTitle,
} from '@pages/Claim/components/DesktopSlide/styles';
import { ISliderContent } from '@pages/Claim/components/DesktopSlider';

interface IDesktopSlide {
    slide: ISliderContent;
    activeSlide: number;
    setActiveFrame: (arg: number) => void;
}

const DesktopSlide: FC<IDesktopSlide> = ({ setActiveFrame, activeSlide, slide }) => {
    const numberSlide = slide.frame;
    const isActive = activeSlide === numberSlide;
    const [titleAlign, setTitleAlign] = useState<'left' | 'center'>('left');

    const onClickToSlide = () => {
        setActiveFrame(numberSlide);
    };

    useEffect(() => {
        if (isActive) setTitleAlign('left');
    }, [isActive]);

    return (
        <SliderBlock
            onClick={onClickToSlide}
            type="button"
            activeSlide={activeSlide}
            numberSlide={numberSlide}
            isActive={isActive}
        >
            <Decoration />
            <SlideTitle
                style={{ textAlign: titleAlign }}
                onTransitionEnd={() => {
                    if (!isActive) setTitleAlign('center');
                    if (isActive) setTitleAlign('left');
                }}
            >
                {slide.title}
            </SlideTitle>
            <div style={{ overflow: 'hidden' }}>
                <ParagraphsWrapper>
                    {slide.paragraphs.map((paragraph) => (
                        <ParagraphSlide key={paragraph}>{paragraph}</ParagraphSlide>
                    ))}
                </ParagraphsWrapper>
            </div>
        </SliderBlock>
    );
};

export default DesktopSlide;
