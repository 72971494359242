import React from 'react';
import SuccessIcon from '@assets/icons/checkCircle.svg';
import ProcessingIcon from '@assets/icons/cancel.svg';
import ErrorIcon from '@assets/icons/error.svg';
import colors from '@theme/colors';
import { logout, setError } from '@store/Auth';
import { AxiosResponse } from 'axios';
import { formatValue } from 'react-currency-input-field';
import dayjs from 'dayjs';
import {
    ISO_SHORT_DATE_FORMAT,
    labelTroublesInternetConnection,
    labelTroubleXHR,
    networkErrorResponse,
} from '@utils/constants';
import { ORDER_ITEM_FIELDS, TOrderItem } from '@store/Order/types';
import { IClient } from '@store/Client/types';
import { IClientCounterparty } from '@store/ClientCounterparty/types';
import { RefundOrderStatuses } from '@store/OrderRefunds/types';
import { IStory } from '@pages/Dashboard/components/Stories/types';
import { map } from 'lodash';
import flattenDataAndAttributes from '@utils/helpers/flattenDataAndAttributes';
import { ICommonResponse } from './http';

export async function thunkHandler<T>(
    asyncFn: Promise<AxiosResponse<ICommonResponse<T>>>,
    thunkAPI: any,
    returnMetaData = false,
) {
    try {
        const response = await asyncFn;
        return returnMetaData ? response.data : response.data.data;
    } catch (error) {
        const isNetworkError = error.toString() === networkErrorResponse;
        if (isNetworkError) {
            thunkAPI.dispatch(setError({ title: labelTroublesInternetConnection, isActive: true }));
        }
        const isSessionExpired = error.response.data.error.code === 'unauthorized';
        if (isSessionExpired) {
            thunkAPI.dispatch(logout());
        }
        thunkAPI.dispatch(setError({ title: labelTroubleXHR, isActive: true }));
        return thunkAPI.rejectWithValue(error.response.data);
    }
}

export const toValidCurrencyFloat = (val: number) => Math.round(val * 100) / 100;
export const extractVat = (amount: number, vatRate: number) => (amount * vatRate * 100) / (100 + vatRate * 100);

export const formatCurrencyToShow = (currency: number) => {
    const formattedValue = formatValue({
        value: String(currency),
        groupSeparator: ' ',
        decimalSeparator: ',',
    });

    return `${formattedValue} ₽`;
};

export const getRequestUrlOrNull = (url: string, condition: boolean) => (condition ? url : null);

export const convertCurrencyToPointNumber = (currency: string): number => Number(currency.split(',').join('.'));

export const addMinutesToDate = (date: Date, minutes: number): Date => new Date(date.getTime() + minutes * 60000);

export const getTwoWordsFromName = (name: string): string => name.split(' ').slice(0, 2).join(' ');

export const convertDateAndTimeToShow = (date: string) => dayjs(date).format(ISO_SHORT_DATE_FORMAT);

export const getTotalAmountAndTotalVat = (itemsOrder: TOrderItem[]) => {
    if (itemsOrder && itemsOrder.length > 0) {
        const { totalAmount, totalVat } = itemsOrder.reduce(
            (sum, current) => ({
                totalAmount: sum.totalAmount + current[ORDER_ITEM_FIELDS.totalAmount],
                totalVat: sum.totalVat + current[ORDER_ITEM_FIELDS.totalVatAmount],
            }),
            {
                totalAmount: 0,
                totalVat: 0,
            },
        );
        return { totalAmount, totalVat };
    }
    return { totalAmount: 0, totalVat: 0 };
};

export const copeOrShareLink = async (linkURL: string, isMobile: boolean, openToast: () => void) => {
    if (isMobile && navigator.share) {
        await navigator.share({ url: linkURL });
    } else if (navigator.clipboard?.writeText) {
        await navigator.clipboard.writeText(linkURL);
        openToast();
    } else {
        window.open(linkURL);
    }
};

export const getFirstAndLastDaysMonthCurrentDay = (date: Date) => {
    const firstDayOfSelectedMonth = new Date(date.getFullYear(), date.getMonth(), 1);
    const lastDayOfSelectedMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    return {
        firstDayOfSelectedMonth,
        lastDayOfSelectedMonth,
    };
};

export const getSortedClients = (clientsArray: IClient[] | IClientCounterparty[]) =>
    clientsArray.sort((firstItemList, secondItemList) => firstItemList.name.localeCompare(secondItemList.name));

export const downloadFile = async (fileUrl: string) => {
    const a = document.createElement('a');
    a.download = 'my-file.txt';
    a.href = URL.createObjectURL(fileUrl);
    a.addEventListener('click', () => {
        setTimeout(() => URL.revokeObjectURL(a.href), 30 * 1000);
    });
    a.click();
};

export const getRefundIconByStatusAndColor = (status: string | undefined, colorsTheme: any) => {
    if (status === RefundOrderStatuses.processing) return { icon: <ProcessingIcon />, color: colorsTheme.yellow() };
    if (status === RefundOrderStatuses.error) return { icon: <ErrorIcon />, color: colorsTheme.red() };
    if (status === RefundOrderStatuses.completed) return { icon: <SuccessIcon />, color: colorsTheme.green() };
    return { icon: null, color: colors.base() };
};

export const getNoun = (number: number, one: string, two: string, five: string) => {
    let n = Math.abs(number);

    n %= 100;
    if (n >= 5 && n <= 20) return five;

    n %= 10;
    if (n === 1) return one;
    if (n >= 2 && n <= 4) return two;

    return five;
};

export const isEqualObjects = (object1: any, object2: any) => {
    const props1 = Object.getOwnPropertyNames(object1);
    const props2 = Object.getOwnPropertyNames(object2);

    if (props1.length !== props2.length) {
        return false;
    }

    for (let i = 0; i < props1.length; i += 1) {
        const prop = props1[i];
        const bothAreObjects = typeof object1[prop] === 'object' && typeof object2[prop] === 'object';

        if (
            (!bothAreObjects && object1[prop] !== object2[prop]) ||
            (bothAreObjects && !isEqualObjects(object1[prop], object2[prop]))
        ) {
            return false;
        }
    }

    return true;
};

export const pipe = (arg: any, ...fns: Array<Function>) => fns.reduce((prev, fn) => fn(prev), arg);

export const calculateOrderItemValues = (amount: number, quantity: number, vatCode: number) => {
    const vatAmount = extractVat(amount, vatCode) || 0; // размер НДС с единицы товара
    const totalAmount = toValidCurrencyFloat(amount * quantity); // количество товара * стоимость одной позиции
    const amountWoVat = toValidCurrencyFloat(amount - vatAmount); // стоимость товара без НДС
    const totalAmountWoWat = amountWoVat * quantity; // сумма позиций без НДС - общая  стоимость без ндс * количество товара
    const totalVatAmount = toValidCurrencyFloat(extractVat(totalAmount, vatCode)); // суммарный НДС за все товары
    return {
        totalAmount,
        totalAmountWoWat,
        totalVatAmount,
        amountWoVat,
    };
};

export const createStoriesArray = (response: { data: any[] } | undefined): IStory[] =>
    // @ts-ignore
    (map(response?.data, (story) => flattenDataAndAttributes(story)) || []).map(
        ({
            preview: {
                web: { url },
            },
            slides,
            ...rest
        }) => ({
            ...rest,
            preview: url,
            // @ts-ignore
            // eslint-disable-next-line no-shadow
            slides: slides.map(({ background: { url }, ...slide }) => ({ ...slide, background: url })),
        }),
    );
