// eslint-disable-next-line no-shadow
export enum MERCHANT_FIELDS {
    id = 'id',
    name = 'name',
    description = 'description',
    email = 'email',
    phone = 'phone',
    website = 'website',
    address = 'address',
    counterpartyId = 'counterpartyId',
    systemCounterpartyId = 'systemCounterpartyId',
    bankDetailId = 'bankDetailId',
    mccId = 'mccId',
    timezoneId = 'timezoneId',
    testMode = 'testMode',
    shipmentRequired = 'shipmentRequired',
    status = 'status',
    active = 'active',
    notUniqueOrderIdAllowed = 'notUniqueOrderIdAllowed',
    needSendEmptyReport = 'needSendEmptyReport',
    languageId = 'languageId',
    merchantContractId = 'merchantContractId',
    code = 'code',
    hours = 'hours',
    lat = 'lat',
    lon = 'lon',
    distance = 'distance',
    public = 'public',
    type = 'type',
    logoImageId = 'logoImageId',
}
