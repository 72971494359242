import styled from 'styled-components';

export const PaginationWrapper = styled.div`
    width: 12px;
    height: 12px;
`;

export const Pagination = styled.div`
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: ${({ theme }) => theme.colors.base()};
    margin: 0 auto;
`;

export const MobileSliderWrapper = styled.div`
    margin-bottom: 40px;
    .slick-dots {
        bottom: 18px;
    }

    @media ${({ theme }) => theme.breakpoints.sm} {
        margin-bottom: 30px;

        .slick-dots {
            bottom: 28px;
        }
    }

    .slick-active {
        ${Pagination} {
            width: 12px;
            height: 12px;
            background: ${({ theme }) => theme.colors.yellow()};
            margin: 0 auto;
            position: relative;
            bottom: 3px;
        }
    }
`;
