import React, { ChangeEvent, FC, useContext, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import Button from '@components/Button';
import InputCode from '@components/InputCode';
import { useAppDispatch } from '@store';
import { getExpiredAt, getIsAuthLoading, getLeftAttempts, getVerificationCodeValid } from '@store/Auth/selectors';
import { verificationByCode, verificationByPhone } from '@store/Auth';
import { secondsToTimeFormatter, removePlusAndGapsToPhone } from '@utils/formatters';
import { useMobile } from '@hooks/useMedia';
import ArrowLeftIcon from '@assets/icons/arrow-left.svg';
import { getTimeDiff } from '@utils/time';
import { Nullable } from '@utils/utilityTypes';
import { useIntl } from 'react-intl';
import { LoginContext } from '../context';
import { AuthButtonsContainer, AuthDescription, AuthInner, AuthTitle, FormWrapper, TimeoutText } from '../styles';
import { useReCaptchaTokenContext } from '../../../context/ReCaptchaTokenContext';

const CODE_LENGTH = 6;
const COUNTDOWN_INTERVAL = 120; // 2min.

const EnterCodeForm: FC = () => {
    const intl = useIntl();
    const { toPrevStep, phone } = useContext(LoginContext);

    const newCodeIntervalId = useRef<Nullable<number>>(null);
    const expiredIntervalId = useRef<Nullable<number>>(null);

    const isMobile = useMobile();
    const [timer, setTimer] = useState(COUNTDOWN_INTERVAL);
    const [code, setCode] = useState('');
    const [isExpired, setIsExpired] = useState(false);

    const { token, onRefreshReCaptcha } = useReCaptchaTokenContext();
    const dispatch = useAppDispatch();
    const isLoading = useSelector(getIsAuthLoading);
    const codeIsValid = useSelector(getVerificationCodeValid);
    const leftAttempts = useSelector(getLeftAttempts) as number;
    const expiredAt = useSelector(getExpiredAt);

    const inputPlaceholder = '0'.repeat(CODE_LENGTH);

    const clearNewCodeInterval = () => {
        if (newCodeIntervalId.current) {
            window.clearInterval(newCodeIntervalId.current);
        }
    };

    const clearExpiredTimer = () => {
        if (expiredIntervalId.current) {
            window.clearInterval(expiredIntervalId.current);
        }
    };

    const codeChangeHandler = async ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
        setCode(value);

        if (value.length >= CODE_LENGTH && token) {
            const result = await dispatch(verificationByCode({ code: value, token }));
            onRefreshReCaptcha();
            // @ts-ignore
            if (result?.error) {
                setCode('');
            }
        }
    };

    const getNewCode = async () => {
        setCode('');
        setTimer(COUNTDOWN_INTERVAL);
        startNewCodeTimer();
        setIsExpired(false);
        if (token) {
            await dispatch(verificationByPhone({ phone: removePlusAndGapsToPhone(phone), token }));
            onRefreshReCaptcha();
        }
    };

    const startNewCodeTimer = () => {
        newCodeIntervalId.current = window.setInterval(() => {
            setTimer((time) => time - 1);
        }, 1000);

        return () => clearNewCodeInterval();
    };

    const startExpiredTimer = () => {
        if (expiredAt && !isExpired) {
            expiredIntervalId.current = window.setInterval(() => {
                if (getTimeDiff(new Date(expiredAt), new Date()) <= 0) {
                    setIsExpired(true);
                }
            }, 1000);
            return () => clearExpiredTimer();
        }
    };

    useEffect(startNewCodeTimer, []);
    useEffect(startExpiredTimer, [isExpired, expiredAt]);

    useEffect(() => {
        if (timer === 0) clearNewCodeInterval();
    }, [timer]);

    return (
        <AuthInner>
            <AuthTitle>{intl.formatMessage({ defaultMessage: 'Верификация' })}</AuthTitle>
            <AuthDescription>
                {intl.formatMessage({ defaultMessage: 'Пожалуйста, введите код с СМС сообщения отправленный на' })}{' '}
                {phone}
            </AuthDescription>
            {timer !== 0 && (
                <FormWrapper>
                    <InputCode
                        value={code}
                        placeholder={inputPlaceholder}
                        autoFocus
                        name="code"
                        onChange={codeChangeHandler}
                        disabled={isLoading}
                        hasError={!codeIsValid}
                        leftAttempts={leftAttempts}
                        inputMode="numeric"
                        timeIsUp={isExpired}
                    />
                    {timer > 0 && (
                        <TimeoutText>
                            {intl.formatMessage({ defaultMessage: 'Новый код через:' })} {secondsToTimeFormatter(timer)}
                        </TimeoutText>
                    )}
                </FormWrapper>
            )}
            <AuthButtonsContainer>
                <Button type="secondary" icon={isMobile && <ArrowLeftIcon />} onClick={toPrevStep}>
                    {!isMobile && intl.formatMessage({ defaultMessage: 'Назад' })}
                </Button>
                {!timer && <Button onClick={getNewCode}>{intl.formatMessage({ defaultMessage: 'Новый код' })}</Button>}
            </AuthButtonsContainer>
        </AuthInner>
    );
};

export default EnterCodeForm;
