import React, { FC } from 'react';
import {
    ColumnFieldWrapper,
    Label,
    SkeletonValue,
    Value,
} from '@pages/Shop/ShopTabsTables/RefundDetail/components/ColumnField/styles';

interface IColumnField {
    label: string;
    value?: string | null;
    className?: string;
}

const ColumnField: FC<IColumnField> = ({ className, label, value }) => {
    if (value || value === null) {
        return (
            <ColumnFieldWrapper className={className}>
                <Label>{label}</Label>
                <Value>{value === null ? <SkeletonValue /> : value}</Value>
            </ColumnFieldWrapper>
        );
    }
    return null;
};

export default ColumnField;
