import React, { FC } from 'react';
import logoImage from '@assets/icons/logoBigQR.png';
import colors from '@theme/colors';
import { copeOrShareLink } from '@utils/helpers';
import { useMobile } from '@hooks/useMedia';
import { QRCode } from 'react-qrcode-logo';
import useToast from '@hooks/useToast';
import Toast from '@components/Toast';
import Skeleton from '@components/Skeleton';
import { useIntl } from 'react-intl';
import { CopyLink, CopyLinkWrapper, QRSubtitle, QRTitle, SkeletonLink, WrapperQRBlock, WrapperQRCode } from './styles';

interface IQRCode {
    paymentURL: string;
}

const QRCodeBlock: FC<IQRCode> = ({ paymentURL }) => {
    const intl = useIntl();
    const { open: openToast, isShow: isShowToast, setIsShow: setIsShowToast } = useToast();

    const isMobile = useMobile();

    return (
        <WrapperQRBlock>
            <QRTitle>{intl.formatMessage({ defaultMessage: 'QR код для оплаты' })}</QRTitle>
            <QRSubtitle>
                {intl.formatMessage({ defaultMessage: 'Сгенерированный системой QR код которым вы можете поделиться' })}
            </QRSubtitle>
            <WrapperQRCode>
                {paymentURL ? (
                    <QRCode
                        value={paymentURL}
                        eyeRadius={6}
                        size={190}
                        quietZone={8}
                        logoImage={logoImage}
                        logoWidth={45}
                        bgColor={colors.lightGrey()}
                    />
                ) : (
                    <Skeleton borderRadius="base" />
                )}
            </WrapperQRCode>
            <CopyLinkWrapper>
                {paymentURL ? (
                    <CopyLink
                        type="button"
                        onClick={() => {
                            copeOrShareLink(paymentURL, isMobile, openToast);
                        }}
                    >
                        {intl.formatMessage({ defaultMessage: 'Скопировать ссылку на оплату' })}
                    </CopyLink>
                ) : (
                    <SkeletonLink />
                )}
            </CopyLinkWrapper>
            <Toast
                onClick={() => {}}
                isShowToast={isShowToast}
                setIsShowToast={setIsShowToast}
                title={intl.formatMessage({ defaultMessage: 'Скопировано в буфер обмена' })}
            />
        </WrapperQRBlock>
    );
};

export default QRCodeBlock;
