import React, { FC, ReactNode, useEffect, useState } from 'react';
import CircleIcon from '@components/CircleIcon';
import Tooltip from '@components/Tooltip';
import { useLocation } from 'react-router-dom';
import { Routes } from '@pages/constants';
import AddIcon from '@assets/icons/plus.svg';
import useModal from '@hooks/useModal';
import MerchantModalInner from '@pages/Introduce/components/MerchantModalInner';
import { AddingMerchantContext } from '@components/ProductsAccordion/context';
import { useIntl } from 'react-intl';
import {
    ArrowWrapper,
    ButtonAddMerchant,
    ClickableItem,
    CollapseBody,
    CollapseBodyItem,
    CollapseBodyItemWrapper,
    CollapseItemWrapper,
    CollapseTitle,
    Label,
    ModalMerchantCreate,
    Title,
} from '../styles';

import ErrorIcon from '../assets/error-icon.svg';
import ArrowIcon from '../assets/arrow-down.svg';

export interface ICollapseBodyItem {
    title: string;
    label?: string;
    id: string;
    activeId?: string;
}

export interface ICollapseItemProps {
    title: string;
    icon: ReactNode;
    items: Array<ICollapseBodyItem>;
    errorTooltipContent?: ReactNode;
    renderItem?: (props: ICollapseBodyItem) => ReactNode;
    activeId?: string;
    isActive?: boolean;
}

const COLLAPSE_BODY_ITEM_HEIGHT = 76;

const CollapseItem: FC<ICollapseItemProps> = ({
    isActive,
    activeId,
    icon,
    title,
    items,
    errorTooltipContent,
    renderItem,
}) => {
    const intl = useIntl();
    const { isOpen: isOpenModal, open: openModal, close: onCloseModal } = useModal(false);
    const [isOpen, setIsOpen] = useState(false);
    const location = useLocation();
    const isCurrentShopPath = location.pathname.includes(Routes.shop);
    const collapseBodyHeight = isOpen ? (items.length + 1) * COLLAPSE_BODY_ITEM_HEIGHT : 0;

    const toggle = () => setIsOpen((v) => !v);

    useEffect(() => {
        if (!isCurrentShopPath) {
            setIsOpen(false);
        }
    }, [isCurrentShopPath]);

    return (
        <CollapseItemWrapper isOpen={isOpen}>
            <ClickableItem onClick={errorTooltipContent ? () => {} : toggle} isActive={isActive}>
                <CircleIcon icon={icon} isActive={isActive} />
                <Title>{title}</Title>
                {errorTooltipContent ? (
                    <Tooltip content={errorTooltipContent} interactive placement="right">
                        <ErrorIcon />
                    </Tooltip>
                ) : (
                    <ArrowWrapper isOpen={isOpen}>
                        <ArrowIcon />
                    </ArrowWrapper>
                )}
            </ClickableItem>

            <CollapseBody itemHeight={collapseBodyHeight}>
                {items.map((item) => (
                    <CollapseBodyItemWrapper itemHeight={COLLAPSE_BODY_ITEM_HEIGHT} key={item.id}>
                        {renderItem ? (
                            renderItem({ title: item.title, label: item.label, id: item.id, activeId })
                        ) : (
                            <CollapseBodyItem>
                                <CollapseTitle>{item.title}</CollapseTitle>
                                {item.label && <Label>{item.label}</Label>}
                            </CollapseBodyItem>
                        )}
                    </CollapseBodyItemWrapper>
                ))}
                <CollapseBodyItemWrapper itemHeight={COLLAPSE_BODY_ITEM_HEIGHT} key="buttonAddMerchant">
                    <ButtonAddMerchant type="button" onClick={openModal}>
                        <AddIcon />
                        {intl.formatMessage({ defaultMessage: 'Добавить новый магазин' })}
                    </ButtonAddMerchant>
                </CollapseBodyItemWrapper>
            </CollapseBody>
            <ModalMerchantCreate
                title={intl.formatMessage({ defaultMessage: 'Добавить новый магазин' })}
                isOpen={isOpenModal}
                closeModal={onCloseModal}
            >
                <AddingMerchantContext.Provider
                    value={{
                        closeModal: onCloseModal,
                    }}
                >
                    <MerchantModalInner />
                </AddingMerchantContext.Provider>
            </ModalMerchantCreate>
        </CollapseItemWrapper>
    );
};

export default CollapseItem;
