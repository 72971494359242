import styled from 'styled-components';
import Skeleton from '@components/Skeleton';

export const SkeletonWrapper = styled.div`
    border-radius: ${({ theme }) => theme.decorations.borderRadius.base}px;
    border: 1px solid ${({ theme }) => theme.colors.lightGrey()};
    overflow: hidden;
`;

export const SkeletonOrderGroupHead = styled(Skeleton).attrs({ borderRadius: 'none' })`
    height: 30px;
`;
