import styled from 'styled-components';
import FormField from '@components/FormField';

export const FieldNameItem = styled(FormField)`
    grid-column-start: 1;
    grid-column-end: 7;
    @media ${({ theme }) => theme.breakpoints.sm} {
        grid-column-start: 1;
        grid-column-end: 5;
    }
`;

export const FieldTypeItem = styled(FormField)`
    grid-column-start: 1;
    grid-column-end: 7;
    @media ${({ theme }) => theme.breakpoints.sm} {
        grid-column-start: 1;
        grid-column-end: 5;
    }
`;

export const FieldArticleItem = styled(FormField)`
    grid-column-start: 1;
    grid-column-end: 4;
    @media ${({ theme }) => theme.breakpoints.sm} {
        grid-column-start: 1;
        grid-column-end: 3;
    }
`;

export const FieldAmountItem = styled(FormField)`
    grid-column-start: 4;
    grid-column-end: 7;
    @media ${({ theme }) => theme.breakpoints.sm} {
        grid-column-start: 3;
        grid-column-end: 5;
    }
`;

export const FieldQuantityItem = styled(FormField)`
    grid-column-start: 1;
    grid-column-end: 3;
    @media ${({ theme }) => theme.breakpoints.sm} {
        grid-column-start: 1;
        grid-column-end: 3;
    }
`;

export const FieldVatItem = styled(FormField)`
    grid-column-start: 3;
    grid-column-end: 5;
    @media ${({ theme }) => theme.breakpoints.sm} {
        grid-column-start: 3;
        grid-column-end: 5;
    }
`;

export const FieldMeasureItem = styled(FormField)`
    grid-column-start: 5;
    grid-column-end: 7;
    @media ${({ theme }) => theme.breakpoints.sm} {
        grid-column-start: 1;
        grid-column-end: 5;
    }
`;
