import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { getCurrentCounterpartyMerchants } from '@store/Merchant/selectors';
import InvoicingIcon from '@assets/icons/invoicing.svg';
import BillsIcon from '@assets/icons/bills.svg';
import ProductCard from '@components/ProductCard';
import useModal from '@hooks/useModal';
import { getSettlementBalances } from '@store/MerchantSettlementBalance/selectors';
import { getCounterpartiesIsPending, getCurrentCounterpartyId } from '@store/Counterparty/selectors';
import { useHistory } from 'react-router-dom';
import { Routes } from '@pages/constants';
import { useIntl } from 'react-intl';
import { AddingMerchantContext } from '@components/ProductsAccordion/context';
import MerchantModalInner from '@pages/Introduce/components/MerchantModalInner';
import { ModalMerchantCreate } from '@components/ProductsAccordion/styles';
import { IMerchantModalValue, MerchantsModal } from './components/MerchantsModal';
import { AccessToInvoicingModal } from './components/AccessToInvoicing';
import { ProductCardSkeleton, ProductsGrid } from './styles';

const Products: FC = () => {
    const intl = useIntl();
    const history = useHistory();
    const { isOpen: invoicingModalIsVisible, open: openModal, close: closeModal } = useModal();
    const {
        isOpen: isOpenModalCreatingMerchant,
        open: openModalCreatingMerchant,
        close: onCloseModalCreatingMerchant,
    } = useModal(false);

    const selectedCounterpartyId = useSelector(getCurrentCounterpartyId) || null;
    const merchants = useSelector(getCurrentCounterpartyMerchants);
    const balances = useSelector(getSettlementBalances);
    const counterpartiesIsPending = useSelector(getCounterpartiesIsPending);

    const merchantModalSubmit = ({ merchantId }: IMerchantModalValue) => {
        history.push(`${Routes.dashboard}${Routes.shop}/${merchantId}${Routes.overview}`);
    };

    const ProductCardWithLoading = counterpartiesIsPending ? ProductCardSkeleton : ProductCard;

    const goToRoute = (link: string) => {
        history.push(link);
    };

    return (
        <>
            <ProductsGrid>
                <ProductCardWithLoading
                    icon={<InvoicingIcon />}
                    hasWarning={!selectedCounterpartyId}
                    title={intl.formatMessage({ defaultMessage: 'Продажи' })}
                    onClick={openModal}
                />
                <ProductCardWithLoading
                    icon={<BillsIcon />}
                    title={intl.formatMessage({ defaultMessage: 'Входящие счета' })}
                    onClick={() => {
                        goToRoute(`${Routes.payment}/${selectedCounterpartyId || 'user'}`);
                    }}
                />
            </ProductsGrid>
            {merchants && balances && selectedCounterpartyId && merchants.length > 0 ? (
                <MerchantsModal
                    closeModal={closeModal}
                    isOpen={invoicingModalIsVisible}
                    onSubmit={merchantModalSubmit}
                    merchants={merchants}
                    balances={balances}
                />
            ) : (
                <AccessToInvoicingModal
                    onClickCreateMerchant={openModalCreatingMerchant}
                    isCounterparty={Boolean(selectedCounterpartyId)}
                    closeModal={closeModal}
                    isOpen={invoicingModalIsVisible}
                />
            )}
            <ModalMerchantCreate
                title={intl.formatMessage({ defaultMessage: 'Добавить новый магазин' })}
                isOpen={isOpenModalCreatingMerchant}
                closeModal={onCloseModalCreatingMerchant}
            >
                <AddingMerchantContext.Provider
                    value={{
                        closeModal: onCloseModalCreatingMerchant,
                    }}
                >
                    <MerchantModalInner />
                </AddingMerchantContext.Provider>
            </ModalMerchantCreate>
        </>
    );
};

export default Products;
