import styled from 'styled-components';
import FormField from '@components/FormField';

export const InputsWrapper = styled.div`
    display: grid;
    grid-template-columns: 42% 25% 25%;
    grid-column-gap: 30px;
    grid-row-gap: 20px;
    @media ${({ theme }) => theme.breakpoints.ld} {
        grid-template-columns: repeat(3, 1fr);
    }
    @media ${({ theme }) => theme.breakpoints.md} {
        grid-template-columns: 1fr;
        grid-row-gap: 10px;
    }
`;

export const BankDetailFormWrapper = styled.form`
    padding-top: 40px;
    @media ${({ theme }) => theme.breakpoints.md} {
        padding-top: 30px;
    }
`;

export const BankNameInput = styled(FormField)`
    grid-column-start: 1;
    grid-column-end: 4;
    @media ${({ theme }) => theme.breakpoints.md} {
        grid-column-end: auto;
    }
`;

export const SettlementAccountInput = styled(FormField)`
    grid-column-start: 1;
    grid-column-end: 2;
    @media ${({ theme }) => theme.breakpoints.md} {
        grid-column-end: auto;
    }
`;
