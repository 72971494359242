import styled from 'styled-components';
import Skeleton from '@components/Skeleton';

export const SkeletonClientWrapper = styled.div``;

export const AvatarSkeleton = styled(Skeleton).attrs({ circle: true })`
    width: 50px;
    height: 50px;
    margin: 0 auto 8px;
`;

export const SkeletonName = styled(Skeleton)`
    width: 70px;
    margin: 0 auto;
`;
