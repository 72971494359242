export default {
    sm: '24%',
    md: '48%',
    ld: '72%',
    xl: '100%',
};

// eslint-disable-next-line no-shadow
export enum SizeWidgetEnum {
    sm = 'sm',
    md = 'md',
    ld = 'ld',
    xl = 'xl',
}
