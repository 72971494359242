import styled from 'styled-components';
import Button from '@components/Button';

export const ReadOnlyWrapper = styled.div`
    padding: 40px 30px;
`;

export const ButtonCancelPayment = styled(Button)`
    margin: 50px auto 0;
`;
