import styled from 'styled-components';
import Typography from '@components/Typography';
import InputCurrency from '@components/InputCurrency';

export const ServiceFooterWrapper = styled.div`
    ${({ theme }) => theme.mixins.flexCenter};
    justify-content: space-between;
    margin-top: 10px;
`;

export const AmountField = styled(InputCurrency)`
    width: 166px;
    @media ${({ theme }) => theme.breakpoints.xxl} {
        width: 135px;
    }
`;

export const Total = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 7px;
`;

export const TotalLabel = styled(Typography).attrs({ variant: 'regular12' })`
    color: ${({ theme }) => theme.colors.grey()};
    white-space: nowrap;
`;

export const TotalValue = styled(Typography).attrs({ variant: 'regular14' })``;
