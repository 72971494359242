import yup from '@utils/validations';
import { COUNTERPARTY_FIELDS } from '@store/Counterparty/contants';
import { INDIVIDUAL_VAT_NUMBER_LENGTH } from '@utils/constants';

export const contactSchema = yup.object().shape({
    [COUNTERPARTY_FIELDS.phone]: yup.string().isValidPhone(),
    [COUNTERPARTY_FIELDS.ceoName]: yup.string().required(),
    [COUNTERPARTY_FIELDS.email]: yup.string().email(),
    [COUNTERPARTY_FIELDS.cfoName]: yup.string().required(),
    [COUNTERPARTY_FIELDS.website]: yup.string(),
});

export const generalSchema = yup.object().shape({
    [COUNTERPARTY_FIELDS.vatNumber]: yup.string().isValidInn().required(),
    [COUNTERPARTY_FIELDS.name]: yup.string().required(),
    [COUNTERPARTY_FIELDS.legalEntityTypeId]: yup.string().required(),
    [COUNTERPARTY_FIELDS.registrationAddress]: yup.string().required(),
    [COUNTERPARTY_FIELDS.registrationNumber]: yup.string().when(COUNTERPARTY_FIELDS.vatNumber, {
        is: (vat: string) => String(vat).length === INDIVIDUAL_VAT_NUMBER_LENGTH,
        then: yup.string().isValidOgrnip(),
        otherwise: yup.string().isValidOgrn(),
    }),
    [COUNTERPARTY_FIELDS.taxRegistrationReasonCode]: yup.string().isValidKpp(),
});
