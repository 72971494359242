import styled from 'styled-components';

export const DocumentsWrapper = styled.div`
    padding-top: 40px;
    @media ${({ theme }) => theme.breakpoints.md} {
        padding-top: 30px;
    }
`;

export const DocumentsButtons = styled.div`
    ${({ theme }) => theme.mixins.flexStart};
    flex-direction: column;
    grid-row-gap: 24px;
    @media ${({ theme }) => theme.breakpoints.md} {
        grid-row-gap: 20px;
    }
`;
