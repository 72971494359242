import React, { FC } from 'react';
import { BreadcrumbsWrapper } from './styles';
import Item, { IBreadcrumbItem } from './components/Item';

interface IBreadcrumbComposition {
    Item: FC<IBreadcrumbItem>;
}

interface IBreadcrumb {
    className?: string;
}

const Breadcrumb: FC<IBreadcrumb> & IBreadcrumbComposition = ({ className, children }) => (
    <BreadcrumbsWrapper className={className}>{children}</BreadcrumbsWrapper>
);

Breadcrumb.Item = Item;

export default Breadcrumb;
