import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { ModalStoriesWrapper } from '@pages/Dashboard/components/Stories/components/ModalStories/styles';
import { IStory } from '@pages/Dashboard/components/Stories/types';
import ProgressBarStories from '@pages/Dashboard/components/Stories/components/ProgressBarStories';
import { delaySwitchSlidesInStoriesInSec } from '@utils/constants';
import Story from '@pages/Dashboard/components/Stories/components/Story';

interface IModalStories {
    isOpen: boolean;
    onClose: () => void;
    storiesArray: IStory[];
    selectedStoryIndex: number;
    setSelectedStoryIndex: (indexStory: number) => void;
    imagesToLoad: { uri: string }[];
}

const ModalStories: FC<IModalStories> = ({
    storiesArray,
    isOpen,
    onClose,
    selectedStoryIndex,
    setSelectedStoryIndex,
    imagesToLoad,
}) => {
    const currentStory: IStory = useMemo(
        () => storiesArray.find((story, index) => index === selectedStoryIndex) as any,
        [selectedStoryIndex, storiesArray],
    );
    const [indexSlide, setIndexSlide] = useState(0);
    const currentSlide = useMemo(() => currentStory?.slides[indexSlide], [currentStory?.slides, indexSlide]);

    // for switch slide toTimer
    const [timer, setTimer] = useState(delaySwitchSlidesInStoriesInSec);
    const [isPaused, setIsPaused] = useState(false);

    useEffect(() => {
        imagesToLoad.forEach((imageObj) => {
            const img = new Image();
            img.src = imageObj.uri;
        });
    }, [imagesToLoad]);

    useEffect(() => {
        setIndexSlide(0);
    }, [currentStory]);

    const countSlidesInStory = storiesArray.length;
    const isCanSwitchNextStory = countSlidesInStory > selectedStoryIndex + 1;
    const isCanSwitchPrevStory = selectedStoryIndex !== 0;
    const isCanSwitchNextSlide = currentStory && currentStory?.slides.length > indexSlide + 1;
    const isCanSwitchNextSlideOrNextStories = isCanSwitchNextSlide || isCanSwitchNextStory;
    const isCanSwitchToPrevSlide = currentStory && indexSlide !== 0;
    const isCanSwitchToPrevSlideOrPrevStories = isCanSwitchToPrevSlide || isCanSwitchPrevStory;

    const goToNextSlideOrStory = useCallback(() => {
        if (isCanSwitchNextSlide) {
            setIndexSlide(indexSlide + 1);
            setTimer(delaySwitchSlidesInStoriesInSec);
        } else if (isCanSwitchNextStory) {
            setSelectedStoryIndex(selectedStoryIndex + 1);
            setTimer(delaySwitchSlidesInStoriesInSec);
        } else {
            onClose();
        }
    }, [indexSlide, isCanSwitchNextSlide, isCanSwitchNextStory, onClose, selectedStoryIndex, setSelectedStoryIndex]);

    const goToPrevSlideOrStory = useCallback(() => {
        if (isCanSwitchToPrevSlide) {
            setIndexSlide(indexSlide - 1);
        } else if (isCanSwitchPrevStory) {
            setSelectedStoryIndex(selectedStoryIndex - 1);
        }
        setTimer(delaySwitchSlidesInStoriesInSec);
    }, [indexSlide, isCanSwitchPrevStory, isCanSwitchToPrevSlide, selectedStoryIndex, setSelectedStoryIndex]);

    useEffect(() => {
        const switcherSlidesInterval = setInterval(() => {
            if (!isPaused) {
                if (timer === 0) {
                    goToNextSlideOrStory();
                } else {
                    setTimer(timer - 1);
                }
            }
        }, 1000);

        return () => {
            clearInterval(switcherSlidesInterval);
        };
    }, [goToNextSlideOrStory, isPaused, timer]);

    return (
        <ModalStoriesWrapper
            onClickNextSlide={isCanSwitchNextSlideOrNextStories ? goToNextSlideOrStory : undefined}
            onClickPrevSlide={isCanSwitchToPrevSlideOrPrevStories ? goToPrevSlideOrStory : undefined}
            isOpen={isOpen}
            closeModal={onClose}
        >
            <ProgressBarStories isPaused={isPaused} currentIndex={indexSlide} countSlides={countSlidesInStory} />
            <Story
                goToPrevSlideOrStory={goToPrevSlideOrStory}
                goToNextSlideOrStory={goToNextSlideOrStory}
                currentSlide={currentSlide}
                isPaused={isPaused}
                setIsPaused={setIsPaused}
            />
        </ModalStoriesWrapper>
    );
};

export default ModalStories;
