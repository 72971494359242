// currency
const intlCurrencyFormatter = new Intl.NumberFormat('ru', {
    maximumFractionDigits: 2,
    style: 'currency',
    currency: 'RUB',
});

export const currencyFormatter = (value: number) => intlCurrencyFormatter.format(value);

// date
const intlDateFormatter = new Intl.DateTimeFormat('ru');
const intlDateFormatterWithTime = new Intl.DateTimeFormat('ru', {
    year: 'numeric',
    day: 'numeric',
    month: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
});

export const dateFormatter = (dateStr: string, withTime?: boolean) => {
    const formatter = withTime ? intlDateFormatterWithTime : intlDateFormatter;
    return formatter.format(new Date(dateStr)).replace(',', '');
};

// phone
export const phoneFormatter = (phone: string) => phone.replace(/[^+\d]/g, '');

export const removePlusFromPhone = (phone: string) => phone.replace('+', '');

export const removePlusAndGapsToPhone = (phone: string) => {
    if (phone) {
        const result = phone.split(' ').join('');
        if (result[0] === '+') {
            return result.slice(1);
        }
        return result;
    }
    return '';
};

// phone is 79992223311 converted to +7 999 222 33 11
export const convertPhoneToShow = (phone: string) =>
    `+${phone.substring(0, 1)} ${phone.substring(1, 4)} ${phone.substring(4, 7)} ${phone.substring(
        7,
        9,
    )} ${phone.substring(9, 11)}`;

// time

// 5 -> 05 | 50 -> 50
const timeValueFormatter = (timeValue: string | number) => `0${timeValue}`.slice(-2);

export const secondsToTimeFormatter = (seconds: number) => {
    const min = timeValueFormatter(Math.floor(seconds / 60));
    const sec = timeValueFormatter(seconds % 60);
    return `${min}:${sec}`;
};

export const nameToInitialsFormatter = (name: string) =>
    name
        .split(' ')
        .splice(0, 2)
        .map((word) => (word[0] ? word[0].toUpperCase() : ''))
        .join('');
