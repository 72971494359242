import styled from 'styled-components';
import Typography from '@components/Typography';

export const ButtonOrganization = styled.button`
    border: 1px solid ${({ theme }) => theme.colors.lightGrey()};
    border-top: none;
    &:first-child {
        border-top: 1px solid ${({ theme }) => theme.colors.lightGrey()};
    }
    padding: 20px 30px;
    width: 100%;
    display: grid;
    grid-template-columns: auto 120px;
    align-items: center;
    grid-column-gap: 20px;
    ${({ theme }) => theme.mixins.getTypography('regular14')}
    &:hover {
        background: ${({ theme }) => theme.colors.hoverGray()};
    }
    @media ${({ theme }) => theme.breakpoints.sm} {
        padding: 18px 20px;
    }
`;

export const Title = styled.div`
    ${({ theme }) => theme.mixins.flexCenter};
    grid-column-gap: 20px;
    @media ${({ theme }) => theme.breakpoints.sm} {
        grid-column-gap: 10px;
    }
`;

export const IconWrapper = styled.div`
    border-radius: 50%;
    background: ${({ theme }) => theme.colors.lightGrey()};
    width: 36px;
    height: 36px;
    ${({ theme }) => theme.mixins.flexCenterCenter};
    svg {
        width: 16px;
        height: 16px;
    }
    flex-shrink: 0;
    @media ${({ theme }) => theme.breakpoints.sm} {
        width: 30px;
        height: 30px;
        svg {
            width: 13px;
            height: 13px;
        }
    }
`;

export const Name = styled(Typography).attrs({ variant: 'regular14' })``;

export const VatNumber = styled(Typography).attrs({ variant: 'regular14' })`
    text-align: end;
    @media ${({ theme }) => theme.breakpoints.sm} {
        ${({ theme }) => theme.mixins.getTypography('regular12')};
    }
`;
