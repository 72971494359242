import { createEntityAdapter } from '@reduxjs/toolkit';
import { createIntl, createIntlCache } from 'react-intl';
import { IRefundOrder, RefundOrderStatuses } from './types';
import ruMessages from '../../i18/ru';

const cache = createIntlCache();
const intl = createIntl({ locale: 'ru', messages: ruMessages }, cache);

export const orderRefundsAdapter = createEntityAdapter<IRefundOrder>();
export const initialState = orderRefundsAdapter.getInitialState<{
    isLoading: boolean;
    orderIds: string[];
    metaData: {
        totalCount: number;
        pageSize: number;
        page: number;
    };
}>({
    isLoading: false,
    orderIds: [],
    metaData: {
        totalCount: 0,
        pageSize: 0,
        page: 0,
    },
});

export const REFUND_STATUSES = [
    {
        [RefundOrderStatuses.created]: intl.formatMessage({ defaultMessage: 'Создан' }),
        label: intl.formatMessage({ defaultMessage: 'Создан' }),
        state: 'created',
    },
    {
        [RefundOrderStatuses.error]: intl.formatMessage({ defaultMessage: 'Ошибка' }),
        label: intl.formatMessage({ defaultMessage: 'Ошибка' }),
        state: 'error',
    },
    {
        [RefundOrderStatuses.processing]: intl.formatMessage({ defaultMessage: 'Обрабатывается' }),
        label: intl.formatMessage({ defaultMessage: 'Обрабатывается' }),
        state: 'processing',
    },
    {
        [RefundOrderStatuses.completed]: intl.formatMessage({ defaultMessage: 'Выполнен' }),
        label: intl.formatMessage({ defaultMessage: 'Выполнен' }),
        state: 'completed',
    },
];
