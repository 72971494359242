import React, { FC } from 'react';
import { IClient } from '@store/Client/types';
import {
    ContactTitle,
    Email,
    IconWrapper,
    RowContactWrapper,
} from '@pages/Shop/ShopTabsTables/CreateNewClient/components/RowContactModal/styles';
import IconProfile from '@assets/icons/profile.svg';
import { CLIENT_FIELDS } from '@store/Client/contants';

interface IRowContactModal {
    contactPerson: IClient;
    selectContactPerson: (contactPerson: IClient) => void;
}

const RowContactModal: FC<IRowContactModal> = ({ contactPerson, selectContactPerson }) => (
    <RowContactWrapper
        type="button"
        onClick={() => {
            selectContactPerson(contactPerson);
        }}
    >
        <ContactTitle>
            <IconWrapper>
                <IconProfile />
            </IconWrapper>
            {contactPerson[CLIENT_FIELDS.name]}
        </ContactTitle>
        <Email>{contactPerson[CLIENT_FIELDS.email]}</Email>
    </RowContactWrapper>
);

export default RowContactModal;
