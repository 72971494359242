import React, { FC } from 'react';
import {
    ButtonRowWrapper,
    IconWrapper,
    NotificationContent,
    NotificationDate,
    NotificationTitle,
} from '@components/Header/components/NotificationRow/styles';
import IconChat from '@assets/icons/chat.svg';
import { useHistory } from 'react-router-dom';
import useModal from '@hooks/useModal';
import ModalFeed from '@components/Widgets/FeedWidget/components/ModalFeed';
import { typesFeedOpenOrderContainer } from '@store/Feed/constants';
import { Routes } from '@pages/constants';
import { IFeedItem } from '@store/Feed/types';
import dayjs from 'dayjs';
import { HH_MM_FORMAT } from '@utils/constants';

interface INotificationRow {
    feed: IFeedItem;
    hideWindowNotification: () => void;
}

const NotificationRow: FC<INotificationRow> = ({ hideWindowNotification, feed }) => {
    const history = useHistory();
    const { isOpen: isOpenModal, open: openModal, close: onCloseModal } = useModal(false);

    const onClickNotificationRow = () => {
        if (typesFeedOpenOrderContainer.find((type) => type === feed.type) && feed?.metadata?.targetId) {
            history.push(`${Routes.ordercontainer}/${feed.metadata.targetId}`, { isOrderContainer: true });
        } else {
            openModal();
        }
        hideWindowNotification();
    };

    return (
        <ButtonRowWrapper type="button" onClick={onClickNotificationRow}>
            <IconWrapper>
                <IconChat />
            </IconWrapper>
            <NotificationContent>
                <NotificationTitle>{feed.title}</NotificationTitle>
                <NotificationDate>{dayjs(feed.createdAt).format(HH_MM_FORMAT)}</NotificationDate>
            </NotificationContent>
            <ModalFeed onClose={onCloseModal} isOpen={isOpenModal} feed={feed} />
        </ButtonRowWrapper>
    );
};

export default NotificationRow;
