import styled from 'styled-components';
import Typography from '@components/Typography';

export const LabelWrapper = styled.div``;

export const LabelField = styled(Typography).attrs({ variant: 'regular12' })`
    color: ${({ theme }) => theme.colors.grey()};
`;

export const ValueField = styled(Typography).attrs({ variant: 'regular14' })`
    margin-top: 7px;
`;
