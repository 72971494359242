import React from 'react';
import { UnprocessableOrderStatuses } from '@store/Order/types';
import SuccessIcon from '@assets/icons/checkCircle.svg';
import CanceledIcon from '@assets/icons/cancel.svg';
import ExpiredIcon from '@assets/icons/error.svg';

import colors from '@theme/colors';

export const getIconByStatusAndColor = (status: string | undefined, colorsTheme: any) => {
    if (status === UnprocessableOrderStatuses.canceled) return { icon: <CanceledIcon />, color: colorsTheme.yellow() };
    if (status === UnprocessableOrderStatuses.expired) return { icon: <ExpiredIcon />, color: colorsTheme.red() };
    if (status === UnprocessableOrderStatuses.completed) return { icon: <SuccessIcon />, color: colorsTheme.green() };
    return { icon: null, color: colors.base() };
};
