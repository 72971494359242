import styled from 'styled-components';
import Modal from '@components/Modal';
import FormField from '@components/FormField';
import Button from '@components/Button';

export const ModalWrapper = styled(Modal)`
    padding-top: 45px;
    width: 434px;
    @media ${({ theme }) => theme.breakpoints.ld} {
        padding: 27px 20px 40px 20px;
    }
    @media ${({ theme }) => theme.breakpoints.md} {
        width: 100%;
    }
`;

export const FormWrapper = styled.form``;

export const InputsWrapper = styled.div`
    display: grid;
    grid-template-columns: 36% 64%;
    grid-column-gap: 20px;
    grid-row-gap: 20px;

    @media ${({ theme }) => theme.breakpoints.md} {
        grid-template-columns: 1fr;
        grid-row-gap: 10px;
    }
`;

export const BankNameInput = styled(FormField)`
    grid-column-start: 1;
    grid-column-end: 3;
    @media ${({ theme }) => theme.breakpoints.md} {
        grid-column-end: auto;
    }
`;

export const CorrespondentAccountInput = styled(FormField)`
    grid-column-start: 2;
    grid-column-end: 3;
    @media ${({ theme }) => theme.breakpoints.md} {
        grid-column-start: auto;
        grid-column-end: auto;
    }
`;

export const SettlementAccountInput = styled(FormField)`
    grid-column-start: 1;
    grid-column-end: 3;
    @media ${({ theme }) => theme.breakpoints.md} {
        grid-column-end: auto;
    }
`;

export const ButtonAdd = styled(Button)`
    margin: 50px auto 0;
    @media ${({ theme }) => theme.breakpoints.md} {
        margin-top: 40px;
    }
`;
