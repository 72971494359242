import React from 'react';
import { AvatarSkeleton, SkeletonClientWrapper, SkeletonName } from './styles';

const SkeletonLastClient = () => (
    <SkeletonClientWrapper>
        <AvatarSkeleton />
        <SkeletonName />
    </SkeletonClientWrapper>
);

export default SkeletonLastClient;
