import styled from 'styled-components';
import Typography from '@components/Typography';
import leftIcon from '@assets/icons/circleLeftSolid.svg';
import Avatar from '@components/Avatar';

export const Container = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 24px;
    margin-bottom: 12px;

    .slick-track {
        margin-left: 0;
    }
`;

export const WrapTitle = styled.div`
    ${({ theme }) => theme.mixins.flexCenter}
`;

export const Heading = styled.div`
    width: 100%;
    position: relative;
    ${({ theme }) => theme.mixins.flexCenter}
    justify-content: space-between;
`;

export const ButtonsNextPrev = styled.div`
    ${({ theme }) => theme.mixins.flexCenter};
    grid-column-gap: 18px;
`;

export const WrapAvatar = styled.div`
    width: auto;
    max-width: 150px;
`;

export const ContainerClients = styled.div`
    overflow: hidden;
`;

export const ClientName = styled(Typography).attrs({ variant: 'regular14' })`
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const Title = styled(Typography).attrs({ variant: 'title' })`
    line-height: ${({ theme }) => theme.typography.bigTitle.fontSize}px;

    @media ${({ theme }) => theme.breakpoints.ld} {
        font-size: ${({ theme }) => theme.typography.subheading.fontSize}px;
    }

    @media ${({ theme }) => theme.breakpoints.sm} {
        margin: 5px 5px 0;
    }
`;

export const LeftIcon = styled(leftIcon)`
    width: 20px;
    height: 20px;
    padding: 0;
    border-radius: 50%;
    path {
        fill: ${({ theme }) => theme.colors.grey()};
    }
`;

export const RightIcon = styled(leftIcon)`
    transform: rotate(180deg);
    width: 20px;
    height: 20px;
    padding: 0;
    border-radius: 50%;
    path {
        fill: ${({ theme }) => theme.colors.grey()};
    }
`;

export const AvatarClient = styled(Avatar)`
    margin: 0 auto 8px;
`;
