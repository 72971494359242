import React from 'react';
import { SkeletonListWrapper } from '@components/Widgets/OrderPaymentWidget/components/SkeletonPaymentList/styles';
import SkeletonPaymentItem from '@components/Widgets/OrderPaymentWidget/components/SkeletonPaymentItem';

const SkeletonPaymentList = () => {
    const skeletonItems = 4;
    const arrSkeletonItems = new Array(skeletonItems).fill(SkeletonPaymentItem);
    return (
        <SkeletonListWrapper>
            {arrSkeletonItems.map((SkeletonItem, index) => (
                <SkeletonItem
                    /* eslint-disable-next-line react/no-array-index-key */
                    key={index}
                />
            ))}
        </SkeletonListWrapper>
    );
};

export default SkeletonPaymentList;
