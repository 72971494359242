import styled from 'styled-components';

export const Header = styled.div`
    background: ${({ theme }) => theme.colors.lightGrey()};
    padding: 8px 30px;
    ${({ theme }) => theme.mixins.getTypography('regular12')};
    color: ${({ theme }) => theme.colors.grey()};
    @media ${({ theme }) => theme.breakpoints.md} {
        padding: 7px 20px;
    }
`;
