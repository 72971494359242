import React, { forwardRef, InputHTMLAttributes } from 'react';
import styled, { DefaultTheme } from 'styled-components';
import { COLOR_BRIGHTNESS } from '@theme/utils';
import Typography from '@components/Typography';
import { COUNT_TRY_ENTER_CODE_PHONE } from '@store/Auth/constants';
import { useIntl } from 'react-intl';

interface IInputCodeProps extends InputHTMLAttributes<HTMLInputElement> {
    className?: string;
    placeholder?: string;
    disabled?: boolean;
    hasError?: boolean;
    leftAttempts: number;
    value: string;
    timeIsUp?: boolean;
}

interface InputCodePlaceholderSign {
    disabled: boolean;
    hasError: boolean;
    isActive: boolean;
}

const InputCode = forwardRef<HTMLInputElement, IInputCodeProps>(
    (
        {
            className,
            placeholder = '000000',
            disabled = false,
            hasError = false,
            leftAttempts,
            timeIsUp = false,
            value,
            ...inputProps
        },
        ref,
    ) => {
        const intl = useIntl();
        const isDisabled = disabled || leftAttempts < 1;

        const getCaptionText = () => {
            if (timeIsUp) {
                return intl.formatMessage({ defaultMessage: 'Время ожидания вышло, запросите новый код' });
            }
            if (leftAttempts < COUNT_TRY_ENTER_CODE_PHONE && leftAttempts > 0) {
                return `${intl.formatMessage({
                    defaultMessage: 'Неверный код, осталось',
                })} ${leftAttempts} ${intl.formatMessage({ defaultMessage: 'попыток' })}`;
            } else if (leftAttempts === 0) {
                return intl.formatMessage({ defaultMessage: 'Неверный код, запросите новый код' });
            }
        };

        return (
            <Wrapper className={className}>
                <PlaceholderWrapper>
                    {placeholder.split('').map((sign, idx) => {
                        const isInValue = idx < value.length;
                        const cellValue = isInValue ? value[idx] : sign;

                        return (
                            <InputCodePlaceholderSign
                                // eslint-disable-next-line react/no-array-index-key
                                key={idx}
                                disabled={isDisabled}
                                hasError={hasError || timeIsUp}
                                isActive={isInValue}
                            >
                                {cellValue}
                            </InputCodePlaceholderSign>
                        );
                    })}
                </PlaceholderWrapper>
                <InputField
                    {...inputProps}
                    ref={ref}
                    value={value}
                    autoComplete="off"
                    disabled={isDisabled}
                    maxLength={placeholder?.length}
                />

                {(hasError || timeIsUp) && <Caption>{getCaptionText()}</Caption>}
            </Wrapper>
        );
    },
);

export default InputCode;

const getPlaceholderColor = (theme: DefaultTheme, isActive: boolean, disabled: boolean) => {
    if (disabled) return theme.colors.grey(COLOR_BRIGHTNESS.LIGHT);
    if (isActive) return theme.colors.base();
    return theme.colors.base(COLOR_BRIGHTNESS.LIGHT);
};

const getPlaceholderBorderColor = (theme: DefaultTheme, hasError: boolean, disabled: boolean) => {
    if (disabled) return theme.colors.grey(COLOR_BRIGHTNESS.LIGHT);
    if (hasError) return theme.colors.red();
    return theme.colors.base(COLOR_BRIGHTNESS.LIGHT);
};

const Wrapper = styled.div`
    position: relative;
    margin: 8px auto 0 auto;
`;

const PlaceholderWrapper = styled.div`
    display: flex;
    pointer-events: none;
`;

// eslint-disable-next-line no-redeclare
const InputCodePlaceholderSign = styled.div<InputCodePlaceholderSign>`
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
    width: 48px;
    height: 55px;
    font-size: 28px;
    line-height: 22px;
    flex: 0 0 48px;
    color: ${({ theme, isActive, disabled }) => getPlaceholderColor(theme, isActive, disabled)};
    border-bottom: 1px solid ${({ theme, disabled, hasError }) => getPlaceholderBorderColor(theme, hasError, disabled)};

    &:not(:last-child) {
        margin-right: 13px;
    }

    @media ${({ theme }) => theme.breakpoints.sm} {
        width: 42px;
        font-size: 24px;
        flex: 0 0 42px;
    }
`;

const InputField = styled.input`
    position: absolute;
    top: 0;
    left: 0;
    padding: 0;
    font-size: 28px;
    line-height: 53px;
    letter-spacing: 45px;
    text-indent: 16px;
    z-index: 1;
    color: transparent;
    background-color: transparent;
    width: 100%;
    caret-color: ${({ theme }) => theme.colors.base()};
    outline: none;

    &::selection {
        background: transparent;
    }

    &::-moz-selection {
        background: transparent;
    }

    @media ${({ theme }) => theme.breakpoints.sm} {
        letter-spacing: 39px;
        text-indent: 13px;
    }
`;

const Caption = styled(Typography).attrs({ variant: 'regular12', center: true })`
    margin-top: 15px;
    color: ${({ theme }) => theme.colors.red()};
`;
