import React from 'react';
import { SkeletonItemValue, SkeletonRefundItemWrapper } from '@pages/Refund/components/SkeletonRefundItem/styles';

const SkeletonRefundItem = () => (
    <SkeletonRefundItemWrapper>
        <SkeletonItemValue />
        <SkeletonItemValue />
        <SkeletonItemValue />
    </SkeletonRefundItemWrapper>
);

export default SkeletonRefundItem;
