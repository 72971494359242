import React, { FC, ReactNode, useState } from 'react';
import styled, { css } from 'styled-components';

import { COLOR_BRIGHTNESS } from '@theme/utils';
import Typography from '@components/Typography';
import CircleIcon from '@components/CircleIcon';
import useClickOutside from '@hooks/useClickOutside';
import { ICounterparty } from '@store/Counterparty/types';
import { Nullable } from '@utils/utilityTypes';
import WorkIcon from '@assets/icons/work.svg';
import ArrowIcon from './assets/arrow-down.svg';

export interface INavDropdownItem {
    id: Nullable<string>;
    title: string;
    icon: ReactNode;
}

export interface INavDropdownProps {
    items: Array<INavDropdownItem>;
    activeItemId: Nullable<string>;
    onChange: (id: Nullable<string>) => void;
}

interface IDropdownItemProps {
    isActive?: boolean;
    isOpen?: boolean;
}

interface IArrowProps {
    isOpen: boolean;
}

export const counterpartyToNavDropdownItem = (counterparty: ICounterparty) => ({
    title: counterparty.name,
    id: counterparty.id,
    icon: <WorkIcon />,
});

const NavDropdown: FC<INavDropdownProps> = ({ items, activeItemId, onChange }) => {
    const [isOpen, setIsOpen] = useState(false);

    const close = () => setIsOpen(false);
    const toggle = () => setIsOpen((v) => !v);

    const currentItem = items.find((item) => item?.id === activeItemId) as INavDropdownItem;
    const otherItems = items.filter((item) => item?.id !== activeItemId) as Array<INavDropdownItem>;

    const listItemsRef = useClickOutside(close);

    const itemClickHandler = ({ id }: INavDropdownItem) => {
        onChange(id);
        close();
    };

    return (
        <Wrapper ref={listItemsRef}>
            <DropdownTriggerItem isOpen={isOpen} onClick={toggle}>
                <Icon isActive icon={currentItem?.icon} />
                <Title>{currentItem?.title}</Title>

                {otherItems.length > 0 && (
                    <ArrowWrapper isOpen={isOpen}>
                        <ArrowIcon />
                    </ArrowWrapper>
                )}
            </DropdownTriggerItem>

            {isOpen && otherItems.length > 0 && (
                <ListItems>
                    {/* the current element will always be the first */}
                    {[currentItem, ...otherItems].map((item) => (
                        <DropdownItem key={item.id} onClick={() => itemClickHandler(item)}>
                            <Icon icon={item.icon} />
                            <Title>{item.title}</Title>
                        </DropdownItem>
                    ))}
                </ListItems>
            )}
        </Wrapper>
    );
};

export default NavDropdown;

const Wrapper = styled.div`
    width: 100%;
    position: relative;
    z-index: 10;
`;

const DropdownItem = styled.button<IDropdownItemProps>`
    ${({ theme }) => theme.mixins.dropdownItem};
    background-color: ${({ theme }) => theme.colors.white()};
    cursor: pointer;
    transition: ${({ theme }) => theme.decorations.transition.base};

    &:hover {
        background-color: ${({ theme }) => theme.colors.lightGrey(COLOR_BRIGHTNESS.MEDIUM)};
    }

    &:not(:last-child) {
        border-bottom: 1px solid ${({ theme }) => theme.colors.lightGrey(COLOR_BRIGHTNESS.MEDIUM)};
    }

    &:first-child {
        & > * {
            opacity: 0;
        }
    }
`;

const DropdownTriggerItem = styled.button<IDropdownItemProps>`
    ${({ theme }) => theme.mixins.dropdownItem};
    z-index: 2;
    position: relative;
    background-color: ${({ theme }) => theme.colors.lightGrey(COLOR_BRIGHTNESS.MEDIUM)};
`;

const Icon = styled(CircleIcon)`
    @media ${({ theme }) => theme.breakpoints.ld} {
        width: 30px;
        min-width: 30px;
        height: 30px;
        svg {
            width: 16.7px;
            height: 16.7px;
        }
    }
`;

const Title = styled(Typography).attrs({ variant: 'subheading' })`
    margin-left: 20px;
    margin-right: auto;
    padding-right: 10px;
    ${({ theme }) => theme.mixins.textOverflowDots};

    @media ${({ theme }) => theme.breakpoints.ld} {
        margin-left: 10px;
        ${({ theme }) => theme.mixins.getTypography('regular14')}
    }
`;

const ArrowWrapper = styled.div<IArrowProps>`
    display: flex;
    pointer-events: none;
    transform: rotate(0);
    transition: ${({ theme }) => theme.decorations.transition.base};
    ${({ isOpen }) =>
        isOpen &&
        css`
            transform: rotate(180deg);
        `};
`;

const ListItems = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    overflow: hidden;
    border-bottom-left-radius: ${({ theme }) => theme.decorations.borderRadius.base}px;
    border-bottom-right-radius: ${({ theme }) => theme.decorations.borderRadius.base}px;
    box-shadow: ${({ theme }) => theme.decorations.boxShadow.secondary};
    background-color: ${({ theme }) => theme.colors.white()};
`;
