import React, { FC } from 'react';
import { LoadingItem } from '../styles';

const SKELETONS_COUNT = 3;

const LoadingIndicator: FC = () => (
    <>
        {Array(SKELETONS_COUNT)
            .fill('')
            .map((_, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <div key={index}>
                    <LoadingItem />
                </div>
            ))}
    </>
);

export default LoadingIndicator;
