import React, { FC, InputHTMLAttributes } from 'react';
import useHover from '@hooks/useHover';
import { Label, LabelText, StyledInput, Wrapper } from './styles';

export interface IToggleProps extends InputHTMLAttributes<HTMLInputElement> {
    leftLabel?: string;
    rightLabel?: string;
    className?: string;
}

export const Toggle: FC<IToggleProps> = ({ className, leftLabel, rightLabel, checked = false, ...inputProps }) => {
    const { isHovered, hoverProps } = useHover({ disabled: inputProps.disabled });

    const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (inputProps.onChange) {
            inputProps.onChange(e);
        }
    };

    return (
        <Wrapper className={className} {...hoverProps}>
            <Label>
                {leftLabel && (
                    <LabelText disabled={inputProps.disabled} position="left" isActive={checked}>
                        {leftLabel}
                    </LabelText>
                )}
                <StyledInput
                    type="checkbox"
                    checked={checked}
                    isHovered={isHovered}
                    {...inputProps}
                    onChange={onChangeHandler}
                />
                {rightLabel && (
                    <LabelText position="right" disabled={inputProps.disabled} isActive={checked}>
                        {rightLabel}
                    </LabelText>
                )}
            </Label>
        </Wrapper>
    );
};
