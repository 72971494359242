import React, { FC } from 'react';
import {
    ChapterTitle,
    CompositionOrder,
    Description,
    GeneralData,
    GeneralFields,
    ModalContent,
    ModalHeader,
    ModalOrderDetailWrapper,
    OrderBasketItems,
    OrderIcon,
    OrderStatus,
    OrderTitle,
    WrapperIcon,
    WrapStatusIcon,
} from '@pages/Shop/ShopTabsTables/RefundDetail/components/ModalOrderDetail/styles';
import { ORDER_FIELDS, TOrder } from '@store/Order/types';
import { getIconByStatusAndColor } from '@components/Widgets/helpers';
import colors from '@theme/colors';
import { processableOrderStatuses } from '@store/Order/constants';
import ColumnField from '@pages/Shop/ShopTabsTables/RefundDetail/components/ColumnField';
import { convertDateAndTimeToShow } from '@utils/helpers';
import OrderItem from '@pages/Shop/ShopTabsTables/RefundDetail/components/OrderItem';
import { useIntl } from 'react-intl';

interface IModalOrderDetail {
    onClose: () => void;
    isOpen: boolean;
    order: TOrder;
}

const ModalOrderDetail: FC<IModalOrderDetail> = ({ order, onClose, isOpen }) => {
    const intl = useIntl();
    const { icon, color } = getIconByStatusAndColor(order.status, colors);

    const orderFields = [
        {
            label: intl.formatMessage({ defaultMessage: 'Номер счёта' }),
            value: order.merchantOrderId,
        },
        {
            label: intl.formatMessage({ defaultMessage: 'Срок оплаты' }),
            value: convertDateAndTimeToShow(order[ORDER_FIELDS.expirationDate]),
        },
    ];

    return (
        <ModalOrderDetailWrapper closeModal={onClose} isOpen={isOpen}>
            <ModalHeader>
                <OrderTitle>
                    {intl.formatMessage({ defaultMessage: 'Заказ' })} {order.merchantOrderId}
                </OrderTitle>
                <OrderStatus color={color}>
                    <WrapperIcon>
                        <OrderIcon />
                        {icon && <WrapStatusIcon>{icon}</WrapStatusIcon>}
                    </WrapperIcon>
                    {processableOrderStatuses[order.status]}
                </OrderStatus>
            </ModalHeader>
            <ModalContent>
                <GeneralData>
                    <ChapterTitle>{intl.formatMessage({ defaultMessage: 'Общие сведения' })}</ChapterTitle>
                    <GeneralFields>
                        {orderFields.map((orderField) => (
                            <ColumnField key={orderField.label} label={orderField.label} value={orderField.value} />
                        ))}
                        <Description
                            label={intl.formatMessage({ defaultMessage: 'Комментарий' })}
                            value={order.description}
                        />
                    </GeneralFields>
                </GeneralData>
                <CompositionOrder>
                    <ChapterTitle>{intl.formatMessage({ defaultMessage: 'Состав заказа' })}</ChapterTitle>
                    <OrderBasketItems>
                        {order.basketItems.map((basketItem) => (
                            <OrderItem key={basketItem.sku} orderItem={basketItem} />
                        ))}
                    </OrderBasketItems>
                </CompositionOrder>
            </ModalContent>
        </ModalOrderDetailWrapper>
    );
};

export default ModalOrderDetail;
