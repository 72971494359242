import React, { FC } from 'react';
import Button from '@components/Button';
import ArrowIcon from '@assets/icons/arrow-right.svg';
import OrderPaymentList from '@components/Widgets/OrderPaymentWidget/components/OrderPaymentList';
import { useSelector } from 'react-redux';
import { getCurrentCounterpartyId } from '@store/Counterparty/selectors';
import { Routes } from '@pages/constants';
import { useIntl } from 'react-intl';
import { Container, Heading, WrapTitle, Title, MoreBtn } from './styles';

const OrderPaymentWidget: FC = () => {
    const intl = useIntl();
    const selectedCounterpartyId = useSelector(getCurrentCounterpartyId) || null;

    return (
        <Container>
            <Heading>
                <WrapTitle>
                    <Title>{intl.formatMessage({ defaultMessage: 'Входящие: ожидает оплаты' })}</Title>
                    <Button type="link" icon={<ArrowIcon />} />
                </WrapTitle>
                <MoreBtn to={`${Routes.payment}/${selectedCounterpartyId || 'user'}`}>
                    {intl.formatMessage({ defaultMessage: 'Все' })}
                </MoreBtn>
            </Heading>
            <OrderPaymentList />
        </Container>
    );
};

export default OrderPaymentWidget;
