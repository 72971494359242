import { createContext, Dispatch, SetStateAction } from 'react';

// eslint-disable-next-line no-shadow
export enum AuthStepType {
    enterPhoneForm,
    enterCodeForm,
}

export interface ILoginContextProps {
    step: AuthStepType;
    phone: string;
    setPhone: Dispatch<SetStateAction<string>>;
    toNextStep: () => void;
    toPrevStep: () => void;
}

export const LoginContext = createContext<ILoginContextProps>({} as ILoginContextProps);
