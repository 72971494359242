import styled from 'styled-components';

export const IconWrapper = styled.button`
    ${({ theme }) => theme.mixins.flexCenter}
`;

export const NotificationWrapper = styled.div`
    width: 50px;
    ${({ theme }) => theme.mixins.flexCenterCenter};
    &:hover {
        ${IconWrapper} {
            path {
                stroke: ${({ theme }) => theme.colors.yellow()};
            }
        }
    }
    position: relative;
    z-index: 10;
`;
