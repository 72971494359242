import { createIntl, createIntlCache } from 'react-intl';
import { ProcessableOrderStatuses, UnprocessableOrderStatuses } from './types';
import ruMessages from '../../i18/ru';

const cache = createIntlCache();
const intl = createIntl({ locale: 'ru', messages: ruMessages }, cache);

export const unprocessableOrderStatuses = {
    [UnprocessableOrderStatuses.created]: intl.formatMessage({ defaultMessage: 'Создан' }),
    [UnprocessableOrderStatuses.completed]: intl.formatMessage({ defaultMessage: 'Оплачен' }),
    [UnprocessableOrderStatuses.expired]: intl.formatMessage({ defaultMessage: 'Просрочен' }),
    [UnprocessableOrderStatuses.canceled]: intl.formatMessage({ defaultMessage: 'Отменён' }),
};

export const processableOrderStatuses = {
    ...unprocessableOrderStatuses,
    [ProcessableOrderStatuses.processing]: intl.formatMessage({ defaultMessage: 'Обрабатывается' }),
    [ProcessableOrderStatuses.processingError]: intl.formatMessage({ defaultMessage: 'Ошибка' }),
};

const ORDER_GENERAL_STATUSES = [
    {
        [UnprocessableOrderStatuses.completed]: intl.formatMessage({ defaultMessage: 'Оплачен' }),
        label: intl.formatMessage({ defaultMessage: 'Оплачен' }),
        state: 'completed',
    },
    {
        [UnprocessableOrderStatuses.expired]: intl.formatMessage({ defaultMessage: 'Просрочен' }),
        label: intl.formatMessage({ defaultMessage: 'Просрочен' }),
        state: 'expired',
    },
    {
        [UnprocessableOrderStatuses.canceled]: intl.formatMessage({ defaultMessage: 'Отменён' }),
        label: intl.formatMessage({ defaultMessage: 'Отменён' }),
        state: 'canceled',
    },
];

export const ORDER_STATUSES = [
    ...ORDER_GENERAL_STATUSES,
    {
        [UnprocessableOrderStatuses.created]: intl.formatMessage({ defaultMessage: 'Создан' }),
        label: intl.formatMessage({ defaultMessage: 'Создан' }),
        state: 'created',
    },
];
export const ALL_STATUSES = { label: intl.formatMessage({ defaultMessage: 'Все' }), id: 1 };

export const ORDER_CONTAINER_STATUSES = [
    {
        [UnprocessableOrderStatuses.created]: intl.formatMessage({ defaultMessage: 'Создан' }),
        label: intl.formatMessage({ defaultMessage: 'Создан' }),
        state: 'pending',
    },
    ...ORDER_GENERAL_STATUSES,
];
