import React, { FC } from 'react';
import RowLabel from '@components/RowLabel';
import GeneralForm from '@pages/Shop/ShopTabsTables/Settings/components/GeneralForm/index';
import { FORM_MERCHANT_GENERAL_INFORMATION } from '@pages/Shop/ShopTabsTables/Settings';
import { IMcc, IMerchant } from '@store/Merchant/types';
import { MERCHANT_FIELDS } from '@store/Merchant/contants';
import { convertPhoneToShow } from '@utils/formatters';
import useDictionary from '@hooks/useDictionarty';
import { DICTIONARY_TYPES } from '@store/Dictionaries/constants';
import { useIntl } from 'react-intl';
import { GeneralInformationSettings } from '../../styles';

interface IGeneralInformation {
    isEditMode?: boolean;
    formId?: string;
    setIsEditSettings?: (arg: boolean) => void;
    merchant: IMerchant;
    toggleSaving?: () => void;
}

const GeneralInformation: FC<IGeneralInformation> = ({
    isEditMode = false,
    formId = FORM_MERCHANT_GENERAL_INFORMATION,
    setIsEditSettings = () => null,
    merchant,
    toggleSaving = () => null,
}) => {
    const intl = useIntl();
    const mccList: IMcc[] = useDictionary(DICTIONARY_TYPES.mcc)?.data as any;
    const merchantMccId = merchant?.[MERCHANT_FIELDS.mccId];

    const generalValuesMerchant = [
        {
            label: intl.formatMessage({ defaultMessage: 'Наименование' }),
            value: merchant?.[MERCHANT_FIELDS.name],
        },
        {
            label: intl.formatMessage({ defaultMessage: 'Описание' }),
            value: merchant?.[MERCHANT_FIELDS.description],
        },
        {
            label: intl.formatMessage({ defaultMessage: 'Адрес сайта' }),
            value: merchant?.[MERCHANT_FIELDS.website],
        },
        {
            label: intl.formatMessage({ defaultMessage: 'Телефон' }),
            value: merchant?.[MERCHANT_FIELDS.phone] && convertPhoneToShow(merchant[MERCHANT_FIELDS.phone]),
        },
        {
            label: 'Email',
            value: merchant?.[MERCHANT_FIELDS.email],
        },
        {
            label: intl.formatMessage({ defaultMessage: 'Категория' }),
            value: mccList?.find((category) => category.id === merchantMccId)?.name || '',
        },
    ];

    const isShowSkeleton = !merchant?.[MERCHANT_FIELDS.name];

    const renderContentGeneralInformation = (isEdit: boolean) => {
        if (isEdit) {
            return (
                <GeneralForm
                    mccList={mccList}
                    formId={formId}
                    setIsEditSettings={setIsEditSettings}
                    toggleSaving={toggleSaving}
                    merchant={merchant}
                />
            );
        }
        return generalValuesMerchant.map((merchantField) => (
            <RowLabel
                isShowSkeleton={isShowSkeleton}
                label={merchantField.label}
                value={merchantField.value}
                key={merchantField.label}
            />
        ));
    };

    return (
        <GeneralInformationSettings isEdit={isEditMode}>
            {renderContentGeneralInformation(isEditMode)}
        </GeneralInformationSettings>
    );
};

export default GeneralInformation;
