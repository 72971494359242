import React, { FC } from 'react';
import { useSWR } from '@hooks/useSWR';
import Select, { ISelectOption, ISelectProps } from '../Select';

export interface IExternalSelectProps extends Omit<ISelectProps, 'options'> {
    dataUrl?: string;
    transformResponse?: (data: any) => ISelectOption;
}

const ExternalSelect: FC<IExternalSelectProps> = ({
    dataUrl,
    transformResponse = (items) => items,
    ...selectProps
}) => {
    if (!dataUrl) throw new Error('Props "dataUrl" is required for ExternalSelect');

    const { data } = useSWR<any>(dataUrl);
    const options = data?.data.map((item: any) => transformResponse(item));
    if (options) {
        return <Select {...selectProps} options={options} />;
    }
    return null;
};

export default React.memo(ExternalSelect);
