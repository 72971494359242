import { documentAdapter } from './index';
import { RootState } from '../index';

const { selectById, selectAll } = documentAdapter.getSelectors((state: RootState) => state.document);

export const getCurrentDocumentId = (state: RootState) => state.document.selectedId;
export const getCurrentDocument = (state: RootState) => {
    const selectedId = getCurrentDocumentId(state);
    return selectedId ? selectById(state, selectedId) : undefined;
};
export const getDocumentsList = (state: RootState) => selectAll(state);
export const getUploadDocumentByContractId = (contractId: number) => (state: RootState) =>
    getDocumentsList(state).find(
        (document) =>
            document.setting.merchantContractId === contractId && document.type === 'merchant-contract-package-upload',
    );
