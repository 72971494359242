import React from 'react';
import { BankDetailWrapper } from '@components/Widgets/ClaimModeratingWidget/components/BankDetail/styles';
import { useSelector } from 'react-redux';
import { getSelectedContract } from '@store/Contracts/selectors';
import { getBankDetailById } from '@store/Counterparty/selectors';
import { IContract } from '@store/Contracts/types';
import ButtonBankCard from '@pages/Claim/components/ButtonBankCard';
import { ICounterpartyBankDetail } from '@store/Counterparty/types';
import { BANK_DETAILS_FIELDS } from '@store/Counterparty/contants';

const BankDetail = () => {
    const selectedContract: IContract = useSelector(getSelectedContract) as any;
    const selectedBankDetail: ICounterpartyBankDetail = useSelector(
        getBankDetailById(selectedContract?.bankDetailId),
    ) as any;

    return (
        <BankDetailWrapper>
            {selectedBankDetail && (
                <ButtonBankCard selectedBankId={selectedBankDetail[BANK_DETAILS_FIELDS.id]} bank={selectedBankDetail} />
            )}
        </BankDetailWrapper>
    );
};

export default BankDetail;
