import { IntlShape } from 'react-intl';

// eslint-disable-next-line no-shadow
export enum enumDynamicFieldNames {
    notificationUrl = 'notificationUrl',
    url = 'url',
    roundRobinSupport = 'roundRobinSupport',
    bearerAuthToken = 'bearerAuthToken',
    basicAuthUsername = 'basicAuthUsername',
    basicAuthPassword = 'basicAuthPassword',
    signMethod = 'signMethod',
    signKey = 'signKey',
    signAlgorithm = 'signAlgorithm',
    connectionTimeout = 'connectionTimeout',
    connectionRetryPeriod = 'connectionRetryPeriod',
    connectionRetryCount = 'connectionRetryCount',
}

export const getDynamicFieldsLabel = (fieldsName: enumDynamicFieldNames, intl: IntlShape) => {
    const dynamicFieldsLabel = {
        [enumDynamicFieldNames.notificationUrl]: intl.formatMessage({ defaultMessage: 'URL нотификации' }),
        [enumDynamicFieldNames.url]: 'URL',
        [enumDynamicFieldNames.roundRobinSupport]: intl.formatMessage({ defaultMessage: 'Round Robin поддержка' }),
        [enumDynamicFieldNames.bearerAuthToken]: intl.formatMessage({ defaultMessage: 'Bearer авторизационный токен' }),
        [enumDynamicFieldNames.basicAuthUsername]: intl.formatMessage({
            defaultMessage: 'Имя пользователя для авторизации',
        }),
        [enumDynamicFieldNames.basicAuthPassword]: intl.formatMessage({ defaultMessage: 'Пароль для авторизации' }),
        [enumDynamicFieldNames.signMethod]: intl.formatMessage({ defaultMessage: 'Метод входа' }),
        [enumDynamicFieldNames.signKey]: intl.formatMessage({ defaultMessage: 'Ключ входа' }),
        [enumDynamicFieldNames.signAlgorithm]: intl.formatMessage({ defaultMessage: 'Алгоритм входа' }),
        [enumDynamicFieldNames.connectionTimeout]: intl.formatMessage({ defaultMessage: 'Интервал подключения' }),
        [enumDynamicFieldNames.connectionRetryPeriod]: intl.formatMessage({
            defaultMessage: 'Интервал повтора подключения',
        }),
        [enumDynamicFieldNames.connectionRetryCount]: intl.formatMessage({
            defaultMessage: 'Количество попыток повторного подключению',
        }),
    };
    return dynamicFieldsLabel[fieldsName] || fieldsName;
};
