import yup from '@utils/validations';
import { enumsReasons } from '@pages/Refund/components/ReasonRefund';
import { ORDER_FIELDS } from '@store/Order/types';
import { REFUND_FIELD } from '@store/OrderRefunds/types';

export const createRefundSchema = yup.object().shape({
    [REFUND_FIELD.reasonId]: yup.string().required(),
    [ORDER_FIELDS.description]: yup.string().when(REFUND_FIELD.reasonId, {
        is: (idReason: string) => idReason === enumsReasons.other,
        then: yup.string().min(10).required(),
        otherwise: yup.string(),
    }),
});
