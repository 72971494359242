import http, { ICommonResponse } from '@utils/http';
import {
    TCreateSecurityApiUserGroup,
    TSecurityApiUserGroup,
    TSecurityApiUserGroupFilter,
} from '@store/SecurityApiUseGroup/types';

export const fetchSecurityApiUserGroupListRequest = (params: TSecurityApiUserGroupFilter) =>
    http.get<ICommonResponse<Array<TSecurityApiUserGroup>>>('security/api/api-user-group', {
        params,
    });

export const createSecurityApiUserGroupRequest = (params: TCreateSecurityApiUserGroup) =>
    http.post<ICommonResponse<TSecurityApiUserGroup>>('security/api/api-user-group/application', params);

export const codeActivateSecurityApiUserGroupRequest = (id: number, activationExpiredAt: string) =>
    http.post<ICommonResponse<TSecurityApiUserGroup>>(`security/api/api-user-group/${id}/code`, {
        activationExpiredAt,
    });
