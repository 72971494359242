import { createSlice } from '@reduxjs/toolkit';
import { find, uniq } from 'lodash';
import createAsyncThunkWithErrorHandler from '@utils/helpers/createAsyncThunkWithErrorHandler';
import { ICommonListRequest } from '@utils/http';
import { IRefundOrder, TCommonListThunkResponse } from './types';
import { createRefundRequest, fetchOrderRefundsListRequest } from './http';
import { TOrder } from '../Order/types';
import { setOrders } from '../Order';
import { orderRefundsAdapter, initialState } from './constants';

export const fetchOrderRefundsList = createAsyncThunkWithErrorHandler<
    TCommonListThunkResponse<IRefundOrder>,
    ICommonListRequest<IRefundOrder>
>('orderRefunds/fetchList', async (params, thunkAPI) => {
    const {
        data: { data, extendedData, metaData },
    } = await fetchOrderRefundsListRequest({ ...params, _extend: ['order'] });
    const orders = find(extendedData, { entity: 'order' })?.data as TOrder[];
    thunkAPI.dispatch(setOrders(orders));
    thunkAPI.dispatch(setOrderIds(uniq(orders.map(({ id }) => id))));
    return { data, metaData };
});

export const createRefund = createAsyncThunkWithErrorHandler<IRefundOrder, IRefundOrder>(
    'orderRefunds/create',
    async (params) => {
        const {
            data: { data },
        } = await createRefundRequest(params);
        return data;
    },
);

const OrderRefunds = createSlice({
    name: 'orderRefunds',
    initialState,
    reducers: {
        setOrderIds(state, { payload }) {
            state.orderIds = payload;
        },
        upsertOrderIds(state, { payload }) {
            state.orderIds = [...state.orderIds, ...payload];
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchOrderRefundsList.pending, (state) => {
            orderRefundsAdapter.removeAll(state);
            state.isLoading = true;
        });
        builder.addCase(fetchOrderRefundsList.fulfilled, (state, { payload: { metaData, data } }) => {
            state.isLoading = false;
            state.metaData = metaData;
            orderRefundsAdapter.setAll(state, data);
        });
        builder.addCase(createRefund.fulfilled, (state, { payload }) => {
            orderRefundsAdapter.addOne(state, payload);
        });
    },
});

const { setOrderIds } = OrderRefunds.actions;
export default OrderRefunds.reducer;
