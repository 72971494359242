import React, { FC, useState } from 'react';

import useClickOutside from '@hooks/useClickOutside';
import RuFlag from '../assets/ru.svg';
import UsFlag from '../assets/us.svg';
import {
    DrawerLanguageActive,
    DrawerLanguageItem,
    DrawerLanguageItemLabel,
    DrawerLanguageList,
    DrawerLanguageTrigger,
    DrawerLanguageWrapper,
    LanguageDropdownIcon,
} from '../styles';

const LANGUAGES_LIST = [
    { label: 'Ru', icon: <RuFlag /> },
    { label: 'En', icon: <UsFlag /> },
];

const DrawerLanguageDropdown: FC = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [activeLanguage, setActiveLanguage] = useState('Ru');

    const open = () => setIsOpen(true);
    const close = () => setIsOpen(false);

    const wrapperRef = useClickOutside(close);

    const changeLanguage = (language: string) => {
        setActiveLanguage(language);
        close();
    };

    return (
        <DrawerLanguageWrapper ref={wrapperRef}>
            <DrawerLanguageTrigger onClick={open}>
                {activeLanguage}
                <LanguageDropdownIcon />
            </DrawerLanguageTrigger>

            {isOpen && (
                <DrawerLanguageList>
                    {LANGUAGES_LIST.map(({ label, icon }, index) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <DrawerLanguageItem onClick={() => changeLanguage(label)} key={index}>
                            {icon}
                            <DrawerLanguageItemLabel isActive={activeLanguage === label}>
                                {label}
                            </DrawerLanguageItemLabel>
                        </DrawerLanguageItem>
                    ))}
                    <DrawerLanguageActive onClick={close}>
                        {activeLanguage}
                        <LanguageDropdownIcon />
                    </DrawerLanguageActive>
                </DrawerLanguageList>
            )}
        </DrawerLanguageWrapper>
    );
};

export default DrawerLanguageDropdown;
