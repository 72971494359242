import { FEED_TYPES } from '@store/Feed/types';

export const COUNT_FEEDS_TO_FEEDS_PAGE = 20;
export const COUNT_ITEMS_TO_DASHBOARD_PAGE = 10;

export const typesFeedOpenOrderContainer = [
    FEED_TYPES.orderContainerCreated,
    FEED_TYPES.orderContainerExpired,
    FEED_TYPES.orderCanceled,
];
