import React, { FC, useContext } from 'react';

import Button from '@components/Button';
import { useMobile } from '@hooks/useMedia';
import ArrowRightIcon from '@assets/icons/arrow-right.svg';
import { Helmet } from 'react-helmet-async';
import { useIntl } from 'react-intl';
import { IntroduceContext, ModalTypes } from '../../context';
import { ButtonsContainer, Description, IntroduceInner, Title } from '../../styles';

const MerchantTarget: FC = () => {
    const intl = useIntl();
    const { toNextStep, openModal } = useContext(IntroduceContext);
    const isMobile = useMobile();

    const openStoreModal = () => openModal(ModalTypes.company);

    return (
        <IntroduceInner>
            <Helmet>
                <title>{intl.formatMessage({ defaultMessage: 'Информация о магазине' })}</title>
            </Helmet>
            <Title>{intl.formatMessage({ defaultMessage: 'Я хочу продавать товары или услуги' })}</Title>
            <Description>
                {intl.formatMessage({
                    defaultMessage: 'Если вы хотите принимать платежи вы можете добавить информацию о магазине',
                })}
            </Description>
            <ButtonsContainer>
                <Button onClick={openStoreModal}>{intl.formatMessage({ defaultMessage: 'Добавить магазин' })}</Button>
                <Button type="secondary" onClick={toNextStep} icon={isMobile && <ArrowRightIcon />}>
                    {!isMobile && intl.formatMessage({ defaultMessage: 'Пропустить' })}
                </Button>
            </ButtonsContainer>
        </IntroduceInner>
    );
};

export default MerchantTarget;
