import styled from 'styled-components';
import Typography from '@components/Typography';
import orderIcon from '@assets/icons/order.svg';
import { NavLink } from 'react-router-dom';

export const Wrap = styled.div`
    position: relative;
    ${({ theme }) => theme.mixins.flexCenter}
`;

export const PriceDate = styled.div`
    ${({ theme }) => theme.mixins.flexCenter}
    grid-column-gap: 10px;
`;

export const OrderNumber = styled(Typography).attrs({ variant: 'regular14' })`
    ${({ theme }) => theme.mixins.textOverflowDots};
    width: 90%;
`;

export const WrapStatusIcon = styled.div`
    display: flex;
    position: absolute;
    bottom: -2px;
    left: 7px;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors.white()};
`;

export const OrderIcon = styled(orderIcon)``;

export const WrapOrderIcon = styled.div`
    position: relative;
    display: flex;
    margin-right: 11px;
    width: 13px;
    height: 15px;

    @media ${({ theme }) => theme.breakpoints.ld} {
        margin-right: 6px;
    }
`;

export const OrderData = styled(Typography).attrs({ variant: 'regular14' })`
    color: ${({ theme }) => theme.colors.grey()};
`;

export const OrderContainerLink = styled(NavLink)`
    text-decoration: none;
    width: 100%;
    display: grid;
    grid-template-columns: minmax(35%, 60%) auto;
    grid-column-gap: 20px;
    justify-content: space-between;
    padding: 4px 0;
`;
