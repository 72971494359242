import React, { FC, useState } from 'react';
import {
    ArrowOpenWrapper,
    ButtonClaimValues,
    ClaimModeratingWrapper,
    ClaimsValues,
    IconTariffWrapper,
    ValueStatus,
    ValueTariff,
} from '@components/Widgets/ClaimModeratingWidget/styles';
import ClaimColumn from '@components/Widgets/ClaimModeratingWidget/components/ClaimColumn';
import IconTariff from '@assets/icons/tariff.svg';
import ArrowDownIcon from '@assets/icons/arrow-down.svg';
import { IClaim } from '@store/Claims/types';
import { ClaimFields, ClaimStatuses, claimStatusesLabelsMap } from '@store/Claims/constants';
import { useHistory, useParams } from 'react-router-dom';
import { Routes } from '@pages/constants';
import { useSelector } from 'react-redux';
import { getCounterpartyIdByIdMerchant } from '@store/Counterparty/selectors';
import ClaimFieldsReadOnly from '@components/Widgets/ClaimModeratingWidget/components/ClaimFieldsReadOnly';
import { getContractById } from '@store/Contracts/selectors';
import { useIntl } from 'react-intl';

interface IClaimModeratingWidget {
    claim: IClaim;
}

const ClaimModeratingWidget: FC<IClaimModeratingWidget> = ({ claim }) => {
    const intl = useIntl();
    const history = useHistory();

    const [isOpen, setIsOpen] = useState(false);
    const { merchantId } = useParams<{ merchantId: string }>();
    const counterpartyId = useSelector(getCounterpartyIdByIdMerchant(merchantId));
    const contract = useSelector(getContractById(claim[ClaimFields.merchantContractId]));
    const isCanOpened =
        claim[ClaimFields.status] === ClaimStatuses.moderating || claim[ClaimFields.status] === ClaimStatuses.canceled;

    const claimData = [
        {
            label: intl.formatMessage({ defaultMessage: 'Тариф' }),
            value: (
                <ValueTariff>
                    <IconTariffWrapper>
                        <IconTariff />
                    </IconTariffWrapper>
                    {contract?.title}
                </ValueTariff>
            ),
        },
        {
            label: intl.formatMessage({ defaultMessage: 'Статус' }),
            value: <ValueStatus>{claimStatusesLabelsMap[claim[ClaimFields.status]]}</ValueStatus>,
        },
    ];

    const onClickButtonValues = () => {
        if (isCanOpened) {
            setIsOpen(!isOpen);
        } else {
            history.push(`${Routes.claim}/${counterpartyId}/${merchantId}`);
        }
    };

    return (
        <ClaimModeratingWrapper>
            <ButtonClaimValues onClick={onClickButtonValues} type="button" isOpen={isOpen}>
                <ClaimsValues>
                    {claimData.map((claimField) => (
                        <ClaimColumn key={claimField.label} label={claimField.label} value={claimField.value} />
                    ))}
                </ClaimsValues>
                {isCanOpened && (
                    <ArrowOpenWrapper isOpen={isOpen}>
                        <ArrowDownIcon />
                    </ArrowOpenWrapper>
                )}
            </ButtonClaimValues>
            {isOpen && <ClaimFieldsReadOnly claim={claim} />}
        </ClaimModeratingWrapper>
    );
};

export default ClaimModeratingWidget;
