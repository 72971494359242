import React, { FC, useCallback } from 'react';
import DocumentIcon from '@assets/icons/document.svg';
import { IButtonDocument } from '@pages/Claim/components/Documents';
import { useDropzone } from 'react-dropzone';
import { useAppDispatch } from '@store';
import { setFileMerchant } from '@store/Merchant';
import { useSelector } from 'react-redux';
import { getCurrentCounterpartyId } from '@store/Counterparty/selectors';
import { getCurrentClaim } from '@store/Claims/selectors';
import DeleteIcon from '@assets/icons/delete.svg';
import { IMerchantFile } from '@store/Merchant/types';
import {
    DocumentUpload,
    ButtonDocument,
    ButtonRemoveFile,
    DocumentContent,
    DocumentDescription,
    DocumentDropZone,
    DocumentText,
    DocumentTitle,
    IconContainer,
    NameFile,
    Number,
} from './styles';

interface IDocumentCard {
    buttonDocument: IButtonDocument;
    setFile?: (file: IMerchantFile | null) => void;
    fileName?: string;
}

const DocumentCard: FC<IDocumentCard> = ({ fileName, buttonDocument, setFile }) => {
    const dispatch = useAppDispatch();
    const counterpartyId = useSelector(getCurrentCounterpartyId);
    const selectedClaim = useSelector(getCurrentClaim);

    const onDrop = useCallback(
        async (acceptedFiles) => {
            const uploadFile = acceptedFiles[0];
            const formData: FormData = new FormData();
            formData.append('file', uploadFile);
            formData.append('counterpartyId', counterpartyId || '');
            const resultUpload = await dispatch(setFileMerchant(formData));
            if (resultUpload?.payload && selectedClaim && counterpartyId && setFile) {
                setFile(resultUpload.payload);
            }
        },
        [counterpartyId, dispatch, selectedClaim, setFile],
    );

    const { getRootProps, getInputProps } = useDropzone({ onDrop });

    const renderContent = () => (
        <>
            <Number>{buttonDocument.id}</Number>
            <DocumentContent>
                <IconContainer>
                    <DocumentIcon />
                </IconContainer>
                <DocumentText>
                    <DocumentTitle>
                        {buttonDocument.title}
                        {buttonDocument.icon}
                    </DocumentTitle>
                    {buttonDocument.isDropZoneUpload && fileName && (
                        <DocumentUpload>
                            <NameFile>{fileName}</NameFile>
                            <ButtonRemoveFile
                                type="button"
                                onClick={(event) => {
                                    if (setFile) {
                                        setFile(null);
                                    }
                                    event.stopPropagation();
                                }}
                            >
                                <DeleteIcon />
                            </ButtonRemoveFile>
                        </DocumentUpload>
                    )}
                    <DocumentDescription>{buttonDocument.description}</DocumentDescription>
                </DocumentText>
            </DocumentContent>
        </>
    );

    if (buttonDocument.isDropZoneUpload) {
        return (
            <DocumentDropZone {...getRootProps()}>
                <input {...getInputProps()} />
                {renderContent()}
            </DocumentDropZone>
        );
    }

    return (
        <ButtonDocument type="button" onClick={buttonDocument.action}>
            {renderContent()}
        </ButtonDocument>
    );
};

export default DocumentCard;
