import styled from 'styled-components';

export const RefundsWrapper = styled.div`
    box-shadow: ${({ theme }) => theme.decorations.boxShadow?.base};
    border-radius: ${({ theme }) => theme.decorations.borderRadius.base}px;
    padding: 30px;
    @media ${({ theme }) => theme.breakpoints.sm} {
        padding: 0;
    }
`;
